import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(5),
  },
  modalMainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  upperContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
  },
  belowContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
}))
