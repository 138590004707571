import React, { useEffect, useState } from 'react'
import {
  BoxV2,
  ExternalLinkButton,
  LabraToolTipV2,
  TextInputV2,
} from '@labrav/react-components'
import { Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HelpDrawer } from '../../../../../../common/components/HelpDrawerV2/HelpDrawer'
import { useStyles as iamRolesCreationStyles } from './IamRolesCreation.styles'
import { useStyles as commonStyles } from '../CloudSettingsWrapper.styles'
import { useFormikContext } from 'formik'
import {
  AwsCloudSettingsSections,
  AwsCloudSettingsSectionsProps,
} from '../../types'
import { ShowConnectionStatuses } from '../../../../../../flyout/components/FlyOutCloudSettings/FlyOutArnDetails/FlyOutArnDetails'
import {
  faCircleInfo,
  faUpRightFromSquare,
} from '@fortawesome/pro-regular-svg-icons'
import { NextSectionButton } from '../NextSectionButton/NextSectionButton'
import StepStatusIcon from '../StepStatusIcon/StepStatusIcon'
import { TestConnectionStatusType } from '../../../../../../flyout/modules/flyOutOnboarding/reducer'
import { useDispatch } from 'react-redux'
import { coSellTestConnections } from '../../../../../modules/aceApisOnboarding/action'
import { AnyAction } from '@reduxjs/toolkit'
import { usePartnerId } from '../../../../../../common/utils/Hooks/usePartnerData'
import Persona from '../Persona/Persona'
import { IamArnRegex } from '../../../../../../common/utils/constants'

type IamRolesCreationSection = {
  isOnboardingCompleted?: boolean
}

export const IamRolesCreationSection: React.FC<IamRolesCreationSection> = ({
  isOnboardingCompleted,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const classes = iamRolesCreationStyles()
  const commonClasses = commonStyles()
  const formik = useFormikContext<AwsCloudSettingsSectionsProps>()
  const handleReadMore = () => {
    setIsExpanded(!isExpanded)
  }
  const partnerId = usePartnerId()
  const [testConnectionPrdStatus, setTestConnectionPrdStatus] = useState<{
    status: TestConnectionStatusType
    error?: string
  }>({ status: 'standBy' })
  const [testConnectionStgStatus, setTestConnectionStgStatus] = useState<{
    status: TestConnectionStatusType
    error?: string
  }>({ status: 'standBy' })

  const {
    values,
    handleBlur,
    errors,
    handleChange,
    touched,
    setFieldTouched,
    setFieldValue,
  } = formik
  const dispatch = useDispatch()
  const { productionArn, stagingArn } =
    values[AwsCloudSettingsSections.CREATE_IAM_ROLE]

  const cfLink =
    'https://eu-central-1.console.aws.amazon.com/cloudformation/home?region=eu-central-1#/stacks/quickcreate?templateURL=https://oppsync-crossaccount-role-template.s3.us-west-2.amazonaws.com/oppsync_ace_apis.yaml'

  const getCfLink = (cfLink: string, stackName: string, partnerId: string) => {
    const roleName = `APN-ACE-${partnerId.substr(0, 8)}-AccessUser`
    return `${cfLink}&stackName=${stackName}&param_IAMRoleName=${roleName}&param_ExternalId=${partnerId}`
  }

  const validateArn = (
    arn: string,
    awsAccountId: string,
    keyForRequest: string
  ) => {
    let errorMessage = ''

    if (!arn) {
      errorMessage = 'ARN is required.'
    } else if (!arn.match(IamArnRegex)) {
      errorMessage = 'Please provide a valid IAM ARN.'
    } else if (!arn.includes(awsAccountId)) {
      errorMessage = `ARN must include AWS account ID: ${awsAccountId}.`
    }

    if (errorMessage) {
      const isProduction = keyForRequest.includes('productionArn')

      if (isProduction) {
        setTestConnectionPrdStatus({ status: 'failure', error: errorMessage })
        setFieldTouched(
          `${AwsCloudSettingsSections.CREATE_IAM_ROLE}._customProductionArnError`,
          true
        )
        setFieldValue(
          `${AwsCloudSettingsSections.CREATE_IAM_ROLE}._customProductionArnError`,
          'invalid-arn'
        )
      } else {
        setTestConnectionStgStatus({ status: 'failure', error: errorMessage })
        setFieldTouched(
          `${AwsCloudSettingsSections.CREATE_IAM_ROLE}._customStagingArnError`,
          true
        )
        setFieldValue(
          `${AwsCloudSettingsSections.CREATE_IAM_ROLE}._customStagingArnError`,
          'invalid-arn'
        )
      }
      return false
    }

    return true
  }

  const testConnection = async (keyForRequest = 'productionArn') => {
    if (keyForRequest.includes('productionArn')) {
      if (!productionArn) return

      setTestConnectionPrdStatus({ status: 'loading' })
      const data = await dispatch(
        coSellTestConnections(
          partnerId || '',
          productionArn,
          'production'
        ) as unknown as AnyAction
      )
      setTestConnectionPrdStatus({ status: data.status, error: data.error })

      if (data.status === 'failure') {
        setFieldTouched(
          `${AwsCloudSettingsSections.CREATE_IAM_ROLE}._customProductionArnError`,
          true
        )
        setFieldValue(
          `${AwsCloudSettingsSections.CREATE_IAM_ROLE}._customProductionArnError`,
          'invalid-arn'
        )
      } else if (errors.createIamRole?._customProductionArnError) {
        setFieldValue(
          `${AwsCloudSettingsSections.CREATE_IAM_ROLE}._customProductionArnError`,
          null
        )
      }
    }

    if (keyForRequest.includes('stagingArn')) {
      if (!stagingArn) return

      setTestConnectionStgStatus({ status: 'loading' })
      const data = await dispatch(
        coSellTestConnections(
          partnerId || '',
          stagingArn,
          'staging'
        ) as unknown as AnyAction
      )
      setTestConnectionStgStatus({ status: data.status, error: data.error })

      if (data.status === 'failure') {
        setFieldTouched(
          `${AwsCloudSettingsSections.CREATE_IAM_ROLE}._customStagingArnError`,
          true
        )
        setFieldValue(
          `${AwsCloudSettingsSections.CREATE_IAM_ROLE}._customStagingArnError`,
          'invalid-arn'
        )
      } else if (errors.createIamRole?._customStagingArnError) {
        setFieldValue(
          `${AwsCloudSettingsSections.CREATE_IAM_ROLE}._customStagingArnError`,
          null
        )
      }
    }
  }

  const handleBlurRoleArn = (event: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = event?.target?.name
    const arn = event?.target?.value
    const awsAccountId = values.linkAccount?.awsAccountId

    // Run Formik validation first
    handleBlur(event)

    // Validate ARN
    const isValid = validateArn(arn, awsAccountId, fieldName)

    // Only test connection if ARN is valid
    if (isValid) {
      testConnection(fieldName)
    }
  }

  const handleChangeArn = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event)
    if (event?.target?.name.includes('stagingArn')) {
      setTestConnectionStgStatus({ status: 'standBy' })
    } else if (event?.target?.name.includes('productionArn')) {
      setTestConnectionPrdStatus({ status: 'standBy' })
    }
  }

  useEffect(() => {
    if (!isOnboardingCompleted) {
      testConnection('stagingArn')
      testConnection('productionArn')
    }
  }, [isOnboardingCompleted])

  return (
    <BoxV2
      heading="Creating IAM ARN roles"
      subHeading={
        <Typography className={commonClasses.subHeading}>
          Establish the IAM role to enable the Labra Platform to seamlessly
          access co-sell opportunities while upholding the highest standar
          {!isExpanded ? (
            <>
              <span className={commonClasses.readmore} onClick={handleReadMore}>
                ...read more
              </span>
              .
            </>
          ) : (
            <>
              ds of security and data protection. An Admin must be logged into
              the AWS account corresponding to the AWS Account ID entered in
              Step 2 and must have linked the same AWS account to AWS Partner
              Central. Please refer to the Help section for further details.
            </>
          )}
        </Typography>
      }
      actions={
        <>
          <div
            className={commonClasses.personaSection}
            data-testid="persona-section"
          >
            <Persona memberType="admin" />
          </div>
        </>
      }
      headingIcon={
        <>
          <StepStatusIcon
            sectionName={AwsCloudSettingsSections.CREATE_IAM_ROLE}
          />
          {!isOnboardingCompleted && (
            <Typography className={commonClasses.loadingText}>
              STEP 04
            </Typography>
          )}
        </>
      }
      variant={'small'}
      headingVariant={'small'}
      collapsible
    >
      <div data-testid="iam-roles-creation-main-container">
        <div className={classes.buttonField}>
          {partnerId && (
            <div>
              <ExternalLinkButton
                data-testid="launch-cloudformation-stack-button"
                buttonTitle={'Launch Cloudformation Stack'}
                href={getCfLink(cfLink, 'OppsyncIAMRole', partnerId)}
                modalTitleAcceptButton="Open window"
                modalTitleCancleButton="Back"
                modalTitle="You are being re-directed to another window"
                modalIcon={
                  <FontAwesomeIcon
                    icon={faUpRightFromSquare}
                    className={commonClasses.externalLinkIcon}
                  />
                }
                modalContent={
                  <>
                    <div className={commonClasses.modalContentContainer}>
                      <Typography className={commonClasses.modalDescription}>
                        You are being redirected to the AWS Console window to
                        generate the IAM role, enabling the Labra Platform to
                        seamlessly access and share co-sell opportunities with
                        AWS. <br />
                        An AWS Admin must be logged into the AWS account
                        corresponding to the one entered in Step 2. Once the IAM
                        roles are generated, enter the IAM ARN in Step 4.
                      </Typography>
                    </div>
                  </>
                }
              />
            </div>
          )}
        </div>
        <div className={classes.primaryContainer}>
          <div id="left" className={commonClasses.leftContainer}>
            <div className={classes.inputColumn}>
              <div className={commonClasses.inLineInfo}>
                <div className={classes.inputField}>
                  <TextInputV2
                    title={'Enter your ARN for ACE Staging Environment '}
                    placeHolder={''}
                    keyName={'createIamRole.stagingArn'}
                    type="text"
                    onBlur={handleBlurRoleArn}
                    onChange={handleChangeArn}
                    data-testid="staging-arn-input"
                    isReadOnly={false}
                    isRequired={true}
                    value={values.createIamRole.stagingArn || ''}
                    isError={
                      !!(
                        touched?.createIamRole?.stagingArn &&
                        errors?.createIamRole?.stagingArn
                      ) ||
                      !!(
                        touched?.createIamRole?._customStagingArnError &&
                        errors?.createIamRole?._customStagingArnError
                      )
                    }
                    errorText={null}
                  />
                </div>
                <LabraToolTipV2
                  tooltipPlacement="top-start"
                  description="Enter the ARN for the Staging IAM role generated by the above Cloudformation stack."
                >
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className={commonClasses.info}
                  />
                </LabraToolTipV2>
              </div>
              <div className={classes.field} data-testid="test-connection">
                <ShowConnectionStatuses
                  testConnectionStatus={testConnectionStgStatus}
                />
              </div>
            </div>
            <div className={classes.inputColumn}>
              <div className={commonClasses.inLineInfo}>
                <div className={classes.inputField}>
                  <TextInputV2
                    title={'Enter your ARN for ACE Production Environment'}
                    placeHolder={''}
                    keyName={'createIamRole.productionArn'}
                    isRequired={true}
                    isReadOnly={false}
                    type="text"
                    value={values.createIamRole.productionArn || ''}
                    isError={
                      !!(
                        touched?.createIamRole?.productionArn &&
                        errors?.createIamRole?.productionArn
                      ) ||
                      !!(
                        touched?.createIamRole?._customProductionArnError &&
                        errors?.createIamRole?._customProductionArnError
                      )
                    }
                    errorText={null}
                    onChange={handleChangeArn}
                    onBlur={handleBlurRoleArn}
                  />
                </div>
                <LabraToolTipV2
                  tooltipPlacement="top-start"
                  description="Enter the ARN for the Production IAM role generated by the above Cloudformation stack."
                >
                  <FontAwesomeIcon
                    icon={faCircleInfo}
                    className={commonClasses.info}
                  />
                </LabraToolTipV2>
              </div>
              <div className={classes.field} data-testid="test-connection">
                <ShowConnectionStatuses
                  testConnectionStatus={testConnectionPrdStatus}
                />
              </div>
            </div>
          </div>
        </div>
        {!isOnboardingCompleted && (
          <>
            <NextSectionButton
              sectionName={AwsCloudSettingsSections.CREATE_IAM_ROLE}
            />
          </>
        )}
      </div>
    </BoxV2>
  )
}
