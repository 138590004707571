import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  noOptionsTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    pointerEvents: 'none',
    width: '100%',
  },
  noOptionsText: {
    color: theme.palette.labraText.description,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  noOptionsButton: {
    display: 'flex',
    gap: theme.spacing(1),
    marginTop: theme.spacing(2),
    alignItems: 'center',
    pointerEvents: 'auto',
  },
  text: {
    color: theme.palette.labraText.tertiary,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  externalIcon: {
    height: theme.spacing(1.75),
    width: theme.spacing(1.75),
    color: theme.palette.select.light,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  customOption: {
    background: theme.palette.text.white,
    boxShadow: 'none',
    padding: theme.spacing(0.75, 2),
    margin: theme.spacing(-0.75, -2),
    cursor: 'default',
    width: '100%',
    '&:hover': {
      background: theme.palette.text.white,
    },
  },
}))
