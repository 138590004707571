import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  primaryContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  selectField: {
    width: theme.spacing(59.37),
  },
  partnershipSection: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
  },
  choiceBox: {
    width: 'fit-content',
    borderRadius: theme.spacing(0.5),
    border: `1px solid ${theme.palette.textField.light}`,
    padding: theme.spacing(2, 3),
  },
  heading: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    marginTop: theme.spacing(1),
  },
  choiceBoxText: {
    marginLeft: theme.spacing(4),
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: theme.palette.labraText.description,
  },
  selectProgramText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: theme.palette.labraText.description,
  },
  servicePartnerSelect: {
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectProgramList: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(10),
  },
  selectProgramCheckBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectProgramCheckBoxField: {
    display: 'flex',
    flexDirection: 'row',
  },
  errorText: {
    color: theme.palette.labraV2Errors.main,
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    marginBottom: theme.spacing(1),
  },
  helperText: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
  },
  externalLinkIcon: {
    alignContent: 'center',
  },
  refreshIcon: {
    alignSelf: 'center',
    height: theme.spacing(2.5),
    width: theme.spacing(1.75),
    '&.rotating': {
      animation: '$rotate 2s linear infinite',
    },
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  refreshButton: {
    width: 'fit-content',
  },
  refreshSolutionsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
}))
