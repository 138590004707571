import React from 'react'
import DisablePage from '../DisablePage/DisablePage'

const FlyoutBlockPage: React.FC<{ showHeader?: boolean }> = ({
  showHeader = true,
}) => {
  const productInfo = {
    title: 'Your plan does not include Marketplace.',
    features: [
      {
        text: 'Ensure your product meets technical benchmarks and gain comprehensive vendor insights, all while integrating seamlessly with Marketplace APIs.',
      },
      {
        text: 'Effortlessly prepare and list your product directly from your CRM or our UI.',
      },
      {
        text: 'Efficiently manage the entire lifecycle of marketplace transactions.',
      },
      {
        text: "Tailor private offers (CPPOs & MPPOs) to meet buyer's needs with custom negotiated pricing, flexible payment schedules, and terms.",
      },
      {
        text: 'Get notified to many crucial events, including listing updates, new orders, private offer acceptance and many more.',
      },
    ],
    buttonText: 'Contact Labra Team',
    subheading:
      'Navigate Cloud Commerce seamlessly with Labra Marketplace to get the following benefits:',
    link: 'https://www.labra.io/product/flyout/',
  }
  return (
    <div>
      <DisablePage
        productInfo={productInfo}
        showHeader={showHeader}
        header={'Marketplace'}
      />
    </div>
  )
}

export default FlyoutBlockPage
