import { makeStyles, Theme } from '@material-ui/core/styles'
export const useStyles = makeStyles((theme: Theme) => ({
  addButton: {
    display: 'flex',
  },
  img: {
    width: '24px',
    height: '24px',
    objectFit: 'contain',
    paddingTop: '4px',
  },
  description: {
    color: theme.palette.labraText.description,
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    marginBottom: theme.spacing(1.5),
  },
}))
