import { AnyAction } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../store'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import {
  fetchResellerAgreementData,
  getSingleResaleAuthorization,
  syncAWSResellerAgreementDataToDb,
} from '../../api/markeplace'
import { camelize } from 'casing'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import {
  RequestFailureMessage,
  syncReceivedResaleAuthSuccessMessage,
} from '../../../common/utils/messagesContants'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { getErrorMessages } from '../../../common/utils/error'
import { AxiosResponse } from 'axios'
import { errorLogger } from '../../../common/utils/errorLogger'
import {
  ReceivedResellerAuthorization,
  ReceivedResellerAuthorizationState,
} from './reducer'
import { isEmpty } from 'lodash/fp'
import { toUpper } from 'lodash'

export enum ReceivedResellerAuthorizationActionTypes {
  SET_RECEIVED_RESALE_AUTHORIZATIONS_DATA = 'SET_RECEIVED_RESALE_AUTHORIZATIONS_DATA',
  SET_CURRENT_RECEIVED_RESELLER_AUTHORIZATION = 'SET_CURRENT_RECEIVED_RESELLER_AUTHORIZATION',
  CLEAR_CURRENT_RECEIVED_RESELLER_AUTHORIZATION = 'CLEAR_CURRENT_RECEIVED_RESELLER_AUTHORIZATION',
  SET_ACTIVE_RECEIVED_RESALE_AUTHORIZATIONS_DATA = 'SET_ACTIVE_RECEIVED_RESALE_AUTHORIZATIONS_DATA',
  SET_SELECTED_ACTIVE_RECEIVED_RESALE_AUTHORIZATIONS_DATA = 'SET_SELECTED_ACTIVE_RECEIVED_RESALE_AUTHORIZATIONS_DATA',
  CLEAR_ACTIVE_RECEIVED_RESELLER_AUTHORIZATION = 'CLEAR_ACTIVE_RECEIVED_RESELLER_AUTHORIZATION',
}

export const setReceivedResaleAuthorizations = (
  agreements: ReceivedResellerAuthorizationState,
  pageSize: number,
  pageNumber: number
) => ({
  type: ReceivedResellerAuthorizationActionTypes.SET_RECEIVED_RESALE_AUTHORIZATIONS_DATA,
  payload: { agreements, pageSize, pageNumber },
})

export const setActiveReceivedResaleAuthorizations = (
  agreements: ReceivedResellerAuthorization[]
) => ({
  type: ReceivedResellerAuthorizationActionTypes.SET_ACTIVE_RECEIVED_RESALE_AUTHORIZATIONS_DATA,
  payload: { agreements },
})

export const setSelectedActiveReceivedResaleAuthorizations = (
  authorizationId: string
) => ({
  type: ReceivedResellerAuthorizationActionTypes.SET_SELECTED_ACTIVE_RECEIVED_RESALE_AUTHORIZATIONS_DATA,
  payload: { authorizationId },
})

export const transformSingleReceivedResellerAgreement = (
  data: Record<string, unknown>
) => {
  const {
    discount_type,
    received_reseller_agreement_dimensions,
    ...otherItem
  } = data
  return {
    ...otherItem,
    dimensions: received_reseller_agreement_dimensions,
    discount_type: discount_type ? toUpper(discount_type as string) : '',
  }
}

export const transformReceivedResellerAgreements = (
  data: Record<string, unknown>[]
) => {
  return (data || [])?.map((dataItem: Record<string, unknown>) =>
    transformSingleReceivedResellerAgreement(dataItem)
  )
}

export const processReceivedResellerAgreementsForCP = async ({
  partnerId,
  pageSize,
  pageNumber,
  crmOpportunityId,
  status,
  origin,
  input,
  selectedInput,
  sectionName,
  cloudProductUid,
}: {
  partnerId: string
  pageSize?: number
  pageNumber?: number
  crmOpportunityId?: string
  status?: string //comma separated for multiple status selection
  origin?: string
  input?: string
  selectedInput?: string
  sectionName?: string
  cloudProductUid?: string
}) => {
  if (isEmpty(input)) {
    const receivedResaleAuthsInDbResponse = await fetchResellerAgreementData({
      partnerId,
      pageSize,
      pageNumber,
      sortBy: 'created_at',
      sortOrder: 'desc',
      crmOpportunityId,
      status: status || '',
      origin: 'RECEIVED',
      cloudProductUid,
    })
    if (receivedResaleAuthsInDbResponse.data) {
      return receivedResaleAuthsInDbResponse
    } else {
      // do this in logic in BE or handle here for new API trigger ?
      const initiateAwsSyncResponse = await syncAWSResellerAgreementDataToDb(
        partnerId,
        (origin = 'RECEIVED') // update with proper enum once finalized with backend
      )
      return initiateAwsSyncResponse
    }
  } else {
    // placeholder for search functionality
    return { data: null }
  }
}

export const getReceivedResaleAuthorizationsForCP =
  ({
    partnerId,
    pageSize = 10,
    pageNumber = 1,
    crmOpportunityId,
    input,
    selectedInput,
    sectionName,
  }: {
    partnerId: string
    pageSize?: number
    pageNumber?: number
    crmOpportunityId?: string
    input?: string // for search functionality
    selectedInput?: string // for search functionality
    sectionName?: string // for search functionality
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.FETCHING_RECEIVED_RESALE_AUTHS))
    try {
      const { data } = await processReceivedResellerAgreementsForCP({
        partnerId,
        pageSize,
        pageNumber,
        crmOpportunityId,
        input,
        selectedInput,
        sectionName,
      })
      if (!data || data.message) {
        // need to check again in database after sync completes "successfully"
        Error('No data')
      } else {
        data &&
          (await dispatch(
            setReceivedResaleAuthorizations(
              camelize({
                ...data,
                awsResellerAgreements: transformReceivedResellerAgreements(
                  data?.aws_reseller_agreements
                ),
              }),
              pageSize,
              pageNumber
            )
          ))
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.FETCHING_RECEIVED_RESALE_AUTHS))
    }
  }

export const getActiveReceivedResaleAuthorizations =
  ({
    partnerId,
    crmOpportunityId,
    subscriptionId,
  }: {
    partnerId: string
    crmOpportunityId?: string
    subscriptionId?: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.ACTIVE_RESELLER_AUTHORIZATION))
    try {
      const subscriptionDetail = getState().subscriptions.subscriptions?.find(
        item => item?.subscriptionId === subscriptionId
      )
      const cloudProductUid = subscriptionDetail?.awsProductUid
      const { data } = await processReceivedResellerAgreementsForCP({
        partnerId,
        status: 'active',
        crmOpportunityId,
        cloudProductUid,
      })
      if (!data || data.message) {
        // need to check again in database after sync completes "successfully"
        Error('No data')
      } else {
        await dispatch(
          setActiveReceivedResaleAuthorizations(
            camelize(
              transformReceivedResellerAgreements(data?.aws_reseller_agreements)
            )
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.ACTIVE_RESELLER_AUTHORIZATION))
    }
  }

export const setCurrentReceivedResaleAuthorization = (
  data: Record<string, unknown>
) =>
  ({
    type: ReceivedResellerAuthorizationActionTypes.SET_CURRENT_RECEIVED_RESELLER_AUTHORIZATION,
    payload: data,
  } as unknown as AnyAction)

export const clearCurrentReceivedResaleAuthorization = () =>
  ({
    type: ReceivedResellerAuthorizationActionTypes.CLEAR_CURRENT_RECEIVED_RESELLER_AUTHORIZATION,
  } as unknown as AnyAction)

export const clearActiveReceivedResaleAuthorization = () =>
  ({
    type: ReceivedResellerAuthorizationActionTypes.CLEAR_ACTIVE_RECEIVED_RESELLER_AUTHORIZATION,
  } as unknown as AnyAction)

export const getCurrentReceivedResaleAuthorization =
  (
    partnerId: string,
    resaleAuthorizationId: string,
    crmOpportunityId?: string
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(clearCurrentReceivedResaleAuthorization())
    try {
      const { data } = await getSingleResaleAuthorization(
        partnerId,
        resaleAuthorizationId,
        crmOpportunityId
      )
      dispatch(
        setCurrentReceivedResaleAuthorization(
          camelize(transformSingleReceivedResellerAgreement(data))
        )
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const syncReceivedResaleAuthorizations =
  (partnerId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(
      startLoading(LoadingTypes.SYNC_RECEIVED_RESALE_AUTHS_FROM_CLOUD)
    )
    try {
      await syncAWSResellerAgreementDataToDb(
        partnerId,
        'RECEIVED' // update with proper enum once finalized with backend
      )
      await dispatch(
        updateAppAlert({
          message: syncReceivedResaleAuthSuccessMessage,
          messageType: 'SUCCESS',
          autoClose: false,
        })
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(
        stopLoading(LoadingTypes.SYNC_RECEIVED_RESALE_AUTHS_FROM_CLOUD)
      )
    }
  }
