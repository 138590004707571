import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  swapIcon: {
    color: theme.palette.button.primary,
  },
  contentContainer: {
    display: 'flex',
    backgroundColor: theme.palette.note.main,
  },
  contentText: {
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    margin: theme.spacing(1, 1.5),
  },
  descriptionText: {
    color: theme.palette.labraText.description,
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
}))
