import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    gap: theme.spacing(3),
  },
  choiceContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionalText: {
    fontStyle: theme.typography.fontFamily,
    fontSize: '14px',
    color: theme.palette.labraText.description,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
}))
