import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    margin: theme.spacing(4, 5, 16, 5),
  },
  footerContainer: {
    marginLeft: theme.spacing(5),
  },
  loadingText: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    marginLeft: theme.spacing(1),
  },
  successText: {
    color: theme.palette.labraText.tertiary,
  },
  loadingTextContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  loader: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    flexShrink: 0,
    color: theme.palette.labraText.tertiary,
  },
  check: {
    color: theme.palette.textBackground.dark,
  },
  normal: {
    color: theme.palette.labraIcon.main,
  },
  cross: {
    color: theme.palette.error.dark,
  },
  field: {
    textAlign: 'left',
  },
  subHeading: {
    fontStyle: theme.typography.fontFamily,
    fontSize: '14px',
    color: theme.palette.labraText.description,
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    marginTop: theme.spacing(1),
  },
  checkBoxText: {
    fontStyle: theme.typography.fontFamily,
    fontSize: '14px',
    color: theme.palette.labraText.tertiary,
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  inLineInfo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    marginLeft: theme.spacing(1),
    width: theme.spacing(2),
    height: theme.spacing(2),
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: '0.15px',
    color: theme.palette.labraIcon.main,
  },
  readmore: {
    cursor: 'pointer',
    color: theme.palette.select.light,
  },
  nextButton: {
    width: 'fit-content',
  },
  videoContainer: {
    display: 'flex',
    width: theme.spacing(50.87),
    height: theme.spacing(30.5),
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1.25),
    background: theme.palette.dividerV2.main,
  },
  verticalDivider: {
    display: 'flex',
    width: theme.spacing(0.125),
    height: '100%',
    minHeight: theme.spacing(30.5),
    alignItems: 'center',
    gap: theme.spacing(5),
    alignSelf: 'stretch',
    borderRadius: theme.spacing(0.5),
    background: theme.palette.dividerV2.main,
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  buttonSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(5),
  },
  externalLinkIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  modalContentContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  modalDescription: {
    fontSize: '14px',
    color: theme.palette.labraText.description,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  personaSection: {
    marginTop: theme.spacing(0.25),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
    marginRight: theme.spacing(1.25),
  },
}))
