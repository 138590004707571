import { Reducer } from 'redux'
import { CloudType } from '../../../common/modules/types'
import { FlyOutOnboardingChangeType, FlyoutOnboardingForm } from './actions'
import { FlyOutListingUserType } from '../../../admin/modules/flyOutSyncs/reducer'

export enum FlyoutOnboardingSectionName {
  PAGE_MARKER = 'pageMarker',
  LABRA_PARTNER_DETAILS = 'labraPartnerDetails',
  CLOUD_SETTINGS = 'cloudSettings',
  TERMS_AND_CONDITIONS = 'termsAndConditions',
  LOCK_METADATA = 'lockMetadata',
  GENERAL_INFORMATION = 'generalInformation',
  LANDING_PAGE_DETAILS = 'infoCollectionFormMetaData',
}

export enum FlyoutOnboardingPartnershipType {
  ISV = 'ISV',
  CP = 'CP',
  ISV_AND_CP = 'ISV_AND_CP', // this just gets stored in draft json
}

export enum AWSPartnershipType {
  TECHNOLOGY_PARTNER = 'TECHNOLOGY_PARTNER',
  CONSULTING_PARTNER = 'CONSULTING_PARTNER',
}

export interface FlyoutCloudSettings {
  cloudProvider: CloudType
  cloudAccountId: string
  cloudAccountName: string
  cloudRoleArn: string
  cloudCasS3BucketName: string
  cloudCasSnsTopic: string
  cloudKmsKeyArn: string
  connectionTested: boolean
  formFilled: boolean
  formUpdated: boolean
  testConnectionStatus: TestConnectionStatusType
}
export type ListingType = keyof typeof FlyOutListingUserType

export interface TeamMembers {
  admins: string[]
  staff: string[]
}

export interface FlyoutLabraPartnerDetails {
  sellerOriginCountry: string
  companyName: string
  companyWebsite: string
  industry: string
  partnershipType?: string
  teamMembers: TeamMembers
  listingType: ListingType
  isManaged?: boolean
  formFilled: boolean
}

export type KeyValue = {
  Text: string
  Url: string | boolean | number | null | undefined
}

export interface LockMetadata {
  lockedBy?: string
  lastActivityAt?: string
  lockAcquisitionProbe?: boolean
  windowId?: string
  lockedByName?: string
  editingEnabled?: boolean
  lockByEmail?: string
}
export type FlyOutOnboardingTerm = {
  label: string
  value: boolean
  type: 'checkbox' | 'radio'
  subTermsWhenTrue?: FlyOutOnboardingTerm
  subTermsWhenFalse?: FlyOutOnboardingTerm
}
export type FlyOutOnboardingTermsAndConditions = Record<
  string,
  FlyOutOnboardingTerm[]
>

export type FlyOutOnboardingGeneralInformation = {
  companyName: string
  logoUrl?: string
}

export type FlyOutOnboardingLandingPageDetails = {
  companyName: string
  sellerName: string
  companyWebsite: string
  logoUrl?: string
}

export type Sections = {
  [FlyoutOnboardingSectionName.LABRA_PARTNER_DETAILS]: FlyoutLabraPartnerDetails
  [FlyoutOnboardingSectionName.CLOUD_SETTINGS]: FlyoutCloudSettings
  [FlyoutOnboardingSectionName.PAGE_MARKER]: Record<string, boolean>
  [FlyoutOnboardingSectionName.TERMS_AND_CONDITIONS]: FlyOutOnboardingTermsAndConditions
  [FlyoutOnboardingSectionName.LOCK_METADATA]: LockMetadata
  [FlyoutOnboardingSectionName.GENERAL_INFORMATION]: FlyOutOnboardingGeneralInformation
  [FlyoutOnboardingSectionName.LANDING_PAGE_DETAILS]: FlyOutOnboardingLandingPageDetails
}
export type TestConnectionStatusType =
  | 'loading'
  | 'success'
  | 'failure'
  | 'standBy'

export type TestConnectionStatusProps = {
  status: TestConnectionStatusType
  error?: string
}
export interface FlyOutOnboarding {
  onboardingForm: Sections
  formChanged: boolean
  testConnectionStatus: TestConnectionStatusProps
}
export const initialState: FlyOutOnboarding = {
  onboardingForm: {} as Sections,
  formChanged: false,
  testConnectionStatus: {
    status: 'standBy',
  },
}

type actionType =
  | {
      type: FlyoutOnboardingForm.SET_FLYOUT_ONBOARDING_FORM
      payload: Sections
    }
  | {
      type: FlyoutOnboardingForm.UPDATE_FLYOUT_ONBOARDING_FORM
      payload: FlyOutOnboardingChangeType
    }
  | {
      type: FlyoutOnboardingForm.UPDATE_FLYOUT_TEST_CONNECTION_STATUS
      payload: TestConnectionStatusProps
    }

export const reducer: Reducer<FlyOutOnboarding, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FlyoutOnboardingForm.SET_FLYOUT_ONBOARDING_FORM: {
      return {
        ...state,
        onboardingForm: action.payload,
      }
    }
    case FlyoutOnboardingForm.UPDATE_FLYOUT_ONBOARDING_FORM: {
      const { sectionName, keyName, value } = action.payload
      return {
        ...state,
        onboardingForm: {
          ...state.onboardingForm,
          [sectionName]: {
            ...state.onboardingForm[sectionName],
            [keyName]: value,
          },
        },
      }
    }
    case FlyoutOnboardingForm.UPDATE_FLYOUT_TEST_CONNECTION_STATUS: {
      return {
        ...state,
        testConnectionStatus: action.payload,
      }
    }
    default: {
      return state
    }
  }
}
