import { Reducer } from 'redux'
import { contractsActionTypes } from './action'

interface Contract {
  contractId: string
  contractIdentifier: string
  awsAccountId: string
  awsCustomerIdentifier?: string | null
  createdAt: string
  contractStartDate?: string | null
  contractExpirationDate?: string | null
  tcv: number
  autoRenew?: boolean | null
  contractStatus: string
  expiringOn: string | null
  productName: string
  productType: string
  productId: string
  contractName: string
  cloudOfferId: string
  offerType: string
  aboAgreementId: string | null
  creatorName: string | null
  offerSentAt: string | null
  offerViewedAt: string | null
  offerAcceptedAt?: string | null
}

export interface Contracts {
  contracts: Contract[]
}

export const initialState: Contracts = { contracts: [] }

type actionType = {
  type: contractsActionTypes.SET_BUYER_CONTRACTS
  payload: Contracts
}

export const reducer: Reducer<Contracts, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contractsActionTypes.SET_BUYER_CONTRACTS: {
      return {
        ...state,
        contracts: action.payload.contracts,
      }
    }
    default:
      return state
  }
}
