import { LazyExoticComponent, useCallback, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTracker } from '../Tracker/hook'
import { useSelector } from '../../../store'
import { usePartnerId } from './usePartnerData'

export type productTab = {
  key: string
  label: string
  product?: string
  path: string
  component?: LazyExoticComponent<any> | React.FC
  search?: string
  renderElement?: JSX.Element
  childRoutes?: productTab[]
}
const routeMatchMapper: Record<string, string> = {
  '*': '',
  'oppsync/*': '/oppsync',
  'flyout/*': '/flyout',
  'app-settings': '/app-settings',
  crm: '/crm',
  users: '/users',
  integrations: '/integrations',
  cloud: '/cloud',
  notifications: '/notifications',
  sync: '/sync',
  'labra/*': '/labra',
  sales: '/sales',
  productsListing: '/productsListing',
  syncs: '/syncs',
  'private-offers': '/private-offers',
  'channel-offers': '/channel-offers',
  'resale-authorizations': '/resale-authorizations',
  'incoming-referrals': 'incoming-referrals',
  'outgoing-referrals': 'outgoing-referrals',
  'incoming-leads': 'incoming-leads',
  'admin-settings': '/admin-settings',
  sent: '/sent',
  received: '/received',
  'co-sell': 'co-sell',
  marketplace: 'marketplace',
}
export const settingsTabs = (
  productTabs: productTab[],
  defaultTabs: productTab[],
  shouldRedirectOnChange?: boolean,
  track?: boolean,
  eventHeading?: string
) => {
  const defaultSettingsTabs = defaultTabs.concat(productTabs)
  const sellerName = useSelector<string | undefined>(
    state => state.flyoutSellerInfo.awsAccountName
  )
  const { pathname } = useLocation()
  const tracker = useTracker()
  const partnerId = usePartnerId() || ''

  const trackEvent = async (heading: string, subHeading: string) => {
    const formattedHeading = heading.replace(/\s+/g, '-')
    const formattedSubHeading = subHeading.replace(/\s+/g, '-')
    tracker?.track({
      customTracking: true,
      section: `${formattedHeading}`,
      component: `${formattedSubHeading}`,
      action: 'clicked',
      title: `${subHeading} viewed`,
      source: `${subHeading} page`,
      partnerId: `${partnerId}`,
      customerName: `${sellerName}`,
    })
  }

  // "/settings" will match with any other nested routes, so we need to move it to the last place, that way other routes
  // like "/settings/oppsync" will match before the "/settings" one.
  const reversedIndex = [...defaultSettingsTabs].reverse().findIndex(d => {
    return pathname.includes(routeMatchMapper[d.path])
  })

  // then we translate the reversedIndex to the regular index. math formula -> x = |(index - length)| - 1
  const activeTabIndexFromRoute =
    reversedIndex === -1
      ? reversedIndex
      : Math.abs(reversedIndex - defaultSettingsTabs.length) - 1

  const navigate = useNavigate()

  const [activeTab, selectTab] = useState(
    activeTabIndexFromRoute < 0 ? 0 : activeTabIndexFromRoute
  )

  const handleTabChange = useCallback(
    (index: number) => {
      selectTab(index)
      if (shouldRedirectOnChange) {
        if (track && eventHeading) {
          trackEvent(eventHeading, defaultSettingsTabs[index].label)
        }
        navigate(
          {
            pathname: `${defaultSettingsTabs[index].path}`,
            search: `${defaultSettingsTabs[index].search || ''}`,
          },
          { replace: true }
        )
      }
    },
    [defaultSettingsTabs]
  )

  // "/settings" needs to be after "/settings/:product" so we reverse the tabs in order to move it last
  const settingsTabsForRouting = [...defaultSettingsTabs].reverse()

  useEffect(() => {
    if (activeTabIndexFromRoute > 0) {
      selectTab(activeTabIndexFromRoute)
    } else {
      selectTab(0)
    }
    if (activeTabIndexFromRoute === -1) {
      navigate(defaultSettingsTabs[0].path, { replace: true })
    }
  }, [activeTabIndexFromRoute])

  return {
    handleTabChange,
    settingsTabsForRouting,
    activeTab,
    defaultSettingsTabs,
  }
}
