import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState, useDispatch } from '../../../../../../store'
import {
  getPartnerDataForAceApi,
  updateEngineServicePartnerData,
} from '../../../../../modules/engineServicePartner/action'
import { AnyAction } from '@reduxjs/toolkit'
import {
  usePartnerId,
  useUserType,
} from '../../../../../../common/utils/Hooks/usePartnerData'
import { RequiredPersonsModal } from './RequiredPersonsModal/RequiredPersonsModal'

export const RequiredPersonsModalWrapper: React.FC = () => {
  const partnerType = useUserType()

  const isAcknowledged = useSelector(
    (state: RootState) =>
      state.PartnerData[partnerType].partnerData?.awsConfigMetadata
        ?.acknowledgedRequiredPersons
  )
  const awsConfigMetaData = useSelector(
    (state: RootState) =>
      state.PartnerData[partnerType].partnerData?.awsConfigMetadata
  )

  const dispatch = useDispatch()
  const partnerId = usePartnerId()
  useEffect(() => {
    if (partnerId) {
      dispatch(
        getPartnerDataForAceApi(
          partnerId || '',
          partnerType
        ) as unknown as AnyAction
      )
    }
  }, [partnerId])

  const [openRequirementsModal, setRequirementsModal] = useState<boolean>(false)
  const openModal = () => {
    setRequirementsModal(true)
  }
  const closeModal = () => {
    setRequirementsModal(false)
  }

  useEffect(() => {
    if (!isAcknowledged) {
      openModal()
    }
  }, [isAcknowledged])

  const handleRequirements = async () => {
    closeModal()
    if (partnerId && awsConfigMetaData) {
      await dispatch(
        updateEngineServicePartnerData(partnerId, partnerType, {
          awsConfigMetadata: {
            ...awsConfigMetaData,
            acknowledgedRequiredPersons: true,
          },
        })
      )
    }
  }
  return (
    <RequiredPersonsModal
      open={openRequirementsModal}
      onClose={closeModal}
      onAccept={handleRequirements}
    />
  )
}
