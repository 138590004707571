import React, { useCallback, useEffect, useState } from 'react'
import { useStyles } from './FlyOutArnDetails.styles'
import { CFSButtonV2, TextInputV2, ButtonV2 } from '@labrav/react-components'
import {
  Sections,
  TestConnectionStatusProps,
  TestConnectionStatusType,
} from '../../../modules/flyOutOnboarding/reducer'
import { flyOutCFTLink as baseFlyOutCFTLink } from '../../../../common/utils/constants'
import { FormikProps, useFormikContext } from 'formik'
import { usePartnerId } from '../../../../common/utils/Hooks/usePartnerData'
import {
  flyOutTestConnection,
  setFlyOutTestConnectionStatus,
} from '../../../modules/flyOutOnboarding/actions'
import { RootState, useDispatch } from '../../../../store'
import { useSelector } from 'react-redux'

import { Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLoader } from '@fortawesome/pro-duotone-svg-icons'
import { faCircleCheck } from '@fortawesome/free-regular-svg-icons'
import { clsx } from 'clsx'
import { arnTestConnectionFailureMessage } from '../../../../common/utils/messagesContants'
import { FormLockedType } from '../../../types/FormLockedType'
import { extractCompanyNameFromEmail } from '../../../../common/utils/extractCompanyNameFromEmail'

export const FlyOutArnDetails: React.FC<FormLockedType> = ({
  isFormLocked,
}) => {
  const { values, errors, touched, handleBlur, handleChange, setFieldValue } =
    useFormikContext() as FormikProps<Sections>
  const classes = useStyles()
  const dispatch = useDispatch()
  const partnerId = usePartnerId()
  const cloudSettings = values.cloudSettings
  const settingsTouched = touched?.cloudSettings
  const settingsError = errors?.cloudSettings

  const testConnectionStatus = values.cloudSettings.testConnectionStatus
  useEffect(() => {
    updateCloudSettings()
  }, [testConnectionStatus])
  const updateCloudSettings = async () => {
    await setFieldValue(
      'cloudSettings.connectionTested',
      testConnectionStatus === 'success'
    )
    await setFieldValue(
      'cloudSettings.testConnectionStatus',
      testConnectionStatus
    )
  }

  const handleChangeField = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    await handleChange(event)
    if (event?.target?.name === 'cloudSettings.cloudRoleArn') {
      await updateFlyoutTestConnection({ status: 'standBy' })
    }
    await setFieldValue('cloudSettings.formFilled', false)
    await setFieldValue('cloudSettings.formUpdated', true)
  }
  const handleBlurRoleArn = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    await handleBlur(event)

    await dispatch(
      flyOutTestConnection(
        partnerId || '',
        event.target.value,
        updateFlyoutTestConnection
      )
    )
  }

  const updateFlyoutTestConnection = async (
    testConnectionStatus: TestConnectionStatusProps
  ) => {
    await setFieldValue(
      'cloudSettings.testConnectionStatus',
      testConnectionStatus.status
    )
  }

  return (
    <div className={classes.fieldsArnContainer}>
      <CFSButtonV2 cfLink={baseFlyOutCFTLink} />
      <div className={classes.fieldsContainer}>
        <div className={clsx(classes.field)}>
          <TextInputV2
            keyName="cloudSettings.cloudRoleArn"
            title="Enter your platform role"
            type="text"
            placeHolder=""
            value={cloudSettings.cloudRoleArn}
            isError={
              !!(
                settingsError?.cloudRoleArn && settingsTouched?.cloudRoleArn
              ) || testConnectionStatus === 'failure'
            }
            onChange={handleChangeField}
            onBlur={handleBlurRoleArn}
            isReadOnly={isFormLocked as boolean}
            isRequired={false}
            errorText={
              (!!settingsTouched?.cloudRoleArn &&
                settingsError?.cloudRoleArn) ||
              ''
            }
            showSpinner={true}
          />
        </div>
      </div>
      <div className={classes.field} data-testid="test-connection">
        <ShowConnectionStatuses
          testConnectionStatus={{ status: testConnectionStatus }}
        />
      </div>
    </div>
  )
}

export interface ShowConnectionStatusesProps {
  testConnectionStatus: {
    status: TestConnectionStatusType
    error?: string
  }
}
export const ShowConnectionStatuses = React.memo(
  ({ testConnectionStatus }: ShowConnectionStatusesProps) => {
    const classes = useStyles()
    switch (testConnectionStatus.status) {
      case 'loading': {
        return (
          <div
            className={classes.loadingTextContainer}
            data-testid="loading-test-connection"
          >
            <FontAwesomeIcon icon={faLoader} spin className={classes.loader} />
            <Typography
              className={clsx(classes.loadingText, classes.textColor)}
            >
              Testing connection, please wait...
            </Typography>
          </div>
        )
      }
      case 'failure': {
        return (
          <>
            <div
              className={classes.loadingTextContainer}
              data-testid="failed-test-connection"
            >
              <Typography
                className={clsx(classes.loadingText, classes.errorText)}
              >
                {testConnectionStatus.error || 'Test connection failed'}
                {/* Try again. */}
              </Typography>
            </div>
          </>
        )
      }
      case 'success': {
        return (
          <div
            className={classes.loadingTextContainer}
            data-testid="success-test-connection"
          >
            <FontAwesomeIcon
              icon={faCircleCheck}
              className={clsx(classes.loader, classes.check)}
            />
            <Typography
              className={clsx(classes.successText, classes.textColor)}
            >
              Connection established successfully
            </Typography>
          </div>
        )
      }
      default:
        return <div data-testid="empty-connection-string"></div>
    }
  }
)
