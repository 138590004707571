export enum Role {
  PARTNER_ADMIN = 'PARTNER_ADMIN',
  PARTNER_OWNER = 'PARTNER_OWNER',
  PARTNER_SALES_EXECUTIVE = 'PARTNER_SALES_EXECUTIVE',
  PARTNER_SALES_REPRESENTATIVE = 'PARTNER_SALES_REPRESENTATIVE',
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  ACCOUNT_EXECUTIVE = 'ACCOUNT_EXECUTIVE',
  PARTNER_STAFF = 'PARTNER_STAFF',
}

export enum UserRole {
  PARTNER_ADMIN = 'Admin',
  PARTNER_STAFF = 'Staff',
}

export enum UserRoleForTable {
  PARTNER_ADMIN = 'Admin',
  PARTNER_STAFF = 'Staff',
  PARTNER_OWNER = 'Owner',
}

export enum InvitationStatus {
  INVITED = 'INVITED',
  INVITATION_ACCEPTED = 'INVITATION_ACCEPTED',
}
