import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(3),
  },
  leftContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1.5),
    alignItems: 'center',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionsContainer: {
    marginLeft: 'auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  envName: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(0.5),
    alignItems: 'center',
  },
  envStatus: {
    display: 'flex',
    flexDirection: 'row',
  },
  divider: {
    width: theme.spacing(0.125),
    height: theme.spacing(4),
    backgroundColor: theme.palette.dividersV2.main,
  },
  envNameText: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    textAlign: 'center',
  },
  changeEnvDescription: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: theme.palette.labraText.description,
    textAlign: 'center',
  },
  gearsIcon: {
    height: theme.spacing(2.5),
    weight: theme.spacing(2.5),
    color: theme.palette.labraIcon.main,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  check: {
    color: theme.palette.textBackground.dark,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(0),
    alignSelf: 'center',
  },
  failed: {
    color: theme.palette.labraV2Errors.main,
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(1),
    alignSelf: 'center',
  },
  loading: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(0),
    alignSelf: 'center',
  },
  exclaimIcon: {
    height: theme.spacing(2.5),
    weight: theme.spacing(2.5),
    color: theme.palette.labraV2Errors.main,
    alignSelf: 'center',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  switchTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
}))
