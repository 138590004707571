import React, { useCallback, useEffect, useState } from 'react'
import { useStyles } from './CoSellNotifications.styles'
import {
  CloudChangeV2,
  CustomCloudOptions,
} from '../../../common/components/CloudChangeV2/CloudChangeV2'
import clsx from 'clsx'
import { useCurrentCloudProvider } from '../../../common/utils/Hooks/useCurrentCloudProvider'
import {
  ButtonV2,
  LabraToolTipV2,
  SwitchV2,
  SwitchVarient,
  TableComponentV2,
  AvatarV3,
  ModalConfirmV2,
  ModalSizeVariants,
  AutoComplete,
} from '@labrav/react-components'
import { TableCell, Typography } from '@material-ui/core'
import {
  customerFormData,
  usePartnerId,
  useUserType,
} from '../../../common/utils/Hooks/usePartnerData'
import { getUsers, AuthProvider } from '../../modules/userList/action'
import { UserListState, UserState } from '../../modules/userList/reducer'
import { isEmpty, isEqual } from 'lodash'
import { useDispatch, useSelector } from '../../../store'
import {
  NotificationEnabledType,
  NotificationsSettingPage,
  ProductNotificationTypes,
  NotificationsTypeMethods,
  RecipientType,
} from '../../modules/notification/reducer'
import AWSAcceptanceApproved from '../../../common/images/AWSAcceptanceApproved.png'
import LeadNotification from '../../../common/images/LeadNotification.png'
import SyncFailure from '../../../common/images/SyncFailure.png'
import { CoSellAddRecipientsNotificationsButton } from './CoSellAddRecipientsNotificationsButton/CoSellAddRecipientsNotificationsButton'
import { Product } from '../../../common/modules/partner/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faBookmark,
  faExternalLinkAlt,
  faInfoCircle,
  faUserPlus,
  faXmarkCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { UserProfile } from '../../../common/modules/userProfile/action'
import { Role } from '../../../common/utils/roles'
import {
  getSettingsPageData,
  saveNotificationsV2,
  setNotificationsSettingsPage,
  updateMultipleSlackRecipients,
  updateProductNotificationValues,
} from '../../modules/notification/actions'
import { IntegrationValue } from '../../../common/modules/thirdPartyAppIntegration/reducer'
import { getThirdPartyAppList } from '../../../common/modules/thirdPartyAppIntegration/action'
import { isLoading } from '../../../common/utils/loadingState'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { FooterV2 } from '../../../common/components/FooterV2/FooterV2'
import { LoadingStateComponent } from '../../../common/components/LoadingStateComponent/LoadingStateComponent'
import { CoSellSidePanelDrawer } from './CoSellSidePanelDrawer/CoSellSidePanelDrawer'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import {
  getSlackChannels,
  SlackChannelsData,
} from '../../../common/modules/slackChannels/action'
import { getRandom } from '../../../common/utils/helperFunctions'
import { useActiveProducts } from '../../../common/utils/Hooks/activeProducts'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFlagValue } from '@labrav/flags'
import { useOppSyncOnboardingStatus } from '../../utils/hooks/useOppSyncOnboardingStatus'
import OppsyncBlockPage from '../../../common/components/BlockPages/OppsyncBlockPage'
import { CoSelOnboardingPage } from '../../../common/components/OnboardingPages/CoSellOnboardingPage'
import { NoOptionsCustomForAutoComplete } from '../../../common/components/NoOptionsCustomForAutoComplete/NoOptionsCustomForAutoComplete'
import { useTracker } from '../../../common/utils/Tracker/hook'

export const mapping = {
  'Notification type': 'notificationTypeName',
  'Email recipients': 'recipients',
  'Slack channel': 'slackChannel',
  'Notification status': 'notificationEnabled',
}

const customCloudSelectorOptions: CustomCloudOptions = [
  { name: 'AWS', disabled: false },
  { name: 'GCP', disabled: true },
  { name: 'AZURE', disabled: true },
]

export const getAllData = (
  productNotificationTypeMethods: NotificationsTypeMethods[],
  usersData: UserState[]
) => {
  const emailRecipients = productNotificationTypeMethods
    .filter(method => method.methodId === 'EMAIL')
    .flatMap(method => method.recipients ?? []) as RecipientType[]

  const idpUserIds = emailRecipients
    .map(recipient => recipient?.idpUserId)
    .filter(id => !isEmpty(id))

  const externalRecepients = emailRecipients
    .map(recipient => recipient?.destinationValue)
    .filter(value => !isEmpty(value)) as string[]

  const registeredRecepients = getRegisteredRecipientsData(
    idpUserIds,
    usersData
  )

  const allRecipientsData = [...registeredRecepients, ...externalRecepients]

  return {
    emailRecipients,
    allRecipientsData,
    registeredRecepients,
    externalRecepients,
  }
}

export const getRegisteredRecipientsData = (
  idpUserIds: any[],
  usersData: any[]
) => {
  if (isEmpty(idpUserIds) || isEmpty(usersData)) return []

  return idpUserIds
    .map(idpUserId => {
      const matchedUser = usersData.find(
        userData => userData.idpUserId === idpUserId
      )
      return matchedUser ? matchedUser.name : null
    })
    .filter(name => name !== null)
}

const getSlackNameAndIdFromNotificationMethod = (
  notification: NotificationsTypeMethods[]
) => {
  const method =
    notification?.filter(method => method.methodId === 'SLACK')[0] ||
    ({} as NotificationsTypeMethods)
  let activeChannel = undefined
  if (method?.recipients?.length) {
    activeChannel =
      method.recipients[0].metaData?.activeChannel?.channelId || undefined
  }
  return activeChannel
}

export const getSlackInputParams = (
  slackChannelsList: SlackChannelsData[],
  isAdminOrOwner: boolean,
  activeChannel?: string
) => {
  const randomKey = getRandom()
  const activeChannelLabel =
    (slackChannelsList || []).filter(
      slack => slack.channelId === activeChannel
    )[0]?.name || ''
  return {
    id: `slackChannel-notification-${randomKey}`,
    label: '',
    placeholder: 'Select a Slack channel',
    options: isEmpty(slackChannelsList)
      ? []
      : slackChannelsList.map(slack => ({
          key: slack.channelId,
          label: slack.name,
        })),
    disabled: !isAdminOrOwner,
    required: false,
    value: !isEmpty(activeChannel)
      ? { key: activeChannel, label: activeChannelLabel }
      : '',
  }
}

export const CoSellNotifications: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const tracker = useTracker()
  const partnerType = useUserType()
  const cloud = useCurrentCloudProvider()
  const activeProducts = useActiveProducts()
  const [openSidePanelInfo, setOpenSidePanelInfo] = useState<{
    [key: string]: boolean
  }>({})
  const [openSaveModal, setOpenSaveModal] = useState(false)
  const [openDiscardModal, setOpenDiscardModal] = useState(false)
  const [openRecepientsModal, setOpenRecepientsModal] = useState(false)
  const [currentNotification, setCurrentNotification] = useState<string[]>([])
  const [selectedNotification, setSelectedNotification] = useState<string[]>([])
  const [slackChannelModal, setSlackChannelModal] = useState<string>()
  const [openSlackModal, setOpenSlackModal] = useState<boolean>(false)

  // ---------- All state values ----------

  const loading = useSelector<boolean>(state =>
    isLoading(state.loading)(LoadingTypes.GENERAL)
  )
  const slackChannelDataLoading = useSelector<boolean>(state =>
    isLoading(state.loading)(LoadingTypes.SLACK_CHANNELS_LOADING)
  )
  const sellerName = useSelector<string | undefined>(
    state => state.flyoutSellerInfo.awsAccountName
  )
  const saveDataLoading = useSelector<boolean>(state =>
    isLoading(state.loading)(LoadingTypes.SAVE_NOTIFICATIONS_DATA)
  )
  const { userList, pageSize: pageSizeUser } = useSelector<UserListState>(
    state => state.userList[partnerType]
  )
  const { users } = userList || { users: [], count: 0 }
  const partnerId = usePartnerId()
  const user = useSelector<UserProfile | undefined>(
    state => state.userProfile.userProfile
  )
  const product = useSelector<Product>(
    state => state.platformSettings.selectedProductOnSettings
  )
  const notificationsData = useSelector<NotificationsSettingPage>(
    state => state.notifications?.[product]?.notificationsSettingData
  )
  const slackIntegration = useSelector<IntegrationValue>(
    state => state.integrations?.integrationsObj?.SLACK
  )
  const slackChannelsList = useSelector<SlackChannelsData[]>(
    state => state?.slackChannels?.[product]?.slackChannelsData || []
  )

  const showLink = isEmpty(slackChannelsList)

  const initialNotificationsData = useSelector<NotificationsSettingPage>(
    state => state.notifications?.[product]?.initialNotificationsSettingData
  )
  const [lastSavedNotificationsData, setLastSavedNotificationsData] = useState(
    initialNotificationsData
  )

  //  ---------- constant variables  ----------

  const isAdminOrOwner =
    user?.roles?.some(r =>
      [Role.PARTNER_ADMIN, Role.PARTNER_OWNER].includes(r)
    ) || false

  const idpUserId = isAdminOrOwner ? undefined : user?.idpUserId
  const showLoading =
    loading ||
    slackChannelDataLoading ||
    isEmpty(notificationsData) ||
    isEmpty(product)
  const images = [AWSAcceptanceApproved, LeadNotification, SyncFailure]

  const partnerInviteEnabled = useFlagValue('partnerInvite')
  const customerData = customerFormData()
  const subscribedProducts = customerData.subscribedProducts
  const { OPPSYNC: oppsyncProduct } = subscribedProducts
  const { loading: isFlagCosellLoading, value: cosellEnabled } =
    useFlagValue('cosell')
  const isCosellEnabled = !isFlagCosellLoading && cosellEnabled
  const { onboardingComplete, isNewPartner } =
    useOppSyncOnboardingStatus(isCosellEnabled)

  const { pathname } = useLocation()
  const isOnboardingRoute = pathname.split('/').includes('onboarding')
  const isOppsyncOnboarded =
    activeProducts?.includes('oppsync') &&
    !isOnboardingRoute &&
    (onboardingComplete || !isNewPartner)
  const isOppsyncSubscribed = !partnerInviteEnabled?.value || !!oppsyncProduct

  //  ---------- All UseEffects  ----------

  useEffect(() => {
    if (partnerId && user && product === 'oppsync') {
      dispatch(getSettingsPageData(partnerId, product, idpUserId, true))
    }
  }, [isAdminOrOwner, partnerId, product])

  useEffect(() => {
    if (isEmpty(slackIntegration) && partnerId && isAdminOrOwner) {
      dispatch(getThirdPartyAppList(partnerId))
    }
  }, [partnerId, slackIntegration, isAdminOrOwner])

  useEffect(() => {
    if (
      slackIntegration?.isConfigured &&
      partnerId &&
      product === 'oppsync' &&
      isEmpty(slackChannelsList)
    ) {
      dispatch(getSlackChannels(partnerId, product))
    }
  }, [slackIntegration?.isConfigured, partnerId, product])

  useEffect(() => {
    if (partnerId)
      dispatch(
        getUsers({
          partnerId: partnerId || '',
          pageNumber: 1,
          pageSize: 500,
          authProvider: AuthProvider.AUTH0,
        })
      )
  }, [partnerId])

  useEffect(() => {
    setLastSavedNotificationsData(initialNotificationsData)
  }, [initialNotificationsData])

  //  ---------- All functions  ----------

  // Track event
  const trackEvent = async (
    heading: string,
    subHeading: string,
    action: string,
    title: string,
    source: string
  ) => {
    const formattedHeading = heading.replace(/\s+/g, '-')
    const formattedSubHeading = subHeading.replace(/\s+/g, '-')
    tracker?.track({
      customTracking: true,
      section: `${formattedHeading}`,
      component: `${formattedSubHeading}`,
      action: `${action}`,
      title: `${title}`,
      source: `${source}`,
      partnerId: `${partnerId}`,
      customerName: `${sellerName}`,
    })
  }

  // Data changed
  const hasDataChanged = () => {
    if (!initialNotificationsData || !notificationsData) return false
    return !isEqual(initialNotificationsData, notificationsData)
  }

  // Row Checkbox function
  const onChangeCheckbox = (item: string[]) => {
    setSelectedNotification(item)
  }

  // Notification type related function
  const handleInfoClick = (id: string) => {
    setOpenSidePanelInfo(prevState => ({
      ...prevState,
      [id]: !prevState[id], // Toggle the specific notification
    }))
    trackEvent(
      'Co-sell Notifications',
      'Info icon',
      'clicked',
      'Info icon Clicked',
      'Co-sell Notifications'
    )
  }

  const sidePanelDrawerClose = (id: string) => {
    setOpenSidePanelInfo(prevState => ({
      ...prevState,
      [id]: false, // Close the specific notification
    }))
  }

  // Email Recipients related function
  const handleAddEmailRecipientsButtonClick = useCallback(
    (ids: string[]) => () => {
      setCurrentNotification(ids)
      setOpenRecepientsModal(prevOpen => !prevOpen)
      trackEvent(
        'Co-sell Notifications',
        'Email recipients',
        'clicked',
        'Email recipients clicked',
        'Co-sell Notifications'
      )
    },
    []
  )

  // Slack channel related function

  const handleSlackChannelButtonClick = (ids: string[]) => () => {
    setOpenSlackModal(true)
    setSelectedNotification(ids)
    trackEvent(
      'Co-sell Notifications',
      'Slack channel Button',
      'clicked',
      'Slack channel Button',
      'Co-sell Notifications'
    )
  }

  // Handle Empty slack
  const handleExternalIcon = () => {
    navigate('/settings/integrations')
  }

  const handleAddSlackChannels = useCallback(() => {
    const recipientsToUpdate = getNewRecipientsList(
      selectedNotification,
      slackChannelModal || ''
    )
    dispatch(
      actionTypeWrapper(
        product,
        updateMultipleSlackRecipients(recipientsToUpdate)
      )
    )
    trackEvent(
      'Co-sell Notifications',
      'Slack channel Add Button',
      'clicked',
      'Slack channel added for multiple notifications',
      'Co-sell Notifications'
    )
    setOpenSlackModal(false)
    setSlackChannelModal('')
  }, [selectedNotification, slackChannelModal])

  const handleSlackCancel = () => {
    setOpenSlackModal(false)
    setSlackChannelModal('')
    trackEvent(
      'Co-sell Notifications',
      'Slack channel cancel Button',
      'clicked',
      'Slack channel cancelled for multiple notifications',
      'Co-sell Notifications'
    )

    if (lastSavedNotificationsData) {
      dispatch(
        actionTypeWrapper(
          product,
          setNotificationsSettingsPage(
            lastSavedNotificationsData,
            partnerId || ''
          )
        )
      )
    }
  }

  const getNewRecipientsList = (
    productNotificationTypeIds: string[],
    channelId: string
  ) => {
    const recipientstoUpdate: Record<string, RecipientType[]> = {}
    productNotificationTypeIds.forEach(id => {
      if (channelId) {
        recipientstoUpdate[id] = [
          {
            idpUserId: null,
            destinationValue: null,
            metaData: {
              activeChannel: {
                channelId: channelId,
              },
            },
          },
        ]
      } else {
        recipientstoUpdate[id] = []
      }
    })
    return recipientstoUpdate
  }

  const handleSlackChannelChange =
    (productNotificationTypeIds: string[], modalSlackSelection = false) =>
    (
      _: React.ChangeEvent<Record<string, unknown>>,
      newValue: string | { key: string | undefined; label: string } | null
    ) => {
      const channelId = typeof newValue === 'object' ? newValue?.key ?? '' : ''
      if (modalSlackSelection) {
        setSlackChannelModal(channelId)
        trackEvent(
          'Co-sell Notifications',
          'Slack channel',
          'clicked',
          'Slack channel selected for multiple notifications',
          'Co-sell Notifications'
        )
      } else {
        const recipientstoUpdate = getNewRecipientsList(
          productNotificationTypeIds,
          channelId
        )
        dispatch(
          actionTypeWrapper(
            product,
            updateMultipleSlackRecipients(recipientstoUpdate)
          )
        )
        trackEvent(
          'Co-sell Notifications',
          'Slack channel',
          'clicked',
          'Slack channel selected for single notification',
          'Co-sell Notifications'
        )
        setLastSavedNotificationsData(notificationsData)
      }

      if (channelId) {
        setSelectedNotification(prevSelected => {
          const newSelected = new Set(prevSelected)
          productNotificationTypeIds.forEach(id => newSelected.add(id))
          return Array.from(newSelected)
        })
      }
    }

  // Notification status related function

  const toggleNotificationStatus =
    (productNotificationTypeId: string) =>
    (e: {
      target: {
        checked: boolean
      }
    }) => {
      let notificationStatus = NotificationEnabledType.INACTIVE
      if (e.target.checked) {
        notificationStatus = NotificationEnabledType.ACTIVE
      }
      // add it on the state
      dispatch(
        actionTypeWrapper(
          product,
          updateProductNotificationValues(
            productNotificationTypeId,
            'notificationEnabled',
            notificationStatus
          )
        )
      )
      trackEvent(
        'Co-sell Notifications',
        'Toggle',
        `${e.target.checked ? 'enabled' : 'disabled'}`,
        `Toggle ${e.target.checked ? 'enabled' : 'disabled'}`,
        'Co-sell Notifications'
      )
      setSelectedNotification(prevSelected => {
        const newSelected = new Set(prevSelected)
        newSelected.add(productNotificationTypeId)
        return Array.from(newSelected)
      })
    }

  // Footer related Function

  // Footer common Function
  const handleDiscardChanges = useCallback(() => {
    if (initialNotificationsData) {
      dispatch(
        actionTypeWrapper(
          product,
          setNotificationsSettingsPage(
            initialNotificationsData,
            partnerId || ''
          )
        )
      )
    }
    trackEvent(
      'Co-sell Notifications',
      'Discard button',
      'clicked',
      'Discard Button Clicked',
      'Co-sell Notifications'
    )
    setSelectedNotification([])
    setCurrentNotification([])
    setOpenDiscardModal(false)
    setOpenSaveModal(false)
  }, [initialNotificationsData, partnerId, product, dispatch])

  // Save Button Functions

  const handleOnSave = () => {
    setOpenSaveModal(true)
  }

  const handleSaveCancel = () => {
    setOpenSaveModal(false)
  }

  const handleSaveChanges = useCallback(async () => {
    await dispatch(saveNotificationsV2(partnerId || '', product))
    trackEvent(
      'Co-sell Notifications',
      'Save button',
      'clicked',
      'Save button Clicked',
      'Co-sell Notifications'
    )
    setSelectedNotification([])
    setCurrentNotification([])
    setOpenSaveModal(false)
  }, [notificationsData, partnerId, product, dispatch])

  // Discard Button Functions

  const handleOnDiscard = () => {
    setOpenDiscardModal(true)
  }

  const handleBackChanges = () => {
    setOpenDiscardModal(false)
  }

  // Complete Onboarding Function
  const handleCompleteOnboarding = () => {
    !activeProducts?.includes('oppsync')
      ? navigate('/product-selection')
      : navigate('/oppsync/onboarding/company-information')
  }

  // Custom Header
  const customHeader = (selectedItemArray: string[]) => {
    return {
      notificationTypeName: (
        <TableCell className={classes.tableCell}>
          {selectedItemArray.length > 0 ? (
            <div className={clsx(classes.mainContainer, classes.extraGap)}>
              <FontAwesomeIcon
                icon={faBell}
                style={{
                  height: 20,
                  width: 14,
                  color: '#381E1C',
                  display: 'flex',
                }}
              />
              <div className={classes.headingText}>
                {selectedItemArray.length}{' '}
                {selectedItemArray.length > 1
                  ? 'Notifications'
                  : 'Notification'}{' '}
                selected
              </div>
            </div>
          ) : (
            <div className={classes.defaultHeadingText}>Notification type</div>
          )}
        </TableCell>
      ),
      recipients: (
        <TableCell
          className={clsx(classes.tableCell, classes.recipentsTableCell)}
        >
          {selectedItemArray.length > 1 ? (
            <div className={classes.mainContainer}>
              <LabraToolTipV2
                tooltipPlacement="top"
                description={'Add recipients to all the selected notifications'}
              >
                <ButtonV2
                  style={{ fontWeight: 600 }}
                  onClick={handleAddEmailRecipientsButtonClick(
                    selectedItemArray
                  )}
                  data-testid="add-email-recipients-btn"
                  styleType="outlined"
                  className={classes.button}
                >
                  Add email recipients
                </ButtonV2>
              </LabraToolTipV2>
            </div>
          ) : (
            <div className={classes.defaultHeadingText}>Email recipients</div>
          )}
        </TableCell>
      ),
      slackChannel: (
        <TableCell
          className={clsx(classes.tableCell, classes.slackChannelTableCell)}
        >
          {selectedItemArray.length > 1 ? (
            <div className={classes.headingContainer}>
              <LabraToolTipV2
                tooltipPlacement="top"
                description={
                  'Select a Slack channel for all the selected notifications'
                }
              >
                <ButtonV2
                  style={{ fontWeight: 600 }}
                  onClick={handleSlackChannelButtonClick(selectedItemArray)}
                  data-testid="add-slack-channel-btn"
                  styleType="outlined"
                  className={classes.button}
                >
                  Select a Slack channel
                </ButtonV2>
              </LabraToolTipV2>
            </div>
          ) : (
            <div className={classes.defaultHeadingText}>Slack channel</div>
          )}
        </TableCell>
      ),
      notificationEnabled: (
        <TableCell
          className={clsx(
            classes.tableCell,
            classes.notificationStatusTableCell
          )}
        >
          <div className={classes.defaultHeadingText}>Notification status</div>
        </TableCell>
      ),
    }
  }
  const updatedCustomHeader = customHeader(selectedNotification)

  // ---------- Custom object ----------

  const customObject = notificationsData?.productNotificationTypes?.map(
    (obj: ProductNotificationTypes, index: number) => {
      const handleClick = () => handleInfoClick(obj.productNotificationTypeId)
      const handleClose = () =>
        sidePanelDrawerClose(obj.productNotificationTypeId)
      const isReferralOwner = obj?.specialRecipients?.includes('OWNER') || false
      const {
        emailRecipients,
        allRecipientsData,
        registeredRecepients,
        externalRecepients,
      } = getAllData(obj?.productNotificationTypeMethods, userList?.users)
      const firstThreeRecepients = allRecipientsData.slice(0, 3)
      const activeChannel = getSlackNameAndIdFromNotificationMethod(
        obj.productNotificationTypeMethods
      )

      return {
        id: obj.productNotificationTypeId,
        notificationTypeName: (
          <div className={classes.notificationType}>
            <Typography className={classes.notificationRow}>
              {obj.notificationTypeName}
            </Typography>
            <div className={clsx(classes.infoIcon, 'info-on-hover')}>
              <LabraToolTipV2
                tooltipPlacement={'top-start'}
                description={<div>{obj.notificationTypeDescription}</div>}
                tooltipPlacmentGap="12px"
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{
                    height: 16,
                    width: 16,
                    color: '#1942C5',
                    display: 'flex',
                  }}
                  onClick={handleClick}
                  data-testid="info-icon"
                />
              </LabraToolTipV2>
            </div>
            <CoSellSidePanelDrawer
              open={!!openSidePanelInfo[obj.productNotificationTypeId]}
              notificationType={obj.notificationTypeName}
              notificationDescription={obj.notificationTypeDescription}
              handleClose={handleClose}
              image={
                <img
                  src={images[index % images.length]}
                  className={classes.image}
                  alt="Email preview image"
                />
              }
            />
          </div>
        ),
        recipients: (
          <div className={classes.recepientsContainer}>
            {!isReferralOwner && allRecipientsData.length === 0 ? (
              <></>
            ) : (
              <div className={classes.avatarContainer}>
                <AvatarV3
                  specialUser={isReferralOwner ? 'Referral owner' : ''}
                  tooltipHeading={[
                    'Registered recipients',
                    'External recipients',
                  ]}
                  isUserDataEmpty={allRecipientsData.length === 0}
                  firstThreeUsers={firstThreeRecepients}
                  registeredUsers={registeredRecepients}
                  externalUsers={externalRecepients}
                />
              </div>
            )}
            <CoSellAddRecipientsNotificationsButton
              key={obj.productNotificationTypeId}
              handleClick={handleAddEmailRecipientsButtonClick([
                obj.productNotificationTypeId,
              ])}
              open={currentNotification.includes(obj.productNotificationTypeId)}
              setOpen={setOpenRecepientsModal}
              cloud={cloud}
              recipients={emailRecipients}
              specialRecipients={isReferralOwner ? ['OWNER'] : []}
              currentNotification={currentNotification}
              setCurrentNotification={setCurrentNotification}
              setSelectedItem={setSelectedNotification}
            />
          </div>
        ),
        slackChannel: (
          <AutoComplete
            {...getSlackInputParams(
              slackChannelsList,
              isAdminOrOwner,
              activeChannel
            )}
            onChange={handleSlackChannelChange(
              [obj.productNotificationTypeId],
              false
            )}
            error={false}
            helperText={''}
            noOptionsText={null}
            filterOptions={(options: any[], state: any) => {
              const filteredOptions = options.filter(option =>
                option.label
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              )
              if (filteredOptions.length === 0) {
                return [
                  {
                    label: 'no-options',
                  },
                ]
              }
              return filteredOptions
            }}
            renderOption={(
              option: { label: string },
              { className, ...getOptionProps }: any
            ) => {
              if (option.label === 'no-options') {
                return (
                  <div
                    {...getOptionProps}
                    className={classes.customOption}
                    onClick={event => event.stopPropagation()}
                  >
                    <NoOptionsCustomForAutoComplete
                      handleExternalIcon={handleExternalIcon}
                      showLink={showLink}
                    />
                  </div>
                )
              }
              return <div {...getOptionProps}>{option.label}</div>
            }}
            clearOnBlur
          />
        ),
        notificationEnabled: (
          <div className={classes.switchContainer}>
            <LabraToolTipV2
              tooltipPlacement="top"
              description={
                obj.notificationEnabled === NotificationEnabledType.INACTIVE
                  ? 'Enable notification'
                  : 'Disable notification'
              }
            >
              <SwitchV2
                checked={
                  obj.notificationEnabled === NotificationEnabledType.ACTIVE
                }
                onChange={toggleNotificationStatus(
                  obj.productNotificationTypeId
                )}
                switchVarient={SwitchVarient.DARK}
              />
            </LabraToolTipV2>
          </div>
        ),
      }
    }
  )

  // ---------- Component code ----------

  if (!isOppsyncSubscribed) {
    return <OppsyncBlockPage showHeader={false} />
  }
  if (isOppsyncSubscribed && !isOppsyncOnboarded) {
    return (
      <CoSelOnboardingPage
        customButton={
          <>
            <ButtonV2
              styleType="primary"
              onClick={handleCompleteOnboarding}
              data-testid="cntinue-onboarding-button"
            >
              Continue onboarding
            </ButtonV2>
          </>
        }
      />
    )
  }
  if (showLoading) {
    return (
      <LoadingStateComponent
        heading="Loading your co-sell notifications page"
        description="It may take a few moments, please wait..."
      />
    )
  }
  return (
    <>
      <div
        className={classes.root}
        data-testid="co-sell-notifications-container"
      >
        <div
          className={classes.topContainer}
          data-testid="co-sell-notifications-top-container"
        >
          <div className={classes.noteContainer}>
            Select recipients and add external emails to send notifications via
            email.{' '}
          </div>
          <div data-testid="co-sell-notifications-cloud-selector">
            <CloudChangeV2 customCloudOptions={customCloudSelectorOptions} />
          </div>
        </div>
      </div>
      <div
        className={classes.tableContainer}
        data-testid="co-sell-notifcations-table"
      >
        <TableComponentV2
          mapping={mapping}
          customHeader={updatedCustomHeader}
          objects={customObject}
          count={notificationsData.productNotificationTypes.length}
          fetchMoreData={() => {}}
          onChangeCheckbox={onChangeCheckbox}
          selectedItem={selectedNotification}
          isLoading={showLoading}
          breakWorkHeader={false}
          showCheckbox={true}
          tableHeight={'calc(100vh - 290px)'}
          keyName={'co-sell-notifications-table'}
        />
      </div>
      <FooterV2
        actions={
          <div className={classes.btnWrapper}>
            <ButtonV2
              styleType="outlined"
              data-testid={'discard-button-co-sell-notifications'}
              onClick={handleOnDiscard}
              disabled={!hasDataChanged()}
            >
              Discard
            </ButtonV2>
            <ButtonV2
              data-testid={'save-button-co-sell-notifications'}
              styleType="primary"
              type="submit"
              onClick={handleOnSave}
              disabled={!hasDataChanged()}
            >
              Save changes
            </ButtonV2>
            <ModalConfirmV2
              title="Discard changes?"
              icon={
                <FontAwesomeIcon
                  icon={faXmarkCircle}
                  data-testid="discard-icon-modal"
                />
              }
              onlyCloseModal
              onCloseModal={handleBackChanges}
              onDecline={handleBackChanges}
              content={
                <>
                  <div className={classes.modalContent}>
                    Are you sure you want to discard the changes you have made?
                  </div>
                </>
              }
              open={openDiscardModal}
              titleCancelButton={'Back'}
              titleAcceptButton={'Discard'}
              onAccept={handleDiscardChanges}
              variant={ModalSizeVariants.medium}
              hideCancelButton={false}
            />
            <ModalConfirmV2
              title="Save changes"
              icon={
                <FontAwesomeIcon
                  icon={faBookmark}
                  data-testid="save-co-sell-notifications-icon-modal"
                />
              }
              onlyCloseModal
              onCloseModal={handleSaveCancel}
              onDecline={handleSaveCancel}
              content={
                <div className={classes.modalContent}>
                  Are you sure you want to save these changes?
                </div>
              }
              acceptLoading={saveDataLoading}
              open={openSaveModal}
              titleCancelButton={'Cancel'}
              titleAcceptButton={'Save'}
              onAccept={handleSaveChanges}
              variant={ModalSizeVariants.medium}
              hideCancelButton={false}
            />
          </div>
        }
        width="100%"
        customClass={classes.footerRoot}
      />
      <ModalConfirmV2
        title="Select a Slack channel for all the selected notifications"
        icon={
          <FontAwesomeIcon
            icon={faUserPlus}
            data-testid="select-slack-channel-icon"
          />
        }
        onlyCloseModal
        onCloseModal={handleSlackCancel}
        onDecline={handleSlackCancel}
        content={
          <div className={classes.slackChannelModalContent}>
            <div className={classes.autoComplete}>
              <AutoComplete
                {...getSlackInputParams(
                  slackChannelsList,
                  isAdminOrOwner,
                  slackChannelModal
                )}
                onChange={handleSlackChannelChange(selectedNotification, true)}
                error={false}
                helperText={''}
                noOptionsText={null}
                filterOptions={(options: any[], state: any) => {
                  const filteredOptions = options.filter(option =>
                    option.label
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  )
                  if (filteredOptions.length === 0) {
                    return [
                      {
                        label: 'no-options',
                      },
                    ]
                  }
                  return filteredOptions
                }}
                renderOption={(
                  option: { label: string },
                  { className, ...getOptionProps }: any
                ) => {
                  if (option.label === 'no-options') {
                    return (
                      <div
                        {...getOptionProps}
                        className={classes.customOption}
                        onClick={event => event.stopPropagation()}
                      >
                        <NoOptionsCustomForAutoComplete
                          handleExternalIcon={handleExternalIcon}
                          showLink={showLink}
                        />
                      </div>
                    )
                  }
                  return <div {...getOptionProps}>{option.label}</div>
                }}
                clearOnBlur
              />
            </div>
            <Typography
              className={clsx(
                classes.discardModalContent,
                classes.modalDescription
              )}
            >
              By clicking the "Add" button, you confirm you will be sending the
              selected notifications on the above mentioned slack channel.
            </Typography>
          </div>
        }
        open={openSlackModal}
        titleCancelButton={'Cancel'}
        titleAcceptButton={'Add'}
        acceptDisabled={isEmpty(slackChannelModal)}
        onAccept={handleAddSlackChannels}
        variant={ModalSizeVariants.large}
        hideCancelButton={false}
      />
    </>
  )
}
