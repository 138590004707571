import React, { useEffect, useState } from 'react'
import { getIn, useFormikContext } from 'formik'
import { ButtonV2 } from '@labrav/react-components'
import { useStyles as commonStyles } from '../CloudSettingsWrapper.styles'
import { isEmpty } from 'lodash'

interface NextSectionButtonProps {
  sectionName: string
}

export const NextSectionButton = ({ sectionName }: NextSectionButtonProps) => {
  const [buttonClicked, setButtonClicked] = useState(false)
  const formik = useFormikContext<Record<string, Record<string, unknown>>>()
  const commonClasses = commonStyles()

  const handleOnClick = async () => {
    const sectionValues = formik.values[sectionName] || {}
    const promises = Object.keys(sectionValues).map(async key => {
      await formik.setFieldTouched(`${sectionName}.${key}`, true)
      try {
        await formik.validateField(`${sectionName}.${key}`)
      } catch (error) {
        console.warn(`Error in validation: ${sectionName}.${key}`, error)
      }
    })
    await Promise.all(promises)
    setButtonClicked(true)
  }

  const sectionErrors = formik.errors[sectionName]
  const doesSectionHaveErrors = !isEmpty(sectionErrors)

  useEffect(() => {
    // Only set the step as completed if the button is clicked
    // if the button is not clicked, but it has errors, then set the step as incomplete
    if (doesSectionHaveErrors) {
      formik.setFieldValue(`steps.${sectionName}`, false)
    } else if (buttonClicked) {
      formik.setFieldValue(`steps.${sectionName}`, true)
    }
    if (buttonClicked) {
      setButtonClicked(false)
    }
  }, [sectionName, doesSectionHaveErrors, buttonClicked])

  return (
    <ButtonV2
      className={commonClasses.nextButton}
      data-testid={`${sectionName}-next-button`}
      styleType="primary"
      onClick={handleOnClick}
    >
      Next
    </ButtonV2>
  )
}
