import { Reducer } from 'redux'
import { ReferralChangeType, ReferralFormActions } from './actions'
import { Referral, ReferralAPI, ReferralMetadata } from '../reducer'
import { mapReferralApiToReferral } from '../../../../common/utils/CoSellHelpers/helperFunctions'
import { SyncObjectType } from '../../salesPipeline/syncObject/reducer'
import { ReferralStage, SyncRecord } from '../../../components/CoSell/types'
import { string } from 'yup'

export enum ReferralFormSectionName {
  CUSTOMER_DETAILS = 'customerDetails',
  PROJECT_DETAILS = 'projectDetails',
  AWS_DETAILS = 'awsDetails',
  CONTACTS = 'contacts',
  SAAS_DOCUMENTATION = 'saasDocumentation',
  AWS_CONTACTS_DETAILS = 'awsContactsDetails',
  EXTRA = 'extra',
}

export interface CustomerDetails {
  companyName: string
  website: string
  industry: string
  duns?: string
  city?: string
  state?: string
  country: string
  postalCode?: string
  firstName?: string
  lastName?: string
  title?: string
  email?: string
  phone?: string
  streetAddress?: string
  hasClassifiedNationalSecurityInformation?: string
  otherIndustry?: string
}

export interface ProjectDetails {
  partnerPrimaryNeedFromAws: string[]
  opportunityType: string
  expectedMonthlyAwsRevenue?: number
  partnerProjectTitle: string
  salesActivities: string[]
  customerBusinessProblem: string
  solutionOffered: string[]
  otherSolutionOffered?: string | null
  useCase: string
  deliveryModel: string[]
  targetCloseDate: string
  isThisFromMarketing: string
  marketingFundsUsed: string | null
  marketingActivityUseCase?: string[]
  marketingActivityChannel?: string[]
  marketingCampaignCode?: string | null
  opportunityOwnerEmail: string
  opportunityOwnerName: string
  stage?: ReferralStage
  nextStep?: string
  nextStepHistory?: string
  referredBy?: string
  acceptanceStatus?: string
  rejectionReason?: string
  additionalComments?: string
  parentOpportunityId?: string
}

export interface Contacts {
  partnerContactFirstName: string
  partnerContactLastName: string
  partnerSalesContactPhone: string
  partnerSalesContactEmail: string
  partnerSalesContactTitle?: string
}

export interface AwsContactsDetails {
  awsAccountManagerName?: string
  awsAccountManagerEmail?: string
  awsIsvSuccessManagerName?: string
  awsIsvSuccessManagerEmail?: string
  awsSalesRepName?: string
  awsSalesRepEmail?: string
  pdmName?: string
  pdmEmail?: string
  psmName?: string
  psmEmail?: string
  wwpsPdm?: string
  wwpsPdmEmail?: string
}

export interface AwsDetails {
  apnProgram?: string[]
  awsProducts: string[]
  awsAccountId?: string
  offerId?: string
  closedReason?: string
  awsClosedReason?: string
  competitiveTracking?: string
  competitiveTrackingOther?: string
  partnerCrmUniqueIdentifier?: string
  awsCloseDate?: string
  engagementScore?: string
  recommendedNextBestActions?: string
  customerSegment?: string
  isGreenfield?: string
}

export interface Extra {
  stage?: ReferralStage
  acceptanceStatus?: string
  rejectionReason?: string
  nextStep?: string
  nextStepHistory?: string
  offerId?: string
}

export interface SaaSDocumentation {
  contractEffectiveDate: string
  currency: string
  customerSoftwareValue: number | null
  contractExpirationDate: string
  procurementType: string
}

export interface ProductListType {
  entityId: string
  productTitle: string
  entityArn?: string
}

export interface offerData {
  offerId: string
  offerName: string
  productId: string
  offerArn: string
}

export interface PrivateOfferData {
  productsList?: ProductListType[]
  selectedProductId?: string
  privateOffersList?: ProductListType[]
  selectedPrivateOfferId?: string
  selectedPrivateOfferArn?: string
  selectedPrivateOfferName?: string
  validatedData?: offerData
}

export type PartialReferralObject = {
  referralMetadata: Partial<ReferralMetadata>
  referralObject: Partial<ReferralAPI>
  referralObjectTransformed?: Partial<Referral>
  referralSyncs?: SyncRecord[]
}

export type ReferralOppSections = {
  [ReferralFormSectionName.CUSTOMER_DETAILS]: CustomerDetails
  [ReferralFormSectionName.PROJECT_DETAILS]: ProjectDetails
  [ReferralFormSectionName.CONTACTS]: Contacts
  [ReferralFormSectionName.AWS_DETAILS]: AwsDetails
  [ReferralFormSectionName.SAAS_DOCUMENTATION]?: SaaSDocumentation
  [ReferralFormSectionName.AWS_CONTACTS_DETAILS]?: AwsContactsDetails
  [ReferralFormSectionName.EXTRA]?: Extra
}

export type ReferralOppSectionFields =
  | keyof CustomerDetails
  | keyof ProjectDetails
  | keyof Contacts
  | keyof AwsDetails
  | keyof SaaSDocumentation
  | keyof AwsContactsDetails
  | keyof Extra

export enum LabraReferralStatus {
  RECEIVED_FROM_AWS = 'Received from AWS',
  DRAFT = 'Draft',
  SUBMITTED = 'Submitted',
  APPROVED = 'Approved',
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  CLOSED_LOST = 'Closed Lost',
  LAUNCHED = 'Launched',
}

export interface LabraReferralStatusHistory {
  status: LabraReferralStatus
  updated_at: string | undefined
}

export interface ReferralForm {
  referralForm: ReferralOppSections
  referral?: PartialReferralObject
  linkedPrivateOffer?: PrivateOfferData
  formChanged: boolean
  nextStepInputMessage?: string
  errorsFromBackend?: Partial<Record<keyof Referral, string>>
  statusHistory?: LabraReferralStatusHistory[]
}

// The fields need to be here, even as undefined, to be able to show the errors that come from backend.
// Refer to: ReferralFormSubmit.tsx:62
export const initialState: ReferralForm = {
  referralForm: {
    customerDetails: {
      companyName: '',
      website: '',
      industry: '',
      duns: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
      hasClassifiedNationalSecurityInformation: '',
    },
    projectDetails: {
      partnerPrimaryNeedFromAws: [],
      opportunityType: '',
      parentOpportunityId: undefined,
      additionalComments: undefined,
      expectedMonthlyAwsRevenue: undefined,
      partnerProjectTitle: '',
      salesActivities: [],
      customerBusinessProblem: '',
      solutionOffered: [],
      useCase: '',
      deliveryModel: [],
      targetCloseDate: '',
      isThisFromMarketing: '',
      marketingFundsUsed: '',
      opportunityOwnerName: '',
      opportunityOwnerEmail: '',
      stage: 'Prospect',
      nextStep: '',
      marketingActivityChannel: [],
      marketingActivityUseCase: [],
      otherSolutionOffered: undefined,
      referredBy: 'Partner Referral',
    },
    contacts: {
      partnerContactFirstName: '',
      partnerContactLastName: '',
      partnerSalesContactPhone: '',
      partnerSalesContactEmail: '',
    },
    awsDetails: {
      awsProducts: [],
    },
    saasDocumentation: {
      contractEffectiveDate: '',
      currency: '',
      customerSoftwareValue: 0,
      contractExpirationDate: '',
      procurementType: '',
    },
  },
  formChanged: false,
  nextStepInputMessage: '',
  statusHistory: undefined,
}

type actionType =
  | {
      type: ReferralFormActions.SET_REFERRAL_FORM
      payload: ReferralOppSections
    }
  | {
      type: ReferralFormActions.UPDATE_REFERRAL_FORM
      payload: ReferralChangeType
    }
  | {
      type: ReferralFormActions.SET_REFERRAL
      payload: PartialReferralObject
    }
  | {
      type: ReferralFormActions.SET_SELECTED_PRODUCT
      payload: string
    }
  | {
      type: ReferralFormActions.SET_PRODUCTS_LIST
      payload: ProductListType[]
    }
  | {
      type: ReferralFormActions.SET_SELECTED_PRIVATE_OFFER
      payload: {
        selectedPrivateOfferId: string
        selectedPrivateOfferName: string
        selectedPrivateOfferArn: string
      }
    }
  | {
      type: ReferralFormActions.SET_PRIVATE_OFFERS_LIST
      payload: ProductListType[]
    }
  | {
      type: ReferralFormActions.SET_LINKED_PRIVATE_OFFER_DATA
      payload: offerData
    }
  | {
      type: ReferralFormActions.SET_FORM_REFERRAL_ERRORS
      payload: Partial<Record<keyof Referral, string>>
    }
  | {
      type: ReferralFormActions.CLEAR_REFERRAL_FORM
    }
  | {
      type: ReferralFormActions.SET_NEXT_STEP_INPUT_MESSAGE
      payload: string
    }
  | {
      type: ReferralFormActions.SET_LABRA_REFERRAL_STATUS_HISTORY
      payload: LabraReferralStatusHistory[]
    }
  | {
      type: ReferralFormActions.ADD_LABRA_REFERRAL_STATUS_HISTORY
      payload: LabraReferralStatusHistory
    }

export const reducer: Reducer<ReferralForm, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ReferralFormActions.SET_REFERRAL_FORM: {
      return {
        ...state,
        referralForm: action.payload,
      }
    }
    case ReferralFormActions.SET_NEXT_STEP_INPUT_MESSAGE: {
      return {
        ...state,
        nextStepInputMessage: action.payload,
      }
    }
    case ReferralFormActions.SET_SELECTED_PRODUCT: {
      return {
        ...state,
        linkedPrivateOffer: {
          ...state.linkedPrivateOffer,
          selectedProductId: action.payload,
        },
      }
    }
    case ReferralFormActions.SET_PRODUCTS_LIST: {
      return {
        ...state,
        linkedPrivateOffer: {
          ...state.linkedPrivateOffer,
          productsList: action.payload,
        },
      }
    }
    case ReferralFormActions.SET_SELECTED_PRIVATE_OFFER: {
      return {
        ...state,
        linkedPrivateOffer: {
          ...state.linkedPrivateOffer,
          selectedPrivateOfferId: action.payload.selectedPrivateOfferId,
          selectedPrivateOfferName: action.payload.selectedPrivateOfferName,
          selectedPrivateOfferArn: action.payload.selectedPrivateOfferArn,
        },
      }
    }
    case ReferralFormActions.SET_PRIVATE_OFFERS_LIST: {
      return {
        ...state,
        linkedPrivateOffer: {
          ...state.linkedPrivateOffer,
          privateOffersList: action.payload,
        },
      }
    }
    case ReferralFormActions.SET_LINKED_PRIVATE_OFFER_DATA: {
      return {
        ...state,
        linkedPrivateOffer: {
          ...state.linkedPrivateOffer,
          validatedData: action.payload,
        },
      }
    }
    case ReferralFormActions.UPDATE_REFERRAL_FORM: {
      const { sectionName, data } = action.payload
      return {
        ...state,
        referralForm: {
          ...state.referralForm,
          [sectionName]: {
            ...state.referralForm[sectionName],
            ...data,
          },
        },
      }
    }
    case ReferralFormActions.SET_REFERRAL: {
      const referralsObject = mapReferralApiToReferral(
        action.payload.referralObject
      )
      return {
        ...state,
        referral: {
          ...state.referral,
          ...action.payload,
          referralObjectTransformed: referralsObject,
        },
      }
    }
    case ReferralFormActions.SET_FORM_REFERRAL_ERRORS: {
      return {
        ...state,
        errorsFromBackend: {
          ...action.payload,
        },
      }
    }
    case ReferralFormActions.CLEAR_REFERRAL_FORM: {
      return (state = initialState)
    }
    case ReferralFormActions.SET_LABRA_REFERRAL_STATUS_HISTORY: {
      return {
        ...state,
        statusHistory: action.payload,
      }
    }
    case ReferralFormActions.ADD_LABRA_REFERRAL_STATUS_HISTORY: {
      return {
        ...state,
        statusHistory: [...(state.statusHistory || []), action.payload],
      }
    }

    default: {
      return state
    }
  }
}
