import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faStore,
  faBullhorn,
  faClipboardCheck,
  faChartLine,
  faCog,
  faPuzzlePiece,
  faBuilding,
  faCloud,
  faSquareSliders,
  faHandshake,
} from '@fortawesome/pro-regular-svg-icons'
import { Chip } from '@material-ui/core'
import { useStyles } from '../SidePanelWrapperV2/TeamMembersWrapper.styles'
import clsx from 'clsx'
import {
  SecondaryNavigationRoutesType,
  SecondarySubRouteType,
} from '@labrav/react-components/lib/@types/v3/components/SidePanel'
import { PartnershipTypeValue, Product } from '../../modules/partner/action'
import { CRMTypes } from '../../utils/constants'

export const DisabledRoutesTooltip = () => {
  return (
    <div style={{ padding: '12px 16px 0px 16px' }}>
      This module is not included in your current plan.
    </div>
  )
}

export const planLockDisabledProps = {
  disabledToolTipMsg: <DisabledRoutesTooltip />,
  horizontalPlacement: 'center' as SecondarySubRouteType['horizontalPlacement'],
  verticalPlacement: 'bottom' as SecondarySubRouteType['verticalPlacement'],
  popoverActionName: 'Contact support',
  popoverAction: () =>
    window.open('https://helpcenter.labra.io/hc/en-us/requests/new'),
}

const comingSoonDisabledProps = {
  disabledToolTipMsg: 'Coming soon',
  horizontalPlacement: 'center' as SecondarySubRouteType['horizontalPlacement'],
  verticalPlacement: 'bottom' as SecondarySubRouteType['verticalPlacement'],
}

const marketplaceCompleteOnboardingProps = {
  disabledToolTipMsg:
    'Complete your onboarding for marketplace to access these feature',
  horizontalPlacement: 'center' as SecondarySubRouteType['horizontalPlacement'],
  verticalPlacement: 'bottom' as SecondarySubRouteType['verticalPlacement'],
  popoverActionName: undefined,
  popoverAction: undefined,
}

const coSellCompleteOnboardingProps = {
  disabledToolTipMsg:
    'Complete your onboarding for co-sell to access these feature',
  horizontalPlacement: 'center' as SecondarySubRouteType['horizontalPlacement'],
  verticalPlacement: 'bottom' as SecondarySubRouteType['verticalPlacement'],
  popoverActionName: undefined,
  popoverAction: undefined,
}

export const marketplaceOnboardingRoute = [
  {
    key: 0,
    paths: ['/flyout/onboarding/company-information'],
    name: 'Onboarding',
    icon: () => (
      <FontAwesomeIcon style={{ height: '16px' }} icon={faBuilding} />
    ),
    disabled: false,
  },
]

export const coSellOnboardingRoute = [
  {
    key: 0,
    paths: ['/oppsync/onboarding/company-information'],
    name: 'Onboarding',
    icon: () => (
      <FontAwesomeIcon style={{ height: '16px' }} icon={faBuilding} />
    ),
    disabled: false,
  },
]

// Marketplace
export const MarketplaceNavigationRoutes: SecondarySubRouteType[] = [
  {
    key: 1,
    paths: ['/flyout/overview'],
    name: 'Dashboard',
    disabled: false,
    keyName: 'DASHBOARD',
  },
  {
    key: 2,
    paths: ['/flyout/products-listing'],
    name: 'Listings',
    disabled: false,
    keyName: 'LISTING',
    ...planLockDisabledProps,
  },
  {
    key: 3,
    paths: [
      '/flyout/offers-authorizations/private-offers',
      '/flyout/offers-authorizations/channel-offers',
    ],
    name: 'Offers',
    disabled: false,
    keyName: 'OFFERS',
    ...planLockDisabledProps,
  },
  {
    key: 4,
    paths: ['/flyout/offers-authorizations/resale-authorizations'],
    name: 'Reseller authorizations',
    disabled: false,
    keyName: 'RESELLER_AUTHORIZATIONS',
    ...planLockDisabledProps,
  },
  {
    key: 5,
    paths: ['/flyout/subscriptions'],
    name: 'Contracts',
    disabled: false,
    keyName: 'CONTRACTS',
    ...planLockDisabledProps,
  },
  {
    key: 6,
    paths: ['/flyout/revenue'],
    name: 'Revenue',
    disabled: false,
    keyName: 'REVENUE',
    ...planLockDisabledProps,
  },
  {
    key: 7,
    paths: ['/flyout/buyers'],
    name: 'Buyers',
    disabled: false,
    keyName: 'BUYERS',
    endIcon: () => {
      const classes = useStyles()
      const isSelected = !window.location.href.includes('/flyout/buyers')
      return (
        <Chip
          label={'New'}
          className={clsx(classes.chip, isSelected ? classes.selectedChip : '')}
        />
      )
    },
    ...planLockDisabledProps,
  },
]

export const MarketplaceUsageMeteringRoute: SecondarySubRouteType[] = [
  {
    key: 8,
    paths: ['/flyout/usage-metering'],
    name: 'Usage metering',
    disabled: false,
    keyName: 'USAGE_METERING',
    endIcon: () => {
      const classes = useStyles()
      const isSelected = !window.location.href.includes(
        '/flyout/usage-metering'
      )
      return (
        <Chip
          label={'New'}
          className={clsx(classes.chip, isSelected ? classes.selectedChip : '')}
        />
      )
    },
    ...planLockDisabledProps,
  },
]
export const getMarketplaceRoutes: (
  feature: string[],
  isOnboarded: boolean,
  isSubscribed: boolean,
  activeProducts: Product[],
  isRoute: boolean,
  partnerId: string,
  customerName: string,
  trackEvent: (
    heading: string,
    subHeading: string,
    partnerId: string,
    customerName: string
  ) => void,
  {
    isResellerAuthorizationEnable,
    isChannelOffersEnable,
    isUsageMeteringEnable,
  }: {
    isResellerAuthorizationEnable: boolean
    isChannelOffersEnable?: boolean
    isUsageMeteringEnable?: boolean
  },
  partnershipType: PartnershipTypeValue[]
) => SecondaryNavigationRoutesType[] = (
  feature,
  isOnboarded,
  isSubscribed,
  activeProducts,
  isRoute,
  partnerId,
  customerName,
  trackEvent,
  {
    isResellerAuthorizationEnable,
    isChannelOffersEnable,
    isUsageMeteringEnable,
  },
  partnershipType
) => [
  {
    key: 'marketplace',
    name: 'Marketplace',
    subRoutes: [
      ...(!isOnboarded && isSubscribed
        ? [
            {
              key: 0,
              paths: !activeProducts?.includes('flyout')
                ? ['/product-selection']
                : ['/flyout/onboarding/company-information'],
              name: 'Onboarding',
              icon: () => (
                <FontAwesomeIcon style={{ height: '16px' }} icon={faBuilding} />
              ),
              disabled: false,
            },
          ]
        : []),
      ...[
        ...MarketplaceNavigationRoutes,
        ...(isUsageMeteringEnable ? MarketplaceUsageMeteringRoute : []),
      ]?.map(marketplaceItem => ({
        ...marketplaceItem,
        disabled: !!(isOnboarded
          ? marketplaceItem?.keyName !== 'DASHBOARD' &&
            marketplaceItem?.keyName &&
            !feature?.includes(marketplaceItem?.keyName)
          : marketplaceItem?.keyName === 'DASHBOARD'
          ? false
          : true),
        ...(marketplaceItem?.keyName === 'DASHBOARD'
          ? {
              disabled: isSubscribed && !isOnboarded,
              paths: !isSubscribed
                ? ['/flyout/block-page']
                : ['/flyout/overview'],
            }
          : {}),
        ...(!isSubscribed && marketplaceItem?.keyName !== 'DASHBOARD'
          ? { ...planLockDisabledProps }
          : {}),
        ...(isSubscribed && !isOnboarded
          ? { ...marketplaceCompleteOnboardingProps }
          : {}),
        ...(isResellerAuthorizationEnable &&
        marketplaceItem?.keyName === 'RESELLER_AUTHORIZATIONS'
          ? {
              paths: partnershipType?.includes('CONSULTING_PARTNER')
                ? [
                    '/flyout/reseller-authorizations/received',
                    '/flyout/reseller-authorizations/sent',
                  ]
                : ['/flyout/reseller-authorizations/sent'],
            }
          : {}),

        ...(marketplaceItem?.keyName === 'OFFERS' &&
        isChannelOffersEnable &&
        partnershipType?.includes('CONSULTING_PARTNER')
          ? { paths: marketplaceItem?.paths?.sort() }
          : {}),
        onClick: () => {
          trackEvent(
            'Marketplace',
            marketplaceItem.name,
            partnerId,
            customerName
          )
        },
      })),
    ].sort(
      (
        navigateRouteOne: SecondarySubRouteType,
        navigateRouteTwo: SecondarySubRouteType
      ) => navigateRouteOne.key - navigateRouteTwo.key
    ),
    icon: () => <FontAwesomeIcon icon={faStore} />,
    disabled: false,
    byDefault: (isOnboarded && isSubscribed && isRoute) || isRoute,
  },
]

// Co-sell
export const CosellNavigationRoutes: SecondarySubRouteType[] = [
  {
    key: 1,
    paths: ['/oppsync/dashboard'],
    name: 'Dashboard',
    disabled: true,
    keyName: 'DASHBOARD',
    ...comingSoonDisabledProps,
  },
  {
    key: 2,
    paths: ['/oppsync/sales?object_type=opportunity'],
    name: 'Pipeline',
    disabled: false,
    keyName: 'PIPELINE',
  },
]
export const getCosellRoutes: (
  feature: string[],
  isOnboarded: boolean,
  isSubscribed: boolean,
  activeProducts: Product[],
  isRoute: boolean,
  isFlyoutSubscribed: boolean,
  partnerId: string,
  customerName: string,
  trackEvent: (
    heading: string,
    subHeading: string,
    partnerId: string,
    customerName: string
  ) => void
) => SecondaryNavigationRoutesType[] = (
  feature,
  isOnboarded,
  isSubscribed,
  activeProducts,
  isRoute,
  isFlyoutSubscribed,
  partnerId,
  customerName,
  trackEvent
) => [
  {
    key: 'co-sell',
    name: 'Co-sell',
    subRoutes: [
      ...(!isOnboarded && isSubscribed
        ? [
            {
              key: 0,
              paths: activeProducts?.includes('oppsync')
                ? ['/oppsync/onboarding/company-information']
                : ['/product-selection'],
              name: 'Onboarding',
              icon: () => (
                <FontAwesomeIcon style={{ height: '16px' }} icon={faBuilding} />
              ),
              disabled: false,
            },
          ]
        : []),
      {
        key: 1,
        paths: ['/oppsync/dashboard'],
        name: 'Dashboard',
        disabled: true,
        keyName: 'DASHBOARD',
        ...comingSoonDisabledProps,
      },
      {
        key: 2,
        paths: !isSubscribed
          ? ['/oppsync/block-page']
          : ['/oppsync/sales?object_type=opportunity'],
        name: 'Pipeline',
        keyName: 'PIPELINE',
        disabled: isSubscribed && !isOnboarded,
        ...(isSubscribed && !isOnboarded
          ? { ...coSellCompleteOnboardingProps }
          : {}),
        onClick: () => {
          trackEvent('Co-sell', 'Pipeline', partnerId, customerName)
        },
      },
    ],
    icon: () => <FontAwesomeIcon icon={faHandshake} />,
    disabled: false,
    byDefault:
      (isSubscribed && isOnboarded && !isFlyoutSubscribed && isRoute) ||
      isRoute,
  },
]

// Sales IQ
export const SalesIqPropensityRoute: SecondarySubRouteType[] = [
  {
    key: 1,
    paths: ['/oppsync/propensity'],
    name: 'Propensity Scores',
    disabled: false,
    keyName: 'SALES_INTEL',
  },
]
export const SalesIqNavigationRoutes: SecondarySubRouteType[] = [
  // {
  //   key: 2,
  //   paths: [''],
  //   name: 'Account mapping',
  //   disabled: true,
  //   disabledToolTipMsg: 'Coming Soon',
  //   keyName: 'ACCOUNT_MAPPING',
  //   ...planLockDisabledProps
  // },
]
export const getSalesIqRoutes: (
  feature: string[]
) => SecondaryNavigationRoutesType[] = feature => [
  {
    key: 'sales-iq',
    name: 'Labra AI',
    subRoutes: [...SalesIqNavigationRoutes].map(salesIqItem => ({
      ...salesIqItem,
      disabled: !!(
        salesIqItem?.keyName && !feature?.includes(salesIqItem?.keyName)
      ),
    })),
    icon: () => <FontAwesomeIcon icon={faChartLine} />,
    disabled: false,
  },
]

// Marketing
export const MarketplaceSeo: SecondarySubRouteType[] = [
  {
    key: 1,
    paths: ['/flyout/search-ranking'],
    name: 'Search ranking',
    disabled: false,
    keyName: 'SEARCH_RANKING',
    endIcon: () => {
      const classes = useStyles()
      const isSelected = !window.location.href.includes(
        '/flyout/search-ranking'
      )
      return (
        <Chip
          label={'New'}
          className={clsx(classes.chip, isSelected ? classes.selectedChip : '')}
        />
      )
    },
  },
]
export const StorefrontRoute: SecondarySubRouteType[] = [
  {
    key: 2,
    paths: ['/flyout/storefront'],
    name: 'Storefront',
    disabled: false,
    keyName: 'STOREFRONT',
    endIcon: () => {
      const classes = useStyles()
      const isSelected = !window.location.href.includes('/flyout/storefront')
      return (
        <Chip
          label={'New'}
          className={clsx(classes.chip, isSelected ? classes.selectedChip : '')}
        />
      )
    },
    ...planLockDisabledProps,
  },
]
export const StorefrontMetricsRoute: SecondarySubRouteType[] = [
  {
    key: 3,
    paths: ['/flyout/storefront-metrics'],
    name: 'Storefront insights',
    disabled: false,
    keyName: 'STOREFRONT_METRICS',
    ...planLockDisabledProps,
  },
]
export const MarketingNavigationRoutes: SecondarySubRouteType[] = [
  ...MarketplaceSeo,
  ...StorefrontRoute,
  ...StorefrontMetricsRoute,
]
export const getMarketing: (
  feature: string[]
) => SecondaryNavigationRoutesType[] = feature => [
  {
    key: 'marketing',
    name: 'Marketing',
    subRoutes: [...MarketingNavigationRoutes].map(marketingItem => ({
      ...marketingItem,
      disabled: !!(
        marketingItem?.keyName && !feature?.includes(marketingItem?.keyName)
      ),
    })),
    icon: () => <FontAwesomeIcon icon={faBullhorn} />,
    disabled: false,
  },
]

// Assessments
export const AssessmentsNavigationRoutes: SecondarySubRouteType[] = [
  {
    key: 1,
    paths: ['/cloudfaas/ftr'],
    name: 'FTR',
    disabled: false,
    keyName: 'FTR',
  },
  // {
  //   key: 2,
  //   paths: ['/cloudfaas/wafr'],
  //   name: 'WAFR',
  //   disabled: false,
  //   keyName: 'WAFR',
  //   ...planLockDisabledProps,
  // },
]
export const getAssessmentsRoutes: (
  feature: string[],
  isRoute: boolean,
  partnerId: string,
  customerName: string,
  trackEvent: (
    heading: string,
    subHeading: string,
    partnerId: string,
    customerName: string
  ) => void
) => SecondaryNavigationRoutesType[] = (
  feature,
  isRoute,
  partnerId,
  customerName,
  trackEvent
) => [
  {
    key: 'assessments',
    name: 'Assessments',
    subRoutes: [...AssessmentsNavigationRoutes].map(assessmentItem => ({
      ...assessmentItem,
      disabled: false,
      onClick: () => {
        trackEvent('Assessments', assessmentItem.name, partnerId, customerName)
      },
      // !!(
      //   assessmentItem?.keyName && !feature?.includes(assessmentItem?.keyName)
      // ),
    })),
    icon: () => <FontAwesomeIcon icon={faClipboardCheck} />,
    disabled: false,
    byDefault: isRoute,
  },
]

// Integrations
export const IntegrationsCRMNavigationRoutes: SecondarySubRouteType[] = [
  {
    key: 1,
    paths: ['/settings/crm'],
    name: 'Salesforce',
    disabled: false,
    keyName: 'salesforce',
    ...planLockDisabledProps,
  },
  {
    key: 2,
    paths: ['/settings/crm'],
    name: 'Hubspot',
    disabled: false,
    keyName: 'hubspot',
    ...planLockDisabledProps,
  },
]
export const IntegrationsNavigationRoutes: SecondarySubRouteType[] = [
  {
    key: 3,
    paths: ['/settings/integrations'],
    name: 'Slack',
    disabled: false,
    keyName: 'SLACK',
  },
  // {
  //   key: 4,
  //   paths: ['/settings/integrations'],
  //   name: 'Marketo',
  //   disabled: false,
  //   keyName: 'MARKETO',
  // },
]
export const getIntegrationsRoutes: (
  feature: string[],
  crmType: CRMTypes | null | undefined,
  isRoute: boolean,
  partnerId: string,
  customerName: string,
  trackEvent: (
    heading: string,
    subHeading: string,
    partnerId: string,
    customerName: string
  ) => void
) => SecondaryNavigationRoutesType[] = (
  feature,
  crmType,
  isRoute,
  partnerId,
  customerName,
  trackEvent
) => [
  {
    key: 'integrations',
    name: 'Integrations',
    subRoutes: [
      ...(crmType === CRMTypes.salesforce || crmType === CRMTypes.labra
        ? [
            {
              key: 1,
              paths: ['/settings/crm'],
              name: 'Salesforce',
              disabled: crmType === CRMTypes.labra,
              keyName: 'salesforce',
              ...planLockDisabledProps,
              onClick: () =>
                trackEvent(
                  'Integrations',
                  'Salesforce',
                  partnerId,
                  customerName
                ),
            },
          ]
        : []),
      ...(crmType === CRMTypes.hubspot || crmType === CRMTypes.labra
        ? [
            {
              key: 2,
              paths: ['/settings/crm'],
              name: 'Hubspot',
              disabled: crmType === CRMTypes.labra,
              keyName: 'hubspot',
              ...planLockDisabledProps,
              onClick: () =>
                trackEvent('Integrations', 'Hubspot', partnerId, customerName),
            },
          ]
        : []),
      ...IntegrationsNavigationRoutes.map(route => ({
        ...route,
        onClick: () =>
          trackEvent('Integrations', `${route.name}`, partnerId, customerName),
      })),
    ],
    icon: () => <FontAwesomeIcon icon={faPuzzlePiece} />,
    disabled: false,
    byDefault: isRoute,
  },
]

// Settings
export const SettingsNavigationRoutes: SecondarySubRouteType[] = [
  {
    key: 1,
    paths: ['/settings/app-settings'],
    name: 'App settings',
    disabled: false,
  },
  {
    key: 2,
    paths: ['/settings/users'],
    name: 'User management',
    disabled: false,
  },
]
export const NotificationsRoute: SecondarySubRouteType[] = [
  {
    key: 3,
    paths: [
      '/settings/notifications/marketplace',
      '/settings/notifications/co-sell',
    ],
    name: 'Notifications',
    disabled: false,
  },
]

// Onboarding Routes
export const CoSellOnboardingRoutes: {
  title: string
  routes: SecondaryNavigationRoutesType[]
} = {
  title: 'Onboarding',
  routes: [
    {
      key: 'company-information',
      paths: ['/oppsync/onboarding/company-information'],
      name: 'Company details',
      icon: () => (
        <FontAwesomeIcon style={{ height: '16px' }} icon={faBuilding} />
      ),
      disabled: false,
    },
    {
      key: 'aws-settings',
      paths: ['/oppsync/onboarding/aws-settings'],
      name: 'Cloud settings',
      icon: () => <FontAwesomeIcon style={{ height: '16px' }} icon={faCloud} />,
      disabled: false,
    },
    {
      key: 'crm-settings',
      paths: ['/oppsync/onboarding/crm-settings'],
      name: 'CRM settings',
      icon: () => (
        <FontAwesomeIcon style={{ height: '16px' }} icon={faSquareSliders} />
      ),
      disabled: false,
    },
  ],
}

export const MarketplaceOnboardingRoutes: {
  title: string
  routes: SecondaryNavigationRoutesType[]
} = {
  title: 'Onboarding steps -',
  routes: [
    {
      key: 'company-information',
      paths: ['/flyout/onboarding/company-information'],
      name: 'Company details',
      icon: () => (
        <FontAwesomeIcon style={{ height: '16px' }} icon={faBuilding} />
      ),
      disabled: false,
    },
    {
      key: 'cloud-settings',
      paths: ['/flyout/onboarding/cloud-settings'],
      name: 'Cloud settings',
      icon: () => <FontAwesomeIcon style={{ height: '16px' }} icon={faCloud} />,
      disabled: false,
    },
    {
      key: 'crm-settings',
      paths: ['/flyout/onboarding/crm-settings'],
      name: 'CRM settings',
      icon: () => (
        <FontAwesomeIcon style={{ height: '16px' }} icon={faSquareSliders} />
      ),
      disabled: false,
    },
  ],
}
