import React from 'react'
import { CompleteOnboardingPage } from '../CompleteOnboardingPage/CompleteOnboardingPage'

export const MarketplaceOnboardingPage: React.FC<{
  customButton: React.ReactNode
}> = ({ customButton }) => {
  const productInfo = {
    title: 'Complete your onboarding',
    features: [
      {
        text: 'Create or migrate your listings.',
      },
      {
        text: 'Manage your listing(s) efficiently.',
      },
      {
        text: 'Create and manage private offers, agreement-based offers, and resale authorizations.',
      },
      {
        text: 'View and oversee your subscriptions.',
      },
      {
        text: 'Track your revenue and monitor your overall cloud journey.',
      },
    ],
    subheading:
      'By completing all onboarding steps, you’ll gain the ability to:',
    customButton: customButton,
  }
  return (
    <div>
      <CompleteOnboardingPage onboardingProductInfo={productInfo} />
    </div>
  )
}
