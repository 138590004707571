import { Typography } from '@material-ui/core'
import React from 'react'
import { useStyles } from './EnvironmentConnectionSection.styles'
import { ExternalLink } from '@labrav/react-components'
import { useUserType } from '../../../../../../common/utils/Hooks/usePartnerData'
import { PartnerType } from '../../../../../../common/modules/partner/action'
import { EnvironmentConnectionStatus } from './EnvironmentConnectionStatus/EnvironmentConnectionStatus'
import { ChangeEnvironment } from './Utils/ChangeEnvironment'
import { AwsPlatformSwitch } from './Utils/AwsPlatformSwitch'

export const EnvironmentConnectionSection = () => {
  const classes = useStyles()
  const partnerType = useUserType()

  const isAdmin = partnerType === PartnerType.Admin

  return (
    <>
      <div className={classes.mainContainer}>
        <div className={classes.leftContainer}>
          <EnvironmentConnectionStatus />
        </div>
        {isAdmin ? (
          <div className={classes.actionsContainer}>
            <AwsPlatformSwitch />
            <ChangeEnvironment />
          </div>
        ) : (
          <>
            <div className={classes.divider}></div>
            <div className={classes.rightContainer}>
              <Typography className={classes.changeEnvDescription}>
                To change environment, please{' '}
                <ExternalLink href={'https://helpcenter.labra.io/'}>
                  {' '}
                  contact us{' '}
                </ExternalLink>{' '}
              </Typography>
            </div>
          </>
        )}
      </div>
    </>
  )
}
