import ProductDetails1 from '../../common/images/ProductDetails_1.png'
import ProductDetails2 from '../../common/images/ProductDetails_2.png'

import Notifications1 from '../../common/images/Notifications_1.png'
import Notifications2 from '../../common/images/Notifications_2.png'
import Notifications3 from '../../common/images/Notifications_3.png'

import ProductDimension1 from '../../common/images/ProductDimension_1.png'
import ProductDimension2 from '../../common/images/ProductDimension_2.png'
import ProductDimension3 from '../../common/images/ProductDimension_3.png'

import LandingPage1 from '../../common/images/LandingPage_1.png'
import LandingPage2 from '../../common/images/LandingPage_2.png'
import LandingPage3 from '../../common/images/LandingPage_3.png'
import LandingPage4 from '../../common/images/LandingPage_4.png'

import { get, isBoolean, isEmpty, isNil } from 'lodash'
import { extractHexAndAlpha } from '../../common/utils/flyoutOnboardingBranding'
import {
  PlanType,
  RadioButtonType,
  StatusBarColors,
} from '@labrav/react-components'
import {
  ApplicationAccessMethod,
  FlyoutProductAddEditSectionName,
  FlyoutProductSections,
  StatusListingResObj,
} from '../modules/addEditProduct/reducer'
import aws from '../../common/images/aws.png'
import { DataType } from '@labrav/react-components/lib/@types/types/forms'
import { FormikErrors, FormikProps } from 'formik'
import azureMarketPlace from '../../common/images/azureMarketPlace.png'
import gcpMarketPlace from '../../common/images/gcpMarketPlace.png'
import salesforceMarketplace from '../../common/images/salesforceMarketplace.png'
import atlassian from '../../common/images/atlassian.png'
import alibaba from '../../common/images/alibaba.png'

export const imageFileUploadDescription =
  'The image file for your product logo must be in .png or .jpg format with a white or transparent background. Recommended image size is between 120 and 640 (either width or height) pixels with a 1:1 or 2:1 (wide) ratio'
export const notificationImageFileUploadDescription =
  'The image file for your email notification banner must be in .png or .jpg format with a white or transparent background, and width must be between 600 and 1200 pixels and minimum height 100 pixels in size with 6:1 (landscape) aspect ratio.'
export const imageFileUploadDescriptionLandingPage =
  'The image file for your buyer registration page must be in .png or .jpg format with a white or transparent background, and width must be between 1080 and 1200 pixels and minimum height 1440 pixels in size with a 3:4 (portrait) aspect ratio.'
export const fileUploadButtonText = 'Upload an image'
export const eulaFileUploadButtonText = 'Upload file'

export const fileFormatIncorrectText =
  'Failed to upload the image. Format is incorrect.'

export const fileUploadContainerTitle = 'Drag and drop image here'
export const eulaFileUploadContainerTitle =
  'Drag and drop (.pdf / .txt file) here'

export const eulaFileUploadDescription =
  'Provide a PDF file to the end-user license agreement (EULA) for your product. (File can’t exceed 5 MB.)'

export const imageLandingPageDescription =
  'The image file for your background image must be in .png or .jpg format.'

export const SFBanerText =
  'All common Salesforce AppExchange info has been fetched. Please fill out all mandatory fields to create a listing on AWS Marketplace.'
export const productDetailsImages = [
  {
    image: ProductDetails1,
    alt: 'Listing inside AWS marketplace',
    heading: 'Listing inside AWS marketplace',
  },
  {
    image: ProductDetails2,
    alt: 'Listing inside AWS marketplace',
    heading: 'Listing inside AWS marketplace',
  },
]
export const ProductDimensionsImages = [
  {
    image: ProductDimension1,
    alt: 'Product dimensions: SaaS Contract',
    heading: 'Product dimensions: SaaS Contract',
  },
  {
    image: ProductDimension2,
    alt: 'Product dimensions: SaaS Contract with additional usage',
    heading: 'Product dimensions: SaaS Contract with additional usage',
  },
  {
    image: ProductDimension3,
    alt: 'Product dimensions: SaaS subscription',
    heading: 'Product dimensions: SaaS subscription',
  },
]

export const notificationsImages = [
  {
    image: Notifications1,
    alt: 'Email sent to seller (you) via Labra',
    heading: 'Email sent to seller (you) via Labra',
  },
  {
    image: Notifications2,
    alt: 'Email sent to your buyer via Labra',
    heading: 'Email sent to your buyer via Labra',
  },
  {
    image: Notifications3,
    alt: 'Custom email sent to your buyer via Labra',
    heading: 'Custom email sent to your buyer via Labra',
  },
]

export const lifeCycleNotificationsTextAreaMappings: {
  [key: string]: { title: string; description?: string; note?: string }
} = {
  registrationCompleted: {
    title: 'Buyer registration completed',
    description:
      'When a user has bought your subscription on AWS Marketplace and has successfully completed the registration process on the Buyer registration page. for setting up their account.',
    note: 'Labra will send an email to your buyer to confirm their purchase and to the seller (you) to confirm a new customer.',
  },
  privateOfferRenewalReminder: {
    title: 'Buyer renewal reminder',
    description:
      'When your buyers renewal is coming up where you will like to be reminded regarding it.',
  },
  registrationIncompleteOffer: {
    title: 'Buyer registration pending',
    description:
      'When a user has bought your subscription on AWS Marketplace but has not completed the registration process on the Buyer registration page for setting up their account.',
  },
  subscribeFail: {
    title: 'Subscribe failed',
    description:
      'When a buyer has tried to subscribe to your listing but has failed to do so due to some error.',
  },
  entitlementRenew: {
    title: 'Entitlement renewed',
    description:
      'When a buyer’s initially bought entitlement has been renewed by them.',
  },
  entitlementExpired: {
    title: 'Entitlement expired',
    description: 'When the entitlement purchased by your buyer has expired.',
  },
  entitlementUpgrade: {
    title: 'Entitlement upgraded',
    description:
      'When a buyer upgrades from an old to a new entitlement which is being offered inside your listing.',
  },
  unsubscribePending: {
    title: 'Unsubscribe pending',
    description:
      'When a buyer unsubscribes from your product but it is still under process/pending. The buyer will be able to make use of your product till their unsubscription become successful.',
  },
  unsubscribeSuccess: {
    title: 'Unsubscribe success',
    description:
      'When the buyer has successfully unsubscribed and can no longer use your product.',
  },
}

export const listingNotificationTextAreaMappings = {
  awsProductListingLive: {
    title: 'Listing live on AWS Marketplace',
    description:
      'When AWS Testing has been completed and the Marketplace team has approved your listing to be Public i.e. buyers can start purchasing your listing.',
  },
  awsProductListingRejected: {
    title: 'Listing rejected by AWS Marketplace',
    description:
      'When AWS Testing has been completed and the Marketplace team has rejected your listing to be Public due to any AWS guidelines not being followed.',
  },
  freeTrialActivated: {
    title: 'Free trial activated',
    description:
      'When your buyer activates a free trial if it is offered in your listing.',
  },
  freeTrialDeactivated: {
    title: 'Free trial deactivated',
    description: 'When the free trial for your buyer ends or is deactivated.',
  },
}

export const privateOfferNotificationTeactAreaMappings = {
  privateOfferCreated: {
    title: 'Private offer created',
    description:
      'When a new private offer has been created by someone in your team.',
  },
  privateOfferAccepted: {
    title: 'Private offer accepted',
    description:
      'When a buyer you have sent a private offer to has accepted the offer and taken your product subscription.',
  },
  privateOfferExpiringSoon: {
    title: 'Private offer expiring soon',
    description:
      'When the private offer that you have shared with a buyer is about to expire, and the buyer has still not accepted the offer.',
  },
  privateOfferExpired: {
    title: 'Private offer expired',
    description:
      'When a private offer you shared with a buyer was not accepted and has expired.',
  },
  awsPrivateOfferRestrictionSuccess: {
    title: 'Private offer cancelled',
    description:
      'When a private offer is cancelled and a buyer cannot accept it thereafter.',
  },
}

export const resaleAuthorizationTextareaMappings = {
  awsResellerAgreementCreated: {
    title: 'Resale authorization created',
    description:
      'When a resale authorization has been created by someone in your team.',
  },
  awsResellerAgreementDeactivated: {
    title: 'Resale authorization cancelled',
    description:
      'When a resale authorization has been cancelled and a reseller can no longer accept it.',
  },
  awsResellerAgreementExpiringSeller: {
    title: 'Resale authorization expiring soon',
    description:
      'When a resale authorization which you have shared with a reseller is about to expire, and reseller has still not accept it.',
  },
  awsResellerAgreementExpiredSeller: {
    title: 'Resale authorization expired',
    description:
      'When a resale authorization which you shared with a reseller was not accepted and has expired.',
  },
}

export const meteringNotificationTextAreaMappings = {
  meteringSent: {
    title: 'Metering sent',
    description:
      'When you have sent the usage metering information for a buyer to the AWS Marketplace.',
  },
}

export const landingPageImages = [
  {
    image: LandingPage1,
    alt: 'For a new buyer',
    heading: 'For a new buyer',
  },
  {
    image: LandingPage2,
    alt: 'Submission success message',
    heading: 'Submission success message',
  },
  {
    image: LandingPage3,
    alt: 'For an existing buyer',
    heading: 'For an existing buyer',
  },
  {
    image: LandingPage4,
    alt: 'Email notification',
    heading: 'Email notification',
  },
]

export const hexadecimalColor = (color: string, percentage: number) => {
  if (isEmpty(color) || color?.length !== 7 || isNaN(percentage)) {
    return color
  }
  const decimal = `0${Math.round(255 * (percentage / 100)).toString(16)}`
    .slice(-2)
    .toUpperCase()
  return color + decimal
}

export const extractColorV2 = (colorType: string, colorString: string) => {
  if (colorString === '') {
    return colorType === 'RGB'
      ? ['0', '0', '0', '100', '']
      : ['0', '0', '0', '100', '']
  }
  const hexAndAlpha = extractHexAndAlpha(colorString)
  return colorType === 'RGB'
    ? colorString
        .substring(5, colorString.length - 1)
        .split(',')
        .map(function (value, index) {
          return index === 3 ? parseFloat(value.trim()) + '' : value.trim()
        })
        .concat('')
    : ['0', '0', '0', hexAndAlpha?.alpha || '100', hexAndAlpha?.hexCode || '']
}

export const getApplicationAccessMethodOptions = (disabled?: boolean) => {
  return [
    {
      key: ApplicationAccessMethod.TESTING_CREDENTIALS,
      label: 'Testing credentials',
      type: RadioButtonType.STANDARD,
      description:
        'Indicates your agreement to provide testing credentials for your application to the AWS Marketplace team for conducting UAT on your listing.',
      disabled: disabled,
    },
    {
      key: ApplicationAccessMethod.INSTRUCTIONS,
      label: 'Instructions',
      type: RadioButtonType.STANDARD,
      description:
        'Indicates that you will provide clear instructions to your buyers regarding how and when they will gain access to your application.',
      disabled: disabled,
    },
    {
      key: ApplicationAccessMethod.VIDEO,
      label: 'Demo product video',
      type: RadioButtonType.STANDARD,
      description:
        'Indicates that you will provide a video to your buyers showcasing a demo of the onboarding process and the application.',
      disabled: disabled,
    },
    {
      key: ApplicationAccessMethod.DOCUMENT,
      label: 'Onboarding document',
      type: RadioButtonType.STANDARD,
      description:
        'Indicates that you will provide a document which will outline how your buyers can onboard onto your application.',
      disabled: disabled,
    },
  ]
}

export const getPublicFacingSignupPageAvailableButtonOptions = (
  disabled?: boolean
) => [
  {
    key: 'Yes',
    label: 'Yes',
    type: RadioButtonType.STANDARD,
    description:
      'Indicates that a buyer has the ability to sign up for your application without your involvement.',
    disabled: disabled,
  },
  {
    key: 'No',
    label: 'No',
    type: RadioButtonType.STANDARD,
    description:
      'Indicates that a buyer does not have the ability to sign up for your application without your involvement.',
    disabled: disabled,
  },
]
export const isPublicFacingSignupPageAvailableCustomInstruction =
  'Please provide exact instructions on how buyers can gain access to your application. These instructions will be shared to the buyer in the purchase confirmation email, that is sent immediately after the buyer submit the form on the buyer registration page. '

export const customInstructionsText =
  'Please provide exact instructions on how buyers can gain access to your application. These instructions will be shared to the buyer in the subscription confirmation email sent after they submit the buyer registration page. '

export const publicFacingOptionalText =
  'Custom instructions that can be sent through email (Optional)'
export const nonPublicFacingOptionalText = 'Custom instructions'

export const customApplicationText =
  'Please provide your application login page URL where the user will be redirected 5 seconds after after submitting the form on the buyer registration page.'

export const applicationURLText =
  'Please provide your application login page URL where the user will be redirected 5 seconds after submitting buyer registration page.'

export const getDisableFormLockTooltip = (
  isFormLocked: boolean,
  name?: string
) =>
  isFormLocked ? `Unable to edit as ${name} is editing the form right now.` : ''

export const getPageProductCreation = (
  pathname?: string,
  isMigrated?: boolean
) => {
  const pageNameForProdutDetails = isMigrated
    ? FlyoutProductAddEditSectionName.MIGRATION_DETAILS
    : FlyoutProductAddEditSectionName.GENERAL_INFORMATION
  if (pathname) {
    if (pathname.includes('product-details')) {
      return pageNameForProdutDetails
    } else if (pathname.includes('product-dimensions')) {
      return FlyoutProductAddEditSectionName.PRODUCT_DIMENSIONS
    } else if (pathname.includes('landing-page')) {
      return FlyoutProductAddEditSectionName.LANDING_PAGE_DETAILS
    } else if (pathname.includes('notification-recipients')) {
      return FlyoutProductAddEditSectionName.NOTIFICATIONS_RECIPIENTS
    }
  }
}

export const getpurchasingOptions = (disabled: boolean) => [
  {
    key: 'multipleDimensions',
    label: 'Multiple dimensions per contract',
    type: RadioButtonType.STANDARD,
    description:
      'Buyers can purchase their desired quantities of units for each pricing dimension that you define.',
    disabled: disabled,
  },
  {
    key: 'singleDimension',
    label: 'Single dimension per contract',
    type: RadioButtonType.STANDARD,
    description:
      'Buyers can choose from a list of tiered pricing dimensions to purchase their desired tier or volume of units.',
    disabled: disabled,
  },
]

export const getScheduleTypeOptions = (disabled: boolean) => [
  {
    key: 'Monthly',
    label: 'Monthly',
    type: RadioButtonType.STANDARD,
    description: '',
    disabled: disabled,
  },
  {
    key: 'Quarterly',
    label: 'Quarterly',
    type: RadioButtonType.STANDARD,
    description: '',
    disabled: disabled,
  },
  {
    key: 'Yearly',
    label: 'Yearly',
    type: RadioButtonType.STANDARD,
    description: '',
    disabled: disabled,
  },
  {
    key: 'Custom',
    label: 'Custom',
    type: RadioButtonType.STANDARD,
    description: '',
    disabled: disabled,
  },
]

export const getProductTypeOptions = (disabled: boolean) => [
  {
    key: 'SAAS_CONTRACT',
    label: 'Software-as-a-Service (SaaS) Contract',
    type: RadioButtonType.STANDARD,
    description:
      'Buyers enter into an agreement with you entitling them to a specified quantity of use of your product for an agreed-upon length of time. You can choose to charge your buyers an additional per-unit price for their hourly usage on top of the contract price.',
    disabled: disabled,
  },
  {
    key: 'SAAS_SUBSCRIPTION',
    label: 'Software-as-a-Service (SaaS) Subscription',
    type: RadioButtonType.STANDARD,
    description:
      'A pay-as-you-go model where buyers are charged a per-unit price for their hourly usage of your product. AWS Marketplace calculates usage based on the metering records you provide.',
    disabled: disabled,
  },
]

export const addDimensionCategoryNameFields = {
  title: 'Select a unit type',
  placeHolder: 'Select a unit type',
  id: 'productDimensions.dimensionCategoryName',
  isRequired: false,
  humanReadableKey: 'selectDimensionCat',
  isCustom: false,
  defaultValue: null,
  dataType: 'enum' as DataType,
}

export const offerAvailabilityOptions = (disabled?: boolean) => [
  {
    key: 'all',
    label: 'All countries',
    type: RadioButtonType.STANDARD,
    description:
      'Your product will be available for buyers to purchase in all countries.',
    disabled: disabled,
  },
  {
    key: 'excludedCountries',
    label: 'All countries with exclusions',
    type: RadioButtonType.STANDARD,
    description:
      'Your product will be available for buyers to puchase in all countries except for the ones you exclude.',
    disabled: disabled,
  },
  {
    key: 'includedCountries',
    label: 'Allowlisted countries only',
    type: RadioButtonType.STANDARD,
    description:
      'Your product will be available for buyers to purchase only in the countries you specify.',
    disabled: disabled,
  },
]

export const isSignleFieldsErr = (
  singleFieldArray: string[],
  errors: FormikErrors<Record<string, unknown>>
) => {
  return singleFieldArray.some(
    singleFieldItem => !isEmpty(get(errors, singleFieldItem))
  )
}

export const externalMarketplaceJson = {
  title: 'Select an external marketplace',
  placeHolder: '',
  humanReadableKey: 'marketplace',
  id: 'marketplace',
  isRequired: true,
  isReadOnly: false,
  isCustom: false,
  defaultValue: '',
  dataType: 'enum' as DataType,
  options: [
    {
      label: 'SALESFORCE',
      value: 'Salesforce AppExchange',
      disabled: false,
      imageUrl: salesforceMarketplace,
    },
    {
      label: 'AZURE',
      value: 'Microsoft Marketplace',
      disabled: true,
      imageUrl: azureMarketPlace,
      disabledTooltip: 'Coming soon',
    },
    {
      label: 'GCP',
      value: 'Google Cloud Marketing',
      disabled: true,
      imageUrl: gcpMarketPlace,
      disabledTooltip: 'Coming soon',
    },
    {
      label: 'ALIBABA',
      value: 'Alibaba Marketplace',
      disabled: true,
      imageUrl: alibaba,
      disabledTooltip: 'Coming soon',
    },
    {
      label: 'ATLASSIAN',
      value: 'Atlassian Marketplace',
      disabled: true,
      imageUrl: atlassian,
      disabledTooltip: 'Coming soon',
    },
  ],
}

export const getPageMarkerForAutoSave = (
  formikValues: FormikProps<FlyoutProductSections>
) => {
  const isPage1Err = validateProductCreationFlow(
    FlyoutProductAddEditSectionName.GENERAL_INFORMATION,
    formikValues
  )
  const isPage2Err = validateProductCreationFlow(
    FlyoutProductAddEditSectionName.PRODUCT_DIMENSIONS,
    formikValues
  )
  const isPage3Err = validateProductCreationFlow(
    FlyoutProductAddEditSectionName.LANDING_PAGE_DETAILS,
    formikValues
  )
  const isPage4Err = validateProductCreationFlow(
    FlyoutProductAddEditSectionName.NOTIFICATIONS_RECIPIENTS,
    formikValues
  )
  return {
    ...(isPage1Err ? { page1: false } : {}),
    ...(isPage2Err ? { page2: false } : {}),
    ...(isPage3Err ? { page3: false } : {}),
    ...(isPage4Err ? { page4: false } : {}),
  }
}

export const validateProductCreationFlow = (
  page: FlyoutProductAddEditSectionName,
  formikValues: FormikProps<FlyoutProductSections>,
  isEdit?: boolean,
  previewErrorCheck?: boolean,
  flags?: {
    isFreeTrialEnable?: boolean
  }
) => {
  const hostWithLabra =
    formikValues?.values[FlyoutProductAddEditSectionName.GENERAL_INFORMATION]
      .hostWithLabra
  const isHostWithLabra = isBoolean(hostWithLabra) ? hostWithLabra : true
  if (previewErrorCheck) {
    return isSignleFieldsErr(
      [
        'infoCollectionFormMetaData.bgColor',
        'infoCollectionFormMetaData.buttonColor',
        'infoCollectionFormMetaData.buttonTextColor',
        'infoCollectionFormMetaData.headingMessage',
      ],
      formikValues?.errors
    )
  } else {
    const productType =
      formikValues?.values?.[
        FlyoutProductAddEditSectionName.CLOUD_PRODUCT_DETAILS
      ].productType
    switch (page) {
      case FlyoutProductAddEditSectionName.GENERAL_INFORMATION: {
        const additionalValidation = isEdit
          ? []
          : ['termsAndConditions.generalInformation']
        return isSignleFieldsErr(
          [
            'generalInformation.isGovCloud',
            'generalInformation.title',
            'generalInformation.sku',
            'generalInformation.productLogoUrl',
            'generalInformation.productVideoLink',
            'generalInformation.shortProductDescription',
            'generalInformation.productDescription',
            'generalInformation.highlights',
            'generalInformation.supportResources',
            'generalInformation.resourceUrls',
            'generalInformation.productCategories',
            'generalInformation.searchKeywords',
            'generalInformation.eula',
            'generalInformation.eulaUrl',
            'generalInformation.refundPolicy',
            'generalInformation.supportResources',
            'generalInformation.allowedAccounts',
            ...additionalValidation,
          ],
          formikValues?.errors
        )
      }
      case FlyoutProductAddEditSectionName.PRODUCT_DIMENSIONS: {
        const contractCheck =
          productType === PlanType.SAAS_CONTRACT
            ? [
                'generalInformation.multipleDimensionsAllowed',
                'productDimensions.contractDuration',
                ...(flags &&
                flags?.isFreeTrialEnable &&
                formikValues?.values?.[
                  FlyoutProductAddEditSectionName.PRODUCT_DIMENSIONS
                ].freeTrial?.isEnable &&
                formikValues?.values?.[
                  FlyoutProductAddEditSectionName.CLOUD_PRODUCT_DETAILS
                ].productType === PlanType.SAAS_CONTRACT
                  ? [
                      'productDimensions.freeTrial.numberOfDays',
                      'productDimensions.freeTrial.eulaFile',
                      'productDimensions.freeTrial.eulaType',
                      'productDimensions.freeTrial.dimensions',
                    ]
                  : []),
              ]
            : []
        const additionalValidation = isEdit
          ? []
          : ['termsAndConditions.productDimensions']

        return isSignleFieldsErr(
          [
            'productDimensions.dimensionCategoryName',

            'productDimensions.dimensions', // array
            'productDimensions.additionalDimensions', //array
            'generalInformation.countryAvailability',
            'generalInformation.countriesSelected',
            ...contractCheck,
            ...additionalValidation,
          ],
          formikValues?.errors
        )
      }
      case FlyoutProductAddEditSectionName.LANDING_PAGE_DETAILS: {
        const additionalValidation = isEdit
          ? []
          : ['termsAndConditions.infoCollectionFormMetaData']

        return (
          isHostWithLabra &&
          isSignleFieldsErr(
            [
              'infoCollectionFormMetaData.bgColor',
              'infoCollectionFormMetaData.buttonColor',
              'infoCollectionFormMetaData.buttonTextColor',
              'infoCollectionFormMetaData.headingMessage',
              'infoCollectionFormMetaData.submissionSuccessMessage',
              'infoCollectionFormMetaData.existingBuyerMessage',
              'infoCollectionFormMetaData.emailCustomMessage',
              'infoCollectionFormMetaData.buyerFormFields',
              'infoCollectionFormMetaData.redirectionSettings.applicationLoginPageUrl',
              'infoCollectionFormMetaData.redirectionSettings.customInstructions',
              'infoCollectionFormMetaData.redirectionSettings.demoVideoUrl',
              'infoCollectionFormMetaData.redirectionSettings.testingCredentials.email',
              'infoCollectionFormMetaData.redirectionSettings.testingCredentials.password',
              'infoCollectionFormMetaData.redirectionSettings.demoDocumentUrl',
              ...additionalValidation,
            ],
            formikValues?.errors
          )
        )
      }
      case FlyoutProductAddEditSectionName.NOTIFICATIONS_RECIPIENTS: {
        const additionalValidation = isEdit
          ? []
          : ['generalInformation.termsAndCondition']

        return isSignleFieldsErr(
          [
            'generalInformation.buyerNotificationsAllowed',
            'notificationRecipients.registrationCompleted',
            'notificationRecipients.privateOfferRenewalReminder',
            'notificationRecipients.registrationIncompleteOffer',
            'notificationRecipients.subscribeFail',
            'notificationRecipients.entitlementRenew',
            'notificationRecipients.entitlementExpired',
            'notificationRecipients.entitlementUpgrade',
            'notificationRecipients.unsubscribePending',
            'notificationRecipients.unsubscribeSuccess',
            'notificationRecipients.awsProductListingLive',
            'notificationRecipients.awsProductListingRejected',
            'notificationRecipients.freeTrialActivated',
            'notificationRecipients.freeTrialDeactivated',
            'notificationRecipients.privateOfferCreated',
            'notificationRecipients.privateOfferAccepted',
            'notificationRecipients.privateOfferExpiringSoon',
            'notificationRecipients.privateOfferExpired',
            'notificationRecipients.awsPrivateOfferRestrictionSuccess',
            'notificationRecipients.awsResellerAgreementCreated',
            'notificationRecipients.awsResellerAgreementDeactivated',
            'notificationRecipients.awsResellerAgreementExpiredSeller',
            'notificationRecipients.awsResellerAgreementExpiringSeller',
            'notificationRecipients.meteringSent',
            ...additionalValidation,
          ],
          formikValues?.errors
        )
      }
      case FlyoutProductAddEditSectionName.MIGRATION_DETAILS: {
        return isSignleFieldsErr(
          ['migrationDetails.products'],
          formikValues?.errors
        )
      }
      default: {
        return false
      }
    }
  }
}
export const giveNumberInputValue = (inputValue?: number | null) => {
  if (isNil(inputValue)) {
    return ''
  }
  return inputValue
}
export const contractDimesnionDisplayNameUrl =
  'https://helpcenter.labra.io/hc/en-us/articles/28718676982419-AWS-Marketplace-Listing-Prep-for-Labra-FlyOut'

export const getIsProductSectionCompleted = (
  path: string,
  pageMarker: Record<string, boolean>
) => {
  if (path.includes('product-details')) {
    return !!pageMarker?.page1
  } else if (path.includes('product-dimensions')) {
    return !!pageMarker?.page2
  } else if (path.includes('landing-page')) {
    return !!pageMarker?.page3
  } else if (path.includes('notification-recipients')) {
    return !!pageMarker?.page4
  } else {
    return false
  }
}

export const migrationModalText =
  'Confirm changes in selection of products for migration? \nThe migration process will start upon completion of the product form'

export const getProductCompletePercentage = (obj: Record<string, boolean>) => {
  if (!obj) {
    return 0
  }
  const pageMarkerLength = Object.keys(obj).length
  const pagesCompletedLength = Object.values(obj).filter(p => !!p).length
  const percentage = (pagesCompletedLength / pageMarkerLength) * 100
  return Math.round(percentage)
}

export const getStatusBarDetails = ({
  status,
  companyName,
  auditJson,
}: {
  status: string
  companyName: string
  auditJson: StatusListingResObj
}) => {
  if (status === 'Limited') {
    const { fullDateTime } = productDetailDate(auditJson?.Draft)
    return {
      backgroundColor: StatusBarColors.BLUE,
      content:
        'Labra will fetch and subscribe to certain listing attributes which will help you in managing your listing.',
      subContent: fullDateTime ? `Pending since ${fullDateTime}` : '',
    }
  } else if (status === 'Labra-testing') {
    const { fullDateTime } = auditJson?.Limited
      ? productDetailDate(auditJson?.Limited)
      : { fullDateTime: '' }

    return {
      backgroundColor: StatusBarColors.BLUE,
      content:
        'Labra will test the end-to-end buyer flow for your listing and submit it for publishing to AWS Marketplace.',
      subContent: fullDateTime ? `Pending since ${fullDateTime}` : '',
    }
  } else if (status === 'AWS-testing') {
    const { fullDateTime } = auditJson?.['Labra-testing']
      ? productDetailDate(auditJson?.['Labra-testing'])
      : { fullDateTime: '' }

    return {
      backgroundColor: StatusBarColors.BLUE,
      content:
        'AWS Marketplace team will test end-to-end buyer flow for listing and either accept or reject the listing.',
      subContent: fullDateTime ? `Pending since ${fullDateTime}` : '',
    }
  } else if (status === 'Public') {
    return {
      backgroundColor: StatusBarColors.BLUE,
      content: 'Listing is live on the marketplace, no action required.',
      subContent: '',
    }
  } else if (status === 'Failed') {
    return {
      backgroundColor: StatusBarColors.BLUE,
      content: '',
      subContent: '',
    }
  } else if (status === 'Restricted') {
    return {
      backgroundColor: StatusBarColors.BLUE,
      content: `${companyName} can choose to make the listing public on AWS marketplace again.`,
      subContent: '',
    }
  } else if (status === 'Rejected') {
    const { fullDateTime } = auditJson?.Public
      ? productDetailDate(auditJson?.Public)
      : { fullDateTime: '' }

    return {
      backgroundColor: StatusBarColors.RED,
      content: `${companyName} needs to change listing information according to reason cited for rejection from AWS Marketplace team after AWS testing.`,
      subContent: fullDateTime ? `Pending since ${fullDateTime}` : '',
    }
  } else {
    return {
      backgroundColor: StatusBarColors.BLUE,
      content: '-',
      subContent: '',
    }
  }
}

export const productDetailDate = (date: string | Date, timeZone?: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
    ...(timeZone ? { timeZone } : {}),
  }
  const dateString = new Date(date).toLocaleString('en-US', options)
  const modifiedDate = dateString.split(',').slice(0, 2).join(', ')
  const modifiedTime = dateString.split(',')[2]
  const showModifiedTime = modifiedTime ? `| ${modifiedTime}` : ''
  const fullDateTime = `${modifiedDate} ${showModifiedTime}`
  const shortDate = modifiedDate
    .replace(',', '')
    .split(' ')
    .slice(0, 2)
    .join(' ')

  return { modifiedDate, modifiedTime, fullDateTime, shortDate }
}

export const formatToApiName = (displayName: string) => {
  return displayName.trim().replace(/\s+/g, '_').toLowerCase()
}

export const notEditableAwsFieldTooltip =
  'This field can only be edited by raising a support request on AWS Marketplace.'
export const notEditableContractDimensionsCheckboxTooltip =
  "This field cannot be edited as you don't have additional usage dimensions."

export const dimensionNameRegex = /[^a-zA-Z0-9]/g
export const mapProducts = (productRows: any[]) => {
  return productRows
    .map(product => ({
      label: product.productName,
      value: product.productName,
      disabled: false,
      imageUrl: aws, // Replace with actual image URL if available
      productId: product.productId,
      cloudMarketplace: product.cloudMarketplace,
      status: product.status,
      productType: product.productType,
    }))
    ?.filter(
      i =>
        i.status !== 'In-progress' &&
        [PlanType.SAAS_CONTRACT, PlanType.SAAS_SUBSCRIPTION].includes(
          i.productType
        )
    )
}
