import { Reducer } from 'redux'
import { links } from '../productsListing/reducer'
import { ReceivedResellerAuthorizationActionTypes } from './actions'
import {
  AgreementDurationType,
  ContractDurationType,
  CPPOProducts,
  DiscountType,
  ResellerAgreementStatus,
  ResellerInfo,
  Stakeholders,
} from '../../../admin/modules/resaleAuthorizations/reducer'
import {
  ChannelType,
  EulaType,
  Installments,
  MetaData,
  OfferRecipients,
  PriceTypes,
  Dimension,
} from '../privateOffer/reducer'
import { AgreementApprovers } from '../../../externalApps/modules/cppoResellerAgreement/reducer'
import { PlanType } from '@labrav/react-components'

export type ResaleAuthorizationType = 'CREATED' | 'RECEIVED'

export interface ReceivedResellerAuthorization {
  id: string
  isvName?: string
  isvCloudAccountId?: string
  crmAwsResellerAgreementObjectId: string
  agreementDescription: string
  agreementName: string
  buyerAwsAccountId: string
  agreementStartDate?: string
  agreementExpirationDate: string
  emailCustomMessage: string
  contractDurationInMonthsForEndCustomer?: number
  agreementDurationType: AgreementDurationType
  discountType: DiscountType
  contractDurationType: ContractDurationType
  endCustomerEulaType: EulaType
  endCustomerEulaFiles: string[]
  resellerLicenseAgreementType: EulaType
  resellerLicenseAgreementFiles: string[]
  percentageDiscount?: number
  awsAgreementId: string
  agreementApprovers: AgreementApprovers[]
  agreementRecipients: OfferRecipients[]
  agreementStakeholders: Stakeholders[]
  installmentInfo: Installments[]
  metaData: MetaData[]
  renewal?: boolean
  renewalChannel?: ChannelType | null
  createdAt: string
  status: ResellerAgreementStatus
  resellerName: string
  draft?: boolean
  pricesArray?: [keyof PriceTypes]
  dimensions?: Dimension[]
  companyName?: string
  agreementOrigin?: ResaleAuthorizationType
  offerExtendedStatus?: string
  buyerName?: string
  serviceStartDate?: string
  productName?: string
  productType?: PlanType
  updatedAt?: string
  error?: string
  productId?: string
  receivedOn?: string
  auditJson?: ResaleAuthAuditJson
  cloudProductName?: string
}

export interface ResaleAuthStages {
  'Authorization created'?: string | null
  'Authorization activated'?: string | null
  'Authorization duration ended'?: string | null
  'Authorization deactivated'?: string | null
  'Authorization completed'?: string | null
  'Authorization creation failed'?: string | null
}

export enum ResaleAuthStageToStatusRelationMapEnum {
  CREATED = 'Authorization created',
  ACTIVE = 'Authorization activated',
  EXPIRED = 'Authorization duration ended',
  COMPLETED = 'Authorization completed',
  DEACTIVATED = 'Authorization deactivated',
  FAILED = 'Authorization creation failed',
}

export type ResaleAuthAuditJson = {
  resaleAuthCreatedAt: string | null
  resaleAuthCreationFailedAt: string | null
  resaleAuthActivatedAt: string | null
  resaleAuthDeactivatedAt: string | null
  resaleAuthExpiredAt: string | null
  resaleAuthCompletedAt: string | null
}

export type ViewResellerAuthorizationType = ReceivedResellerAuthorization & {
  resellersInfo?: ResellerInfo[]
  products?: CPPOProducts[]
  awsProductId?: string
  serviceStartDate?: string
}

export type ApiResponse = {
  pageNumber?: number
  pageSize?: number
  totalCount?: number
  sortBy?: string
  sortOrder?: string
  links?: links
}

export type ReceivedResellerAuthorizationState = {
  awsResellerAgreements: ReceivedResellerAuthorization[]
  count: number
  pageNumber: number
  noOfPage: number | null
  pageSize: number
  currentReceivedResellerAuthorizations: {
    [x: string]: ReceivedResellerAuthorization
  }
  activeReceivedResellerAuthorizations: ReceivedResellerAuthorization[]
  selectedActiveReceivedResellerAuthorization: string
} & ApiResponse

const initialState: ReceivedResellerAuthorizationState = {
  awsResellerAgreements: [],
  count: 0,
  pageNumber: 0,
  noOfPage: null,
  pageSize: 10,
  currentReceivedResellerAuthorizations: {},
  activeReceivedResellerAuthorizations: [],
  selectedActiveReceivedResellerAuthorization: '',
}

type actionType =
  | {
      type: ReceivedResellerAuthorizationActionTypes.SET_RECEIVED_RESALE_AUTHORIZATIONS_DATA
      payload: {
        agreements: {
          awsResellerAgreements: ReceivedResellerAuthorization[]
          count: number
        }
        pageNumber: number
        pageSize: number
      }
    }
  | {
      type: ReceivedResellerAuthorizationActionTypes.SET_CURRENT_RECEIVED_RESELLER_AUTHORIZATION
      payload: ReceivedResellerAuthorization
    }
  | {
      type: ReceivedResellerAuthorizationActionTypes.CLEAR_CURRENT_RECEIVED_RESELLER_AUTHORIZATION
    }
  | {
      type: ReceivedResellerAuthorizationActionTypes.SET_ACTIVE_RECEIVED_RESALE_AUTHORIZATIONS_DATA
      payload: {
        agreements: ReceivedResellerAuthorization[]
      }
    }
  | {
      type: ReceivedResellerAuthorizationActionTypes.SET_SELECTED_ACTIVE_RECEIVED_RESALE_AUTHORIZATIONS_DATA
      payload: {
        authorizationId: string
      }
    }
  | {
      type: ReceivedResellerAuthorizationActionTypes.CLEAR_ACTIVE_RECEIVED_RESELLER_AUTHORIZATION
    }

export const reducer: Reducer<
  ReceivedResellerAuthorizationState,
  actionType
> = (state = initialState, action) => {
  switch (action.type) {
    case ReceivedResellerAuthorizationActionTypes.SET_RECEIVED_RESALE_AUTHORIZATIONS_DATA: {
      return {
        ...state,
        awsResellerAgreements: state.awsResellerAgreements.concat(
          action.payload.agreements.awsResellerAgreements
        ),
        count: action.payload.agreements.count,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
      }
    }
    case ReceivedResellerAuthorizationActionTypes.SET_CURRENT_RECEIVED_RESELLER_AUTHORIZATION: {
      return {
        ...state,
        currentReceivedResellerAuthorizations: {
          [action.payload.id]: action.payload as ReceivedResellerAuthorization,
        },
      }
    }
    case ReceivedResellerAuthorizationActionTypes.CLEAR_CURRENT_RECEIVED_RESELLER_AUTHORIZATION: {
      return {
        ...state,
        currentReceivedResellerAuthorizations: {},
      }
    }
    case ReceivedResellerAuthorizationActionTypes.SET_ACTIVE_RECEIVED_RESALE_AUTHORIZATIONS_DATA: {
      return {
        ...state,
        activeReceivedResellerAuthorizations: action.payload.agreements,
      }
    }
    case ReceivedResellerAuthorizationActionTypes.SET_SELECTED_ACTIVE_RECEIVED_RESALE_AUTHORIZATIONS_DATA: {
      return {
        ...state,
        selectedActiveReceivedResellerAuthorization:
          action.payload.authorizationId,
      }
    }
    case ReceivedResellerAuthorizationActionTypes.CLEAR_ACTIVE_RECEIVED_RESELLER_AUTHORIZATION: {
      return {
        ...state,
        activeReceivedResellerAuthorizations: [],
        selectedActiveReceivedResellerAuthorization: '',
      }
    }
    default:
      return state
  }
}
