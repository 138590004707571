import { useFlagValue } from '@labrav/flags'
import { FlyOutListingData } from '../../../admin/modules/flyOutSyncs/reducer'
import { useOppSyncOnboardingStatus } from '../../../oppsync/utils/hooks/useOppSyncOnboardingStatus'
import { useSelector } from '../../../store'
import { customerFormData } from '../../utils/Hooks/usePartnerData'
import { SubscribeProducts } from '../../../admin/modules/NewCustomers/reducer'
import { Product } from '../../modules/partner/action'

export const useAnyOneOnboardingCompeted = (
  activeProducts?: Product[] | string[]
) => {
  const { loading: isFlagCosellLoading, value: cosellEnabled } =
    useFlagValue('cosell')
  const isCosellEnabled = !isFlagCosellLoading && cosellEnabled

  const { flyoutSellerInfo } = useSelector<{
    flyoutSellerInfo: FlyOutListingData
  }>(state => ({
    flyoutSellerInfo: state.flyoutSellerInfo,
  }))

  const { onboardingComplete, isNewPartner } =
    useOppSyncOnboardingStatus(isCosellEnabled)

  const customerData = customerFormData()

  const subscribedProducts = customerData.subscribedProducts

  const isFlyoutOppsyncPurchased =
    subscribedProducts?.FLYOUT &&
    subscribedProducts?.OPPSYNC &&
    activeProducts?.includes('flyout') &&
    activeProducts?.includes('oppsync')

  const isOppsyncOnboarded = onboardingComplete || !isNewPartner
  const isFlyoutOnboarded = flyoutSellerInfo?.onboarded === true
  const isAnyOneOnboardingCompeted = isFlyoutOppsyncPurchased
    ? isOppsyncOnboarded || isFlyoutOnboarded
    : false

  const firstProduct =
    activeProducts &&
    (activeProducts?.length >= 2
      ? getFirstOnboardedProduct({
          subscribedProducts,
          isOppsyncOnboarded,
          isFlyoutOnboarded,
          activeProducts,
        })
      : activeProducts[0])
  return { isAnyOneOnboardingCompeted, firstProduct }
}

const getFirstOnboardedProduct = ({
  subscribedProducts,
  isOppsyncOnboarded,
  isFlyoutOnboarded,
  activeProducts,
}: {
  subscribedProducts: Partial<SubscribeProducts>
  isFlyoutOnboarded: boolean
  isOppsyncOnboarded: boolean
  activeProducts?: Product[] | string[]
}) => {
  if (
    activeProducts &&
    ((activeProducts[0] === 'flyout' && isFlyoutOnboarded) ||
      (activeProducts[0] === 'oppsync' && isOppsyncOnboarded))
  ) {
    return activeProducts[0]
  }

  if (
    activeProducts?.includes('flyout') &&
    subscribedProducts?.FLYOUT &&
    isFlyoutOnboarded
  ) {
    return 'flyout' as Product
  }

  if (
    activeProducts?.includes('oppsync') &&
    subscribedProducts?.OPPSYNC &&
    isOppsyncOnboarded
  ) {
    return 'oppsync' as Product
  }

  if (activeProducts && activeProducts?.length) {
    return activeProducts[0]
  }
}
