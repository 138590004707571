import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  buttonField: {
    display: 'flex',
    flexDirection: 'row',
  },
  primaryContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(5),
  },
  inputColumn: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(4),
  },
  inputField: {
    width: theme.spacing(59.37),
  },
  field: {
    marginLeft: theme.spacing(2),
  },
}))
