import React from 'react'
import { Grid } from '@material-ui/core'
import { useStyles } from './DisbalePage.styles'
import ProductInfo, { ProductInfoProps } from './ProductInfo'
import Disable from '../../images/Disable.png'
import OnboardingHeaderTitleBar from '../../../oppsync/components/Onboarding/OnboardingCRMSettings/OnboardingHeaderTitleBar/OnboardingHeaderTitleBar'
import { CloudType } from '../../modules/types'

interface DisablePageProps {
  productInfo: ProductInfoProps
  header?: string
  showHeader?: boolean
}

const DisablePage: React.FC<DisablePageProps> = ({
  productInfo,
  header,
  showHeader = true,
}) => {
  const classes = useStyles({})
  return (
    <>
      {header && showHeader && (
        <div className={classes.header}>
          <OnboardingHeaderTitleBar title={header} cloudType={CloudType.AWS} />
        </div>
      )}
      <div className={classes.container}>
        <Grid container className={classes.root} data-testid="disable-page">
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <img
              src={Disable}
              alt="Stock-Image"
              className={classes.disableImage}
              data-testid="stock-image"
            />
          </Grid>
          <Grid item xs={12} sm={8} md={8} lg={8}>
            <ProductInfo {...productInfo} />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default DisablePage
