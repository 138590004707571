import { AnyAction, Dispatch } from 'redux'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  DefaultSuccesrOnSubmitData,
  freeTrialDeactivateRequestSuccess,
  freeTrialDeactivateRequestUnsuccess,
  freeTrialOfferIdPatchRequestSuccess,
  freeTrialOfferIdPatchRequestUnsuccess,
  RequestFailureMessage,
} from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import {
  CloudMarketplace,
  Product,
  ProductsListingState,
  FreeTierInfo,
} from './reducer'
import { camelize, snakeize } from 'casing'
import {
  fetchProductsList,
  patchFreeTrial,
  patchProductListingData,
  deleteProductListing,
  publishProductListing,
  unpublishProductListing,
} from '../../api/markeplace'
import { getErrorMessages } from '../../../common/utils/error'

import { AppDispatch, RootState } from '../../../store'
import { isEmpty } from 'lodash'
import { FreeTrialStatus } from '../freeTrials/reducer'
import { errorLogger } from '../../../common/utils/errorLogger'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { fetchAzureProductsList } from '../../api/azure'
import { FlyoutFunction } from '../productListingFilter/reducer'
import { fetchGcpProductsList } from '../../api/gcp'

export enum ProductsListingActionTypes {
  SET_PRODUCTS = 'SET_PRODUCTS',
  SET_PRODUCTS_WITH_LISTING_DATA = 'SET_PRODUCTS_WITH_LISTING_DATA',
  CLEAR_PRODUCTS = 'CLEAR_PRODUCTS',
  UPDATE_CLOUD_FREE_TRIAL_OFFER_ID = 'UPDATE_CLOUD_FREE_TRIAL_OFFER_ID',
  SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT',
  SET_FREE_TIER_DATA = 'SET_FREE_TIER_DATA',
}

const getAPIBasedOnCloud = async (
  partnerId: string,
  cloud: CloudMarketplace,
  include?: string
) => {
  switch (cloud) {
    case 'AWS': {
      return fetchProductsList(partnerId, include)
    }
    case 'AZURE': {
      return fetchAzureProductsList(partnerId)
    }
    case 'GCP': {
      return fetchGcpProductsList(partnerId)
    }
    default: {
      return fetchProductsList(partnerId)
    }
  }
}

export const getProducts =
  (
    partnerId: string,
    cloudMarketplace: CloudMarketplace,
    functionType?: FlyoutFunction
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL)) //TODO: remove general loader
    dispatch(startLoading(LoadingTypes.FETCH_PRODUCTS))
    const shouldClearProductId =
      getState().productsListing[cloudMarketplace]?.selectedProduct !==
      undefined
    if (shouldClearProductId) {
      dispatch(
        actionTypeWrapper(
          cloudMarketplace,
          setSelectedProductOnListing(undefined)
        )
      )
    }
    try {
      const response = await getAPIBasedOnCloud(partnerId, cloudMarketplace)
      if (response?.data) {
        const productsData = camelize(response?.data)
        const freeTierData = camelize({
          additionalAllowedProducts:
            response?.data?.free_tier_info?.additional_allowed_products,
        })
        dispatch(actionTypeWrapper(cloudMarketplace, setProducts(productsData)))

        dispatch(
          actionTypeWrapper(cloudMarketplace, setFreeTierData(freeTierData))
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
      dispatch(stopLoading(LoadingTypes.FETCH_PRODUCTS))
    }
  }

// listing data
export const getProductsWithListing =
  (
    partnerId: string,
    cloudMarketplace: CloudMarketplace,
    functionType?: FlyoutFunction
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    const shouldClearProductId =
      getState().productsListing[cloudMarketplace]?.selectedProduct !==
      undefined
    if (shouldClearProductId) {
      dispatch(
        actionTypeWrapper(
          cloudMarketplace,
          setSelectedProductOnListing(undefined)
        )
      )
    }
    try {
      const response = await getAPIBasedOnCloud(
        partnerId,
        cloudMarketplace,
        'listing_data'
      )
      if (response?.data) {
        const productsData = camelize(response?.data)

        dispatch(
          actionTypeWrapper(
            cloudMarketplace,
            setProductsWithListingData(productsData)
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setProducts = (products: ProductsListingState) => ({
  type: ProductsListingActionTypes.SET_PRODUCTS,
  payload: products,
})
export const setProductsWithListingData = (products: ProductsListingState) => ({
  type: ProductsListingActionTypes.SET_PRODUCTS_WITH_LISTING_DATA,
  payload: products,
})
export const setFreeTierData = (freeTierInfo: FreeTierInfo) => ({
  type: ProductsListingActionTypes.SET_FREE_TIER_DATA,
  payload: freeTierInfo,
})

export const clearProductsListing = () => ({
  type: ProductsListingActionTypes.CLEAR_PRODUCTS,
})
export const updateFreeTrialOfferId = (
  productId: string,
  cloudFreeTrialOfferId: string
) => ({
  type: ProductsListingActionTypes.UPDATE_CLOUD_FREE_TRIAL_OFFER_ID,
  payload: { productId, cloudFreeTrialOfferId },
})

export const patchFreeTrialOffer =
  (productId: string, partnerId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const currentSelctedCloudMarketplace =
        getState().productListingFilters.cloudMarketplace
      const productsRows =
        getState().productsListing[currentSelctedCloudMarketplace].products.rows
      const product =
        productsRows.find(product => product.productId === productId) ||
        ({} as Product)
      const dataToSend = {
        freeTrialEnabled: !isEmpty(product?.freeTrial?.cloudFreeTrialOfferId),
        cloudFreeTrialOfferId: product?.freeTrial?.cloudFreeTrialOfferId || '',
        freeTrialStatus: isEmpty(product?.freeTrial?.cloudFreeTrialOfferId)
          ? FreeTrialStatus.FREE_TRIAL_DEACTIVATED
          : FreeTrialStatus.FREE_TRIAL_CREATED,
        productId: productId,
        freeTrialEnabledAt: new Date().toISOString(),
        cloudMarketplace: product?.cloudMarketplace,
      }
      await patchFreeTrial(
        partnerId,
        snakeize(dataToSend),
        product?.freeTrial?.labraFreeTrialId || ''
      )
      await dispatch(
        updateAppAlert({
          message: freeTrialOfferIdPatchRequestSuccess,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([freeTrialOfferIdPatchRequestUnsuccess])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
export type deactivateFreeTrialParams = {
  labraFreeTrialId: string
  partnerId: string
  productId: string
  cloudMarketplace: string
}
export const deactivateFreeTrial =
  ({
    labraFreeTrialId,
    partnerId,
    productId,
    cloudMarketplace,
  }: deactivateFreeTrialParams) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    const product =
      getState().productsListing[
        getState().productListingFilters.cloudMarketplace
      ].products.rows.find(product => product.productId === productId) ||
      ({} as Product)
    try {
      const dataToSend = {
        productId,
        cloudMarketplace,
        freeTrialStatus: 'FREE_TRIAL_DEACTIVATION_IN_PROGRESS',
      }
      await patchFreeTrial(partnerId, snakeize(dataToSend), labraFreeTrialId)
      await dispatch(
        actionTypeWrapper(
          getState().productListingFilters.cloudMarketplace,
          clearProductsListing()
        )
      )
      await dispatch(
        updateAppAlert({
          message: freeTrialDeactivateRequestSuccess,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([freeTrialDeactivateRequestUnsuccess])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
export const setSelectedProductOnListing = (productId?: string) => ({
  type: ProductsListingActionTypes.SET_SELECTED_PRODUCT,
  payload: productId,
})

interface alertMessagesType {
  success?: string
  error?: string
}
interface updateProductListingDataArgs {
  partnerId: string
  productId: string
  data: Record<string, unknown>
  alertMessages?: alertMessagesType
  handleToggleChecked?: () => void
}

interface deleteProductListingDataArgs {
  partnerId: string
  productId: string
  alertMessages?: alertMessagesType
  handleToggleChecked?: () => void
}
export const updateProductListingData =
  ({
    partnerId,
    productId,
    data,
    alertMessages,
    handleToggleChecked,
  }: updateProductListingDataArgs) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const { cloudMarketplace } = getState().productListingFilters
      await patchProductListingData(partnerId, productId, snakeize(data))

      await dispatch(
        updateAppAlert({
          message: alertMessages?.success || DefaultSuccesrOnSubmitData,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      await dispatch(
        getProducts(partnerId, cloudMarketplace) as unknown as AnyAction
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: alertMessages?.error || RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      if (handleToggleChecked) {
        handleToggleChecked()
      }
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
export const publishProductListingData =
  ({ partnerId, productId, alertMessages }: deleteProductListingDataArgs) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      await publishProductListing(partnerId, productId, {
        status: 'AWS-testing',
      })

      await dispatch(
        updateAppAlert({
          message: alertMessages?.success || DefaultSuccesrOnSubmitData,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: alertMessages?.error || RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const unpublishProductListingData =
  ({ partnerId, productId, alertMessages }: deleteProductListingDataArgs) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      await unpublishProductListing(partnerId, productId, {
        status: 'Restricted',
      })

      await dispatch(
        updateAppAlert({
          message: alertMessages?.success || DefaultSuccesrOnSubmitData,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: alertMessages?.error || RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const deleteProductListingData =
  ({ partnerId, productId, alertMessages }: deleteProductListingDataArgs) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      await deleteProductListing(partnerId, productId)

      await dispatch(
        updateAppAlert({
          message: alertMessages?.success || DefaultSuccesrOnSubmitData,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: alertMessages?.error || RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      throw new Error('Failed to delete the product')
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
