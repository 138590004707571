import { AxiosResponse } from 'axios'
import { API } from '../../common/api'
import {
  azureEngineServiceUrl,
  cloudAccountServiceUrl,
  crmServiceUrl,
  intelligenceServiceUrl,
  labravAWSEngineServiceUrl,
} from '../../common/utils/constants'
import {
  AzureAccountDetails,
  AzureBackendAccountDetails,
} from '../modules/productAccount/reducer'
import { SegmentAndGreenField } from '../../common/modules/CloudAccounts/reducer'
import { LastEvaluatedObject } from '../../flyout/modules/revenueInsightsV2/reducer'
import { camelize } from 'casing'
import { FilterType } from '../modules/Propensity/reducer'
import { downloadPtbReports } from '../modules/Propensity/action'

const mapAzBE2FE: (
  a: AzureBackendAccountDetails
) => AzureAccountDetails = details => ({
  id: details.id,
  name: details.company_name,
  MPNID: details.mpn_id,
  industries: details.industry,
  microsoftPartnerType: details.partner_type,
  ISV: details.is_isv,
  grantConsent: true,
  terms: true,
})

export const fetchAzureAccountDetails = (parnterId: string) =>
  API.get(`${azureEngineServiceUrl}/partners/${parnterId}`)
    .then(({ data }) => data as AzureBackendAccountDetails)
    .then(mapAzBE2FE)

export const deactivateAzureAccount = (
  partnerId: string,
  accountId: string,
  payload: Record<string, unknown>
) => {
  return API.put(
    `${cloudAccountServiceUrl}/azure/organizations/${partnerId}/accounts/${accountId}`,
    payload
  )
}

export const fetchCRMConfiguration = (crmId: string) =>
  API.get(`${crmServiceUrl}/crms/${crmId}`)

export const fetchSegmentAndGreenField = (
  partnerId: string
): Promise<AxiosResponse<SegmentAndGreenField>> =>
  API.get(`${labravAWSEngineServiceUrl}/partners/${partnerId}/segment_reports`)

export const getPreSignedUrlOppSync = (
  partnerId: string,
  path: string,
  bucketName?: string
) => {
  return API.get(`${labravAWSEngineServiceUrl}/partners/${partnerId}/assets`, {
    params: {
      fields: 'aws_presigned_url',
      assets: path,
      operation_method: 'POST',
      expires_in: 3600,
      bucket_name: bucketName ? bucketName : '',
    },
  })
}

export const fetchProductsList = (partnerId: string, productType: string) => {
  return API.get(
    `${labravAWSEngineServiceUrl}/partners/${partnerId}/products`,
    {
      params: {
        product_type: productType,
      },
    }
  )
}

export const fetchPrivateOffersList = (
  partnerId: string,
  productId: string
) => {
  return API.get(
    `${labravAWSEngineServiceUrl}/partners/${partnerId}/private_offers`,
    {
      params: {
        product_id: productId,
      },
    }
  )
}

export const fetchPrivateOffersValidation = (
  partnerId: string,
  offerId: string
) => {
  return API.get(
    `${labravAWSEngineServiceUrl}/partners/${partnerId}/private_offers/${offerId}`
  )
}

type ConnectionResponse = {
  code: number
  message: string
}

export type TestConnectionResponse = {
  aceApisConnection: ConnectionResponse
  legacyConnection: ConnectionResponse
}

export const getArnConnectionStatus = async (
  partnerId: string,
  arn: string,
  environment: string
) => {
  const { data } = await API.get(
    `${labravAWSEngineServiceUrl}/partners/${partnerId}/connections`,
    {
      params: {
        iam_role: arn,
        env: environment,
      },
    }
  )
  return camelize(data) as TestConnectionResponse
}

type SolutionsAndProductsResponse = {
  cloud_config: {
    aws_solutions: Array<unknown>
    aws_products: Array<unknown>
  }
}

export const getPartnerSolutions = async (
  partnerId: string,
  options: { updateCache?: boolean } = {}
) => {
  const { updateCache = false } = options
  const { data } = await API.get(
    `${labravAWSEngineServiceUrl}/partners/${partnerId}/solutions`,
    {
      params: {
        update_cache: updateCache,
      },
    }
  )
  return data as SolutionsAndProductsResponse
}

export const postAssetsOppSync = (
  partnerId: string,
  assets: Record<string, unknown>
) => {
  return API.post(
    `${labravAWSEngineServiceUrl}/partners/${partnerId}/assets`,
    assets
  )
}

export const calculatePropensityScore = (
  partnerId: string,
  data: { prospect: string }[]
) => {
  return API.post(
    `${intelligenceServiceUrl}/partners/${partnerId}/calculate_ptb`,
    { data }
  )
}

export const fetchPropensityData = (
  downloadReport?: boolean,
  partnerId?: string,
  pageSize?: number,
  lastEvaluatedObject?: LastEvaluatedObject | null,
  filters?: FilterType
) => {
  return API.get(
    `${intelligenceServiceUrl}/partners/${partnerId}/ptb_reports`,
    {
      params: {
        download_report: downloadReport,
        page_size: pageSize,
        last_evaluated_object: lastEvaluatedObject
          ? JSON.stringify(lastEvaluatedObject)
          : null,
        ...filters,
      },
    }
  )
}

export const getPreSignedUrlIntelligence = (
  partnerId: string,
  fileKeys: string[]
) => {
  return API.post(
    `${intelligenceServiceUrl}/partners/${partnerId}/generate_upload_urls`,
    {
      file_keys: fileKeys,
    }
  )
}

export const validateCsvFile = (partnerId: string, key: string) => {
  return API.post(
    `${intelligenceServiceUrl}/partners/${partnerId}/validate_csv`,
    null,
    {
      params: {
        key: key,
      },
    }
  )
}

export const postPtb = (partnerId: string, s3Key: string) => {
  return API.post(
    `${intelligenceServiceUrl}/partners/${partnerId}/ptb_reports`,
    null,
    {
      params: {
        s3_key: s3Key,
      },
    }
  )
}
