import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(theme => {
  return {
    loaderRootContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(11),
    },
    logoImg: {
      pointerEvents: 'none',
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
    loaderHeader: {
      fontSize: '18px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '28px',
      color: theme.palette.labraText.tertiary,
      fontFamily: theme.typography.fontFamily,
      textAlign: 'center',
      marginTop: theme.spacing(3),
    },
    loaderSubHeader: {
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '16px',
      color: theme.palette.labraText.description,
      fontFamily: theme.typography.fontFamily,
      textAlign: 'center',
      marginTop: theme.spacing(1),
    },
    loadingSpinner: {
      color: theme.palette.labraFormProgress.main,
      width: theme.spacing(3.875),
      height: theme.spacing(3.875),
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(11),
    },
  }
})
