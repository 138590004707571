import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  headerTitle: {
    color: theme.palette.labraText.primary,
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px',
    fontFamily: theme.typography.fontFamily,
  },
  notificationTypeHeader: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    fontWeight: 400,
    color: theme.palette.labraText.description,
    lineHeight: '16px',
    fontStyle: 'normal',
  },
  notificationTypeDescription: {
    marginTop: theme.spacing(0.5),
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.labraText.tertiary,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    fontStyle: 'normal',
  },
  emailPreviewImageContainer: {
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.backgroundV2.primary,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(5, 13.6875),
  },
}))
