import { AppDispatch, RootState } from '../../../store'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { getErrorMessages } from '../../../common/utils/error'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { errorLogger } from '../../../common/utils/errorLogger'
import { getBuyerContractsAPI } from '../../api/markeplace'
import { camelize } from 'casing'
import { Contracts } from './reducer'

export enum contractsActionTypes {
  SET_BUYER_CONTRACTS = 'SET_BUYER_CONTRACTS',
}

export const getBuyerContracts =
  ({ partnerId, buyerId }: { partnerId: string; buyerId: string }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.BUYERS_OVERVIEW))
    try {
      const response = await getBuyerContractsAPI({ partnerId, buyerId })
      const contracts = camelize(response?.data)
      await dispatch(setBuyerContracts(contracts))
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.BUYERS_OVERVIEW))
    }
  }

export const setBuyerContracts = (buyerContracts: Contracts) => ({
  type: contractsActionTypes.SET_BUYER_CONTRACTS,
  payload: buyerContracts,
})
