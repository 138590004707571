import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-regular-svg-icons'
import { useStyles } from './Persona.styles'
import { theme } from '../../../../../../theme'

interface PersonaProps {
  memberType: 'admin' | 'allianceLead'
}

const Persona: React.FC<PersonaProps> = ({ memberType }) => {
  const classes = useStyles()
  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  let personaTitle: string
  let backgroundColor: string

  if (memberType === 'allianceLead') {
    personaTitle = 'AWS alliance lead'
    backgroundColor = theme.palette.avatarColors.blue
  } else {
    personaTitle = 'AWS admin'
    backgroundColor = theme.palette.avatarColors.purple
  }

  const coeficient = personaTitle.length > 10 ? 0.53 : 0.62

  const onHoverWidth = coeficient * `Required${personaTitle}`.length

  const width = isHovered ? `${onHoverWidth}em` : '12px'

  return (
    <div
      className={classes.roleBadge}
      style={{ width, backgroundColor }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <FontAwesomeIcon icon={faUser} className={classes.titleUserIcon} />
      {isHovered && (
        <>
          <Typography className={classes.requiredText}>Required</Typography>
          <Typography className={classes.boldText}>{personaTitle}</Typography>
        </>
      )}
    </div>
  )
}

export default Persona
