import { Reducer } from 'redux'
import { revenuesActionTypes } from './action'

interface Revenue {
  currency: string
  payerCompanyName: string
  disbursementDate: string
  labraBuyerId: string
  awsRevShare: string
  paymentDueDate: string
  agreementStartDate: string
  listingFeePercentage: string
  paymentTerms: string
  sellerNetRevenue: string
  grossAmount: string
  disbursementBillingEventId: string
  brokerId: string
  payerAwsAccountId: string
  buyerCountryCode: string
  agreementEndDate: string | null
  labraBuyerLogoUrl: string | null
  buyerAccountId: string
  privateOfferName: string
  buyerCompanyName: string | null
  billingAddressId: string
  disbursementStatus: string
  labraSubscriptionId: string
  productTitle: string
  payerAccountId: string
  listingFee: string
  transactionReferenceId: string
  payerStateOrRegion: string
  buyerPostalCode: string
  partnerId: string
  updateDate: string
  offerVisibility: string
  buyerCity: string
  payerEmailDomain: string
  productId: string
  labraProductId: string
  invoiceDate: string
  awsOfferId: string
  payerPostalCode: string
  insertDate: string
  sellerRevShare: string
  buyerEmailDomain: string
  agreementAcceptanceDate: string
  awsTaxShare: string
  buyerAddressId: string
  payerCountryCode: string
  buyerStateOrRegion: string
  grossRevenue: string
  agreementId: string
  invoiceId: string
  buyerAwsAccountId: string
  payerAddressId: string
  bankTraceId: string
  payerCity: string
  tcv: number
  totalReceived: number
  lastDisbursedReceivedDate: string | null
  lastDisbursedAmount: number | null
}

export interface Revenues {
  revenues: Revenue[]
}

export const initialState: Revenues = { revenues: [] }

type actionType = {
  type: revenuesActionTypes.SET_BUYER_REVENUES
  payload: Revenues
}

export const reducer: Reducer<Revenues, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case revenuesActionTypes.SET_BUYER_REVENUES: {
      return {
        ...state,
        revenues: action.payload.revenues,
      }
    }
    default:
      return state
  }
}
