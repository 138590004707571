import React from 'react'
import { useStyles } from './LoadingStateComponent.styles'
import { CircularProgress, Typography } from '@material-ui/core'
import cloudStorageLogo from '../../../common/images/cloud_storage.svg'

export interface LoadingStateProps {
  heading: string
  description: string
}

export const LoadingStateComponent: React.FC<LoadingStateProps> = ({
  heading,
  description,
}) => {
  const classes = useStyles()
  return (
    <div
      className={classes.loaderRootContainer}
      data-testid="loader-state-component"
    >
      <img src={cloudStorageLogo} className={classes.logoImg} />
      <Typography data-testid="loading-header" className={classes.loaderHeader}>
        {heading}
      </Typography>
      <Typography
        data-testid="loading-sub-header"
        className={classes.loaderSubHeader}
      >
        {description}
      </Typography>
      <CircularProgress
        data-testid="circular-progress"
        className={classes.loadingSpinner}
      />
    </div>
  )
}
