import React, { useState } from 'react'
import {
  BoxV2,
  DynamicInput,
  ExternalLinkButton,
} from '@labrav/react-components'
import { Typography } from '@material-ui/core'
import { useStyles as linkAcccountSectionStyles } from './LinkAccountSection.styles'
import { useStyles as commonStyles } from '../CloudSettingsWrapper.styles'
import { useFormikContext } from 'formik'
import {
  AwsCloudSettingsSections,
  AwsCloudSettingsSectionsProps,
} from '../../types'
import { NextSectionButton } from '../NextSectionButton/NextSectionButton'
import StepStatusIcon from '../StepStatusIcon/StepStatusIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import Persona from '../Persona/Persona'

type LinkAccountSection = {
  isOnboardingCompleted?: boolean
}
export const LinkAccountSection: React.FC<LinkAccountSection> = ({
  isOnboardingCompleted,
}) => {
  const classes = linkAcccountSectionStyles()
  const commonClasses = commonStyles()
  const formik = useFormikContext<AwsCloudSettingsSectionsProps>()
  const [isExpanded, setIsExpanded] = useState(false)

  const handleReadMore = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <BoxV2
      data-testid="link-account-box"
      heading="Linking your AWS account to Partner Central"
      actions={
        <>
          <div
            className={commonClasses.personaSection}
            data-testid="persona-section"
          >
            <Persona memberType="allianceLead" />
            <Persona memberType="admin" />
          </div>
        </>
      }
      subHeading={
        <Typography className={commonClasses.subHeading}>
          Enter your AWS Account ID in the provided field and click the link to
          connect your AWS Account to AWS Partner Central.{' '}
          {!isExpanded ? (
            <>
              <span
                //TODO: update to use component from https://ibexlabs.atlassian.net/browse/LT-10058
                onClick={handleReadMore}
                className={commonClasses.readmore}
              >
                ...read more
              </span>
              .
            </>
          ) : (
            <>
              An AWS Admin with IAM role creation access must be logged into the
              intended AWS account. If a product is already listed in the AWS
              Marketplace, AWS mandates using the same account for co-selling.
              Alternatively, a new account can be created and linked to AWS
              Partner Central.
              {/* TODO: TO BE ADDED IN ACE API ONBOARDING PHASE-2
              For further guidance, refer to the demo video or
              the <ExternalLink href="">Help section</ExternalLink>. */}
            </>
          )}
        </Typography>
      }
      headingIcon={
        <>
          <StepStatusIcon sectionName={AwsCloudSettingsSections.LINK_ACCOUNT} />
          {!isOnboardingCompleted && (
            <Typography className={commonClasses.loadingText}>
              STEP 02
            </Typography>
          )}
        </>
      }
      // TODO: TO BE ADDED IN ACE API ONBOARDING PHASE-2
      // secondaryActions={
      //   <HelpDrawer
      //     data-testid="help-drawer"
      //     sectionName="oppsyncCloudAccountDetails"
      //     headerTitle="Help"
      //   />
      // }
      variant={'small'}
      headingVariant={'small'}
      collapsible
    >
      <div
        data-testid="link-account-section-main-container"
        className={classes.mainContainer}
      >
        <div className={classes.primaryContainer}>
          <div id="leftContainer" className={classes.inputField}>
            <div className={classes.inputField}>
              <DynamicInput
                data-testid="aws-account-input"
                field={{
                  title: 'AWS Account ID',
                  placeHolder: 'AWS Account ID',
                  id: 'awsAccountId',
                  isRequired: true,
                  humanReadableKey: 'partnerProjectTitle',
                  isReadOnly: false,
                  isCustom: false,
                  defaultValue: null,
                  dataType: 'string',
                }}
                formik={formik}
                prefix={AwsCloudSettingsSections.LINK_ACCOUNT}
              />
              <div className={classes.externalButton}>
                <ExternalLinkButton
                  data-testid="link-aws-button"
                  buttonTitle={'Link AWS Account to Partner Central'}
                  href={
                    'https://partnercentral.awspartner.com/partnercentral2/s/aws-marketplace'
                  }
                  modalTitleAcceptButton="Open window"
                  modalTitleCancleButton="Back"
                  modalTitle="You are being re-directed to another window"
                  modalIcon={
                    <FontAwesomeIcon
                      icon={faUpRightFromSquare}
                      className={commonClasses.externalLinkIcon}
                    />
                  }
                  modalContent={
                    <>
                      <div className={commonClasses.modalContentContainer}>
                        <Typography className={commonClasses.modalDescription}>
                          You are being redirected to the Partner Central window
                          to link your AWS account. Please ensure that you link
                          the same AWS account whose ID you entered in Step 2.
                          To complete this process, you must have Partner
                          Central access as an Alliance Lead or Cloud Admin. If
                          your account is already linked, you may close this
                          modal and proceed to the next step.
                        </Typography>
                      </div>
                    </>
                  }
                />
              </div>
            </div>
          </div>
          {/* <div className={commonClasses.verticalDivider}></div>
          <div className={commonClasses.videoContainer}></div> */}
        </div>
        {!isOnboardingCompleted && (
          <>
            <NextSectionButton
              sectionName={AwsCloudSettingsSections.LINK_ACCOUNT}
            />
          </>
        )}
      </div>
    </BoxV2>
  )
}
