export const partnerTier = [
  {
    name: 'Select',
    value: 'select',
  },
  {
    name: 'Advanced',
    value: 'advanced',
  },
  {
    name: 'Premier',
    value: 'premier',
  },
]

export const partnershipType = [
  {
    name: 'AWS Consulting Partner',
    value: 'aws_consulting_partner',
  },
  {
    name: 'AWS Technology Partner',
    value: 'aws_technology_partner',
  },
]

export const industries = [
  //TODO: need to create another file for v14
  {
    name: 'Aerospace',
    value: 'Aerospace',
  },
  {
    name: 'Agriculture',
    value: 'Agriculture',
  },
  {
    name: 'Automotive',
    value: 'Automotive',
  },
  {
    name: 'Computers & Electronics',
    value: 'Computers & Electronics',
  },
  {
    name: 'Consumer Goods',
    value: 'Consumer Goods',
  },
  {
    name: 'Education',
    value: 'Education',
  },
  {
    name: 'Financial Services',
    value: 'Financial Services',
  },
  {
    name: 'Gaming',
    value: 'Gaming',
  },
  {
    name: 'Government',
    value: 'Government',
  },
  {
    name: 'Healthcare',
    value: 'Healthcare',
  },

  {
    name: 'Life Sciences',
    value: 'Life Sciences',
  },
  {
    name: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    name: 'Marketing & Advertising',
    value: 'Marketing & Advertising',
  },
  {
    name: 'Media & Entertainment',
    value: 'Media & Entertainment',
  },
  {
    name: 'Mining',
    value: 'Mining',
  },
  {
    name: 'Non-Profit Organization',
    value: 'Non-Profit Organization',
  },
  {
    name: 'Energy - Oil & Gas',
    value: 'Energy - Oil & Gas',
  },
  {
    name: 'Other',
    value: 'Other',
  },
  {
    name: 'Energy - Power & Utilities',
    value: 'Energy - Power & Utilities',
  },
  {
    name: 'Professional Services',
    value: 'Professional Services',
  },
  {
    name: 'Real Estate & Construction',
    value: 'Real Estate & Construction',
  },
  {
    name: 'Retail',
    value: 'Retail',
  },
  {
    name: 'Software & Internet',
    value: 'Software & Internet',
  },
  {
    name: 'Telecommunications',
    value: 'Telecommunications',
  },
  {
    name: 'Transportation & Logistics',
    value: 'Transportation & Logistics',
  },
  {
    name: 'Travel',
    value: 'Travel',
  },
  {
    name: 'Wholesale & Distribution',
    value: 'Wholesale & Distribution',
  },
]

export const cloudProvider = [
  {
    name: 'AWS',
    value: 'aws',
  },
  {
    name: 'GCP',
    value: 'gcp',
  },
  {
    name: 'Azure',
    value: 'azure',
  },
]
