import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  primaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(5),
  },
  verticalDivider: {
    width: theme.spacing(0.125),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: theme.spacing(0.5),
    background: theme.palette.dividerV2.main,
  },
  videoWrapper: {
    width: theme.spacing(50),
    backgroundColor: theme.palette.textBackground.light,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3, 4),
    gap: theme.spacing(2),
    borderRadius: theme.spacing(0.5),
    maxHeight: theme.spacing(29.875),
    alignSelf: 'center',
  },
  videoContainer: {
    width: theme.spacing(31.75),
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  thumbnailWrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  thumbnailImages: {
    width: theme.spacing(30),
    height: theme.spacing(16.875),
    cursor: 'pointer',
    marginBottom: theme.spacing(1.125),
    borderRadius: theme.spacing(0.5),
  },
  playButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    cursor: 'pointer',
    opacity: '0.19',
    width: theme.spacing(9.75),
    height: theme.spacing(6.25),
    color: theme.palette.black.dark,
  },
}))
