import React from 'react'
import { BoxV2, DynamicInput, LabraToolTipV2 } from '@labrav/react-components'
import {
  AwsCloudSettingsSections,
  AwsCloudSettingsSectionsProps,
} from '../../types'
import { Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HelpDrawer } from '../../../../../../common/components/HelpDrawerV2/HelpDrawer'
import { useStyles as prequisitesStyles } from './Prequisites.styles'
import { useStyles as commonStyles } from '../CloudSettingsWrapper.styles'
import { useFormikContext } from 'formik'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { NextSectionButton } from '../NextSectionButton/NextSectionButton'
import StepStatusIcon from '../StepStatusIcon/StepStatusIcon'

export const PrerequisitesSection: React.FC<{
  isOnboardingCompleted: boolean
}> = ({ isOnboardingCompleted }) => {
  const classes = prequisitesStyles()
  const commonClasses = commonStyles()
  const formik = useFormikContext<AwsCloudSettingsSectionsProps>()

  return (
    <BoxV2
      data-testid="prerequisites-box"
      heading="Prerequisites"
      headingIcon={
        <>
          <StepStatusIcon
            sectionName={AwsCloudSettingsSections.PREREQUISITES}
          />
          <Typography
            className={commonClasses.loadingText}
            data-testid="prerequisites-step-text"
          >
            STEP 01
          </Typography>
        </>
      }
      // TODO: TO BE ADDED IN ACE API ONBOARDING PHASE-2
      // secondaryActions={
      //   <HelpDrawer
      //     sectionName="oppsyncCloudAccountDetails"
      //     headerTitle="Help"
      //     data-testid="prerequisites-help-drawer"
      //   />
      // }
      variant={'small'}
      headingVariant={'small'}
      collapsible
    >
      <div
        className={classes.mainContainer}
        data-testid="prerequisites-main-container"
      >
        <div
          className={classes.choiceContainer}
          data-testid="prerequisites-choice-container"
        >
          <DynamicInput
            field={{
              title: (
                <div
                  className={commonClasses.inLineInfo}
                  data-testid="prerequisites-ace-title"
                >
                  <Typography className={commonClasses.checkBoxText}>
                    I confirm that I am an AWS Partner and have enrolled in the
                    APN Customer Engagement (ACE) program
                  </Typography>
                  <LabraToolTipV2
                    tooltipPlacement="top"
                    description="If you are unsure about your AWS Partnership, please refer to the Help section for further details."
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className={commonClasses.info}
                      data-testid="prerequisites-ace-tooltip"
                    />
                  </LabraToolTipV2>
                </div>
              ),
              placeHolder: 'is APN enrolled',
              id: 'isApnEnrolled',
              isRequired: false,
              humanReadableKey: 'isApnEnrolled',
              isReadOnly: false,
              isCustom: false,
              defaultValue: null,
              dataType: 'bool',
            }}
            formik={formik}
            prefix={AwsCloudSettingsSections.PREREQUISITES}
            data-testid="prerequisites-ace-input"
          />
          <DynamicInput
            field={{
              title: (
                <div
                  className={commonClasses.inLineInfo}
                  data-testid="prerequisites-config-title"
                >
                  <Typography className={commonClasses.checkBoxText}>
                    I confirm that I have configured my solution in AWS Partner
                    Central for co-selling.
                  </Typography>
                  <LabraToolTipV2
                    tooltipPlacement="top"
                    description="If you have not yet set up your solutions in AWS Partner Central, please refer to the Help section for further details."
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className={commonClasses.info}
                      data-testid="prerequisites-config-tooltip"
                    />
                  </LabraToolTipV2>
                </div>
              ),
              placeHolder: 'are solutions and products configured',
              id: 'areSolutionsConfigured',
              isRequired: false,
              humanReadableKey: 'areSolutionsConfigured',
              isReadOnly: false,
              isCustom: false,
              defaultValue: null,
              dataType: 'bool',
            }}
            formik={formik}
            prefix={AwsCloudSettingsSections.PREREQUISITES}
            data-testid="prerequisites-config-input"
          />
          <DynamicInput
            field={{
              title: (
                <div
                  className={commonClasses.inLineInfo}
                  data-testid="prerequisites-req-title"
                >
                  <Typography className={commonClasses.checkBoxText}>
                    I confirm that I have completed the ACE eligibility
                    requirements to start receiving referrals from AWS{' '}
                    <span className={classes.optionalText}>(optional)</span>.
                  </Typography>
                  <LabraToolTipV2
                    tooltipPlacement="top"
                    description="If you are unsure about your ACE eligibility, please refer to the Help section for further details."
                  >
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className={commonClasses.info}
                      data-testid="prerequisites-req-tooltip"
                    />
                  </LabraToolTipV2>
                </div>
              ),
              placeHolder: 'is ACE eligible',
              id: 'isAceEligible',
              isRequired: true,
              humanReadableKey: 'isAceEligible',
              isReadOnly: false,
              isCustom: false,
              defaultValue: null,
              dataType: 'bool',
            }}
            formik={formik}
            prefix={AwsCloudSettingsSections.PREREQUISITES}
            data-testid="prerequisites-req-input"
          />
        </div>
        {!isOnboardingCompleted && (
          <NextSectionButton sectionName={'prerequisites'} />
        )}
      </div>
    </BoxV2>
  )
}
