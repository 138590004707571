import React, { useState } from 'react'
import {
  BoxV2,
  ButtonV2,
  DynamicInput,
  ExternalLink,
  SelectV2,
} from '@labrav/react-components'
import { Typography } from '@material-ui/core'
import { HelpDrawer } from '../../../../../../common/components/HelpDrawerV2/HelpDrawer'
import { useStyles as coSellAccountDetailsStyles } from './CosellAccountDetails.styles'
import { useStyles as commonStyles } from '../CloudSettingsWrapper.styles'
import {
  AwsCloudSettingsSections,
  AwsCloudSettingsSectionsProps,
} from '../../types'
import { useFormikContext } from 'formik'
import { NextSectionButton } from '../NextSectionButton/NextSectionButton'
import StepStatusIcon from '../StepStatusIcon/StepStatusIcon'
import { partnerTier } from '../../../../../../common/utils/DataSelect'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsRotate } from '@fortawesome/pro-regular-svg-icons'
import { getPartnerSolutions } from '../../../../../api'
import { useDispatch } from 'react-redux'
import { updateAppAlert } from '../../../../../../common/modules/appAlert/actions'
import { usePartnerId } from '../../../../../../common/utils/Hooks/usePartnerData'

const partnershipTierOptions = partnerTier.map(f => ({
  label: f.name,
  value: f.value,
}))
type CoSellAccountDetailsSection = {
  isOnboardingCompleted?: boolean
}
export const CoSellAccountDetailsSection: React.FC<
  CoSellAccountDetailsSection
> = ({ isOnboardingCompleted }) => {
  const classes = coSellAccountDetailsStyles()
  const commonClasses = commonStyles()
  const dispatch = useDispatch()
  const partnerId = usePartnerId()
  const formik = useFormikContext<AwsCloudSettingsSectionsProps>()
  const { values, errors, touched } = formik
  const partnerTypeError =
    errors[AwsCloudSettingsSections.CLOUD_DETAILS]?._partnerTypeValidation
  const {
    partnerSoftwarePath: partnerSoftwarePathTouched,
    partnerServicePath: partnerServicePathTouched,
  } = touched[AwsCloudSettingsSections.CLOUD_DETAILS] || ({} as any)

  const hasPartnerTypeErrors =
    partnerTypeError && partnerSoftwarePathTouched && partnerServicePathTouched

  const { partnerSoftwarePath, partnerServicePath } =
    values[AwsCloudSettingsSections.CLOUD_DETAILS] || ({} as any)

  const [isLoading, setIsLoading] = useState(false)
  const handleRefreshClick = async () => {
    setIsLoading(true)
    try {
      if (!partnerId) {
        dispatch(
          updateAppAlert({
            message: 'Partner ID is missing.',
            messageType: 'ERROR',
            autoClose: true,
          })
        )
        throw new Error('Partner ID is missing.')
      }

      await getPartnerSolutions(partnerId, { updateCache: true })

      dispatch(
        updateAppAlert({
          message: 'Solutions refreshed successfully',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error) {
      console.error('Error refreshing solutions:', error)
      let errorMessage = 'Failed to refresh solutions. Please try again.'

      if (error instanceof Error) {
        console.error('Specific error:', error.message)
        errorMessage = error.message
      }

      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <BoxV2
        heading="Cloud account details"
        headingIcon={
          <>
            <StepStatusIcon
              sectionName={AwsCloudSettingsSections.CLOUD_DETAILS}
            />
            {!isOnboardingCompleted && (
              <Typography className={commonClasses.loadingText}>
                STEP 03
              </Typography>
            )}
          </>
        }
        // TODO: TO BE ADDED IN ACE API ONBOARDING PHASE-2
        // secondaryActions={
        //   <HelpDrawer sectionName="coSellAccountDetails" headerTitle="Help" />
        // }
        collapsible={true}
        variant={'small'}
        headingVariant={'small'}
      >
        <div
          className={classes.mainContainer}
          data-testid="cosell-account-details-main-container"
        >
          <div className={classes.primaryContainer}>
            <Typography className={classes.heading}>
              Partnership types
            </Typography>
            <div className={classes.partnershipSection}>
              <div className={classes.choiceBox}>
                <DynamicInput
                  field={{
                    title: 'Services path',
                    placeHolder: 'Services path',
                    id: 'partnerServicePath',
                    isRequired: true,
                    humanReadableKey: 'servicesPath',
                    isReadOnly: false,
                    isCustom: false,
                    defaultValue: null,
                    dataType: 'bool',
                  }}
                  formik={formik}
                  prefix={AwsCloudSettingsSections.CLOUD_DETAILS}
                />
                <Typography className={classes.choiceBoxText}>
                  Consulting, professional, managed, and resale services.
                </Typography>
              </div>
              <div className={classes.choiceBox}>
                <DynamicInput
                  field={{
                    title: 'Software path',
                    placeHolder: 'Software path',
                    id: 'partnerSoftwarePath',
                    isRequired: true,
                    humanReadableKey: 'softwarePath',
                    isReadOnly: false,
                    isCustom: false,
                    defaultValue: null,
                    dataType: 'bool',
                  }}
                  formik={formik}
                  prefix={AwsCloudSettingsSections.CLOUD_DETAILS}
                />
                <Typography className={classes.choiceBoxText}>
                  Develop software that runs on or is integrated with AWS.
                </Typography>
              </div>
            </div>
            {hasPartnerTypeErrors && (
              <Typography className={classes.errorText} color="error">
                {partnerTypeError}
              </Typography>
            )}
            {partnerServicePath && (
              <div className={classes.servicePartnerSelect}>
                <div className={classes.selectField}>
                  <DynamicInput
                    formik={formik}
                    prefix={AwsCloudSettingsSections.CLOUD_DETAILS}
                    field={{
                      title: 'AWS Services Partner Tier',
                      placeHolder: 'AWS Services Partner Tier',
                      humanReadableKey: 'aws-services-partner-tier',
                      id: 'awsServicesPartnerTier',
                      isRequired: true,
                      isReadOnly: false,
                      isCustom: false,
                      defaultValue: '',
                      dataType: 'enum',
                      options: partnershipTierOptions,
                      info: 'Kindly choose the relevant service partner tier that your organization is a part of. Refer to the help section for further details about the AWS Services Partner Tiers and find the one your organization is a part of using Partner Central.',
                    }}
                  />
                </div>
              </div>
            )}
            <div>
              <Typography className={classes.heading}>
                Select the AWS partner programs below, you are a part of
              </Typography>
              <Typography className={commonClasses.subHeading}>
                To know the programs you are enrolled in, visit{' '}
                <ExternalLink
                  href={'https://aws.amazon.com/partners/programs/'}
                >
                  {' '}
                  Programs enrolled{' '}
                </ExternalLink>{' '}
                on AWS and check out the list.
              </Typography>
            </div>
            <div className={classes.selectProgramList}>
              <div className={classes.selectProgramCheckBox}>
                <DynamicInput
                  field={{
                    title: (
                      <div className={classes.selectProgramCheckBoxField}>
                        <Typography>
                          Independent Software Vendor (ISV)
                        </Typography>
                        <ExternalLink
                          className={classes.externalLinkIcon}
                          href={
                            'https://aws.amazon.com/what-is/independent-software-vendor/'
                          }
                          children={undefined}
                        />
                      </div>
                    ),
                    placeHolder: 'Independent Software Vendor (ISV)',
                    id: 'isv',
                    isRequired: false,
                    humanReadableKey: 'isv',
                    isReadOnly: false,
                    isCustom: false,
                    defaultValue: null,
                    dataType: 'bool',
                  }}
                  formik={formik}
                  prefix={AwsCloudSettingsSections.CLOUD_DETAILS}
                />
                <DynamicInput
                  field={{
                    title: (
                      <div className={classes.selectProgramCheckBoxField}>
                        <Typography>AWS ISV Accelerate Program</Typography>
                        <ExternalLink
                          className={classes.externalLinkIcon}
                          href={
                            'https://aws.amazon.com/partners/programs/isv-accelerate/'
                          }
                          children={undefined}
                        />
                      </div>
                    ),
                    placeHolder: 'AWS ISV Accelerate Program',
                    id: 'awsIsvAccelerateProgram',
                    isRequired: false,
                    humanReadableKey: 'awsIsvAccelerateProgram',
                    isReadOnly: false,
                    isCustom: false,
                    defaultValue: null,
                    dataType: 'bool',
                  }}
                  formik={formik}
                  prefix={AwsCloudSettingsSections.CLOUD_DETAILS}
                />
                <DynamicInput
                  field={{
                    title: (
                      <div className={classes.selectProgramCheckBoxField}>
                        <Typography>MSSP Program</Typography>
                        <ExternalLink
                          className={classes.externalLinkIcon}
                          href={'https://aws.amazon.com/mssp/'}
                          children={undefined}
                        />
                      </div>
                    ),
                    placeHolder: 'MSSP Program',
                    id: 'msspProgram',
                    isRequired: false,
                    humanReadableKey: 'mssp-program',
                    isReadOnly: false,
                    isCustom: false,
                    defaultValue: null,
                    dataType: 'bool',
                  }}
                  formik={formik}
                  prefix={AwsCloudSettingsSections.CLOUD_DETAILS}
                />
              </div>
              {/* TODO: TO BE FIXED IN ACE ONBOARDING PHASE-2
              <div className={classes.selectProgramCheckBox}>
                <DynamicInput
                  field={{
                    title: (
                      <div className={classes.selectProgramCheckBoxField}>
                        <Typography>Security Service Software Only</Typography>
                        <ExternalLink
                          className={classes.externalLinkIcon}
                          href={' '}
                          children={undefined}
                        />
                      </div>
                    ),
                    placeHolder: 'Security Service Software Only',
                    id: 'securityServiceSoftwareOnly',
                    isRequired: false,
                    humanReadableKey: 'security-service-only',
                    isReadOnly: false,
                    isCustom: false,
                    defaultValue: null,
                    dataType: 'bool',
                  }}
                  formik={formik}
                  prefix={AwsCloudSettingsSections.CLOUD_DETAILS}
                />
                <DynamicInput
                  field={{
                    title: (
                      <div className={classes.selectProgramCheckBoxField}>
                        <Typography>
                          Security Service Managed Services
                        </Typography>
                        <ExternalLink
                          className={classes.externalLinkIcon}
                          href={' '}
                          children={undefined}
                        />
                      </div>
                    ),
                    placeHolder: 'Security Service Managed Services',
                    id: 'securityServiceManagedServices',
                    isRequired: false,
                    humanReadableKey: 'security-service-managed-services',
                    isReadOnly: false,
                    isCustom: false,
                    defaultValue: null,
                    dataType: 'bool',
                  }}
                  formik={formik}
                  prefix={AwsCloudSettingsSections.CLOUD_DETAILS}
                />
                <DynamicInput
                  field={{
                    title: (
                      <div className={classes.selectProgramCheckBoxField}>
                        <Typography>
                          Security Service Professional Services
                        </Typography>
                        <ExternalLink
                          className={classes.externalLinkIcon}
                          href={' '}
                          children={undefined}
                        />
                      </div>
                    ),
                    placeHolder: 'Security Service Professional Services',
                    id: 'securityServiceProfessionalServices',
                    isRequired: false,
                    humanReadableKey: 'security-service-professional-services',
                    isReadOnly: false,
                    isCustom: false,
                    defaultValue: null,
                    dataType: 'bool',
                  }}
                  formik={formik}
                  prefix={AwsCloudSettingsSections.CLOUD_DETAILS}
                />
              </div> */}
            </div>
            {isOnboardingCompleted && (
              <div className={classes.refreshSolutionsSection}>
                <Typography className={classes.heading}>
                  Refresh solution offered list in CRM
                </Typography>
                <ButtonV2
                  onClick={handleRefreshClick}
                  data-testid="refresh-solutions-button"
                  styleType="outlined"
                  icon={
                    <FontAwesomeIcon
                      icon={faArrowsRotate}
                      className={`${classes.refreshIcon} ${
                        isLoading ? 'rotating' : ''
                      }`}
                    />
                  }
                  smallVariant={true}
                  className={classes.refreshButton}
                  disabled={isLoading}
                >
                  Refresh solutions
                </ButtonV2>
              </div>
            )}
          </div>
          {!isOnboardingCompleted && (
            <>
              <NextSectionButton
                sectionName={AwsCloudSettingsSections.CLOUD_DETAILS}
              />
            </>
          )}
        </div>
      </BoxV2>
    </div>
  )
}
