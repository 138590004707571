import React, { useState } from 'react'
import { BoxV2, ExternalLinkButton, Truncate } from '@labrav/react-components'
import { Typography } from '@material-ui/core'
import { HelpDrawer } from '../../../../../../common/components/HelpDrawerV2/HelpDrawer'
import { useStyles as iamPolicyToRoleAssignmentStyles } from './IamPolicyToRoleAssignment.styles'
import { useStyles as commonStyles } from '../CloudSettingsWrapper.styles'
import {
  AwsCloudSettingsSections,
  AwsCloudSettingsSectionsProps,
} from '../../types'
import StepStatusIcon from '../StepStatusIcon/StepStatusIcon'
import Persona from '../Persona/Persona'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import EnvironmentDetails from '../EnvironmentDetailsSection/EnvironmentDetails'
import { useFormikContext } from 'formik'

type IamPolicyToRoleAssignmentSection = {
  isOnboardingCompleted?: boolean
}
export const IamPolicyToRoleAssignmentSection: React.FC<
  IamPolicyToRoleAssignmentSection
> = ({ isOnboardingCompleted }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const classes = iamPolicyToRoleAssignmentStyles()
  const commonClasses = commonStyles()
  const formik = useFormikContext<AwsCloudSettingsSectionsProps>()
  const { productionArn, stagingArn } =
    formik.values[AwsCloudSettingsSections.CREATE_IAM_ROLE]

  const { iamPolicyStaging, iamPolicyProduction } =
    formik.values[AwsCloudSettingsSections.GENERATE_POLICIES]

  const handleReadMore = () => {
    setIsExpanded(!isExpanded)
  }
  return (
    <BoxV2
      heading="Assigning IAM policies to the role"
      subHeading={
        <Typography className={commonClasses.subHeading}>
          Attach the policies generated from Partner Central to the IAM roles
          created earlier. Link the IAM roles and policies in the AWS Cons
          {!isExpanded ? (
            <>
              <span
                //TODO: update to use component from https://ibexlabs.atlassian.net/browse/LT-10058
                className={commonClasses.readmore}
                onClick={handleReadMore}
              >
                ...read more
              </span>
              .
            </>
          ) : (
            <>
              ole through the link provided below, ensuring attaching the policy
              for both staging and production environments.
            </>
          )}
        </Typography>
      }
      actions={
        <>
          <div
            className={commonClasses.personaSection}
            data-testid="persona-section"
          >
            <Persona memberType="admin" />
          </div>
        </>
      }
      headingIcon={
        <>
          <StepStatusIcon
            sectionName={AwsCloudSettingsSections.ASSIGN_POLICIES}
          />
          {!isOnboardingCompleted && (
            <Typography className={commonClasses.loadingText}>
              STEP 06
            </Typography>
          )}
        </>
      }
      // TODO: TO BE ADDED IN ACE API ONBOARDING PHASE-2
      // secondaryActions={
      //   <HelpDrawer
      //     sectionName="oppsyncCloudAccountDetails"
      //     headerTitle="Help"
      //   />
      // }
      variant={'small'}
      headingVariant={'small'}
      collapsible
    >
      <div data-testid="iam-policy-to-role-assignment-main-container">
        <div className={classes.mainContainer}>
          <div id="left" className={commonClasses.leftContainer}>
            <div className={commonClasses.buttonSection}>
              <ExternalLinkButton
                buttonTitle={'Attach Policies'}
                href={
                  'https://signin.aws.amazon.com/signup?request_type=register'
                }
                modalTitleAcceptButton="Open window"
                modalTitleCancleButton="Back"
                modalTitle="You are being re-directed to another window"
                modalIcon={
                  <FontAwesomeIcon
                    icon={faUpRightFromSquare}
                    className={commonClasses.externalLinkIcon}
                  />
                }
                modalContent={
                  <>
                    <div className={commonClasses.modalContentContainer}>
                      <div className={classes.modalMainContainer}>
                        <div className={classes.upperContainer}>
                          <Typography
                            className={commonClasses.modalDescription}
                          >
                            You are being redirected to the AWS Console window
                            to attach the generated policy to the IAM role
                            created in Step 4.
                            <br /> To complete this process, ensure you are
                            logged into the AWS account where you created the
                            IAM role in Step 2.
                            <br /> Use the button below to attach the policy for
                            each environment—Staging and Production
                            respectively.
                          </Typography>
                        </div>
                        <div className={classes.belowContainer}>
                          <EnvironmentDetails
                            arn={stagingArn ?? ''}
                            envType="staging"
                            envPolicy={iamPolicyStaging ?? ''}
                          />
                          <EnvironmentDetails
                            arn={productionArn ?? ''}
                            envType="production"
                            envPolicy={iamPolicyProduction ?? ''}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            </div>
          </div>
          {/* <div
            id="right-container"
            className={commonClasses.rightContainer}
          >
            <div className={commonClasses.verticalDivider}></div>
            <div className={commonClasses.videoContainer}></div>
          </div> */}
        </div>
      </div>
    </BoxV2>
  )
}
