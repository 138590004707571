import { AnyAction, Dispatch } from 'redux'
import { getErrorMessages } from '../../../common/utils/error'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { errorLogger } from '../../../common/utils/errorLogger'
import { AppDispatch, RootState } from '../../../store'
import { DateRange, InteractionsData, MetricsData } from './reducer'
import storefrontMetricsMock from '../../../mocks/storefrontMetricsMock.json'
import { camelize } from 'casing'
import { getMonthAbbreviation } from '../../../common/utils/getGraphDateLabel'
import { getMetricsData, getMetricsProductData } from '../../api/markeplace'
import { contactsFormFields } from '../../../oppsync/utils/cosell/constants'

export enum StorefrontMetricsActionTypes {
  SET_STOREFRONT_METRICS_DATA = 'SET_STOREFRONT_METRICS_DATA',
  CLEAR_STOREFRONT_METRICS_DATA = 'CLEAR_STOREFRONT_METRICS_DATA',
  SET_STOREFRONT_METRICS_DOMAIN = 'SET_STOREFRONT_METRICS_DOMAIN',
  SET_STOREFRONT_METRICS_PRODUCT = 'SET_STOREFRONT_METRICS_PRODUCT',
  SET_DATE_RANGE = 'SET_DATE_RANGE',
}
export const decodeBase64 = (encoded: string): string => {
  try {
    return atob(encoded)
  } catch (error) {
    console.error('Error decoding base64:', error)
    return encoded
  }
}

export const getStorefrontMetricsData =
  (domain?: string, selectedProduct?: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.METRICS_LOADING))
    // await dispatch(clearStorefrontMetricsData())
    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const { dateRange } = getState().storefrontMetricsData
      const finalStartDate = dateRange.startDate
      const finalEndDate = dateRange.endDate

      const products = await getMetricsProductData({ partnerId })
      // const response = camelize(storefrontMetricsMock)
      const { data } = await getMetricsData({
        partnerId,
        finalStartDate,
        finalEndDate,
        domain,
        selectedProduct,
      })
      // const response = camelize(data)
      const response = camelize(data)
      const transformedMetricsData: MetricsData = {
        ...response,
        domainNames: ['All domains', ...response?.domainNames],
        graphData:
          response?.graphData?.map((item: InteractionsData) => ({
            ...item,
            data:
              item?.data?.map(({ date, value }) => ({
                x: getMonthAbbreviation(date),
                y: value,
              })) || [],
          })) || [],
        trafficSource: {
          ...response.trafficSource,
          otherTrafficSource:
            response?.trafficSource?.otherTrafficSource?.map((item: any) => ({
              ...item,
              ref: decodeBase64(item?.ref),
            })) || [],
          top3:
            response?.trafficSource?.top3?.map((item: any) => ({
              ...item,
              ref: decodeBase64(item?.ref),
            })) || [],
        },
        productOptions: [
          { label: 'All listings', value: 'allListings' },
          ...products?.data?.map(
            ({ product_id, name }: { product_id: string; name: string }) => ({
              label: name,
              value: product_id,
            })
          ),
        ],
      }
      await dispatch(setStorefrontMetricsData(transformedMetricsData))
      await dispatch(
        updateAppAlert({
          message: '',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.METRICS_LOADING))
    }
  }
export const setStorefrontMetricsData = (metricsData: MetricsData) => {
  return {
    type: StorefrontMetricsActionTypes.SET_STOREFRONT_METRICS_DATA,
    payload: metricsData,
  }
}
export const setStorefrontMetricDomain = (domain: string) => {
  return {
    type: StorefrontMetricsActionTypes.SET_STOREFRONT_METRICS_DOMAIN,
    payload: domain,
  }
}
export const setStorefrontMetricProduct = (selectedProduct: string) => {
  return {
    type: StorefrontMetricsActionTypes.SET_STOREFRONT_METRICS_PRODUCT,
    payload: selectedProduct,
  }
}
export const clearStorefrontMetricsData = () =>
  ({
    type: StorefrontMetricsActionTypes.CLEAR_STOREFRONT_METRICS_DATA,
  } as unknown as AnyAction)
export const updateMetricDateRange =
  (dates: DateRange) => async (dispatch: AppDispatch) => {
    const { startDate, endDate } = dates
    await dispatch(setDateRange({ startDate, endDate }))
  }
export const setDateRange = (dates: DateRange) => ({
  type: StorefrontMetricsActionTypes.SET_DATE_RANGE,
  payload: dates,
})
