import React from 'react'
import { CompleteOnboardingPage } from '../CompleteOnboardingPage/CompleteOnboardingPage'

export const CoSelOnboardingPage: React.FC<{
  customButton: React.ReactNode
}> = ({ customButton }) => {
  const productInfo = {
    title: 'Complete your onboarding',
    features: [
      {
        text: 'Seamlessly create and manage opportunities for cloud hyperscalers directly from your CRM.',
      },
      {
        text: 'Effectively track and manage your co-sell pipeline with ease.',
      },
      {
        text: 'Access to the co-sell analytics dashboard for actionable insights into your pipeline performance.',
      },
    ],
    subheading:
      'By completing all onboarding steps, you’ll gain the ability to:',
    customButton: customButton,
  }
  return (
    <div>
      <CompleteOnboardingPage onboardingProductInfo={productInfo} />
    </div>
  )
}
