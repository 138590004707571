import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useStyles } from './MarketplaceNotifications.styles'
import {
  CloudChangeV2,
  CustomCloudOptions,
} from '../../../common/components/CloudChangeV2/CloudChangeV2'
import clsx from 'clsx'
import { useCurrentCloudProvider } from '../../../common/utils/Hooks/useCurrentCloudProvider'
import {
  ButtonV2,
  LabraToolTipV2,
  SwitchV2,
  SwitchVarient,
  TableComponentV2,
  AvatarV3,
  BoxV2,
  CheckBoxFieldv2,
  Select,
  AutoComplete,
  ModalConfirmV2,
  ModalSizeVariants,
} from '@labrav/react-components'
import { TableCell, Typography } from '@material-ui/core'
import {
  customerFormData,
  usePartnerId,
  useUserType,
} from '../../../common/utils/Hooks/usePartnerData'
import { get, isEmpty } from 'lodash'
import { useDispatch, useSelector } from '../../../store'
import {
  NotificationEnabledType,
  NotificationsSettingPage,
  ProductNotificationTypes,
  NotificationsTypeMethods,
  recipients,
} from '../../../oppsync/modules/notification/reducer'
import { Product } from '../../../common/modules/partner/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBell,
  faBookmark,
  faInfoCircle,
  faUserPlus,
  faXmarkCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { UserProfile } from '../../../common/modules/userProfile/action'
import { Role } from '../../../common/utils/roles'
import {
  discardSettingsPageData,
  getSettingsPageData,
  saveNotificationsV2,
  updateMultipleSlackRecipients,
  updateProductNotificationValues,
} from '../../../oppsync/modules/notification/actions'
import { IntegrationValue } from '../../../common/modules/thirdPartyAppIntegration/reducer'
import { getThirdPartyAppList } from '../../../common/modules/thirdPartyAppIntegration/action'
import { isLoading } from '../../../common/utils/loadingState'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { FooterV2 } from '../../../common/components/FooterV2/FooterV2'
import { LoadingStateComponent } from '../../../common/components/LoadingStateComponent/LoadingStateComponent'
import {
  getSlackChannels,
  SlackChannelsData,
} from '../../../common/modules/slackChannels/action'
import { getRandom } from '../../../common/utils/helperFunctions'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { useActiveProducts } from '../../../common/utils/Hooks/activeProducts'
import { FlyOutListingData } from '../../../admin/modules/flyOutSyncs/reducer'
import { useFlagValue } from '@labrav/flags'
import FlyoutBlockPage from '../../../common/components/BlockPages/FlyoutBlockPage'
import { MarketplaceOnboardingPage } from '../../../common/components/OnboardingPages/MarketplaceOnboardingPage'
import { useNavigate } from 'react-router-dom'
import { HelpDrawer } from '../../../common/components/HelpDrawerV2/HelpDrawer'
import { NoOptionsCustomForAutoComplete } from '../../../common/components/NoOptionsCustomForAutoComplete/NoOptionsCustomForAutoComplete'
import { useTracker } from '../../../common/utils/Tracker/hook'

export const mapping = {
  'Notification type': 'notificationTypeName',
  'Slack channel': 'slackChannel',
  'Notification status': 'notificationEnabled',
}

export const buyerNotifications = [
  'AWS_REGISTRATION_INCOMPLETE_SUBSCRIPTION',
  'AWS_ENTITLEMENT_EXPIRED',
  'AWS_ENTITLEMENT_EXPIRING_SOON',
  'AWS_ENTITLEMENT_RENEW',
  'AWS_ENTITLEMENT_UPGRADE',
  'AWS_REGISTRATION_INCOMPLETE_CONTRACT',
  'AWS_REGISTRATION_INCOMPLETE_OFFER',
  'AWS_FDA_STARTED_SELLER',
  'AWS_SELLER_ORDER_CONFIRMATION_CONTRACT',
  'AWS_SELLER_ORDER_CONFIRMATION_SUBSCRIPTION',
  'AWS_SUBSCRIPTION_FAIL',
  'AWS_UNSUBSCRIBE_PENDING',
  'AWS_UNSUBSCRIBE_SUCCESS',
  // 'EXTERNAL_CUSTOMER_REQUEST', #To be added later on
]
export const listingNotifications = [
  'PRODUCT_LISTING_LIVE',
  'PRODUCT_LISTING_REJECTED',
  'PRODUCT_LISTING_AWS_TESTING',
  'FREE_TRIAL_ACTIVATED',
  'FREE_TRIAL_DEACTIVATED',
]
export const resellerAuthorizationNotifications = [
  'AWS_RESELLER_AGREEMENT_EXPIRED_SELLER',
  'AWS_RESELLER_AGREEMENT_EXPIRING_SOON_SELLER',
  'AWS_RESELLER_AGREEMENT_DEACTIVATED_SELLER',
  'AWS_RESELLER_AGREEMENT_ACTIVE_SELLER',
]
export const privateOfferNotifications = [
  'AWS_PRIVATE_OFFER_CREATION_SUCCESS',
  'AWS_PRIVATE_OFFER_CREATION_FAILED',
  'AWS_PRIVATE_OFFER_EXPIRED',
  'AWS_PRIVATE_OFFER_ACCEPTED_SELLER',
  'AWS_PRIVATE_OFFER_EXPIRING_SOON',
  'AWS_PRIVATE_OFFER_RESTRICTION_FAILED',
  'AWS_PRIVATE_OFFER_RESTRICTION_SUCCESS',
]
export const meteringNotifications = ['AWS_MARKETPLACE_METERING_SENT']
export const channelPartnerNotifications: string[] = []

export const helpDrawerMappingMarketplace: Record<string, string> = {
  buyerLifecycle: 'flyOutBuyerLifecyclePlatformNotifications',
  listing: 'flyOutListingPlatformNotifications',
  privateOffer: 'flyOutPrivateOfferPlatformNotifications',
  resellerAuthorization: 'flyOutResellerPlatformNotifications',
  metering: 'flyOutMeteringPlatformNotifications',
  // channelPartner: 'flyOutChannelPartnerPlatformNotifications', #To be added later on
}

export const sectionHeadings: Record<string, string> = {
  buyerLifecycle: 'Buyer lifecycle notifications',
  listing: 'Listing notifications',
  privateOffer: 'Private offer notifications',
  resellerAuthorization: 'Reseller authorization notifications',
  metering: 'Metering notifications',
  // channelPartner: 'Channel partner notifications', #To be added later on
}

const getSlackNameAndIdFromNotificationMethod = (
  notification: NotificationsTypeMethods[]
) => {
  const method =
    notification?.filter(method => method.methodId === 'SLACK')[0] ||
    ({} as NotificationsTypeMethods)
  let activeChannel = undefined
  if (method?.recipients?.length) {
    activeChannel =
      method.recipients[0].metaData?.activeChannel?.channelId || undefined
  }
  return activeChannel
}

const getInitalSelectedNotifications = () => {
  return {
    buyerLifecycle: [],
    listing: [],
    privateOffer: [],
    metering: [],
    resellerAuthorization: [],
  }
}

export const getSlackInputParams = (
  slackChannelsList: SlackChannelsData[],
  isAdminOrOwner: boolean,
  activeChannel?: string
) => {
  const randomKey = getRandom()
  const activeChannelLabel =
    (slackChannelsList || []).filter(
      slack => slack.channelId === activeChannel
    )[0]?.name || ''
  return {
    id: `slackChannel-notification-${randomKey}`,
    label: '',
    placeholder: 'Select a Slack channel',
    options: isEmpty(slackChannelsList)
      ? []
      : slackChannelsList.map(slack => ({
          key: slack.channelId,
          label: slack.name,
        })),
    disabled: !isAdminOrOwner,
    required: false,
    value: !isEmpty(activeChannel)
      ? { key: activeChannel, label: activeChannelLabel }
      : '',
  }
}

const customCloudSelectorOptions: CustomCloudOptions = [
  { name: 'AWS', disabled: false },
  { name: 'GCP', disabled: true },
  { name: 'AZURE', disabled: true },
]

export const MarketplaceNotifications: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const tracker = useTracker()
  const navigate = useNavigate()
  const partnerType = useUserType()
  const cloud = useCurrentCloudProvider()
  const activeProducts = useActiveProducts()
  const [openSidePanelInfo, setOpenSidePanelInfo] = useState<{
    [key: string]: boolean
  }>({})
  const [selectedNotifications, setSelectedNotifications] = useState<
    Record<string, string[]>
  >(() => getInitalSelectedNotifications())

  // ---------- All state values ----------

  const loading = useSelector<boolean>(state =>
    isLoading(state.loading)(LoadingTypes.GENERAL)
  )

  const partnerId = usePartnerId()
  const user = useSelector<UserProfile | undefined>(
    state => state.userProfile.userProfile
  )
  const product = useSelector<Product>(
    state => state.platformSettings.selectedProductOnSettings
  )
  const { notificationsData, isFormDirty } = useSelector<{
    notificationsData: NotificationsSettingPage
    isFormDirty: boolean
  }>(state => {
    return {
      notificationsData:
        state.notifications?.[product]?.notificationsSettingData,
      isFormDirty: state.notifications?.[product]?.isFormDirty,
    }
  })

  const slackIntegration = useSelector<IntegrationValue>(
    state => state.integrations?.integrationsObj?.SLACK
  )
  const slackChannelsList = useSelector<SlackChannelsData[]>(
    state => state?.slackChannels?.[product]?.slackChannelsData || []
  )
  const showLink = isEmpty(slackChannelsList)
  const customerData = customerFormData()
  const subscribedProducts = customerData.subscribedProducts
  const crmType = customerData.crmType
  const partnerInviteEnabled = useFlagValue('partnerInvite')
  const isCrmLabra = crmType === 'labra'
  const showCrm = partnerInviteEnabled?.value ? isCrmLabra : false
  const { FLYOUT: flyoutProduct } = subscribedProducts
  const { flyoutSellerInfo } = useSelector<{
    flyoutSellerInfo: FlyOutListingData
  }>(state => ({
    flyoutSellerInfo: state.flyoutSellerInfo,
  }))
  const isFlyoutSubscribed = !partnerInviteEnabled?.value || !!flyoutProduct
  const isFlyoutOnboarded = flyoutSellerInfo?.onboarded === true

  const buyerNotificationsData = useMemo(
    () =>
      notificationsData?.productNotificationTypes.filter(item =>
        buyerNotifications.includes(item.notificationTypeId)
      ),
    [notificationsData?.productNotificationTypes, slackChannelsList.length]
  )
  const listingNotificationsData = useMemo(
    () =>
      notificationsData?.productNotificationTypes.filter(item =>
        listingNotifications.includes(item.notificationTypeId)
      ) || [],
    [notificationsData?.productNotificationTypes, slackChannelsList.length]
  )
  const privateOfferNotificationsData = useMemo(
    () =>
      notificationsData?.productNotificationTypes.filter(item =>
        privateOfferNotifications.includes(item.notificationTypeId)
      ) || [],
    [notificationsData?.productNotificationTypes, slackChannelsList.length]
  )
  const meteringNotificationsData = useMemo(
    () =>
      notificationsData?.productNotificationTypes.filter(item =>
        meteringNotifications.includes(item.notificationTypeId)
      ) || [],
    [notificationsData?.productNotificationTypes, slackChannelsList.length]
  )
  const resellerAuthorizationNotificationsData = useMemo(
    () =>
      notificationsData?.productNotificationTypes.filter(item =>
        resellerAuthorizationNotifications.includes(item.notificationTypeId)
      ) || [],
    [notificationsData?.productNotificationTypes, slackChannelsList.length]
  )
  const channelPartnerNotificationsData = useMemo(
    () =>
      notificationsData?.productNotificationTypes.filter(item =>
        channelPartnerNotifications.includes(item.notificationTypeId)
      ) || [],
    [notificationsData?.productNotificationTypes, slackChannelsList.length]
  )

  const notificationSectionMapping: Record<string, ProductNotificationTypes[]> =
    {
      buyerLifecycle: buyerNotificationsData,
      listing: listingNotificationsData,
      privateOffer: privateOfferNotificationsData,
      metering: meteringNotificationsData,
      resellerAuthorization: resellerAuthorizationNotificationsData,
      channelPartner: channelPartnerNotificationsData,
    }

  const [openTable, setOpenTable] = useState<Record<string, boolean>>({
    buyerLifecycle: false,
    listing: false,
    privateOffer: false,
    metering: false,
    resellerAuthorization: false,
    channelPartner: false,
  })

  const [openSlackModal, setOpenSlackModal] = useState<boolean>(false)
  const [selectedSection, setSelectedSection] = useState<string>('')
  const [slackChannelModal, setSlackChannelModal] = useState<string>()

  const sellerName = useSelector<string | undefined>(
    state => state.flyoutSellerInfo.awsAccountName
  )
  const saveDataLoading = useSelector<boolean>(state =>
    isLoading(state.loading)(LoadingTypes.SAVE_NOTIFICATIONS_DATA)
  )
  const slackChannelDataLoading = useSelector<boolean>(state =>
    isLoading(state.loading)(LoadingTypes.SLACK_CHANNELS_LOADING)
  )
  const [openDiscardModal, setOpenDiscardModal] = useState(false)

  //  ---------- constant variables  ----------

  const isAdminOrOwner =
    user?.roles?.some(r =>
      [Role.PARTNER_ADMIN, Role.PARTNER_OWNER].includes(r)
    ) || false

  const idpUserId = isAdminOrOwner ? undefined : user?.idpUserId
  const showLoading =
    loading ||
    isEmpty(notificationsData) ||
    isEmpty(product) ||
    slackChannelDataLoading

  const [openSaveModal, setOpenSaveModal] = useState(false)

  //  ---------- All UseEffects  ----------

  useEffect(() => {
    if (
      partnerId &&
      user &&
      product === 'flyout' &&
      isEmpty(notificationsData.productNotificationTypes)
    ) {
      dispatch(getSettingsPageData(partnerId, product, idpUserId, true))
    }
  }, [isAdminOrOwner, partnerId, product])

  useEffect(() => {
    if (isEmpty(slackIntegration) && partnerId && isAdminOrOwner) {
      dispatch(getThirdPartyAppList(partnerId))
    }
  }, [partnerId, slackIntegration, isAdminOrOwner])

  useEffect(() => {
    if (
      slackIntegration?.isConfigured &&
      partnerId &&
      product === 'flyout' &&
      isEmpty(slackChannelsList)
    ) {
      dispatch(getSlackChannels(partnerId, product))
    }
  }, [slackIntegration?.isConfigured, partnerId])
  //  ---------- All functions  ----------

  // Track event
  const trackEvent = async (
    heading: string,
    subHeading: string,
    action: string,
    title: string,
    source: string
  ) => {
    const formattedHeading = heading.replace(/\s+/g, '-')
    const formattedSubHeading = subHeading.replace(/\s+/g, '-')
    tracker?.track({
      customTracking: true,
      section: `${formattedHeading}`,
      component: `${formattedSubHeading}`,
      action: `${action}`,
      title: `${title}`,
      source: `${source}`,
      partnerId: `${partnerId}`,
      customerName: `${sellerName}`,
    })
  }

  const handleTableOpen = (section: string) => () => {
    setOpenTable(prevState => ({
      ...prevState,
      [section]: !prevState[section],
    }))
  }
  // Row Checkbox function
  const onChangeCheckbox = (section: string) => (item: string[]) => {
    setSelectedNotifications(prevState => ({
      ...prevState,
      [section]: item,
    }))
  }

  // Handle Empty slack
  const handleExternalIcon = () => {
    navigate('/settings/integrations')
  }

  // Notification type related function
  const handleInfoClick = (id: string) => {
    // TODO: https://ibexlabs.atlassian.net/browse/LT-10468
    // setOpenSidePanelInfo(prevState => ({
    //     ...prevState,
    //     [id]: !prevState[id], // Toggle the specific notification
    // }))
  }

  const sidePanelDrawerClose = (id: string) => {
    // TODO: https://ibexlabs.atlassian.net/browse/LT-10468
    // setOpenSidePanelInfo(prevState => ({
    //     ...prevState,
    //     [id]: false, // Close the specific notification
    // }))
  }

  const getNewRecipientsList = (
    productNotificationTypeIds: string[],
    channelId: string
  ) => {
    const recipientstoUpdate: Record<string, recipients[]> = {}
    productNotificationTypeIds.forEach(id => {
      if (channelId) {
        recipientstoUpdate[id] = [
          {
            idpUserId: null,
            destinationValue: null,
            metaData: {
              activeChannel: {
                channelId: channelId,
              },
            },
          },
        ]
      } else {
        recipientstoUpdate[id] = []
      }
    })
    return recipientstoUpdate
  }

  const handleChange =
    (productNotificationTypeIds: string[], modalSlackSelection = false) =>
    (
      _: React.ChangeEvent<Record<string, unknown>>,
      newValue: string | { key: string | undefined; label: string } | null
    ) => {
      const channelId = typeof newValue === 'object' ? newValue?.key ?? '' : ''
      if (modalSlackSelection) {
        setSlackChannelModal(channelId)
        trackEvent(
          'Marketplace Notifications',
          'Slack channel',
          'clicked',
          'Slack channel selected for multiple notifications',
          'Marketplace Notifications'
        )
      } else {
        const recipientstoUpdate = getNewRecipientsList(
          productNotificationTypeIds,
          channelId
        )
        dispatch(
          actionTypeWrapper(
            product,
            updateMultipleSlackRecipients(recipientstoUpdate)
          )
        )
        trackEvent(
          'Marketplace Notifications',
          'Slack channel',
          'clicked',
          'Slack channel selected for single notification',
          'Marketplace Notifications'
        )
      }
    }

  const handleSlackChannelButtonClick = (section: string) => () => {
    setOpenSlackModal(true)
    setSelectedSection(section)
  }
  const handleAddSlackChannels = () => {
    setOpenSlackModal(false)
    const recipientstoUpdate = getNewRecipientsList(
      selectedNotifications[selectedSection],
      slackChannelModal || ''
    )
    setSelectedSection('')
    setSlackChannelModal('')
    dispatch(
      actionTypeWrapper(
        product,
        updateMultipleSlackRecipients(recipientstoUpdate)
      )
    )
    trackEvent(
      'Marketplace Notifications',
      'Slack channel Add Button',
      'clicked',
      'Slack channel added for multiple notifications',
      'Marketplace Notifications'
    )
  }
  const handleSlackCancel = () => {
    setOpenSlackModal(false)
    setSelectedSection('')
    trackEvent(
      'Marketplace Notifications',
      'Slack channel cancel Button',
      'clicked',
      'Slack channel cancelled for multiple notifications',
      'Marketplace Notifications'
    )
  }

  // Notification status related function
  const handleChangeNotificationStatus =
    (productNotificationTypeId: string) =>
    (e: {
      target: {
        checked: boolean
      }
    }) => {
      let notificationStatus = NotificationEnabledType.INACTIVE
      if (e.target.checked) {
        notificationStatus = NotificationEnabledType.ACTIVE
      }
      // add it on the state
      dispatch(
        actionTypeWrapper(
          product,
          updateProductNotificationValues(
            productNotificationTypeId,
            'notificationEnabled',
            notificationStatus
          )
        )
      )
      trackEvent(
        'Marketplace Notifications',
        'Toggle',
        `${e.target.checked ? 'enabled' : 'disabled'}`,
        `Toggle ${e.target.checked ? 'enabled' : 'disabled'}`,
        'Marketplace Notifications'
      )
    }

  // Footer related Function
  const handleOnSave = () => {
    setOpenSaveModal(true)
  }
  const handleDiscard = () => {
    setOpenDiscardModal(true)
  }

  // Discard Modal CTAs
  const handleDiscardBackChanges = () => {
    setOpenDiscardModal(false)
  }
  const handleDiscardChanges = useCallback(async () => {
    if (product) {
      await dispatch(actionTypeWrapper(product, discardSettingsPageData()))
      trackEvent(
        'Marketplace Notifications',
        'Discard button',
        'clicked',
        'Discard Button Clicked',
        'Marketplace Notifications'
      )
      setSelectedNotifications(getInitalSelectedNotifications())
      setOpenDiscardModal(false)
    }
  }, [product])

  // Save Modal CTAs
  const handleSaveCancel = () => {
    setOpenSaveModal(false)
  }
  const handleSaveChanges = useCallback(async () => {
    if (partnerId && product) {
      await dispatch(saveNotificationsV2(partnerId, product))
      trackEvent(
        'Marketplace Notifications',
        'Save button',
        'clicked',
        'Save button Clicked',
        'Marketplace Notifications'
      )
      setSelectedNotifications(getInitalSelectedNotifications())
      setOpenSaveModal(false)
    }
  }, [product, partnerId])

  // Complete Onboarding Function
  const handleCompleteOnboarding = () => {
    !activeProducts?.includes('flyout')
      ? navigate('/product-selection')
      : navigate('/flyout/onboarding/company-information')
  }

  // Custom Header
  const customHeader = (section: string, isOpen: boolean) => {
    return {
      notificationTypeName: (
        <TableCell
          className={clsx(
            classes.tableCell,
            classes.tableHeadCell,
            !isOpen ? classes.noBorder : ''
          )}
        >
          <div className={classes.mainHeader}>
            <Typography className={classes.tableHeading}>
              {sectionHeadings[section]}
            </Typography>
            {openTable[section] ? (
              <HelpDrawer
                sectionName={helpDrawerMappingMarketplace[section]}
                headerTitle="Help"
              />
            ) : null}
          </div>
        </TableCell>
      ),
      slackChannel: (
        <TableCell
          className={clsx(
            classes.tableCell,
            classes.slackChannelContainer,
            !isOpen ? classes.noBorder : ''
          )}
        >
          {selectedNotifications[section].length > 1 && openTable[section] ? (
            <div className={classes.headingContainer}>
              <LabraToolTipV2
                tooltipPlacement="top"
                description={
                  'Select a Slack channel for all the selected notifications'
                }
              >
                <ButtonV2
                  style={{ fontWeight: 600, flex: 1 }}
                  onClick={handleSlackChannelButtonClick(section)}
                  data-testid="add-slack-recipients-btn"
                  styleType="outlined"
                  className={classes.button}
                >
                  Select a Slack channel
                </ButtonV2>
              </LabraToolTipV2>
            </div>
          ) : (
            <div></div>
          )}
        </TableCell>
      ),
      notificationEnabled: (
        <TableCell
          className={clsx(classes.tableCell, !isOpen ? classes.noBorder : '')}
        >
          {openTable[section] ? (
            <Typography className={classes.subHeading}>
              Notification status
            </Typography>
          ) : (
            <></>
          )}
        </TableCell>
      ),
    }
  }

  // ---------- Custom object ----------

  const customObject = (
    objects: ProductNotificationTypes[],
    sectionName: string
  ) =>
    objects.map((obj: ProductNotificationTypes, index: number) => {
      const handleClick = () => handleInfoClick(obj.productNotificationTypeId)
      const activeChannel = getSlackNameAndIdFromNotificationMethod(
        obj.productNotificationTypeMethods
      )
      return {
        id: obj.productNotificationTypeId,
        notificationTypeName: (
          <div className={classes.notificationType}>
            <Typography className={classes.notificationRow}>
              {obj.notificationTypeName}
            </Typography>
            <div className={clsx(classes.infoIcon, 'info-on-hover')}>
              <LabraToolTipV2
                tooltipPlacement={'top-start'}
                description={<div>{obj.notificationTypeDescription}</div>}
                tooltipPlacmentGap="12px"
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  style={{
                    height: 16,
                    width: 16,
                    color: '#1942C5',
                    display: 'flex',
                  }}
                  onClick={handleClick}
                  data-testid="info-icon"
                />
              </LabraToolTipV2>
            </div>
          </div>
        ),
        slackChannel: (
          <AutoComplete
            {...getSlackInputParams(
              slackChannelsList,
              isAdminOrOwner,
              activeChannel
            )}
            filterOptions={(options: any[], state: any) => {
              const filteredOptions = options.filter(option =>
                option.label
                  .toLowerCase()
                  .includes(state.inputValue.toLowerCase())
              )
              if (filteredOptions.length === 0) {
                return [
                  {
                    label: 'no-options',
                  },
                ]
              }
              return filteredOptions
            }}
            renderOption={(
              option: { label: string },
              { className, ...getOptionProps }: any
            ) => {
              if (option.label === 'no-options') {
                return (
                  <div
                    {...getOptionProps}
                    className={classes.customOption}
                    onClick={event => event.stopPropagation()}
                  >
                    <NoOptionsCustomForAutoComplete
                      handleExternalIcon={handleExternalIcon}
                      showLink={showLink}
                    />
                  </div>
                )
              }
              return <div {...getOptionProps}>{option.label}</div>
            }}
            clearOnBlur
            onChange={handleChange([obj.productNotificationTypeId], false)}
            error={false}
            helperText={''}
          />
        ),
        notificationEnabled: (
          <div className={classes.switchContainer}>
            <LabraToolTipV2
              tooltipPlacement="top"
              description={
                obj.notificationEnabled === NotificationEnabledType.INACTIVE
                  ? 'Enable notification'
                  : 'Disable notification'
              }
            >
              <SwitchV2
                checked={
                  obj.notificationEnabled === NotificationEnabledType.ACTIVE
                }
                onChange={handleChangeNotificationStatus(
                  obj.productNotificationTypeId
                )}
                switchVarient={SwitchVarient.DARK}
              />
            </LabraToolTipV2>
          </div>
        ),
      }
    })

  // ---------- Component code ----------

  if (!isFlyoutSubscribed) {
    return <FlyoutBlockPage showHeader={false} />
  }
  if (isFlyoutSubscribed && !isFlyoutOnboarded) {
    return (
      <MarketplaceOnboardingPage
        customButton={
          <>
            <ButtonV2
              styleType="primary"
              onClick={handleCompleteOnboarding}
              data-testid="cntinue-onboarding-button"
            >
              Continue onboarding
            </ButtonV2>
          </>
        }
      />
    )
  }
  if (showLoading) {
    return (
      <LoadingStateComponent
        heading="Loading your marketplace notifications page"
        description="It may take a few moments, please wait..."
      />
    )
  }
  return (
    <div className={classes.root}>
      <div data-testid="marketplace-notifications-container">
        <div
          className={classes.topContainer}
          data-testid="marketplace-notifications-top-container"
        >
          <div data-testid="marketplace-notifications-cloud-selector">
            <CloudChangeV2 customCloudOptions={customCloudSelectorOptions} />
          </div>
        </div>
      </div>
      <div className={classes.tablesContainer}>
        {Object.keys(sectionHeadings).map((section: string, index) => {
          return (
            <div
              className={classes.table}
              data-testid={`marketplace-notifcations-table-${section}`}
              key={`${section}-${index}`}
            >
              <TableComponentV2
                mapping={mapping}
                showHeaders={false}
                customHeader={customHeader(section, openTable[section])}
                objects={customObject(
                  notificationSectionMapping[section],
                  section
                )}
                count={notificationSectionMapping[section].length}
                fetchMoreData={() => {}}
                onChangeCheckbox={onChangeCheckbox(section)}
                selectedItem={selectedNotifications[section]}
                isLoading={showLoading}
                breakWorkHeader={false}
                showCheckbox={true}
                collapsible={true}
                open={openTable[section]}
                setOpen={handleTableOpen(section)}
                tableHeight={openTable[section] ? 'calc(100% - 68px)' : '68px'}
                keyName={'marketplace-notifications-table'}
              />
            </div>
          )
        })}
      </div>
      <FooterV2
        actions={
          <div className={classes.btnWrapper}>
            <ButtonV2
              styleType="outlined"
              data-testid={'discard-button-marketplace-notifications'}
              onClick={handleDiscard}
              disabled={!isFormDirty}
            >
              Discard
            </ButtonV2>
            <ButtonV2
              data-testid="save-button-marketplace-notifications"
              styleType="primary"
              type="submit"
              onClick={handleOnSave}
              disabled={!isFormDirty}
            >
              Save changes
            </ButtonV2>
          </div>
        }
        width="100%"
        customClass={classes.footerRoot}
      />

      <ModalConfirmV2
        title="Select a Slack channel for all the selected notifications"
        icon={
          <FontAwesomeIcon
            icon={faUserPlus}
            data-testid="select-slack-channel-icon"
          />
        }
        onlyCloseModal
        onCloseModal={handleSlackCancel}
        onDecline={handleSlackCancel}
        content={
          <div className={classes.slackChannelModalContent}>
            <div className={classes.autoComplete}>
              <AutoComplete
                {...getSlackInputParams(
                  slackChannelsList,
                  isAdminOrOwner,
                  slackChannelModal
                )}
                onChange={handleChange(
                  selectedNotifications[selectedSection],
                  true
                )}
                error={false}
                helperText={''}
                filterOptions={(options: any[], state: any) => {
                  const filteredOptions = options.filter(option =>
                    option.label
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  )
                  if (filteredOptions.length === 0) {
                    return [
                      {
                        label: 'no-options',
                      },
                    ]
                  }
                  return filteredOptions
                }}
                renderOption={(
                  option: { label: string },
                  { className, ...getOptionProps }: any
                ) => {
                  if (option.label == 'no-options') {
                    return (
                      <div
                        {...getOptionProps}
                        className={classes.customOption}
                        onClick={event => event.stopPropagation()}
                      >
                        <NoOptionsCustomForAutoComplete
                          handleExternalIcon={handleExternalIcon}
                          showLink={showLink}
                        />
                      </div>
                    )
                  }
                  return <div {...getOptionProps}>{option.label}</div>
                }}
                clearOnBlur
              />
            </div>
            <Typography className={classes.description}>
              By clicking the "Add" button, you confirm you will be sending the
              selected notifications on the above mentioned slack channel.
            </Typography>
          </div>
        }
        open={openSlackModal}
        titleCancelButton={'Cancel'}
        titleAcceptButton={'Add'}
        onAccept={handleAddSlackChannels}
        acceptDisabled={isEmpty(slackChannelModal)}
        variant={ModalSizeVariants.large}
        hideCancelButton={false}
      />
      <ModalConfirmV2
        title="Save changes"
        icon={
          <FontAwesomeIcon
            icon={faBookmark}
            data-testid="save-marketplace-notifications-icon-modal"
          />
        }
        onlyCloseModal
        onCloseModal={handleSaveCancel}
        onDecline={handleSaveCancel}
        content={
          <div className={classes.modalContent}>
            Are you sure you want to save these changes?
          </div>
        }
        acceptLoading={saveDataLoading}
        open={openSaveModal}
        titleCancelButton={'Cancel'}
        titleAcceptButton={'Save'}
        onAccept={handleSaveChanges}
        variant={ModalSizeVariants.medium}
        hideCancelButton={false}
      />
      <ModalConfirmV2
        title="Discard changes?"
        icon={
          <FontAwesomeIcon
            icon={faXmarkCircle}
            data-testid="discard-marketplace-icon-modal"
          />
        }
        onlyCloseModal
        onCloseModal={handleDiscardBackChanges}
        onDecline={handleDiscardBackChanges}
        content={
          <>
            <div className={classes.modalContent}>
              Are you sure you want to continue?
            </div>
          </>
        }
        open={openDiscardModal}
        titleCancelButton={'Back'}
        titleAcceptButton={'Discard'}
        onAccept={handleDiscardChanges}
        variant={ModalSizeVariants.medium}
        hideCancelButton={false}
      />
    </div>
  )
}
