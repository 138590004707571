import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  textHeading: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: theme.palette.labraCheckbox.main,
  },
  sectionHeading: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: theme.palette.labraCheckbox.main,
    marginBottom: theme.spacing(2),
  },
  sectionText: {
    fontSize: '14px !important',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px !important',
    letterSpacing: '0.25px',
    color: theme.palette.labraText.description,
    fontFamily: theme.typography.fontFamily,
    textAlign: 'left',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
  sectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(4),
    width: '100%',
  },
  sectionHeadingContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  companyDetailsContainer: {
    display: 'flex',
    gap: theme.spacing(3),
    width: '100%',
  },
  inputWidth: {
    width: '50%',
  },
  radioButton: {
    '& .MuiFormGroup-root': {
      gap: theme.spacing(1.25),
    },
  },
  textField: {
    marginTop: theme.spacing(2.5),
  },
  specificDurationNote: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color: theme.palette.labraText.primary,
    marginBottom: theme.spacing(-2.5),
  },
  fullWidth: {
    width: '100%',
  },
}))
