import React, { useCallback, useMemo, useState } from 'react'
import {
  ButtonV2,
  ModalConfirmV2,
  ModalSizeVariants,
  JsonTextarea,
} from '@labrav/react-components'
import { Typography } from '@material-ui/core'
import { useStyles } from './IamPolicyGeneration.styles'
import { useDispatch, useSelector } from '../../../../../../../store'
import { useUserType } from '../../../../../../../common/utils/Hooks/usePartnerData'
import {
  PartnerData,
  updatePartnerIAMPolicy,
} from '../../../../../../../common/modules/partner/action'
import clsx from 'clsx'
import { isLoading } from '../../../../../../../common/utils/loadingState'
import { LoadingTypes } from '../../../../../../../common/modules/loading/reducer'
import sjson from 'secure-json-parse'
import { isEmpty } from 'lodash'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import AddIcon from '@mui/icons-material/Add'
import { useTracker } from '../../../../../../../common/utils/Tracker/hook'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleXmark,
  faPenToSquare,
} from '@fortawesome/pro-regular-svg-icons'
import {
  AwsCloudSettingsSections,
  AwsCloudSettingsSectionsProps,
} from '../../../types'
import { useFormikContext } from 'formik'

type ClickType = 'add' | 'edit'

const ErrorMessage = ({
  errorMessage,
  onClick,
  hasPolicy,
}: {
  errorMessage: string
  onClick: () => void
  hasPolicy?: boolean
}) => {
  const classes = useStyles()
  return (
    <div className={classes.buttonContainer}>
      <div className={classes.iamPolicyButtonContainer}>
        <FontAwesomeIcon className={classes.errorColor} icon={faCircleXmark} />
        <Typography className={classes.heading}>{errorMessage}</Typography>
      </div>
      <div className={classes.verticalDivider}></div>
      <span onClick={onClick}>
        <ButtonV2 data-testid="edit-policy-button" styleType="link">
          {hasPolicy ? 'Edit policy' : 'Add policy'}
        </ButtonV2>
      </span>
    </div>
  )
}

export const ModalIcon: React.FC<{ clickType: ClickType }> = ({
  clickType,
}) => {
  const classes = useStyles()
  const action =
    clickType === 'edit' ? (
      <FontAwesomeIcon className={classes.editIcon} icon={faPenToSquare} />
    ) : (
      <AddIcon />
    )
  return action
}
const getModalTitle = (env: 'production' | 'staging', clickType: ClickType) => {
  const action = clickType === 'edit' ? 'Edit' : 'Add'
  return `${action} the policy copied from AWS Partner Central ${
    env === 'production'
      ? 'for the production environment.'
      : 'for the staging environment.'
  }`
}

type IamPolicyGenerationContainerProps = {
  env: 'production' | 'staging'
  isOnboardingCompleted: boolean
}
export const IamPolicyGeneration: React.FC<
  IamPolicyGenerationContainerProps
> = ({ env, isOnboardingCompleted }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const partnerType = useUserType()
  const tracker = useTracker()
  const partner = useSelector<PartnerData | null>(
    state => state.PartnerData[partnerType].partnerData
  )
  const [modalOpen, setModalOpen] = useState(false)
  const [iamPolicy, setIAMPolicy] = useState('')
  const [iamPolicyError, setIAMPolicyError] = useState(false)
  const [clickType, setClickType] = useState<ClickType>('add')
  const loading = useSelector<boolean>(state =>
    isLoading(state.loading)(LoadingTypes.SUBMIT_IAM_POLICY)
  )

  const { setFieldValue, errors, touched } =
    useFormikContext<AwsCloudSettingsSectionsProps>()
  const fieldName =
    env === 'production' ? 'iamPolicyProduction' : 'iamPolicyStaging'
  const sectionName = AwsCloudSettingsSections.GENERATE_POLICIES
  const hasError =
    errors[sectionName]?.[fieldName] && touched[sectionName]?.[fieldName]
  const errorMessage = errors[sectionName]?.[fieldName]

  const handleIAMPolicyChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    try {
      // this sanitizes the user input JSON, by removing the `__proto__` property to prevent prototype pollution
      // https://github.com/HoLyVieR/prototype-pollution-nsec18/blob/master/paper/JavaScript_prototype_pollution_attack_in_NodeJS.pdf
      const userValue = sjson(e.target.value, null, {
        protoAction: 'remove',
        constructorAction: 'remove',
      })
      const value = Object.assign(Object.create(null), userValue)
      setIAMPolicy(JSON.stringify(value, undefined, 4))
      setIAMPolicyError(false)
    } catch (e) {
      setIAMPolicyError(true)
    }
  }

  const handleModalOpen = useCallback((type: ClickType) => {
    setClickType(type)
    setIAMPolicyError(false)
    setModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    tracker?.track({
      product: 'OPPSYNC',
      section: 'ace_apis_aws_cloud_settings',
      component: 'policy_modal_close',
      action: 'clicked',
      pointingToEnv: env,
      partnerId: partner?.partnerId,
    })
    setModalOpen(false)
  }, [])

  const handleSave = useCallback(async () => {
    tracker?.track({
      product: 'OPPSYNC',
      section: 'ace_apis_aws_cloud_settings',
      component: 'policy_modal_save',
      action: 'clicked',
      pointingToEnv: env,
      partnerId: partner?.partnerId,
    })
    const response = await dispatch(
      updatePartnerIAMPolicy(partner?.partnerId || '', partnerType, {
        env,
        value: iamPolicy,
      })
    )
    if (response.code === 200) {
      const fieldName =
        env === 'production' ? 'iamPolicyProduction' : 'iamPolicyStaging'
      setFieldValue(
        `${AwsCloudSettingsSections.GENERATE_POLICIES}.${fieldName}`,
        iamPolicy
      )
    }

    setModalOpen(false)
  }, [dispatch, partner?.partnerId, partnerType, env, iamPolicy])

  const hasPolicy = useMemo(() => {
    switch (env) {
      case 'production':
        if (!isEmpty(partner?.iamPolicyProduction)) {
          return true
        }
        break
      case 'staging':
        if (!isEmpty(partner?.iamPolicyStaging)) {
          return true
        }
        break
    }

    return false
  }, [env, partner?.iamPolicyStaging, partner?.iamPolicyProduction])

  const trackPolicyModal = useCallback(
    (mode: 'edit' | 'create') => () => {
      tracker?.track({
        product: 'OPPSYNC',
        section: 'ace_apis_aws_cloud_settings',
        component: `policy_modal_${mode}`,
        action: 'opened',
        pointingToEnv: env,
        partnerId: partner?.partnerId,
      })
    },
    [tracker, env, partner?.partnerId]
  )

  const handleAddPolicyClick = useCallback(() => {
    tracker?.track({
      product: 'OPPSYNC',
      section: 'ace_apis_aws_cloud_settings',
      component: 'policy_modal_create',
      action: 'clicked',
      pointingToEnv: env,
      partnerId: partner?.partnerId,
    })
    handleModalOpen('add')
  }, [handleModalOpen, tracker, env, partner?.partnerId])

  const handleEditPolicyClick = useCallback(() => {
    tracker?.track({
      product: 'OPPSYNC',
      section: 'ace_apis_aws_cloud_settings',
      component: 'policy_modal_edit',
      action: 'clicked',
      pointingToEnv: env,
      partnerId: partner?.partnerId,
    })
    handleModalOpen('edit')
  }, [handleModalOpen, tracker, env, partner?.partnerId])

  return (
    <>
      <div
        className={
          env === 'staging'
            ? clsx(classes.environmentsSection, classes.extraGap)
            : classes.environmentsSection
        }
      >
        <div className={classes.inputField}>
          For ACE {env === 'production' ? 'Production' : 'Staging'} environment
        </div>
        {hasError && (
          <ErrorMessage
            errorMessage={errorMessage || ''}
            onClick={handleEditPolicyClick}
          />
        )}
        {!hasError && hasPolicy && (
          <div className={classes.buttonContainer}>
            <div className={classes.iamPolicyButtonContainer}>
              <CheckCircleOutlineIcon
                className={classes.checkCircle}
                data-testid={'policy-added-check-circle'}
              />
              <Typography className={classes.heading}>
                Policy has been added successfully
              </Typography>
            </div>
            <div className={classes.verticalDivider}></div>
            <span onClick={trackPolicyModal('edit')}>
              <ButtonV2
                data-testid="edit-policy-button"
                styleType="link"
                onClick={handleEditPolicyClick}
                disabled={false}
              >
                Edit policy
              </ButtonV2>
            </span>
          </div>
        )}
        {!hasError && !hasPolicy && (
          <div
            className={classes.addButton}
            onClick={trackPolicyModal('create')}
          >
            <ButtonV2
              onClick={handleAddPolicyClick}
              data-testid="add-policy-button"
              styleType="outlined"
              disabled={false}
              icon={<AddIcon />}
              smallVariant={true}
            >
              Add the policy generated from Partner Central.
            </ButtonV2>
          </div>
        )}
      </div>
      <ModalConfirmV2
        data-testid="iam-policy-generation-modal"
        title={getModalTitle(env, clickType)}
        open={modalOpen}
        icon={<ModalIcon clickType={clickType} />}
        titleAcceptButton={'Save'}
        titleCancelButton={'Cancel'}
        hideCancelButton={false}
        onAccept={handleSave}
        onDecline={handleModalClose}
        variant={ModalSizeVariants.xLarge}
        acceptDisabled={isEmpty(iamPolicy) || iamPolicyError || loading}
        content={
          <div className={classes.contentContainer}>
            {/* TODO: This should be moved to description prop after fixing gap issue in ModalConfirmV2 */}
            <Typography className={classes.description}>
              {clickType === 'edit'
                ? "Paste the policy below and click the 'Save' button."
                : 'Add the policy below as generated in the previous step and click the Save button.'}
            </Typography>
            <JsonTextarea
              value={
                env === 'production'
                  ? partner?.iamPolicyProduction
                  : partner?.iamPolicyStaging
              }
              data-testid="iam-policy-generation-input"
              placeholder="Paste the copied Policies here"
              onChange={handleIAMPolicyChange}
            />
          </div>
        }
      />
    </>
  )
}
