import { getIn, useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck as solidCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck as normalCircleCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStyles as commonStyles } from '../CloudSettingsWrapper.styles'
import clsx from 'clsx'
import React from 'react'

interface StepStatusIconProps {
  sectionName: string
}
type StatusIconType = 'completed' | 'standBy' | 'error'

function getSectionStatus(
  sectionCompleted: boolean,
  doesSectionHaveErrors: boolean
): StatusIconType {
  if (doesSectionHaveErrors) {
    return 'error'
  } else if (sectionCompleted) {
    return 'completed'
  } else {
    return 'standBy'
  }
}

export const StatusIcon: React.FC<{ status: StatusIconType }> = ({
  status,
}) => {
  const commonClasses = commonStyles()
  switch (status) {
    case 'completed':
      return (
        <FontAwesomeIcon
          data-testid="step-icon"
          icon={solidCircleCheck}
          className={clsx(commonClasses.loader, commonClasses.check)}
        />
      )
    case 'error':
      return (
        <FontAwesomeIcon
          data-testid="step-icon"
          icon={faCircleXmark}
          className={clsx(commonClasses.loader, commonClasses.cross)}
        />
      )
    case 'standBy':
      return (
        <FontAwesomeIcon
          data-testid="step-icon"
          icon={normalCircleCheck}
          className={clsx(commonClasses.loader, commonClasses.normal)}
        />
      )
    default:
      return null
  }
}
export const StepStatusIcon: React.FC<StepStatusIconProps> = ({
  sectionName,
}) => {
  const formik = useFormikContext<Record<string, Record<string, unknown>>>()

  const sectionErrors = formik ? getIn(formik.errors, sectionName) : {}
  const sectionTouched = formik ? getIn(formik.touched, sectionName) : {}
  const sectionCompleted = getIn(formik.values.steps, `${sectionName}`)
  const doesSectionHaveErrors =
    !isEmpty(sectionErrors) && !isEmpty(sectionTouched)
  const status = getSectionStatus(sectionCompleted, doesSectionHaveErrors)

  return <StatusIcon status={status} />
}

export default StepStatusIcon
