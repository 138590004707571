import { API } from '../../../common/api'
import { UpdateResellerDataToBeSent } from '../../../admin/modules/resaleAuthorizations/actions'
import { FreeTrials } from '../../modules/freeTrials/reducer'
import {
  MarketplaceSearchUrl,
  marketplaceServiceUrl,
  overviewPageServiceUrl,
} from '../../../common/utils/constants'
import { GetRevenueInsightsReport } from '../../modules/revenueInsights/actions'
import { CloudType } from '../../../common/modules/types'
import { isEmpty } from 'lodash'
import { GetRevenueInsightsReportV2 } from '../../modules/revenueInsightsV2/actions'
import { encodeBase64Url } from '../../../common/utils/helperFunctions'
import { ContractType } from '../../modules/buyers/reducer'
export const fetchProductsList = (
  partnerId: string,
  include?: string,
  cloudMarketplace?: CloudType,
  getFromCloud?: boolean
) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/products`, {
    params: {
      include: include || 'free_trial',
      ...(cloudMarketplace
        ? { cloud_marketplace: cloudMarketplace.toLocaleLowerCase() }
        : {}),
      ...(getFromCloud !== undefined ? { get_from_cloud: getFromCloud } : {}),
    },
  })
}
export const postContractsData = (
  data: Record<string, unknown>,
  partnerId: string
) => {
  return API.post(
    `${marketplaceServiceUrl}/partners/${partnerId}/meterings`,
    data
  )
}

export const getContractsData = (
  partnerId: string,
  productId: string,
  contractId?: string
) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/products/${productId}/contracts`,
    {
      params: {
        page_size: 500,
        labra_subscription_id: contractId,
      },
    }
  )
}

export const getSearchedData = ({
  partnerId,
  productId,
  input,
  selectedInput,
  pageSize,
  pageNumber,
  sectionName,
  isRevenueSearch,
  downloadToCsv,
}: {
  partnerId: string
  productId?: string
  input?: string
  selectedInput?: string
  pageSize?: number
  pageNumber?: number
  sectionName?: string
  isRevenueSearch?: boolean
  downloadToCsv?: boolean
}) => {
  let encodedInput = ''
  let payloadQuery = `i=${sectionName}&q=${input}&field=${selectedInput}&page_size=${pageSize}&page_number=${pageNumber}`
  if (isEmpty(productId)) {
    if (downloadToCsv) {
      payloadQuery += `&download_to_csv=${downloadToCsv}`
    }
    encodedInput = encodeBase64Url(payloadQuery)
    return API.get(`${MarketplaceSearchUrl}/partners/${partnerId}/search`, {
      params: {
        p: encodedInput,
      },
    })
  } else {
    payloadQuery += `&${
      isRevenueSearch ? 'labra_product_id' : 'aws_product_id'
    }=${productId}&download_to_csv=${downloadToCsv}`
    encodedInput = encodeBase64Url(payloadQuery)
    return API.get(`${MarketplaceSearchUrl}/partners/${partnerId}/search`, {
      params: {
        p: encodedInput,
      },
    })
  }
}

export const getOverviewData = ({ partnerId }: { partnerId: string }) => {
  return API.get(`${overviewPageServiceUrl}/partners/overviews`, {
    params: {
      partner_id: partnerId,
    },
  })
}

export const getBuyersData = ({
  partnerId,
  buyerId,
  search,
}: {
  partnerId: string
  buyerId?: string
  search?: string
}) => {
  if (buyerId) {
    return API.get(
      `${marketplaceServiceUrl}/partners/${partnerId}/buyers?labra_buyer_details_id=${buyerId}`
    )
  } else if (search) {
    return API.get(
      `${marketplaceServiceUrl}/partners/${partnerId}/buyers?search=${search}`
    )
  } else {
    return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/buyers`)
  }
}

export const getBuyerContractsAPI = ({
  partnerId,
  buyerId,
}: {
  partnerId: string
  buyerId: string
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/buyers/${buyerId}/contracts`
  )
}

export const getBuyerContactsAPI = ({
  partnerId,
  buyerId,
}: {
  partnerId: string
  buyerId: string
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/buyers/${buyerId}/contacts`
  )
}

export const getBuyersJourneyContractsDataAPI = ({
  partnerId,
  labraBuyerDetailsId,
}: {
  partnerId: string
  labraBuyerDetailsId: string
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/buyers/${labraBuyerDetailsId}/journeys/contracts`
  )
}

export const getBuyersJourneyDataAPI = ({
  partnerId,
  labraBuyerDetailsId,
  contractId,
  contractType,
}: {
  partnerId: string
  labraBuyerDetailsId: string
  contractId: string
  contractType: ContractType
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/buyers/${labraBuyerDetailsId}/journey`,
    {
      params: {
        contract_id: contractId,
        contract_type: contractType,
      },
    }
  )
}

export const getPrivateOffersData = ({
  partnerId,
  pageSize,
  pageNumber,
  productId,
  crmOpportunityId,
  expiringInDays,
  offerStatus,
}: {
  partnerId: string
  productId?: string
  secureKey?: string
  pageSize?: number
  pageNumber?: number
  crmOpportunityId?: string
  expiringInDays?: string
  offerStatus?: string[]
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/private_offers`,
    {
      params: {
        page_size: pageSize,
        page_number: pageNumber,
        aws_product_id: productId,
        crm_opportunity_id: crmOpportunityId,
        include: 'free_trial',
        expiring_in_days: expiringInDays,
        offer_status: offerStatus?.join(','),
      },
    }
  )
}

export const patchPrivateOffersData = (
  partnerId: string,
  privateOfferId: string,
  data: Record<string, unknown>,
  saveAsDraft?: boolean,
  editOffer = false
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/private_offers/${privateOfferId}`,
    data,
    {
      params: {
        draft: saveAsDraft,
        ...(editOffer ? { edit_offer: editOffer } : {}),
      },
    }
  )
}

export const patchChannelPartnerPrivateOffersData = (
  partnerId: string,
  channelOfferId: string,
  data: Record<string, unknown>,
  saveAsDraft?: boolean,
  editOffer = false
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/cppos/${channelOfferId}`,
    data,
    {
      params: {
        draft: saveAsDraft,
        ...(editOffer ? { edit_offer: editOffer } : {}),
      },
    }
  )
}

export const sendPrivateOfferReminder = (
  partnerId: string,
  privateOfferId: string,
  data: Record<string, unknown>,
  resendOffer?: boolean
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/private_offers/${privateOfferId}/reminders`,
    data,
    {
      params: {
        ...(resendOffer ? { resend_offer: resendOffer } : {}),
      },
    }
  )
}

export const sendPrivateOfferReminderForCPPO = (
  partnerId: string,
  privateOfferId: string,
  data: Record<string, unknown>,
  resendOffer?: boolean
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/cppos/${privateOfferId}/reminders`,
    data,
    {
      params: {
        ...(resendOffer ? { resend_offer: resendOffer } : {}),
      },
    }
  )
}

export const patchProductListingData = (
  partnerId: string,
  productId: string,
  data: Record<string, unknown>
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/products/${productId}`,
    data
  )
}
export const patchProductListingDataSEO = (
  partnerId: string,
  productId: string,
  data: Record<string, unknown>
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws/products/${productId}/update_for_ranking`,
    data
  )
}

export const deleteProductListing = (partnerId: string, productId: string) => {
  return API.delete(
    `${marketplaceServiceUrl}/partners/${partnerId}/products/${productId}`
  )
}

export const publishProductListing = (
  partnerId: string,
  productId: string,
  data: Record<string, unknown>
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/products/${productId}`,
    data
  )
}

export const unpublishProductListing = (
  partnerId: string,
  productId: string,
  data: Record<string, unknown>
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/products/${productId}`,
    data
  )
}

export const getSinglePrivateOffer = (
  partnerId: string,
  privateOfferId: string,
  crmOpportunityId?: string
) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/private_offers/${privateOfferId}`,
    {
      params: {
        crm_opportunity_id: crmOpportunityId,
      },
    }
  )
}

export const deleteSinglePrivateOffer = (
  partnerId: string,
  privateOfferId: string
) => {
  return API.delete(
    `${marketplaceServiceUrl}/partners/${partnerId}/private_offers/${privateOfferId}`
  )
}

export const deleteSingleCPPO = (partnerId: string, privateOfferId: string) => {
  return API.delete(
    `${marketplaceServiceUrl}/partners/${partnerId}/cppos/${privateOfferId}`
  )
}

export const getSingleSubscriptionDetail = (
  partnerId: string,
  subscriptionId: string
) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/subscriptions/${subscriptionId}`
  )
}

export const postToSignedUrl = (presignedUrl: string, formData: FormData) => {
  return API.post(`${presignedUrl}`, formData, {
    headers: {
      Authorization: '',
    },
  })
}

export const postFreeTrial = (
  partnerId: string,
  productFreeTrial: FreeTrials
) => {
  return API.post(
    `${marketplaceServiceUrl}/partners/${partnerId}/free_trials`,
    productFreeTrial
  )
}
export const patchFreeTrial = (
  partnerId: string,
  data: Record<string, string>,
  freeTrialId: string
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/free_trials/${freeTrialId}`,
    data
  )
}

export const syncAWSResellerAgreementDataToDb = (
  partnerId: string,
  origin?: string
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/migrate_resale_auth`,
    {
      params: {
        origin: origin,
      },
    }
  )
}

export const fetchResellerAgreementData = ({
  partnerId,
  pageSize,
  pageNumber,
  sortBy,
  sortOrder,
  productId,
  crmOpportunityId,
  status,
  origin,
  cloudProductUid,
}: {
  partnerId: string
  pageSize?: number
  pageNumber?: number
  sortBy?: string
  sortOrder?: string
  productId?: string
  crmOpportunityId?: string
  status?: string //comma separated for multiple status selection
  origin?: string
  cloudProductUid?: string
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws_reseller_agreements`,
    {
      params: {
        product_id: productId,
        page_size: pageSize,
        page_number: pageNumber,
        sort_by: sortBy,
        sort_order: sortOrder,
        crm_opportunity_id: crmOpportunityId,
        status: status,
        origin: origin,
        ...(cloudProductUid ? { cloud_product_uid: cloudProductUid } : {}),
      },
    }
  )
}

export const fetchChannelPartnerPrivateOffers = (
  partnerId: string,
  pageSize: number,
  pageNumber: number,
  sortBy: string,
  sortOrder: string,
  crmOpportunityId?: string,
  cppoId?: string,
  selectedResellerAuth?: string
) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/cppos`, {
    params: {
      page_size: pageSize,
      page_number: pageNumber,
      sort_by: sortBy,
      sort_order: sortOrder,
      crm_opportunity_id: crmOpportunityId,
      cppoId: cppoId,
      ...(selectedResellerAuth
        ? { resale_authorization_id: selectedResellerAuth }
        : {}),
    },
  })
}

export const patchResellerData = (
  partnerId: string,
  resellerAgreementId: string,
  dataToBeSent: UpdateResellerDataToBeSent,
  saveAsDraft = false
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws_reseller_agreements/${resellerAgreementId}`,
    dataToBeSent,
    {
      params: {
        draft: saveAsDraft,
      },
    }
  )
}

export const getSingleResaleAuthorization = (
  partnerId: string,
  resaleAuthorizationId: string,
  crmOpportunityId?: string
) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws_reseller_agreements/${resaleAuthorizationId}`,
    {
      params: {
        crm_opportunity_id: crmOpportunityId,
      },
    }
  )
}
export const getPreSignedUrl = (
  partnerId: string,
  path: string,
  bucketName?: string
) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/assets`, {
    params: {
      fields: 'aws_presigned_url',
      assets: path,
      operation_method: 'POST',
      expires_in: 3600,
      bucket_name: bucketName ? bucketName : '',
    },
  })
}

export const postAssets = (
  partnerId: string,
  data: Record<string, unknown>
) => {
  return API.post(`${marketplaceServiceUrl}/partners/${partnerId}/assets`, data)
}
export const postPrivateOffer = ({
  partnerId,
  data,
  saveAsDraft,
}: {
  partnerId: string
  data: Record<string, unknown>
  saveAsDraft?: boolean
}) => {
  return API.post(
    `${marketplaceServiceUrl}/partners/${partnerId}/private_offers`,
    data,
    {
      params: {
        draft: saveAsDraft || false,
      },
    }
  )
}
export const getReports = ({
  partnerId,
  reportType,
  cloudMarketplace,
  finalStartDate,
  finalEndDate,
  downloadReport,
  pageSize,
  pageNumber,
  sortBy,
  sortOrder,
  productId,
}: GetRevenueInsightsReport) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/reports`, {
    params: {
      report_type: reportType,
      cloud_marketplace: cloudMarketplace,
      labra_product_id: productId,
      from_date: finalStartDate,
      to_date: finalEndDate,
      download_report: downloadReport,
      page_size: pageSize,
      page_number: pageNumber,
      sort_by: sortBy,
      sort_order: sortOrder,
    },
    responseType: downloadReport ? 'blob' : 'json',
  })
}
export const getReportsV2 = ({
  partnerId,
  finalStartDate,
  finalEndDate,
  pageSize,
  productId,
  dateRangeType,
  paymentStatus,
  offerType,
  disbursementDate,
  paymentDueDate,
  paymentDelayedSince,
  lastEvaluatedKeys,
  lastEvaluatedValues,
  sortBy,
  downloadReport,
  sortOrder,
  shouldSendGetDateParam,
}: GetRevenueInsightsReportV2) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/revenue_reports`,
    {
      params: {
        labra_product_id: productId,
        from_date: finalStartDate,
        to_date: finalEndDate,
        page_size: 100,
        last_evaluated_keys: lastEvaluatedKeys,
        last_evaluated_values: lastEvaluatedValues,
        date_range_filter: dateRangeType,
        disbursement_status: paymentStatus,
        offer_visibility: offerType,
        disbursement_date: disbursementDate,
        payment_due_date: paymentDueDate,
        payment_delayed_since: paymentDelayedSince,
        get_insert_dates: shouldSendGetDateParam,
        download_report: downloadReport,
      },
      responseType: downloadReport ? 'blob' : 'json',
    }
  )
}
export const postResaleAuthorization = ({
  partnerId,
  data,
  saveAsDraft = false,
}: {
  partnerId: string
  data: Record<string, unknown>
  saveAsDraft?: boolean
}) => {
  return API.post(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws_reseller_agreements`,
    data,
    {
      params: {
        draft: saveAsDraft,
      },
    }
  )
}

export const fetchResellers = (partnerId: string) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws_resellers`,
    {
      params: {
        page_size: Number.MAX_SAFE_INTEGER,
      },
    }
  )
}

export const fetchSellerData = ({
  partnerId,
  metadata,
}: {
  partnerId: string
  metadata: Record<string, unknown>
}) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}`, {
    params: {
      ...metadata,
    },
  })
}

export const patchSellersData = (
  partnerId: string,
  sellersData: Record<string, unknown>,
  cloudMarketplace: string,
  operations: string
) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}`,
    sellersData,
    {
      params: {
        cloud_marketplace: cloudMarketplace,
        operations: operations,
      },
    }
  )
}

export const postMigrationSyncData = (
  partnerId: string,
  syncData: Record<string, unknown>
) => {
  return API.post(
    `${marketplaceServiceUrl}/partners/${partnerId}/syncs`,
    syncData
  )
}

export const postSellerCreation = (data: Record<string, unknown>) => {
  return API.post(`${marketplaceServiceUrl}/partners`, data)
}

export const getFlyOutOnboardingFormData = (partnerId: string) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/onboarding`)
}

export const putFlyOutOnboardingFormData = (
  partnerId: string,
  data: Record<string, unknown>
) => {
  return API.put(
    `${marketplaceServiceUrl}/partners/${partnerId}/onboarding`,
    data
  )
}

export const deleteAsset = (
  partnerId: string,
  data: Record<string, string>
) => {
  return API.delete(`${marketplaceServiceUrl}/partners/${partnerId}/assets`, {
    data,
  })
}

export const fetchSubscriptionList = ({
  partnerId,
  pageSize,
  pageNumber,
  crmOpportunityId,
  productId,
  status,
  expiringDays,
  privateOfferId,
}: {
  partnerId: string
  pageSize?: number
  pageNumber?: number
  crmOpportunityId?: string
  productId?: string
  status?: string
  expiringDays?: string
  privateOfferId?: string
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/subscriptions`,
    {
      params: {
        page_size: pageSize,
        page_number: pageNumber,
        crm_opportunity_id: crmOpportunityId,
        include: 'entitlements',
        sort_by: 'contract_expiration_date',
        sort_order: 'asc',
        status: status ? (status === 'All' ? '' : status) : 'active',
        ...(expiringDays ? { expiring_days: expiringDays } : {}),
        ...(productId ? { product_id: productId } : {}),
        ...(privateOfferId ? { private_offer_id: privateOfferId } : {}),
      },
    }
  )
}

export const putFlyOutProductFormData = ({
  partnerId,
  data,
  productId,
}: {
  partnerId: string
  data: Record<string, unknown>
  productId?: string
}) => {
  return API.put(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws/products`,
    data,
    {
      params: {
        ...(productId ? { product_id: productId } : {}),
      },
    }
  )
}

export const getFlyOutProductFormData = ({
  partnerId,
  productId,
  editPage,
}: {
  partnerId: string
  productId?: string
  editPage?: boolean
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws/products/${productId}`,
    {
      params: {
        ...(editPage ? { edit_page: true } : {}),
      },
    }
  )
}

export const patchFlyOutProductFormData = ({
  partnerId,
  productId,
  data,
}: {
  partnerId: string
  productId?: string
  data: Record<string, unknown>
}) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws/products/${productId}`,
    data
  )
}

export const getOtherProductDetails = ({
  partnerId,
  listingLink,
}: {
  partnerId: string
  listingLink: string
}) => {
  return API.put(
    `${marketplaceServiceUrl}/partners/${partnerId}/external_listing`,
    {
      listing_link: listingLink,
    }
  )
}

export const putOnboardingFormData = ({
  partnerId,
  data,
}: {
  partnerId: string
  data: Record<string, unknown>
}) => {
  return API.put(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws/onboarding`,
    data
  )
}

export const getMilestonesData = ({ partnerId }: { partnerId: string }) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}`, {
    params: {
      seller_milestones: true,
    },
  })
}

export const patchMilestonesData = (
  partnerId: string,
  data: Record<string, unknown>
) => {
  return API.patch(`${marketplaceServiceUrl}/partners/${partnerId}`, data, {
    params: {
      operations: 'update_seller_milestones',
      cloud_marketplace: 'aws',
    },
  })
}

export const getTemplatesData = (partnerId: string) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/templates`)
}
export const getIntegrationsData = (partnerId: string) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/integrations`)
}
export const getProductDataFromLink = (partnerId: string, url: string) => {
  // TODO: Change the API call to get the product data from the link
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/aws_discovery/${url}`
  )
}
export const putTemplateData = (
  partnerId: string,
  data: Record<string, unknown>
) => {
  return API.put(
    `${marketplaceServiceUrl}/partners/${partnerId}/templates`,
    data
  )
}
export const putButtonTemplateData = (
  partnerId: string,
  data: Record<string, unknown>
) => {
  return API.put(`${marketplaceServiceUrl}/partners/${partnerId}/buttons`, data)
}
export const getCurrentTemplateData = (
  partnerId: string,
  templateId: string
) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/templates/${templateId}`
  )
}
export const getListingDataById = (partnerId: string, listingId: string) => {
  return API.get(`${marketplaceServiceUrl}/ext/aws/integrations/${listingId}`)
}
export const putListingData = (
  partnerId: string,
  data: Record<string, unknown>
) => {
  return API.put(
    `${marketplaceServiceUrl}/partners/${partnerId}/integrations`,
    data
  )
}

export const patchFlyOutProductSync = ({
  partnerId,
  productId,
}: {
  partnerId: string
  productId?: string
}) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/products/${productId}/syncs`
  )
}

export const getMetricsData = ({
  partnerId,
  finalStartDate,
  finalEndDate,
  domain,
  selectedProduct,
}: {
  partnerId: string
  finalStartDate?: string
  finalEndDate?: string
  domain?: string
  selectedProduct?: string
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/storefront_metrics`,
    {
      params: {
        start_date: finalStartDate,
        end_date: finalEndDate,
        client_path: domain,
        product_id: selectedProduct,
      },
    }
  )
}

export const getMetricsProductData = ({ partnerId }: { partnerId: string }) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/storefront_products`
  )
}

export const getBuyerRevenuesAPI = ({
  partnerId,
  buyerId,
}: {
  partnerId: string
  buyerId: string
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/buyers/${buyerId}/revenues`
  )
}

export const getBuyerMeteringsAPI = ({
  partnerId,
  buyerId,
}: {
  partnerId: string
  buyerId: string
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/buyers/${buyerId}/meterings`
  )
}

export const postChannelOffer = ({
  partnerId,
  data,
  saveAsDraft,
}: {
  partnerId: string
  data: Record<string, unknown>
  saveAsDraft?: boolean
}) => {
  return API.post(
    `${marketplaceServiceUrl}/partners/${partnerId}/cppos`,
    data,
    {
      params: {
        draft: saveAsDraft || false,
      },
    }
  )
}

export const patchChannelOffer = ({
  partnerId,
  data,
  saveAsDraft,
  channelOfferId,
}: {
  partnerId: string
  data: Record<string, unknown>
  saveAsDraft?: boolean
  channelOfferId: string
}) => {
  return API.patch(
    `${marketplaceServiceUrl}/partners/${partnerId}/cppos/${channelOfferId}`,
    data,
    {
      params: {
        draft: saveAsDraft || false,
      },
    }
  )
}

export const getSingleChannelOffer = (
  partnerId: string,
  channelOfferId: string
) => {
  return API.get(`${marketplaceServiceUrl}/partners/${partnerId}/cppos`, {
    params: {
      cppo_id: channelOfferId,
    },
  })
}

export const postMeteringFile = (fileLink: string) => {
  return API.post(`${marketplaceServiceUrl}/metering/metering-files`, {
    metering_file_url: fileLink,
  })
}

export const fetchMeteringReport = ({
  partnerId,
  fromDate,
  toDate,
  cloudProductId,
}: {
  partnerId: string
  fromDate: string
  toDate: string
  cloudProductId: string
}) => {
  return API.get(
    `${marketplaceServiceUrl}/partners/${partnerId}/meterings/download_metering_file`,
    {
      params: {
        from_date: fromDate,
        to_date: toDate,
        cloud_product_id: cloudProductId,
      },
      responseType: 'blob',
    }
  )
}
