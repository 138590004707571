import { AnyAction } from 'redux'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  BulkUploadFailedMessage,
  BulkUploadFileSizeError,
  BulkUploadSuccessMessage,
  DefaultSuccesrOnSubmitData,
  RequestFailureMessage,
  SingleScoreSuccessMessage,
} from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { camelize, snakeize } from 'casing'
import { getErrorMessages } from '../../../common/utils/error'
import { FiltersState, FilterType, PropensityPayload } from './reducer'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'
import { AppDispatch, RootState } from '../../../store'
import { snakeCase } from 'lodash'
import { errorLogger } from '../../../common/utils/errorLogger'
import {
  calculatePropensityScore,
  fetchPropensityData,
  getPreSignedUrlIntelligence,
  postPtb,
  validateCsvFile,
} from '../../api'
import { LastEvaluatedObject } from '../../../flyout/modules/revenueInsightsV2/reducer'
import { convertObjectKeysAndValuesToCommaSeprated } from '../../../common/utils/helperFunctions'
import { newrelicErrLogger } from '../../../common/utils/ErrorHandler'
import { isEmpty } from 'lodash'
import { getSearchedData } from '../../../flyout/api/markeplace'
import FileDownload from 'js-file-download'

export enum PropensityActionTypes {
  SET_REPORTS_DATA = 'SET_REPORTS_DATA',
  PROPENSITY_SET_PAGE_NUMBER = 'PROPENSITY_SET_PAGE_NUMBER',
  PROPENSITY_SET_LIMIT = 'PROPENSITY_SET_LIMIT',
  SET_PRODUCT_ID = 'SET_PRODUCT_ID',
  SET_DOMAIN_URLS = 'SET_DOMAIN_URLS',
  CLEAR_DOMAIN_URLS = 'CLEAR_DOMAIN_URLS',
  CLEAR_PROPENSITY_REPORTS = 'CLEAR_PROPENSITY_REPORTS',
  SET_FILTERS = 'SET_FILTERS',
  CLEAR_PROPENSITY_FILTERS = 'CLEAR_PROPENSITY_FILTERS',
  SET_DROP_DOWN_KEY = 'SET_DROP_DOWN_KEY',
  CLEAR_DROP_DOWN_KEY = 'CLEAR_DROP_DOWN_KEY',
  SHOW_DEFAULT = 'SHOW_DEFAULT',
  HIDE_DEFAULT = 'HIDE_DEFAULT',
  SHOW_ALERT = 'SHOW_ALERT',
  HIDE_ALERT = 'HIDE_ALERT',
}

const handlePropensityFetch = async ({
  partnerId,
  pageSize,
  lastEvaluatedObject,
  pageNumber,
  sectionName,
  selectedInput,
  input,
  filters,
}: {
  partnerId: string
  pageSize: number
  lastEvaluatedObject?: LastEvaluatedObject | null
  selectedInput?: string
  input?: string
  pageNumber?: number
  sectionName?: string
  filters?: FilterType
}) => {
  const formattedLastEvaluatedObject = lastEvaluatedObject
    ? snakeize(lastEvaluatedObject)
    : undefined
  if (isEmpty(input)) {
    const { data } = await fetchPropensityData(
      false,
      partnerId,
      pageSize,
      formattedLastEvaluatedObject,
      filters
    )
    return data
  } else {
    const response = await getSearchedData({
      partnerId,
      input: input,
      selectedInput: selectedInput,
      pageSize,
      pageNumber: pageNumber,
      sectionName: sectionName,
      isRevenueSearch: false,
    })

    const latestRecord = response.data.records
      .map((record: any) => record._source)
      .sort(
        (a: any, b: any) =>
          new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
      )[0]

    const formattedResponse = {
      count: latestRecord ? 1 : 0,
      error: null,
      last_evaluated_object: null,
      records: latestRecord ? [latestRecord] : [],
    }
    return formattedResponse
  }
}

export const getPropensityData =
  ({
    downloadReport,
    pageSizeValue,
    pageNumber,
    lastEvaluatedObject,
    input,
    selectedInput,
    sectionName,
  }: {
    downloadReport?: boolean
    pageSizeValue?: number
    pageNumber?: number
    lastEvaluatedObject?: LastEvaluatedObject | null
    input?: string
    selectedInput?: string
    sectionName?: string
  } = {}) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.PROPENSITY_DATA))

    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const { pageSize: pageSizeStateValue } = getState().propensity
      const pageSize = pageSizeValue ?? pageSizeStateValue
      const { filters } = getState().propensity

      const formatFilterValue = (value: string) =>
        value === 'All' ? 'all' : value

      const formatedFilters: FilterType = {
        time_range: filters?.lastUpdated || '',
        marketplaces:
          filters?.marketplace?.map(formatFilterValue).join(',') || '',
        propensity_level:
          filters?.propensityScore?.map(formatFilterValue).join(',') || '',
        aws_level: filters?.aws?.map(formatFilterValue).join(',') || '',
        azure_level: filters?.azure?.map(formatFilterValue).join(',') || '',
        gcp_level: filters?.gcp?.map(formatFilterValue).join(',') || '',
      }

      if (!downloadReport) {
        const data = await handlePropensityFetch({
          partnerId,
          pageSize,
          lastEvaluatedObject,
          selectedInput,
          input,
          pageNumber,
          sectionName,
          filters: formatedFilters,
        })
        await dispatch(setReportsData(camelize(data), pageSize))
      } else {
        await downloadPtbReports({
          downloadReport,
          partnerId,
          pageSize,
          lastEvaluatedObject,
          pageNumber,
          sectionName,
          selectedInput,
          input,
          filters: formatedFilters,
        })
      }
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.PROPENSITY_DATA))
    }
  }

export const downloadPtbReports = async ({
  downloadReport,
  partnerId,
  pageSize,
  lastEvaluatedObject,
  pageNumber,
  sectionName,
  selectedInput,
  input,
  filters,
}: {
  downloadReport?: boolean
  partnerId: string
  pageSize: number
  lastEvaluatedObject?: LastEvaluatedObject | null
  selectedInput?: string
  input?: string
  pageNumber?: number
  sectionName?: string
  filters?: FilterType
}) => {
  const formattedLastEvaluatedObject = lastEvaluatedObject
    ? snakeize(lastEvaluatedObject)
    : undefined
  if (isEmpty(input)) {
    return fetchPropensityData(
      downloadReport,
      partnerId,
      pageSize,
      formattedLastEvaluatedObject,
      filters
    ).then(response => {
      FileDownload(response.data, `${partnerId}.csv`)
    })
  } else {
    return getSearchedData({
      partnerId,
      input,
      selectedInput,
      pageSize,
      pageNumber,
      sectionName,
      downloadToCsv: true,
    }).then(response => {
      FileDownload(response.data, `${partnerId}.csv`)
    })
  }
}

export const postPtbCall =
  (partnerId: string, s3Path: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.PROPENSITY_DATA))
    try {
      const response = await validateCsvFile(partnerId, s3Path)
      if (response.data.status === true) {
        const { data } = await postPtb(partnerId, s3Path)
        dispatch(
          updateAppAlert({
            message: BulkUploadSuccessMessage,
            messageType: 'SUCCESS',
            autoClose: true,
          })
        )
        return data
      } else if (response.data.status === false) {
        dispatch(
          updateAppAlert({
            message: response.data.message,
            messageType: 'ERROR',
            autoClose: false,
          })
        )
      }
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      newrelicErrLogger(error as Error, {
        message: error,
      })
    } finally {
      await dispatch(stopLoading(LoadingTypes.PROPENSITY_DATA))
    }
  }

export const calculatePtbScore =
  (partnerId: string, domain: string[]) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.PROPENSITY_DATA))
    try {
      const { data } = await calculatePropensityScore(
        partnerId,
        domain.map(prospect => ({ prospect }))
      )
      dispatch(
        updateAppAlert({
          message: SingleScoreSuccessMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      return data
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      newrelicErrLogger(error as Error, {
        message: error,
      })
    } finally {
      await dispatch(stopLoading(LoadingTypes.PROPENSITY_DATA))
    }
  }

export const getPreSignedUrl =
  (partnerId: string, fileKeys: string[]) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))

    try {
      const { data } = await getPreSignedUrlIntelligence(partnerId, fileKeys)
      dispatch(
        updateAppAlert({
          message: DefaultSuccesrOnSubmitData,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      return data
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      newrelicErrLogger(error as Error, {
        message: error,
      })
    } finally {
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const setReportsData = (data: PropensityPayload, pageSize: number) => ({
  type: PropensityActionTypes.SET_REPORTS_DATA,
  payload: { ...data, pageSize },
})

export const showDefault = () => ({
  type: PropensityActionTypes.SHOW_DEFAULT,
})

export const hideDefault = () => ({
  type: PropensityActionTypes.HIDE_DEFAULT,
})

export const setPropensityPageSize = (pageSize: number) => ({
  type: PropensityActionTypes.PROPENSITY_SET_LIMIT,
  payload: pageSize,
})

export const setPropensityPageNumber = (pageNumber: number) => ({
  type: PropensityActionTypes.PROPENSITY_SET_PAGE_NUMBER,
  payload: pageNumber,
})

export const clearPropensityReports = () => ({
  type: PropensityActionTypes.CLEAR_PROPENSITY_REPORTS,
})

export const setDomainUrls = (domainUrls: string[]) => ({
  type: PropensityActionTypes.SET_DOMAIN_URLS,
  payload: domainUrls,
})

export const clearDomainUrls = () => ({
  type: PropensityActionTypes.CLEAR_DOMAIN_URLS,
})

export const setFilters = (filters: FiltersState) => ({
  type: PropensityActionTypes.SET_FILTERS,
  payload: filters,
})

export const showAlert = () => ({
  type: PropensityActionTypes.SHOW_ALERT,
})

export const hideAlert = () => ({
  type: PropensityActionTypes.HIDE_ALERT,
})

export const setDropDownKey = (dropDownKey: string) => ({
  type: PropensityActionTypes.SET_DROP_DOWN_KEY,
  payload: dropDownKey,
})

export const clearDropDownKey = () => ({
  type: PropensityActionTypes.CLEAR_DROP_DOWN_KEY,
})
export const clearFilters = () => ({
  type: PropensityActionTypes.CLEAR_PROPENSITY_FILTERS,
})

export const setPageOneForPropensity = () => async (dispatch: AppDispatch) => {
  await dispatch(setPropensityPageNumber(1))
}
