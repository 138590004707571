import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: '326px 326px',
    gap: theme.spacing(1.25),
    maxHeight: theme.spacing(22.5),
    overflowY: 'auto',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    wordBreak: 'break-word',
  },
  divider: {
    margin: theme.spacing(3, 0),
  },
  externalUsers: {
    marginBottom: theme.spacing(1.5),
  },
  description: {
    color: `var(--M3-sys-light-on-surface-variant, ${theme.palette.labraText.description})`,
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  subHeading: {
    paddingBottom: theme.spacing(1.5),
  },
  content: {
    minHeight: theme.spacing(40.25),
    overflowY: 'auto',
  },
  checkbox: {
    marginTop: `${theme.spacing(-1)}px !important`,
    '&.MuiIconButton-root:hover': {
      backgroundColor: `${theme.palette.text.white} !important`,
    },
  },
  recepientContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  heading: {
    color: theme.palette.labraText.description,
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    marginBottom: theme.spacing(1.5),
  },
}))
