import React, { useState, useEffect } from 'react'
import {
  ModalConfirmV2,
  ModalSizeVariants,
  Switch,
} from '@labrav/react-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { useStyles } from '../EnvironmentConnectionSection.styles'
import { useSelector } from 'react-redux'
import { RootState, useDispatch } from '../../../../../../../store'
import {
  usePartnerId,
  useUserType,
} from '../../../../../../../common/utils/Hooks/usePartnerData'
import { updateEngineServicePartnerData } from '../../../../../../modules/engineServicePartner/action'
import { updateAppAlert } from '../../../../../../../common/modules/appAlert/actions'
import { Typography } from '@material-ui/core'
import { SwitchProps } from '@labrav/react-components/lib/@types/v2/components/Switch/Switch'

export const AwsPlatformSwitch = () => {
  const classes = useStyles()
  const partnerType = useUserType()
  const partnerId = usePartnerId()
  const dispatch = useDispatch()
  const isUsingAceApi = useSelector(
    (state: RootState) =>
      state.engineServicePartner[partnerType]?.engineServicePartnerData
        ?.useAceApi
  )

  const companyName = useSelector(
    (state: RootState) =>
      state.PartnerData[partnerType].partnerData?.companyName
  )
  const [checked, setIsChecked] = useState<boolean>(isUsingAceApi || false)
  const [isSwitching, setIsSwitching] = useState<boolean>(false)
  const onChange: SwitchProps['onChange'] = e => {
    setPlatformSwitchModal(true)
  }

  const [openPlatformSwitchModal, setPlatformSwitchModal] =
    useState<boolean>(false)

  const onCloseModal = () => {
    setPlatformSwitchModal(false)
    if (isSwitching) {
      setIsChecked(prev => !prev)
      setIsSwitching(false)
    }
  }

  const handleChangeCompanyService = async () => {
    setIsSwitching(true)
    onCloseModal()
    try {
      if (partnerId) {
        const newUseAceApiValue = !isUsingAceApi
        await dispatch(
          updateEngineServicePartnerData(partnerId, partnerType, {
            useAceApi: newUseAceApiValue,
          })
        )
        dispatch(
          updateAppAlert({
            message: `Successfully switched to ${
              newUseAceApiValue ? 'new ACE APIs' : 'Legacy S3'
            }.`,
            messageType: 'SUCCESS',
            autoClose: true,
          })
        )
        setIsChecked(newUseAceApiValue)
      }
    } catch (error: any) {
      console.error('Error updating engine service partner data:', error)
      dispatch(
        updateAppAlert({
          message: `Failed to switch to ${
            !isUsingAceApi ? 'new ACE APIs' : 'Legacy S3'
          }. Please try again.`,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      setIsChecked(prev => !prev)
    } finally {
      setIsSwitching(false)
    }
  }

  useEffect(() => {
    setIsChecked(isUsingAceApi || false)
  }, [isUsingAceApi])

  return (
    <>
      <div className={classes.switchContainer}>
        <Typography className={classes.switchTitle}>Enable ACE APIs</Typography>
        <Switch
          checked={checked}
          onChange={onChange}
          disabled={isSwitching}
          data-testid="company-service-switch"
        />
      </div>
      <ModalConfirmV2
        data-testid="change-company-modal"
        titleAcceptButton={`Switch to ${
          !isUsingAceApi ? 'new ACE APIs' : 'Legacy S3'
        }`}
        title="Confirm service change"
        icon={
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className={classes.exclaimIcon}
          />
        }
        onCloseModal={onCloseModal}
        onAccept={handleChangeCompanyService}
        scrollContent={true}
        description={`Are you sure you want to move "${companyName}" to ${
          !isUsingAceApi ? 'new ACE APIs' : 'Legacy S3'
        }?
                  It can disrupt the customer's sync.`}
        acceptDisabled={isSwitching}
        hideAcceptButton={false}
        onlyCloseModal={false}
        open={openPlatformSwitchModal}
        hideCancelButton={false}
        variant={ModalSizeVariants.small}
        onDecline={onCloseModal}
      />
    </>
  )
}
