import { Reducer } from 'redux'
import { DateTime } from 'luxon'
import { PropensityActionTypes } from './action'
import { LastEvaluatedObject } from '../../../flyout/modules/revenueInsightsV2/reducer'

export enum ScoreStatus {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum PageVariant {
  LOCKED = 'locked',
  INFO = 'info',
  PTBLOCK = 'ptb',
  DEFAULT = 'default',
}

export enum Marketplace {
  AWS = 'AWS',
  AZURE = 'Microsoft',
  GCP = 'GCP',
  ALL = 'All',
}
export enum LastUpdated {
  ALL = 'all',
  TWENTY_FOUR_HOURS = '24hours',
  SEVEN_DAYS = '7days',
  THIRTY_DAYS = '30days',
}

export enum PropensityScore {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
  ALL = 'All',
}

export interface FilterType {
  time_range?: string
  marketplaces?: string
  propensity_level?: string
  aws_level?: string
  azure_level?: string
  gcp_level?: string
}

export interface PropensityTier {
  [key: string]: string | number
}

export interface PropensityRow {
  inferenceBatchId: string
  domainName: string
  aws: PropensityTier
  azure: PropensityTier
  gcp: PropensityTier
  partnerId: string
  propensityScore: string
  prospect: string
  seller: string
  timestamp: string
  companyName: string
  industry: string
  marketplacePresence: string
}

export interface FiltersState {
  marketplace: Marketplace[]
  lastUpdated: string
  propensityScore: PropensityScore[]
  aws: ScoreStatus[]
  azure: ScoreStatus[]
  gcp: ScoreStatus[]
}

export interface PropensityData {
  records: PropensityRow[]
  pageSize: number
  pageNumber: number
  noOfPage: number | null
  lastEvaluatedObject?: LastEvaluatedObject | null
  count?: number
  domainUrls?: string[]
  dropDownKey?: string
  showDefault?: boolean
  showAlert?: boolean
  filters: FiltersState
}
export interface PropensityPayload {
  records: PropensityRow[]
  count: number
  pageSize: number
}
type actionType =
  | {
      type: PropensityActionTypes.SET_REPORTS_DATA
      payload: PropensityPayload
    }
  | {
      type: PropensityActionTypes.PROPENSITY_SET_PAGE_NUMBER
      payload: number
    }
  | {
      type: PropensityActionTypes.PROPENSITY_SET_LIMIT
      payload: number
    }
  | {
      type: PropensityActionTypes.CLEAR_PROPENSITY_REPORTS
    }
  | {
      type: PropensityActionTypes.SET_FILTERS
      payload: FiltersState
    }
  | {
      type: PropensityActionTypes.SET_DROP_DOWN_KEY
      payload: string
    }
  | {
      type: PropensityActionTypes.CLEAR_DROP_DOWN_KEY
    }
  | {
      type: PropensityActionTypes.SHOW_ALERT
    }
  | {
      type: PropensityActionTypes.HIDE_ALERT
    }
  | {
      type: PropensityActionTypes.SHOW_DEFAULT
    }
  | {
      type: PropensityActionTypes.HIDE_DEFAULT
    }
  | {
      type: PropensityActionTypes.CLEAR_PROPENSITY_FILTERS
    }
  | {
      type: PropensityActionTypes.SET_DOMAIN_URLS
      payload: string[]
    }
  | {
      type: PropensityActionTypes.CLEAR_DOMAIN_URLS
    }

export const initialFilterPropensityState: FiltersState = {
  marketplace: [Marketplace.ALL],
  lastUpdated: LastUpdated.ALL,
  propensityScore: [PropensityScore.ALL],
  aws: [],
  azure: [],
  gcp: [],
}

export const initialState: PropensityData = {
  records: [],
  pageSize: 10,
  pageNumber: 1,
  noOfPage: null,
  lastEvaluatedObject: null,
  count: 0,
  showDefault: true,
  showAlert: false,
  domainUrls: [],
  dropDownKey: '',
  filters: {
    ...initialFilterPropensityState,
  },
}
export const reducer: Reducer<PropensityData, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case PropensityActionTypes.SET_REPORTS_DATA: {
      return {
        ...state,
        ...action.payload,
        records: [...state.records, ...action.payload.records],
        noOfPage: Math.ceil(action.payload.count / action.payload.pageSize),
      }
    }
    case PropensityActionTypes.PROPENSITY_SET_LIMIT: {
      return {
        ...state,
        pageSize: action.payload,
      }
    }
    case PropensityActionTypes.PROPENSITY_SET_PAGE_NUMBER: {
      return {
        ...state,
        pageNumber: action.payload,
      }
    }
    case PropensityActionTypes.CLEAR_PROPENSITY_REPORTS: {
      return {
        ...state,
        records: [],
        count: 0,
        lastEvaluatedObject: null,
      }
    }
    case PropensityActionTypes.SET_FILTERS: {
      return {
        ...state,
        filters: action.payload,
        lastEvaluatedObject: null,
      }
    }
    case PropensityActionTypes.CLEAR_PROPENSITY_FILTERS: {
      return {
        ...state,
        filters: { ...initialFilterPropensityState },
        lastEvaluatedObject: null,
      }
    }
    case PropensityActionTypes.SET_DROP_DOWN_KEY: {
      return {
        ...state,
        dropDownKey: action.payload,
      }
    }
    case PropensityActionTypes.CLEAR_DROP_DOWN_KEY: {
      return {
        ...state,
        dropDownKey: '',
      }
    }
    case PropensityActionTypes.SHOW_DEFAULT: {
      return {
        ...state,
        showDefault: true,
      }
    }
    case PropensityActionTypes.HIDE_DEFAULT: {
      return {
        ...state,
        showDefault: false,
      }
    }
    case PropensityActionTypes.SHOW_ALERT: {
      return {
        ...state,
        showAlert: true,
      }
    }
    case PropensityActionTypes.HIDE_ALERT: {
      return {
        ...state,
        showAlert: false,
      }
    }

    case PropensityActionTypes.SET_DOMAIN_URLS: {
      return {
        ...state,
        domainUrls: action.payload,
      }
    }
    case PropensityActionTypes.CLEAR_DOMAIN_URLS: {
      return {
        ...state,
        domainUrls: [],
      }
    }
    default: {
      return state
    }
  }
}
