import { FormikErrors, FormikProps, FormikTouched } from 'formik'
import {
  ChannelPartnerPrivateOfferInCreation,
  CppoPriceAdjustmentType,
  CppoStageToStatusRelationMapEnum,
  CppoStages,
} from '../modules/channelPartnerPrivateOffer/reducer'
import { PlanType, RadioButtonType } from '@labrav/react-components'
import { isSignleFieldsErr } from './productCreationUtil'
import {
  checkIfFormikTouchedArrayHasErr,
  checkIsErrOnOfferInstallments,
  checkIsErrOnRecipientOrStakeHolder,
} from '../../common/utils/OfferValidationHelper'
import {
  Dimension,
  Installments,
  OfferRecipients,
  OfferStatus,
} from '../modules/privateOffer/reducer'
import { isNonEmptyNumber } from '../../common/utils/validateMinMax'
import { getPricesArray } from '../modules/resaleAuthorization/actions'
import { get, isEmpty } from 'lodash'
import { DataType } from '@labrav/react-components/lib/@types/types/forms'
import { ReceivedResellerAuthorization } from '../modules/receivedResellerAuthorization/reducer'
import {
  ContractDurationType,
  DiscountType,
} from '../../admin/modules/resaleAuthorizations/reducer'
import {
  faCheck,
  faKey,
  faTimes,
  faClock,
  faPaperPlaneTop,
  faEye,
  faHandshake,
} from '@fortawesome/pro-regular-svg-icons'
import { StatusListingColors } from '@labrav/react-components'

export const getIsErrorForChannelOfferTab = (
  tabName:
    | 'channel-offer-details'
    | 'channel-offer-pricing'
    | 'channel-offer-other',
  formikValues: FormikProps<ChannelPartnerPrivateOfferInCreation>,
  productType?: PlanType
) => {
  const { values, errors, touched } = formikValues
  if (formikValues?.submitCount <= 0) return false

  switch (tabName) {
    case 'channel-offer-details': {
      const recipentErr =
        checkIfFormikTouchedArrayHasErr(
          formikValues as unknown as FormikProps<Record<string, unknown>>,
          'offerRecipients'
        ) &&
        (
          touched?.offerRecipients as unknown as FormikTouched<OfferRecipients>[]
        ).some((recipientTouched, idx) =>
          checkIsErrOnRecipientOrStakeHolder(
            recipientTouched,
            idx,
            errors?.offerRecipients as unknown as FormikErrors<OfferRecipients>[]
          )
        )
      return (
        isSignleFieldsErr(
          [
            'companyName',
            'awsAccountId',
            'privateOfferName',
            'offerExpirationDate',
            'offerDescription',
            'offerRecipients',
            'emailCustomMessage',
            ...(productType === PlanType.SAAS_SUBSCRIPTION
              ? ['subscriptionEndDate']
              : []),
          ],
          errors
        ) || recipentErr
      )
    }
    case 'channel-offer-pricing': {
      const installmentsWithTouchedErr =
        checkIfFormikTouchedArrayHasErr(
          formikValues as unknown as FormikProps<Record<string, unknown>>,
          'installments'
        ) &&
        (
          touched?.installments as unknown as FormikTouched<Installments>[]
        )?.some((installmentsTouched, idx) =>
          checkIsErrOnOfferInstallments(
            installmentsTouched,
            idx,
            errors?.installments as unknown as FormikErrors<Installments>[]
          )
        )

      const dimensionsWithTouchedErr =
        checkIfFormikTouchedArrayHasErr(
          formikValues as unknown as FormikProps<Record<string, unknown>>,
          'dimensions'
        ) &&
        (touched?.dimensions as unknown as FormikTouched<Dimension>[])?.some(
          (dimensionTouched, idx) =>
            checkIsErrOnOfferDimensions(
              dimensionTouched,
              idx,
              errors?.dimensions as unknown as FormikErrors<Dimension>[]
            )
        )
      return (
        isSignleFieldsErr(
          [
            'priceAdjustmentType',
            'adjustmentPercentageInputValue',
            'serviceLength',
            ...(formikValues?.values?.variableStartDate === false
              ? ['agreementStartDate']
              : []),
          ],
          errors
        ) ||
        installmentsWithTouchedErr ||
        (values?.flexiblePaymentTerms && dimensionsWithTouchedErr)
      )
    }
    case 'channel-offer-other': {
      return isSignleFieldsErr(
        [
          'metaData',
          'offerStakeholders',
          'eulaType',
          'customOfferStakeholders',
          'eulaFile',
          'isResellerLicenseAgreementAccepted',
        ],
        formikValues?.errors
      )
    }
    default: {
      return false
    }
  }
}

export const checkIsErrOnOfferDimensions = (
  touchedObject: FormikTouched<Dimension>,
  idx: number,
  errorObject?: FormikErrors<Dimension>[]
) => {
  return !!(
    get(touchedObject, 'quantity') &&
    !isEmpty(get(errorObject, `[${idx}].quantity`))
  )
}

export const channelOfferExpirationDateField = {
  title: 'Offer expiration date',
  humanReadableKey: 'Offer expiration date',
  placeHolder: 'Offer expiration date',
  id: 'offerExpirationDate',
  isRequired: false,
  isReadOnly: false,
  isCustom: false,
  dataType: 'date',
  minDate: 'today',
}
export const channelOfferSubscriptionEndDateField = {
  title: 'Usage end date',
  humanReadableKey: 'Usage end date',
  placeHolder: 'Usage end date',
  id: 'subscriptionEndDate',
  isRequired: false,
  isReadOnly: false,
  isCustom: false,
  dataType: 'date',
  minDate: 'today',
}

export const getMarkupOptions = () => [
  {
    key: CppoPriceAdjustmentType.FIXED,
    label: 'Fixed amount',
    type: RadioButtonType.STANDARD,
    description:
      'Enter the total contract value for buyer to see the markup percentage and margin.',
  },
  {
    key: CppoPriceAdjustmentType.PERCENTAGE,
    label: 'Markup percentage',
    type: RadioButtonType.STANDARD,
    description:
      'Enter the markup percentage to calculate the total contract value for buyer and margin.',
  },
]

export const getPriceQuantityTotal = (
  dimension: Dimension,
  prices: string[]
) => {
  return prices.reduce(
    (acc, next) =>
      acc + get(dimension, 'quantity', 0) * get(dimension?.prices, next, 0),
    0
  )
}

export const getCustomPriceQuantityTotal = (dimension: Dimension) => {
  return (dimension?.quantity || 0) * ((dimension?.price as number) || 0)
}

export const getMarkupValues = ({
  dimensions,
  adjustmentPercentage,
  priceAdjustmentType,
  resaleAuthorization,
}: {
  dimensions?: Dimension[]
  adjustmentPercentage?: number
  priceAdjustmentType?: CppoPriceAdjustmentType
  resaleAuthorization?: ReceivedResellerAuthorization
}) => {
  if (isEmpty(dimensions) || isEmpty(priceAdjustmentType)) {
    return { margin: '', markupOrTotal: '' }
  }
  const resaleAuthorizationDimensions = resaleAuthorization?.dimensions
  const prices = getPricesArray(resaleAuthorization?.dimensions || [])

  const isResaleFlexiblePayment =
    resaleAuthorization?.discountType === DiscountType.FLEXIBLE_PAYMENT_TERMS

  const installmentsTotal = (resaleAuthorization?.installmentInfo || []).reduce(
    (acc, next) => (acc || 0) + (next.amount || 0),
    0
  )

  const isCustomPrice =
    resaleAuthorization?.contractDurationType === ContractDurationType.CUSTOM
  const totalDueToIsv = isResaleFlexiblePayment
    ? installmentsTotal
    : (resaleAuthorizationDimensions || []).reduce((acc, obj) => {
        const currentDimensionQuantity = dimensions?.find(
          item =>
            item?.name === obj.name &&
            obj.isAdditionalUsageAllowed === item?.isAdditionalUsageAllowed
        )?.quantity
        return (
          acc +
          (isCustomPrice
            ? getCustomPriceQuantityTotal({
                ...obj,
                quantity: currentDimensionQuantity,
              })
            : getPriceQuantityTotal(
                { ...obj, quantity: currentDimensionQuantity },
                prices
              ))
        )
      }, 0)

  if (!isNonEmptyNumber(adjustmentPercentage)) {
    return {
      totalDueToIsv,
      margin: 0,
      markupOrTotal:
        priceAdjustmentType === CppoPriceAdjustmentType.FIXED
          ? 0
          : totalDueToIsv,
    }
  }

  const totalContractToBuyer = Number(
    (totalDueToIsv! + (totalDueToIsv! * adjustmentPercentage!) / 100).toFixed(2)
  )

  const margin =
    priceAdjustmentType === CppoPriceAdjustmentType.FIXED
      ? adjustmentPercentage! - totalDueToIsv!
      : totalContractToBuyer - totalDueToIsv!

  const markupPercentage = ((margin / totalDueToIsv!) * 100).toFixed(2)

  const markupOrTotal =
    priceAdjustmentType === CppoPriceAdjustmentType.FIXED
      ? markupPercentage
      : totalContractToBuyer

  return { totalDueToIsv, margin, markupOrTotal }
}

export const channelPrivateOfferAgreementStartDateField = {
  title: 'Select a date',
  humanReadableKey: 'Select a date',
  placeHolder: 'Input',
  id: 'agreementStartDate',
  isRequired: false,
  isReadOnly: false,
  isCustom: false,
  dataType: 'date',
  minDate: 'today',
}

export const channelPrivateOfferContractStartDateField = {
  title: 'Contract start date',
  placeHolder: 'Contract start date',
  humanReadableKey: 'Contract start date',
  id: 'variableStartDate',
  isRequired: false,
  isReadOnly: false,
  isCustom: false,
  defaultValue: 'Offer acceptance date',
  dataType: 'enum' as DataType,
}

export const getChannelOfferStagesData = (
  auditJson: CppoStages,
  offerStatus: OfferStatus
) => {
  return Object.entries(auditJson).map(([status, completed]) => {
    let icon
    let color
    let background
    let borderColor
    let display = false
    let sortOrder = 0
    let tooltipText = ''
    // based on status name to resale auth status mapping
    if (
      status === CppoStageToStatusRelationMapEnum.IN_PROGRESS &&
      offerStatus !== OfferStatus.PRIVATE_OFFER_IN_DRAFT
    ) {
      display = true
      icon = faCheck
      color = StatusListingColors.WHITE
      background = StatusListingColors.COMPLETED
      borderColor = StatusListingColors.COMPLETED
      sortOrder = 1
      tooltipText =
        'Your offer was created on this date and time at Labra. Please check the status for further updates.'
    }

    if (
      status === CppoStageToStatusRelationMapEnum.SENT &&
      offerStatus === OfferStatus.PRIVATE_OFFER_FAILED &&
      isEmpty(completed)
    ) {
      display = true
      icon = faTimes
      color = StatusListingColors.WHITE
      background = StatusListingColors.REJECTED
      borderColor = StatusListingColors.REJECTED
      sortOrder = 2
      tooltipText = 'Your offer creation failed at Cloud provider'
    }

    if (
      status === CppoStageToStatusRelationMapEnum.SENT &&
      offerStatus !== OfferStatus.PRIVATE_OFFER_FAILED
    ) {
      display = true
      icon =
        !isEmpty(completed) &&
        offerStatus !== OfferStatus.PRIVATE_OFFER_CREATION_IN_PROGRESS
          ? faCheck
          : faPaperPlaneTop
      background =
        !isEmpty(completed) &&
        offerStatus !== OfferStatus.PRIVATE_OFFER_CREATION_IN_PROGRESS
          ? StatusListingColors.COMPLETED
          : StatusListingColors.WHITE
      borderColor =
        !isEmpty(completed) &&
        offerStatus !== OfferStatus.PRIVATE_OFFER_CREATION_IN_PROGRESS
          ? StatusListingColors.COMPLETED
          : StatusListingColors.GREY
      color =
        !isEmpty(completed) &&
        offerStatus !== OfferStatus.PRIVATE_OFFER_CREATION_IN_PROGRESS
          ? StatusListingColors.WHITE
          : StatusListingColors.GREY
      sortOrder = 2
      tooltipText =
        'Your offer was created on cloud marketplcae and emailed to recipients on this date and time.'
    }

    if (status === CppoStageToStatusRelationMapEnum.VIEWED) {
      display =
        !isEmpty(completed) ||
        offerStatus !== OfferStatus.PRIVATE_OFFER_RESTRCITED
          ? true
          : false
      icon =
        !isEmpty(completed) ||
        offerStatus === OfferStatus.PRIVATE_OFFER_ACCEPTED
          ? faCheck
          : faEye
      background =
        !isEmpty(completed) ||
        offerStatus === OfferStatus.PRIVATE_OFFER_ACCEPTED
          ? StatusListingColors.COMPLETED
          : StatusListingColors.WHITE
      borderColor =
        !isEmpty(completed) ||
        offerStatus === OfferStatus.PRIVATE_OFFER_ACCEPTED
          ? StatusListingColors.COMPLETED
          : StatusListingColors.GREY
      color =
        !isEmpty(completed) ||
        offerStatus === OfferStatus.PRIVATE_OFFER_ACCEPTED
          ? StatusListingColors.WHITE
          : StatusListingColors.GREY
      sortOrder = 3
      tooltipText = 'Your offer was viewed by buyer on this date and time.'
    }

    if (status === CppoStageToStatusRelationMapEnum.ACCEPTED) {
      display =
        !isEmpty(completed) ||
        offerStatus !== OfferStatus.PRIVATE_OFFER_RESTRCITED
          ? true
          : false
      icon = !isEmpty(completed) ? faCheck : faHandshake
      background = !isEmpty(completed)
        ? StatusListingColors.COMPLETED
        : StatusListingColors.WHITE
      borderColor = !isEmpty(completed)
        ? StatusListingColors.COMPLETED
        : StatusListingColors.GREY
      color = !isEmpty(completed)
        ? StatusListingColors.WHITE
        : StatusListingColors.GREY
      sortOrder = 4
      tooltipText = 'Your offer was accepted by buyer on this date and time.'
    }

    if (status === CppoStageToStatusRelationMapEnum.CANCELLED) {
      display = !isEmpty(completed) ? true : false
      icon = faTimes
      background = !isEmpty(completed)
        ? StatusListingColors.REJECTED
        : StatusListingColors.WHITE
      borderColor = !isEmpty(completed)
        ? StatusListingColors.REJECTED
        : StatusListingColors.GREY
      color = !isEmpty(completed)
        ? StatusListingColors.WHITE
        : StatusListingColors.GREY
      sortOrder = 5
      tooltipText =
        'Your offer was cancelled / restriced on this date and time.'
    }

    return {
      status,
      completed,
      icon,
      color,
      background,
      display,
      sortOrder,
      tooltipText,
      borderColor,
    }
  })
}
