import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  primaryContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(5),
  },
  inputField: {
    width: theme.spacing(59.37),
  },
  externalButton: {
    marginTop: theme.spacing(3),
  },
}))
