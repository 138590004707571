import { PlanType } from '../../../admin/modules/NewCustomers/reducer'
import { PrivateOfferFreeTierInfo } from '../../../flyout/modules/privateOffer/reducer'
import { Product } from '../../../flyout/modules/productsListing/reducer'
import { useSelector } from '../../../store'
import { useCurrentCloudProvider } from './useCurrentCloudProvider'

export const useFlyOutProductsBasedOnSelectedCloud = () => {
  const selectedCloudMarketplace = useCurrentCloudProvider()
  const products = useSelector<Product[]>(
    state =>
      state.productsListing[selectedCloudMarketplace]?.products?.rows || []
  )
  const selectedProduct = useSelector<string | undefined>(
    state => state.productsListing[selectedCloudMarketplace]?.selectedProduct
  )

  const additionalAllowedProducts = useSelector<number | undefined>(
    state =>
      state.productsListing[selectedCloudMarketplace]?.freeTierInfo
        ?.additionalAllowedProducts
  )

  const additionalAllowedPrivateOffers = useSelector<number | undefined>(
    state => state.privateOffers.freeTierInfo?.additionalAllowedPrivateOffers
  )
  const plan = useSelector<PlanType | undefined>(
    state =>
      state.PartnerData.user.partnerData?.subscribedProducts?.FLYOUT?.plan
  )

  return {
    products,
    selectedProduct,
    additionalAllowedProducts,
    additionalAllowedPrivateOffers,
    plan,
  }
}
