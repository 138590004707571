import React from 'react'
import {
  AvatarForSingleUser,
  CheckBoxFieldv2,
  TextAreaWithChips,
} from '@labrav/react-components'
import { Divider, Typography } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { RecipientType } from '../../../modules/notification/reducer'
import { UserState } from '../../../modules/userList/reducer'
import { useStyles } from './CoSellEmailNotificationsRecipientContent.styles'
import clsx from 'clsx'
export interface CoSellEmailNotificationsRecipientContentProps {
  id: string
  referralOwner: boolean
  handleOwnerChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  registeredUsers: UserState[]
  registeredRecipients: RecipientType[]
  unregisteredRecipients: RecipientType[]
  selectedTags: string[]
  handleRegisteredRecipientChange: (
    idpUserId: string
  ) => (e: React.ChangeEvent<HTMLInputElement>) => void
  handleTagsChange: (newTags: string) => void
  handleDelete: (tag: string) => void
  externalUsersError: string
}
export const CoSellEmailNotificationsRecipientContent: React.FC<
  CoSellEmailNotificationsRecipientContentProps
> = ({
  id,
  selectedTags,
  registeredRecipients,
  registeredUsers,
  referralOwner,
  handleOwnerChange,
  handleDelete,
  handleRegisteredRecipientChange,
  handleTagsChange,
  externalUsersError,
}) => {
  const classes = useStyles()
  return (
    <div data-testid="add-recipients-modal-content" className={classes.content}>
      <CheckBoxFieldv2
        keyName="referralOwner"
        title="Referral owner"
        classNames={classes.checkbox}
        value={referralOwner}
        onChangeBox={handleOwnerChange}
        size="small"
      />
      <Divider className={classes.divider} />
      <Typography className={clsx(classes.description, classes.subHeading)}>
        Registered recipients
      </Typography>
      <div className={classes.gridContainer}>
        {registeredUsers.map(user => {
          const name =
            user.name[0].toUpperCase() +
            user.name.slice(1, user.name.length + 1)
          return (
            <div
              key={user.idpUserId}
              className={classes.gridItem}
              data-testid={`registered-user-${user.idpUserId}`}
            >
              <CheckBoxFieldv2
                keyName={user.idpUserId}
                title={
                  <div className={classes.recepientContainer}>
                    <AvatarForSingleUser
                      isUserDataEmpty={true}
                      specialUser={user.name.trim() || user.email}
                    />
                    {name || user.email}
                  </div>
                }
                classNames={classes.checkbox}
                value={Boolean(
                  registeredRecipients.some(
                    recipient => recipient.idpUserId === user.idpUserId
                  )
                )}
                onChangeBox={handleRegisteredRecipientChange(user.idpUserId)}
                size="small"
              />
            </div>
          )
        })}
      </div>
      <Divider className={classes.divider} />
      <div className={classes.heading} data-testid="textarea-heading">
        Add upto 5 external email recipients
      </div>
      <div className={classes.externalUsers}>
        <TextAreaWithChips
          id={'selectedTags'}
          label={'Press enter or comma to add emails'}
          placeholder={''}
          selectedValues={selectedTags}
          onChange={handleTagsChange}
          handleDelete={handleDelete}
          maxChips={5}
          showSpinner={false}
          data-testid="external-users"
          size="small"
          error={Boolean(!isEmpty(externalUsersError))}
          helperText={externalUsersError}
        />
      </div>
      <Typography className={classes.description}>
        By clicking the "Add" button, you confirm you have permission from all
        the above recipients to send this notification to them.
      </Typography>
    </div>
  )
}
