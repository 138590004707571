import { makeStyles } from '@material-ui/core'

export const useStyles = (screenHeight: number) =>
  makeStyles(theme => ({
    root: {
      background: theme.palette.bg.main,
    },
    tabContainer: {
      padding: theme.spacing(0, 5),
      borderBottom: `1px solid ${theme.palette.labraV2BoxShadow.dark}`,
    },
    content: {
      padding: theme.spacing(4, 5),
      height: screenHeight > 1000 ? '82vh' : '78vh',
      overflowY: 'auto',
    },
    marketplace: {
      height: '100%',
    },
  }))
