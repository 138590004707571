import { makeStyles } from '@material-ui/core/styles'
import { footerStateType } from './FooterV2'
export const useStyles = (width?: string, footerState?: string) =>
  makeStyles(theme => ({
    footerContainer: {
      display: 'block',
      marginTop: 'auto',
      width: width ? width : 'calc(100% - 280px)',
      position: 'absolute',
      alignItems: 'center',
      bottom: '0',
      margin: theme.spacing(0, -5, 0, -5),
      padding: theme.spacing(3, 5),
      boxSizing: 'border-box',
      boxShadow: `0px 1px 0px 0px ${theme.palette.boxShadow?.dark} inset`,
      opacity: 1,
      zIndex: footerState !== '' ? 105 : 99,
      transition: 'bottom 1s ease',
      overflow: 'hidden',
      backgroundColor:
        footerState === footerStateType.LOCKED
          ? theme.palette.bgPrimary.dark
          : footerState === footerStateType.UNLOCKED
          ? theme.palette.avatarColors.green
          : theme.palette.text.white,
    },
    open: {
      bottom: '0px',
    },
    closed: {
      bottom: '-88px',
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      marginRight: theme.spacing(0),
      height: '100%',
    },
  }))
