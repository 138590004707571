import { AxiosResponse } from 'axios'
import { camelize, snakeize } from 'casing'
import { fetchOrganizationAccounts } from '../../../flyout/api/cmcm'
import { RootState, AppDispatch } from '../../../store'
import { actionTypeWrapper } from '../../utils/actionTypeWrapper'
import { getErrorMessages } from '../../utils/error'
import { errorLogger } from '../../utils/errorLogger'
import { isAdminRole } from '../../utils/isAdminRole'
import { updateAppAlert } from '../appAlert/actions'
import { startLoading, stopLoading } from '../loading/actions'
import { LoadingTypes } from '../loading/reducer'
import { PartnerType } from '../partner/action'
import { ProductType } from '../partner/reducer'

import { CloudType } from '../types'
import { Account, SegmentAndGreenField } from './reducer'
import {
  postAssetsOppSync,
  fetchSegmentAndGreenField,
} from '../../../oppsync/api'
import { fetchUserProfile } from '../../api'
import { get } from 'lodash'
import { validate } from 'uuid'

export enum CLOUD_ACCOUNT_ACTIONS {
  SET_ACCOUNTS = 'SET_ACCOUNTS',
  SET_SEGMENT_AND_GREEN_FIELD = 'SET_SEGMENT_AND_GREEN_FIELD',
  UPDATE_SEGMENT_AND_GREEN_FIELD = 'UPDATE_SEGMENT_AND_GREEN_FIELD',
}

export const setAccounts = (accounts: Account[]) => ({
  type: CLOUD_ACCOUNT_ACTIONS.SET_ACCOUNTS,
  payload: accounts,
})

export const setSegmentAndGreenField = (data: SegmentAndGreenField[]) => ({
  type: CLOUD_ACCOUNT_ACTIONS.SET_SEGMENT_AND_GREEN_FIELD,
  payload: data,
})
export const updateSegmentAndGreenField = (data: SegmentAndGreenField[]) => ({
  type: CLOUD_ACCOUNT_ACTIONS.UPDATE_SEGMENT_AND_GREEN_FIELD,
  payload: data,
})
export const fetchAccounts =
  (cloudType: CloudType, productId: ProductType) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.CLOUD_ACCOUNTS))
    try {
      const state = getState()
      const isAdmin = isAdminRole(state.userProfile.userProfile)
      const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
      const organizationId =
        state.PartnerData?.[partnerType]?.partnerData?.partnerId || ''
      const response = await fetchOrganizationAccounts(
        organizationId,
        cloudType,
        productId
      )
      await dispatch(
        actionTypeWrapper(productId, setAccounts(camelize(response.data)))
      )
    } catch (e) {
      const errorMessage = getErrorMessages([
        'Unable to fetch organization accounts',
      ])(e as AxiosResponse<ErrorResponse>)

      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.CLOUD_ACCOUNTS))
    }
  }
export const getSegmentAndGreenField =
  (productId: ProductType) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SET_SEGMENT_AND_GREEN_FIELD))
    try {
      const state = getState()
      const isAdmin = isAdminRole(state.userProfile.userProfile)
      const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
      const partnerId =
        state.PartnerData?.[partnerType]?.partnerData?.partnerId || ''
      const response = await fetchSegmentAndGreenField(partnerId)
      const data = get(camelize(response), 'data', { results: [] })
      const segmentData = get(data, 'results.0', {})
      const userId = segmentData?.createdBy
      if (validate(userId)) {
        const userProfile = await fetchUserProfile(partnerId, userId)
        segmentData.createdBy = userProfile?.email
        data.results[0] = { ...segmentData }
      }
      await dispatch(
        actionTypeWrapper(productId, setSegmentAndGreenField(data))
      )
    } catch (e) {
      const errorMessage = getErrorMessages([
        'Unable to fetch Segment And GreenField data',
      ])(e as AxiosResponse<ErrorResponse>)

      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SET_SEGMENT_AND_GREEN_FIELD))
    }
  }

export enum AssetType {
  'SEGMENT' = 'segment',
}
export interface OppSyncAssets {
  type: AssetType
  fileUrl: string
  fileName: string
  createdBy: string
}
export interface AddAssetsOppSync {
  partnerId: string
  assets: OppSyncAssets[]
}
export const addAssetsOppSync =
  ({ partnerId, assets }: AddAssetsOppSync) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SET_SEGMENT_AND_GREEN_FIELD))
    try {
      const { data } = await postAssetsOppSync(
        partnerId,
        snakeize({ assets: assets })
      )
      dispatch(
        updateAppAlert({
          message: 'File uploaded successfully.',
          messageType: 'SUCCESS',
        })
      )
      const segmentData = camelize(get(data, 'data[0]', {}))
      const email = getState().userProfile.userProfile.email
      segmentData.createdBy = email
      await dispatch(
        actionTypeWrapper('oppsync', updateSegmentAndGreenField(segmentData))
      )
    } catch (e) {
      const errorMessage = getErrorMessages(['Unable to post the asset url.'])(
        e as AxiosResponse<ErrorResponse>
      )

      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SET_SEGMENT_AND_GREEN_FIELD))
    }
  }
