import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
    alignSelf: 'stretch',
  },

  field: {
    width: '459.5px',
    textAlign: 'left',
  },
  loadingText: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.1px',
    fontFamily: theme.typography.fontFamily,
  },
  textColor: {
    color: theme.palette.labraText.description,
  },
  successText: {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.1px',
    fontFamily: theme.typography.fontFamily,
  },
  loadingTextContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
  loader: {
    width: '16px',
    height: '16px',
    flexShrink: 0,
    color: theme.palette.labraText.tertiary,
  },
  check: {
    color: theme.palette.textBackground.dark,
  },
  buttonContainer: {
    marginTop: theme.spacing(0.625),
  },
  errorText: {
    color: theme.palette.labraV2Errors.main,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.1px',
    fontFamily: theme.typography.fontFamily,
  },
  content: {
    display: 'flex',
    gap: `${theme.spacing(5)}px !important`,
    flexDirection: 'row',
  },
  fieldsArnContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
}))
