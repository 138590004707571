import { useDispatch, useSelector } from '../../../store'
import { useEffect } from 'react'
import { PartnerData } from '../../modules/partner/action'
import { getFlyoutListingData } from '../../../admin/modules/flyOutSyncs/actions'
import { FlyOutListingData } from '../../../admin/modules/flyOutSyncs/reducer'
import { getFlyOutOnboardingData } from '../../../flyout/modules/flyOutOnboarding/actions'
import { CloudType } from '../../modules/types'
import { isLoading } from '../loadingState'
import { LoadingTypes } from '../../modules/loading/reducer'
import { isEmpty } from 'lodash'

export const useSellerInfo = () => {
  const {
    partnerData,
    flyoutSellerInfo,
    isLoadingSeller,
    hasFlyoutOnboardingData,
  } = useSelector<{
    flyoutSellerInfo: FlyOutListingData
    partnerData: undefined | PartnerData
    isLoadingSeller: boolean
    hasFlyoutOnboardingData: boolean
  }>(state => ({
    flyoutSellerInfo: state.flyoutSellerInfo,
    partnerData: state.PartnerData.user.partnerData || undefined,
    isLoadingSeller:
      isLoading(state.loading)(LoadingTypes.MARKETPLACE_INFO) ||
      isLoading(state.loading)(LoadingTypes.FLYOUT_ONBOARDING_DATA),
    hasFlyoutOnboardingData: !isEmpty(state.flyOutOnboarding.onboardingForm),
  }))
  const dispatch = useDispatch()

  useEffect(() => {
    if (
      partnerData?.partnerId &&
      partnerData?.activeProducts &&
      partnerData?.activeProducts?.includes('flyout')
    ) {
      dispatch(
        getFlyoutListingData({
          partnerId: partnerData?.partnerId,
          metadata: true,
        })
      )
    }
  }, [partnerData?.partnerId, partnerData?.activeProducts])

  const loading = Boolean(!partnerData || isLoadingSeller)

  useEffect(() => {
    if (
      partnerData?.partnerId &&
      flyoutSellerInfo?.onboarded === false &&
      !loading &&
      !hasFlyoutOnboardingData
    ) {
      dispatch(getFlyOutOnboardingData(partnerData?.partnerId, CloudType.AWS))
    }
  }, [
    partnerData?.partnerId,
    flyoutSellerInfo,
    loading,
    hasFlyoutOnboardingData,
  ])
  return { loading: loading, flyoutSellerInfo }
}
