import React from 'react'
import { FormikProps, useFormikContext } from 'formik'
import {
  BoxV2,
  DatePickerV2,
  labraUIDateFormat,
  RadioButtonsV2,
  SelectV2,
} from '@labrav/react-components'
import { Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListCheck } from '@fortawesome/pro-regular-svg-icons'
import { get, isEmpty } from 'lodash'
import { DataType } from '@labrav/react-components/lib/@types/types/forms'
import {
  getContractDurationOptions,
  startDateOptions,
} from '../../../../common/utils/contractDurationOptions'
import { useStyles } from './ContractDuration.styles'
import {
  channelPrivateOfferAgreementStartDateField,
  channelPrivateOfferContractStartDateField,
} from '../../../utils/channelOfferUtil'
import { getPricesArray } from '../../../modules/resaleAuthorization/actions'
import { Dimension } from '../../../modules/privateOffer/reducer'
import { ReceivedResellerAuthorization } from '../../../modules/receivedResellerAuthorization/reducer'

export const ContractDuration = ({
  specificDuration,
  showDurationSelection,
  resellerAuthorization,
}: {
  specificDuration?: string
  showDurationSelection?: boolean
  resellerAuthorization?: ReceivedResellerAuthorization
}) => {
  const formikProps = useFormikContext() as FormikProps<Record<string, unknown>>
  const { values, errors, touched, handleBlur, setFieldValue } = formikProps
  const classes = useStyles()

  const prices = getPricesArray(
    (resellerAuthorization?.dimensions || []) as Dimension[]
  )
  const contractDurationOptions = getContractDurationOptions(
    false,
    values.flexiblePaymentTerms as boolean,
    true
  )?.filter(item => prices?.includes(getMappedKey(item?.key)))

  const checkedValueForSaasContract = () => {
    const selectedKey = values.serviceLength?.toString()
    return selectedKey
  }

  const handleSelectionForContractDuration = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value as string
    await setFieldValue(
      'serviceLength',
      isEmpty(value) ? values?.serviceLength : Number(value)
    )
  }

  return (
    <div data-testid="Contract-duration-section">
      <BoxV2
        heading={'Contract duration'}
        headingIcon={
          <FontAwesomeIcon
            icon={faListCheck}
            style={{ width: '18px', height: '14px' }}
          />
        }
      >
        <div className={classes.sectionContainer}>
          <>
            {specificDuration && (
              <div className={classes.specificDurationNote}>
                {specificDuration}
              </div>
            )}
            <div className={classes.companyDetailsContainer}>
              <div>
                <SelectV2
                  field={{
                    ...channelPrivateOfferContractStartDateField,
                    options: startDateOptions,
                  }}
                  value={values.variableStartDate}
                  onChange={formikProps.handleChange}
                  onBlur={handleBlur}
                  isError={
                    !!(
                      get(touched, 'variableStartDate', false) &&
                      get(errors, 'variableStartDate', false)
                    )
                  }
                  errorText={
                    (!!get(touched, 'variableStartDate', false) &&
                      get(errors, 'variableStartDate', '')) ||
                    ''
                  }
                />
              </div>
              <div className={classes.inputWidth}>
                {values.variableStartDate === false ? (
                  <div className={classes.inputWidth}>
                    <DatePickerV2
                      format={labraUIDateFormat}
                      field={channelPrivateOfferAgreementStartDateField}
                      key={'agreementStartDate'}
                      showErrorHelper={
                        !!touched.agreementStartDate &&
                        !isEmpty(errors.agreementStartDate)
                      }
                      fieldId={'agreementStartDate'}
                      formik={
                        formikProps as unknown as FormikProps<
                          Record<string, unknown>
                        >
                      }
                      errorHelperText={
                        (!!touched.agreementStartDate &&
                          errors.agreementStartDate) ||
                        ''
                      }
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {showDurationSelection && (
              <div
                className={classes.fullWidth}
                data-testid={'duration-selection'}
              >
                <Typography className={classes.sectionHeading}>
                  Contract duration options
                </Typography>
                <div className={classes.radioButton}>
                  <RadioButtonsV2
                    checkedValue={{
                      key: checkedValueForSaasContract() as string,
                      value: checkedValueForSaasContract() as string,
                    }}
                    name="serviceLength"
                    radioButtonMappings={contractDurationOptions}
                    handleChange={handleSelectionForContractDuration}
                  />
                </div>
              </div>
            )}
          </>
        </div>
      </BoxV2>
    </div>
  )
}

export const getMappedKey = (priceKey: string) => {
  switch (priceKey) {
    case '1': {
      return 'price1m'
    }
    case '12': {
      return 'price12m'
    }
    case '24': {
      return 'price24m'
    }
    case '36': {
      return 'price36m'
    }
    default: {
      return ''
    }
  }
}

export const getMappedMonthKey = (priceKey: string) => {
  switch (priceKey) {
    case 'price1m': {
      return 1
    }
    case 'price12m': {
      return 12
    }
    case 'price24m': {
      return 24
    }
    case 'price36m': {
      return 36
    }
    default: {
      return 0
    }
  }
}
