import { Reducer } from 'redux'
import { OverviewActionTypes } from './action'

export type StatRiseType = 'up' | 'down'

export type CloudMarketplace = 'AWS' | 'GCP' | 'MICROSOFT'

export interface Stat {
  total: number
  percentage?: number
  rise?: StatRiseType
}

export interface OtherProduct {
  cloud: CloudMarketplace
  name: string
  value: number
}

export interface RevenueData {
  id: string
  data: { date: string; value: number }[]
  cloudTotal?: number
}

export interface RevenueByProduct {
  name: string
  cloud: CloudMarketplace
  value: number
  percentage: number
  otherProducts?: OtherProduct[]
}

export interface RevenueViaISV {
  isv: string
  value: number
  percentage: number
  otherISV?: { isv: string; value: number }[]
}

export interface RevenueByType {
  type: string
  value: number
  percentage: number
  otherTypes?: { type: string; value: number }[]
}

export interface SubscriptionData {
  date: string
  aws: number
  gcp: number
  microsoft: number
}

export interface OfferData {
  count: number
  percentage: number
}

export interface AcceptedOffer {
  name: string
  percentage: number
  value: number
}

export interface OfferItem {
  id: string
  offerName: string
  productId?: string
  companyLogo: string
  companyName: string
  expiryDate: string
  salesBy?: string
  subscriptionId?: string
  cloud: CloudMarketplace
  offerStatus: string
  offerViewedBy: string
  offerViewedAt: string
  tcv?: number
  autoRenewal?: boolean
}

export interface Overview {
  revenue: Stat & {
    revenueData: RevenueData[]
    revenueByProduct: RevenueByProduct[]
    isvRevenue?: RevenueViaISV[]
    typeRevenue?: RevenueByType[]
  }
  activeSubscription: Stat & {
    data: SubscriptionData[]
  }
  churnedSubscription: Stat & {
    data: SubscriptionData[]
  }
  offersForBuyers: Stat & {
    data: {
      aws: OfferData
      gcp: OfferData
      microsoft: OfferData
    }
  }
  receivedResellerAgreementCount?: Stat & {
    data: {
      aws: { count: number }
      gcp: { count: number }
      microsoft: { count: number }
    }
  }
  givenResellerAgreementCount?: Stat & {
    data: {
      aws: { count: number }
      gcp: { count: number }
      microsoft: { count: number }
    }
  }
  offersForChannelPartners: Stat & {
    data: {
      aws: OfferData
      gcp: OfferData
      microsoft: OfferData
    }
  }
  acceptedOffer: {
    data: AcceptedOffer[]
  }
  offersNeedingAttention: OfferItem[]
  upcomingRenewals: OfferItem[]
}

export interface OverviewState {
  overview: Overview
}

export const initialState: OverviewState = {
  overview: {
    revenue: {
      total: 0,
      percentage: 0,
      rise: 'up',
      revenueData: [],
      revenueByProduct: [],
      isvRevenue: [],
      typeRevenue: [],
    },
    activeSubscription: {
      total: 0,
      percentage: 0,
      rise: 'up',
      data: [],
    },
    churnedSubscription: {
      total: 0,
      percentage: 0,
      rise: 'up',
      data: [],
    },
    offersForBuyers: {
      total: 0,
      percentage: 0,
      rise: 'up',
      data: {
        aws: { count: 0, percentage: 0 },
        gcp: { count: 0, percentage: 0 },
        microsoft: { count: 0, percentage: 0 },
      },
    },
    offersForChannelPartners: {
      total: 0,
      percentage: 0,
      rise: 'up',
      data: {
        aws: { count: 0, percentage: 0 },
        gcp: { count: 0, percentage: 0 },
        microsoft: { count: 0, percentage: 0 },
      },
    },
    acceptedOffer: {
      data: [],
    },
    offersNeedingAttention: [],
    upcomingRenewals: [],
  },
}
type actionType = {
  type: OverviewActionTypes.SET_OVERVIEW
  payload: OverviewState
}

export const reducer: Reducer<OverviewState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case OverviewActionTypes.SET_OVERVIEW: {
      return {
        ...state,
        overview: action.payload.overview,
      }
    }
    default:
      return state
  }
}
