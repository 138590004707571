import React, { useCallback, useEffect, useState } from 'react'
import { CoSellAccountDetailsSection } from './CosellAccountDetails/CosellAccountDetails'
import { LinkAccountSection } from './LinkAccountSection/LinkAccountSection'
import { PrerequisitesSection } from './Prequisites/Prequisites'
import { Formik, Form } from 'formik'
import { AwsCloudSettingsSectionsProps } from '../types'
import { IamRolesCreationSection } from './IamRolesCreation/IamRolesCreation'
import { IamPolicyGenerationSection } from './IamPolicyGenerationSection/IamPolicyGenerationSection'
import { IamPolicyToRoleAssignmentSection } from './IamPolicyToRoleAssignment/IamPolicyToRoleAssignment'
import { useStyles } from './CloudSettingsWrapper.styles'
import CloudSettingsFormikValidationSchema from '../../../../../common/utils/CloudSettingsFormikValidationSchema'
import { CoSellCloudSettingsFooter } from './CoSellCloudSettingsFooter/CoSellCloudSettingsFooter'
import {
  combineTransformedData,
  getPartnerDataForAceApi,
  saveCosellCloudSettings,
} from '../../../../modules/engineServicePartner/action'
import { AnyAction } from 'redux'
import {
  usePartnerId,
  useUserType,
} from '../../../../../common/utils/Hooks/usePartnerData'
import { RootState, useDispatch, useSelector } from '../../../../../store'
import { useLocation, useNavigate } from 'react-router-dom'
import { useOppSyncOnboardingStatus } from '../../../../utils/hooks/useOppSyncOnboardingStatus'
import { PartnerType } from '../../../../../common/modules/partner/action'
import { RequiredPersonsModalWrapper } from './RequiredPersonsModalWrapper/RequiredPersonsModalWrapper'
import { EnvironmentConnectionSection } from './EnvironmentConnectionSection/EnvironmentConnectionSection'

export const isCloudAccountFormComplete = (
  steps: AwsCloudSettingsSectionsProps['steps'],
  aceEligible: boolean
) => {
  if (!aceEligible) {
    return [
      steps.prerequisites,
      steps.linkAccount,
      steps.cloudDetails,
      steps.createIamRole,
    ].every(a => a === true)
  } else {
    return [
      steps.prerequisites,
      steps.linkAccount,
      steps.cloudDetails,
      steps.createIamRole,
      steps.generatePolicies,
    ].every(a => a === true)
  }
}

export const CloudSettingsWrapper: React.FC = () => {
  const partnerType = useUserType()
  const navigate = useNavigate()
  const location = useLocation()
  const isAdmin = partnerType === PartnerType.Admin
  const engineServicePartnerData = useSelector(
    (state: RootState) =>
      state.engineServicePartner[partnerType].engineServicePartnerData
  )
  const partnershipType = useSelector(
    (state: RootState) =>
      state.PartnerData[partnerType].partnerData?.partnershipType
  )

  const dispatch = useDispatch()
  const partnerId = usePartnerId()
  const classes = useStyles()
  useEffect(() => {
    if (partnerId) {
      dispatch(
        getPartnerDataForAceApi(
          partnerId || '',
          partnerType
        ) as unknown as AnyAction
      )
    }
  }, [partnerId])

  const { hasConnectedCrmAtLeastOnce } = useOppSyncOnboardingStatus()

  const onSubmit = useCallback(
    async (values: AwsCloudSettingsSectionsProps) => {
      if (
        partnerId &&
        isCloudAccountFormComplete(
          values.steps,
          values.prerequisites?.isAceEligible
        )
      ) {
        const saveResult = await dispatch(
          saveCosellCloudSettings(values, partnerId, partnerType)
        )
        if (saveResult) {
          if (hasConnectedCrmAtLeastOnce) {
            navigate('/oppsync/dashboard')
          } else {
            navigate('/oppsync/onboarding/crm-settings')
          }
        }
      }
    },
    [dispatch, hasConnectedCrmAtLeastOnce]
  )

  const { onboardingComplete } = useOppSyncOnboardingStatus()
  const isOnboardingCompleted =
    (onboardingComplete || isAdmin) && !location.pathname.includes('onboarding')
  return (
    <>
      {engineServicePartnerData ? (
        <Formik
          initialValues={combineTransformedData({
            ...engineServicePartnerData,
            partnershipType,
          })}
          validationSchema={CloudSettingsFormikValidationSchema}
          onSubmit={onSubmit}
        >
          {formData => (
            <Form>
              <div className={classes.mainContainer}>
                {isOnboardingCompleted && <EnvironmentConnectionSection />}
                {(!isOnboardingCompleted || isAdmin) && (
                  <PrerequisitesSection
                    isOnboardingCompleted={isOnboardingCompleted}
                  />
                )}
                <LinkAccountSection
                  isOnboardingCompleted={isOnboardingCompleted}
                />
                <CoSellAccountDetailsSection
                  isOnboardingCompleted={isOnboardingCompleted}
                />
                <IamRolesCreationSection
                  isOnboardingCompleted={isOnboardingCompleted}
                />
                {isOnboardingCompleted ? (
                  <>
                    <IamPolicyGenerationSection
                      isOnboardingCompleted={isOnboardingCompleted}
                    />
                    <IamPolicyToRoleAssignmentSection
                      isOnboardingCompleted={isOnboardingCompleted}
                    />
                  </>
                ) : formData.values.prerequisites.isAceEligible ? (
                  <>
                    <IamPolicyGenerationSection />
                    <IamPolicyToRoleAssignmentSection />
                  </>
                ) : null}
              </div>
              <>
                <div className={classes.footerContainer}>
                  <CoSellCloudSettingsFooter
                    isOnboardingCompleted={isOnboardingCompleted}
                  />
                </div>
              </>
            </Form>
          )}
        </Formik>
      ) : (
        <></>
      )}
      {!isAdmin && <RequiredPersonsModalWrapper />}
    </>
  )
}
