import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  roleBadge: {
    height: theme.spacing(3.62),
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.avatarColors.purple,
    width: 'fit-content',
    padding: theme.spacing(0, 1),
    gap: theme.spacing(1),
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    transition: 'width 0.8s ease',
  },
  titleUserIcon: {
    height: theme.spacing(1.62),
    color: theme.palette.button.primary,
  },
  requiredText: {
    fontWeight: 400,
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    marginRight: theme.spacing(-0.5),
  },
  boldText: {
    fontWeight: 500,
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
  },
}))
