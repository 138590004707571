import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  root: {
    height: theme.spacing(8),
    padding: theme.spacing(1.5, 5),
  },
  onboardingActions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  onboardingCompletedActions: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
  },
  btnWrapper: {
    display: 'flex',
    gap: theme.spacing(3),
  },
  autosave: {
    display: 'flex',
    alignItems: 'center',
  },
  footerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  bookmarkIcon: {
    height: theme.spacing(2),
    weight: theme.spacing(1.75),
  },
}))
