import { AppDispatch, RootState } from '../../../store'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { getErrorMessages } from '../../../common/utils/error'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { AxiosError, AxiosResponse } from 'axios'
import { errorLogger } from '../../../common/utils/errorLogger'
import {
  getBuyersData,
  getBuyersJourneyContractsDataAPI,
  getBuyersJourneyDataAPI,
} from '../../api/markeplace'
import { camelize } from 'casing'
import {
  BuyersData,
  JourneyContractDetails,
  BuyerJourneyContracts,
  ContractType,
} from './reducer'

export enum BuyersActionTypes {
  SET_BUYERS = 'SET_BUYERS',
  SET_JOURNEY_DATA = 'SET_JOURNEY_DATA',
  SET_BUYER_CONTRACTS_DATA = 'SET_BUYER_CONTRACTS_DATA',
  CLEAR_BUYERS = 'CLEAR_BUYERS',
}

export const setBuyerJourneyData = (journeyData: JourneyContractDetails) => ({
  type: BuyersActionTypes.SET_JOURNEY_DATA,
  payload: journeyData,
})

export const setBuyerContractsData = (
  contractsData: BuyerJourneyContracts[]
) => ({
  type: BuyersActionTypes.SET_BUYER_CONTRACTS_DATA,
  payload: contractsData,
})

export const getBuyer =
  ({ partnerId, buyerId }: { partnerId: string; buyerId: string }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.BUYERS_OVERVIEW))
    try {
      const response = await getBuyersData({ partnerId, buyerId })
      const buyersData = camelize(
        response?.data?.buyer ? { buyers: [response.data.buyer] } : {}
      )
      await dispatch(setBuyersData(buyersData))
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.BUYERS_OVERVIEW))
    }
  }

export const getBuyersOverview =
  ({ partnerId, inputValue }: { partnerId: string; inputValue?: string }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.BUYERS_OVERVIEW))
    try {
      let response
      let buyersData

      if (inputValue) {
        response = await getBuyersData({ partnerId, search: inputValue })
        buyersData = camelize(response?.data ?? {})
      } else {
        response = await getBuyersData({ partnerId })
        buyersData = camelize(response?.data ?? {})
      }

      await dispatch(setBuyersData(buyersData))
    } catch (error) {
      const axiosError = error as AxiosError<ErrorResponse>
      await dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            axiosError.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.BUYERS_OVERVIEW))
    }
  }

export const getBuyersJourneyContractsData =
  ({
    partnerId,
    labraBuyerDetailsId,
  }: {
    partnerId: string
    labraBuyerDetailsId: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.BUYER_JOURNEY_CONTRACTS))
    try {
      const { data } = await getBuyersJourneyContractsDataAPI({
        partnerId,
        labraBuyerDetailsId,
      })
      const buyersJourneyData = camelize(data?.buyer_contracts ?? [])
      await dispatch(setBuyerContractsData(buyersJourneyData))
    } catch (error: any) {
      await dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.BUYER_JOURNEY_CONTRACTS))
    }
  }

export const getBuyersJourneyData =
  ({
    partnerId,
    labraBuyerDetailsId,
    contractId,
    contractType,
  }: {
    partnerId: string
    labraBuyerDetailsId: string
    contractId: string
    contractType: ContractType
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.BUYER_JOURNEY))
    try {
      const { data } = await getBuyersJourneyDataAPI({
        partnerId,
        labraBuyerDetailsId,
        contractId,
        contractType,
      })
      const buyersJourneyData = camelize(data?.buyer_contract_details[0])
      await dispatch(setBuyerJourneyData(buyersJourneyData))
    } catch (error: any) {
      await dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.BUYER_JOURNEY))
    }
  }

export const clearBuyersOverView = () => ({
  type: BuyersActionTypes.CLEAR_BUYERS,
})

export const setBuyersData = (buyersData: BuyersData) => ({
  type: BuyersActionTypes.SET_BUYERS,
  payload: buyersData,
})
