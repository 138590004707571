import { UsageMeteringActionTypes } from './actions'
import { Reducer } from 'redux'

export interface Record {
  name: string
  pricePerBandwidth: string
  quantity: string
  price: string
}

export interface UsageData {
  buyerName: string
  buyerLogo: string
  productName: string
  status: string
  meteringDate: string
  amount: string
  cloud: string
  records: Record[]
}

export interface UsageDataState {
  usageData: UsageData[]
}

const initialState: UsageDataState = {
  usageData: [],
}

type actionType =
  | {
      type: UsageMeteringActionTypes.SET_USAGE_METERING_DATA
      payload: UsageDataState
    }
  | {
      type: UsageMeteringActionTypes.CLEAR_USAGE_METERING_DATA
    }

export const reducer: Reducer<UsageDataState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UsageMeteringActionTypes.SET_USAGE_METERING_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case UsageMeteringActionTypes.CLEAR_USAGE_METERING_DATA:
      return initialState

    default: {
      return state
    }
  }
}
