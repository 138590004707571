import { AxiosResponse } from 'axios'
import { PartnerType } from '../../../../common/modules/partner/action'
import { getErrorMessages } from '../../../../common/utils/error'
import { isAdminRole } from '../../../../common/utils/isAdminRole'
import { AppDispatch, RootState } from '../../../../store'
import {
  getListingDataById,
  getProductDataFromLink,
  putListingData,
} from '../../../api/markeplace'
import {
  BWAProductGet,
  BWAProduct,
  ListingCardInCreation,
  ListingCardData,
} from './reducer'
import { updateAppAlert } from '../../../../common/modules/appAlert/actions'
import { errorLogger } from '../../../../common/utils/errorLogger'
import {
  startLoading,
  stopLoading,
} from '../../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../../common/modules/loading/reducer'
import { awsMarketplaceProductLink } from '../../../../common/utils/constants'
import { v4 as uuid4 } from 'uuid'
import { TemplateInCreation } from '../templateCreation/reducer'
import { camelize } from 'casing'
import {
  DefaultSuccesrOnSubmitData,
  RequestFailureMessage,
} from '../../../../common/utils/messagesContants'
import { Dispatch } from 'react'
export enum ListingCardCreationActionTypes {
  SET_CREATE_LISTING_DATA = 'SET_CREATE_LISTING_DATA',
  SET_TEMPLATE_ON_LISTING = 'SET_TEMPLATE_ON_LISTING',
  UPDATE_LISTING_CARD_DATA = 'UPDATE_LISTING_CARD_DATA',
  SET_SELECTED_TEMPLATE_ID = 'SET_SELECTED_TEMPLATE_ID',
  CLEAR_LISTING_CARD_DATA = 'CLEAR_LISTING_CARD_DATA',
  SET_PRODUCT_DATA = 'SET_PRODUCT_DATA',
}
export const setListingCardFormData = (integrationData: ListingCardData) => ({
  type: ListingCardCreationActionTypes.SET_CREATE_LISTING_DATA,
  payload: integrationData,
})

export const setTemplateOnListing = (integrationData: TemplateInCreation) => ({
  type: ListingCardCreationActionTypes.SET_TEMPLATE_ON_LISTING,
  payload: integrationData,
})
export const getListingById =
  (listingId: string) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.CREATE_LISTING_CARD))
    await dispatch(clearListingCardData())
    try {
      const state = getState()
      const isAdmin = isAdminRole(state.userProfile.userProfile)
      const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
      const partnerId =
        state.PartnerData?.[partnerType]?.partnerData?.partnerId || ''
      const response = await getListingDataById(partnerId, listingId)
      const { data } = response
      const updatedData = transformDataFromGet(data)
      dispatch(setListingCardFormData(updatedData))
    } catch (e) {
      const errorMessage = getErrorMessages([
        'Unable to fetch details for the listing.',
      ])(e as AxiosResponse<ErrorResponse>)

      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.CREATE_LISTING_CARD))
    }
  }

export const transformDataFromGet = (data: Record<string, unknown>) => {
  const { product, id, ...rest } = data
  const updatedProduct: BWAProduct[] =
    Object.keys(product as BWAProductGet).map((key: string) => {
      return {
        ...(product as BWAProductGet)[key],
        url: `${awsMarketplaceProductLink}/${key}`,
        isOpen: true,
        uniqueId: uuid4(),
        isLoading: false,
      }
    }) || []
  if (updatedProduct.length === 0) {
    updatedProduct.push({
      uniqueId: String(uuid4()),
      url: '',
      isOpen: true,
      isLoading: false,
    }) as unknown as BWAProduct[]
  }
  return {
    ...camelize(rest),
    product: updatedProduct,
    integrationId: id,
  } as ListingCardData
}

export const fetchProductDetailsFromUrl =
  (productId: string, index: number, listingData: ListingCardInCreation) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.PRODUCT_INFORMATION))
    try {
      const state = getState()
      const isAdmin = isAdminRole(state.userProfile.userProfile)
      const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User
      const partnerId =
        state.PartnerData?.[partnerType]?.partnerData?.partnerId || ''
      const response = await getProductDataFromLink(partnerId, productId)
      const { data } = response
      listingData.listingCardData.product[index] = {
        ...listingData.listingCardData.product[index],
        product_id: productId,
        isLoading: false,
        data: { ...data.product_data },
      }
      // dispatch(saveListingData(listingData))
      dispatch(setListingCardFormData(listingData.listingCardData))
    } catch (e) {
      const errorMessage = getErrorMessages([
        'Unable to fetch details for the provided url.',
      ])(e as AxiosResponse<ErrorResponse>)

      dispatch(
        updateAppAlert({
          message: errorMessage,
          messageType: 'ERROR',
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(e as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.PRODUCT_INFORMATION))
    }
  }

export const saveListingData =
  (listingData: ListingCardInCreation, showAlert = false) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const {
        integrationId,
        name,
        template,
        cardAlignment,
        logoPosition,
        product,
        type,
      } = listingData.listingCardData
      const templateId = template?.id || null
      const cards = product.map(prod => {
        const match = prod.url.match(/prodview-[a-zA-Z0-9]+/)
        return match ? match[0] : null
      })
      const updatedData = {
        integration_id: integrationId || null,
        name: name || '',
        template_id: templateId,
        card_alignment: cardAlignment,
        logo_position: logoPosition,
        type: type,
        cards,
      }

      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const { data } = await putListingData(partnerId, updatedData)
      const getIntegrationId = data?.integration_id || null
      const updatedListingCardData: ListingCardData = {
        ...listingData.listingCardData,
        integrationId: getIntegrationId,
      }
      dispatch(setListingCardFormData(updatedListingCardData))
      dispatch(
        updateAppAlert({
          message: showAlert ? DefaultSuccesrOnSubmitData : '',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const clearListingCardData = () => ({
  type: ListingCardCreationActionTypes.CLEAR_LISTING_CARD_DATA,
})
