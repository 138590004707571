import React from 'react'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@material-ui/core'
import { useStyles } from './CompleteOnboardingPage.styles'

export interface OnboardingProductInfoProps {
  title: React.ReactNode
  features: { text: string }[]
  subheading?: string
  customButton?: React.ReactNode
}

export const OnboardingProductInfo: React.FC<OnboardingProductInfoProps> = ({
  title,
  features,
  subheading,
  customButton,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.contentContainer} data-testid="info">
      <Typography className={classes.title} data-testid="title">
        {title}
      </Typography>
      {subheading && (
        <Typography className={classes.subTitle} data-testid="subheading">
          {subheading}
        </Typography>
      )}
      {features.map((feature, index) => (
        <div className={classes.listItem} data-testid={`feature-${index}`}>
          <FontAwesomeIcon
            icon={faCheck}
            style={{
              width: '14px',
              height: '15px',
              marginRight: '8px',
            }}
          />
          <li key={index} className={classes.listItemText}>
            {feature.text}
          </li>
        </div>
      ))}
      <div className={classes.button}>{customButton}</div>
    </div>
  )
}
