import { Reducer } from 'redux'
import { meteringsActionTypes } from './action'

export type DimensionWiseMetering = {
  name: string
  description: string | null
  price: number
  quantity: number
  totalAmount: number
}

export type Metering = {
  meteringCount: number
  lastMeteringDate: string
  lastMeteringStatus: string
  lastMeteringAmount: number
  offerName: string | null
  productName: string
  productId: string
  subscriptionStatus: string
  subscriptionId: string
  growthRatePercent: number
  dimensionWiseMetering: DimensionWiseMetering[]
}

export interface Meterings {
  meterings: Metering[]
}

type actionType = {
  type: meteringsActionTypes.SET_BUYER_METERINGS
  payload: Meterings
}

const initialState: Meterings = {
  meterings: [],
}

export const reducer: Reducer<Meterings, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case meteringsActionTypes.SET_BUYER_METERINGS: {
      return {
        ...state,
        meterings: action.payload.meterings,
      }
    }
    default:
      return state
  }
}
