import { FormikErrors, FormikProps, FormikTouched } from 'formik'
import {
  Dimension,
  Installments,
  OfferRecipients,
  OfferStakeholders,
} from '../../flyout/modules/privateOffer/reducer'
import { OffersWithErrors } from '../../flyout/modules/privateOffer/privateOfferCreation/reducer'
import { get, isEmpty } from 'lodash'

export const checkIfFormikTouchedArrayHasErr = (
  formikProps: FormikProps<Record<string, unknown>>,
  key: string
) => {
  const { touched, errors } = formikProps
  return (
    (get(touched, key, []) as FormikTouched<Record<string, boolean>>[]).length >
      0 && get(errors, key, []).length > 0
  )
}

export const checkIsErrOnOfferInstallments = (
  touchedObject: FormikTouched<Installments>,
  idx: number,
  errorObject?: FormikErrors<Installments>[]
) => {
  return !!(
    (get(touchedObject, 'amount') &&
      !isEmpty(get(errorObject, `[${idx}].amount`))) ||
    (get(touchedObject, 'paymentDate') &&
      !isEmpty(get(errorObject, `[${idx}].paymentDate`)))
  )
}

export const checkIsErrOnOfferDimensions = (
  touchedObject: FormikTouched<Dimension>,
  idx: number,
  errorObject?: FormikErrors<Dimension>[]
) => {
  return !!(
    (get(touchedObject, 'price') &&
      !isEmpty(get(errorObject, `[${idx}].price`))) ||
    (get(touchedObject, 'quantity') &&
      !isEmpty(get(errorObject, `[${idx}].quantity`))) ||
    (get(touchedObject, 'name') &&
      !isEmpty(get(errorObject, `[${idx}].name`))) ||
    (get(touchedObject, 'labelDescription') &&
      !isEmpty(get(errorObject, `[${idx}].labelDescription`))) ||
    (get(touchedObject, 'label') &&
      !isEmpty(get(errorObject, `[${idx}].label`)))
  )
}

export const isSignleFieldsHaveErr = (
  singleFieldArray: string[],
  errors: FormikErrors<OffersWithErrors>,
  touched: FormikTouched<OffersWithErrors>
) => {
  return singleFieldArray.some(
    singleFieldItem =>
      get(touched, singleFieldItem) && !isEmpty(get(errors, singleFieldItem))
  )
}

export const checkIsErrOnRecipientOrStakeHolder = (
  touchedObject: FormikTouched<OfferStakeholders | OfferRecipients>,
  idx: number,
  errorObject?: FormikErrors<OfferStakeholders | OfferRecipients>[]
) => {
  return !!(
    (get(touchedObject, 'firstName') &&
      !isEmpty(get(errorObject, `[${idx}].firstName`))) ||
    (get(touchedObject, 'lastName') &&
      !isEmpty(get(errorObject, `[${idx}].lastName`))) ||
    (get(touchedObject, 'email') &&
      !isEmpty(get(errorObject, `[${idx}].email`)))
  )
}
