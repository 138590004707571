import { Typography } from '@material-ui/core'
import React, { ReactNode } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faChevronLeft } from '@fortawesome/pro-regular-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './HeaderRoutingWrapper.styles'
import clsx from 'clsx'
import { ButtonV2 } from '@labrav/react-components'
import { CustomNavButtonProp } from '../RoutingApp/RoutingApp'
import { useFlag } from '@labrav/flags'

type HeaderRoutingAppProps = {
  title?: string | ReactNode
  titleIconOrBadge?: React.FC
  actions?: React.FC
  pageYOffset?: number
  hideTitle?: boolean
  subHeading?: string
  isBack?: boolean
  customNavButton?: CustomNavButtonProp
  onBack?: () => void
  isExternal?: boolean
}

const HeaderRoutingApp: React.FC<HeaderRoutingAppProps> = ({
  title: Title,
  titleIconOrBadge: TitleIconOrBadge,
  actions: Actions,
  hideTitle = false,
  subHeading,
  isBack = false,
  customNavButton,
  onBack,
  isExternal,
}) => {
  const { flag: isLabraUiRevampEnable } = useFlag('labraUiRevamp')
  const classes = useStyles(
    customNavButton?.shouldShowCustomNavButton as boolean,
    isExternal,
    isLabraUiRevampEnable?.value
  )()
  const navigate = useNavigate()
  return (
    <>
      {!hideTitle && (
        <div className={clsx(classes.headerContainer, classes.stickyContainer)}>
          <div className={classes.appTitle}>
            {Title ? (
              <div className={classes.appTitleHeading}>
                {!isBack ? (
                  <></>
                ) : (
                  (isBack || isLabraUiRevampEnable?.value) && (
                    <div
                      className={classes.appBackIcon}
                      data-testid={'back-button'}
                      onClick={() => (onBack ? onBack() : navigate(-1))}
                    >
                      {' '}
                      <FontAwesomeIcon
                        icon={faArrowLeft}
                        style={{
                          width: '24px',
                          height: '24px',
                          fontSize: '24px',
                          color: '#1942C5',
                        }}
                      />
                    </div>
                  )
                )}
                <div
                  className={`${
                    customNavButton?.shouldShowCustomNavButton
                      ? classes.customNavButton
                      : ''
                  }`}
                >
                  {customNavButton?.shouldShowCustomNavButton &&
                    !isLabraUiRevampEnable.value && (
                      <div>
                        <ButtonV2
                          styleType="link"
                          onClick={() =>
                            navigate(customNavButton.link, { replace: true })
                          }
                          data-testid="add-a-installments-button"
                          icon={
                            <FontAwesomeIcon
                              icon={faChevronLeft}
                              width={'12px'}
                              height={'14px'}
                            />
                          }
                          smallVariant
                        >
                          {customNavButton.ButtonName}
                        </ButtonV2>
                      </div>
                    )}
                  <Typography
                    variant="h1"
                    data-testid="heading"
                    className={classes.appTitleHeadingText}
                  >
                    {Title}
                  </Typography>
                </div>
                {TitleIconOrBadge ? <TitleIconOrBadge /> : <></>}
              </div>
            ) : null}
            <div data-testid="actions" className={classes.action}>
              {Actions ? <Actions /> : null}
            </div>
          </div>
          {subHeading && (
            <Typography
              data-testid="subHeading-wrapper"
              className={classes.subTitleText}
            >
              {subHeading}
            </Typography>
          )}
        </div>
      )}
    </>
  )
}

export default HeaderRoutingApp
