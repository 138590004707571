import React, { useState } from 'react'
import { SidePanelInfo } from '@labrav/react-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { useStyles } from './CoSellSidePanelDrawer.styles'

export interface CoSellSidePanelDrawerProps {
  open: boolean
  notificationType: string
  notificationDescription: string
  handleClose: () => void
  image?: React.ReactNode
}
export const CoSellSidePanelDrawer = React.memo(
  ({
    open,
    notificationType,
    notificationDescription,
    handleClose,
    image,
  }: CoSellSidePanelDrawerProps) => {
    const classes = useStyles()

    return (
      <>
        <div data-testid="co-sell-side-panel-drawer">
          <SidePanelInfo
            icon={
              <FontAwesomeIcon
                icon={faCircleInfo}
                style={{ width: '24px', height: '24px', fontSize: '24px' }}
              />
            }
            headerTitle={
              <div className={classes.headerTitle}>
                Notification information
              </div>
            }
            open={open}
            onClose={handleClose}
            content={
              <div className={classes.root}>
                <div data-testid="nottification-type-container">
                  <div className={classes.notificationTypeHeader}>
                    Notification type
                  </div>
                  <div className={classes.notificationTypeDescription}>
                    {notificationType}
                  </div>
                </div>

                <div data-testid="description-container">
                  <div className={classes.notificationTypeHeader}>
                    Description
                  </div>
                  <div className={classes.notificationTypeDescription}>
                    {notificationDescription}
                  </div>
                </div>

                <div data-testid="email-preview-container">
                  <div className={classes.notificationTypeHeader}>
                    Sample email notification preview
                  </div>
                  <div className={classes.emailPreviewImageContainer}>
                    {image}
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </>
    )
  }
)
