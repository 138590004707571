import { Reducer } from 'redux'

export enum CloudfaasFTRReviewFormSectionName {
  ASSESSMENT_DETAILS = 'assessmentDetails',
  PARTNER_HOSTED = 'partnerHosted',
  SUPPORT_LEVEL = 'supportLevel',
  ARCHITECTURE_REVIEW = 'architectureReview',
  ALTERNATIVE_REVIEW = 'alternativeReview',
  AWS_ROOT_ACCOUNT = 'awsRootAccount',
  COMMUNICATION_FROM_AWS = 'communicationFromAws',
  IDENTITY_AND_ACCESS_MANAGEMENT = 'identityAndAccessMgmt',
  OPERATIONAL_SECURITY = 'operationalSecurity',
  NETWORK_SECURITY = 'networkSecurity',
  BACKUPS_AND_RECOVERY = 'backupsAndRecovery',
  RESILIENCY = 'resiliency',
  AMAZON_S3_BUCKET_ACCESS = 'amazonS3BucketAccess',
  CROSS_ACCOUNT_ACCESS = 'crossAccountAccess',
  SENSITIVE_DATA = 'sensitiveData',
  REGULATORY_COMPLIANCE_VALIDATION_PROCESS = 'regulatoryComplianceValidationProcess',
  LOCK_METADATA = 'lockMetadata',
  PAGE_MARKER = 'pageMarker',
}

export interface CloudfaasFormAssessmentDetails {
  name: string
  workloadType?: string
  workloadName?: string
  productRunOn?: string
  solutionHostedOn?: string
  assessmentType?: string
  assessmentId?: string
}

export interface CloudfaasFormPartnerHosted {
  partnerHostedQue: string
  partnerHostedCompList: string
}

export interface CloudfaasFormSupportLevel {
  supportLevelQue: string
  supportLeveActionPlanList: string
}

export interface CloudfaasFormArchitectureReview {
  wafr001Que: string
  wafr0011Que: string
  wafr0012Que: string
  wafr002Que: string
  wafr0021Que: string
}

export interface CloudfaasFormAlternativeReview {
  alternativeReviewQue: string
}

export interface CloudfaasFormAWSRootAccount {
  awsRootAccountUsed: string
  mfaEnabledRootUser: string
  mfaMethod: string
  accessKeys: string
  documentProcess: string
}

export interface CloudfaasFormCommunicationFromAWS {
  alternateContactsConfigured: string
  configuredToUseCompanyEmail: string
}

export interface CloudfaasFormIdentityAndAccessMgmt {
  mfaEnabledForHumanUsers: string
  accessKeysRotated: string
  staticKeysList: string
  monitoringEnabled: string
  revokingCredentials: string
  strongPasswordPolicy: string
  uniqueCredentials: string
  accessToProdWorkload: string
  tempOrStaticCreds: string
  leastPrivilegePrincipleFollowed: string
  changePolicy: string
  auditConducted: string
  hardCodedCreds: string
  secretMgmtTools: string
  encryptCreds: string
}

export interface CloudfaasFormOperationalSecurity {
  operationalSecurityQue: string
}

export interface CloudfaasFormNetworkSecurity {
  restrictTraffic: string
  allowIngress: string
  hostedInPrivateSubnet: string
}

export interface CloudfaasFormBackupAndRecovery {
  automaticBackup: string
  periodicRecovery: string
  testDataRecovery: string
}

export interface CloudfaasFormResiliency {
  rpoDefined: string
  establishRPO: string
  resiliencyTesting: string
  documentationAvailable: string
  slaDefined: string
  slaSupported: string
  customerCommunicationPlan: string
}

export interface CloudfaasFormAmazonS3BucketAccess {
  amazonS3BucketAccessQue: string
}

export interface CloudfaasFormCrossAccountAccess {
  customerAwsAccount: string
  crossAccountRole: string
  provideGuidance: string
  externalIdUsed: string
  generateValuesForExternalId: string
  uniqueExternalAccounts: string
  readOnlyAccess: string
  historicalUse: string
}

export interface CloudfaasFormSensitiveData {
  storePIIData: string
  reviewProcess: string
  dataEncryptionAtRest: string
  protocolForEncryption: string
}

export interface CloudfaasFormRegulatoryComplianceValProcess {
  complianceStandards: string
  compliantWithStandards: string
}
export type pageMarkerType = {
  [x: string]: boolean
}

export interface LockMetadata {
  lockedBy?: string
  lastActivityAt?: string
  lockAcquisitionProbe?: boolean
  windowId?: string
  lockedByName?: string
  editingEnabled?: boolean
  lockByEmail?: string
}

export type CloudfaasFTRReviewFormSections = {
  [CloudfaasFTRReviewFormSectionName.ASSESSMENT_DETAILS]: CloudfaasFormAssessmentDetails
  [CloudfaasFTRReviewFormSectionName.PARTNER_HOSTED]: CloudfaasFormPartnerHosted
  [CloudfaasFTRReviewFormSectionName.SUPPORT_LEVEL]: CloudfaasFormSupportLevel
  [CloudfaasFTRReviewFormSectionName.ARCHITECTURE_REVIEW]: CloudfaasFormArchitectureReview
  [CloudfaasFTRReviewFormSectionName.ALTERNATIVE_REVIEW]: CloudfaasFormAlternativeReview
  [CloudfaasFTRReviewFormSectionName.AWS_ROOT_ACCOUNT]: CloudfaasFormAWSRootAccount
  [CloudfaasFTRReviewFormSectionName.COMMUNICATION_FROM_AWS]: CloudfaasFormCommunicationFromAWS
  [CloudfaasFTRReviewFormSectionName.IDENTITY_AND_ACCESS_MANAGEMENT]: CloudfaasFormIdentityAndAccessMgmt
  [CloudfaasFTRReviewFormSectionName.OPERATIONAL_SECURITY]: CloudfaasFormOperationalSecurity
  [CloudfaasFTRReviewFormSectionName.NETWORK_SECURITY]: CloudfaasFormNetworkSecurity
  [CloudfaasFTRReviewFormSectionName.BACKUPS_AND_RECOVERY]: CloudfaasFormBackupAndRecovery
  [CloudfaasFTRReviewFormSectionName.RESILIENCY]: CloudfaasFormResiliency
  [CloudfaasFTRReviewFormSectionName.AMAZON_S3_BUCKET_ACCESS]: CloudfaasFormAmazonS3BucketAccess
  [CloudfaasFTRReviewFormSectionName.CROSS_ACCOUNT_ACCESS]: CloudfaasFormCrossAccountAccess
  [CloudfaasFTRReviewFormSectionName.SENSITIVE_DATA]: CloudfaasFormSensitiveData
  [CloudfaasFTRReviewFormSectionName.REGULATORY_COMPLIANCE_VALIDATION_PROCESS]: CloudfaasFormRegulatoryComplianceValProcess
  [CloudfaasFTRReviewFormSectionName.PAGE_MARKER]: pageMarkerType
  [CloudfaasFTRReviewFormSectionName.LOCK_METADATA]: LockMetadata
}

export enum CloudfaasFTRReviewForm {
  SET_CLOUDFAAS_FTR_REVIEW_FORM = 'SET_CLOUDFAAS_FTR_REVIEW_FORM',
  SET_CLOUDFAAS_ASSESSMENT_DETAIL_FORM = 'SET_CLOUDFAAS_ASSESSMENT_DETAIL_FORM',
  UPDATE_CLOUDFAAS_FTR_REVIEW_FORM = 'UPDATE_CLOUDFAAS_FTR_REVIEW_FORM',
  SET_CLOUDFAAS_ASSESSMENT_TYPE = 'SET_CLOUDFAAS_ASSESSMENT_TYPE',
  SET_ERROR_ON_FINAL_SUBMIT = 'SET_ERROR_ON_FINAL_SUBMIT',
  CLEAR_CLOUDFAAS_FTR_REVIEW_FORM = 'CLEAR_CLOUDFAAS_FTR_REVIEW_FORM',
  SET_DEFAULT_ASSESSMENT_INFO = 'SET_DEFAULT_ASSESSMENT_INFO',
  SET_CLOUDFAAS_ASSESSMENT_ID = 'SET_CLOUDFAAS_ASSESSMENT_ID',
  CLEAR_CLOUDFAAS_ASSESSMENT_ID = 'CLEAR_CLOUDFAAS_ASSESSMENT_ID',
}

export interface FTRReviewDetail {
  FTRReviewForm: CloudfaasFTRReviewFormSections
  assessmentType?: string
  assessmentId?: string
  status: string
  edit?: boolean
  error?: string
}

export type FTRReviewFormChangeType = {
  sectionName: CloudfaasFTRReviewFormSectionName
  keyName: string
  value: string | boolean
}

export interface FTRReviewDefaultInfo {
  [CloudfaasFTRReviewFormSectionName.ASSESSMENT_DETAILS]: CloudfaasFormAssessmentDetails
}

type actionType =
  | {
      type: CloudfaasFTRReviewForm.SET_CLOUDFAAS_FTR_REVIEW_FORM
      payload: CloudfaasFTRReviewFormSections
    }
  | {
      type: CloudfaasFTRReviewForm.SET_CLOUDFAAS_ASSESSMENT_DETAIL_FORM
      payload: FTRReviewDetail
    }
  | {
      type: CloudfaasFTRReviewForm.UPDATE_CLOUDFAAS_FTR_REVIEW_FORM
      payload: FTRReviewFormChangeType
    }
  | {
      type: CloudfaasFTRReviewForm.SET_CLOUDFAAS_ASSESSMENT_TYPE
      payload: string
    }
  | {
      type: CloudfaasFTRReviewForm.SET_CLOUDFAAS_ASSESSMENT_ID
      payload: string
    }
  | { type: CloudfaasFTRReviewForm.SET_ERROR_ON_FINAL_SUBMIT; payload: string }
  | { type: CloudfaasFTRReviewForm.CLEAR_CLOUDFAAS_FTR_REVIEW_FORM }
  | { type: CloudfaasFTRReviewForm.CLEAR_CLOUDFAAS_ASSESSMENT_ID }
  | {
      type: CloudfaasFTRReviewForm.SET_DEFAULT_ASSESSMENT_INFO
      payload: FTRReviewDefaultInfo
    }

export interface FTRReviewObjectDetail {
  FTRReviewForm: CloudfaasFTRReviewFormSections
  assessmentType?: string
  assessmentId?: string
  status: string
  edit?: boolean
  error?: string
}

export interface FTRReviewObjectInCreation {
  FTRReviewForm: CloudfaasFTRReviewFormSections
  assessmentType: string
  assessmentId: string
  errorOnSubmit: string
  error?: string
}

export interface FTRReviewInCreation {
  currentFTRReviewInCreation: FTRReviewObjectInCreation
  currentFTRReviewDetail: FTRReviewObjectDetail
  FTRReviewDefaultInfo?: FTRReviewDefaultInfo
}

const initialState: FTRReviewInCreation = {
  currentFTRReviewInCreation: {
    FTRReviewForm: {} as CloudfaasFTRReviewFormSections,
    assessmentType: 'FTR',
    errorOnSubmit: '',
    assessmentId: '',
  },
  currentFTRReviewDetail: {
    FTRReviewForm: {} as CloudfaasFTRReviewFormSections,
    assessmentType: 'FTR',
    status: '',
    edit: true,
    error: '',
  },
}

export const reducer: Reducer<FTRReviewInCreation, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CloudfaasFTRReviewForm.SET_CLOUDFAAS_FTR_REVIEW_FORM:
      return {
        ...state,
        currentFTRReviewInCreation: {
          ...state.currentFTRReviewInCreation,
          FTRReviewForm: action.payload,
        },
      }
    case CloudfaasFTRReviewForm.SET_CLOUDFAAS_ASSESSMENT_DETAIL_FORM:
      return {
        ...state,
        currentFTRReviewDetail: action.payload,
      }
    case CloudfaasFTRReviewForm.CLEAR_CLOUDFAAS_FTR_REVIEW_FORM:
      return {
        ...state,
        currentFTRReviewDetail: initialState.currentFTRReviewDetail,
      }
    case CloudfaasFTRReviewForm.UPDATE_CLOUDFAAS_FTR_REVIEW_FORM: {
      const { sectionName, keyName, value } = action.payload
      return {
        ...state,
        currentFTRReviewInCreation: {
          ...state.currentFTRReviewInCreation,
          FTRReviewForm: {
            ...state.currentFTRReviewInCreation.FTRReviewForm,
            [sectionName]: {
              ...state.currentFTRReviewInCreation.FTRReviewForm[sectionName],
              [keyName]: value,
            },
          },
        },
      }
    }
    case CloudfaasFTRReviewForm.SET_CLOUDFAAS_ASSESSMENT_TYPE:
      return {
        ...state,
        currentFTRReviewInCreation: {
          ...state.currentFTRReviewInCreation,
          assessmentType: 'FTR',
        },
      }
    case CloudfaasFTRReviewForm.SET_CLOUDFAAS_ASSESSMENT_ID:
      return {
        ...state,
        currentFTRReviewInCreation: {
          ...state.currentFTRReviewInCreation,
          assessmentType: 'FTR',
          assessmentId: action.payload,
        },
      }
    case CloudfaasFTRReviewForm.CLEAR_CLOUDFAAS_ASSESSMENT_ID: {
      return {
        ...state,
        currentProductDetail: initialState.currentFTRReviewInCreation,
      }
    }
    case CloudfaasFTRReviewForm.SET_ERROR_ON_FINAL_SUBMIT:
      return {
        ...state,
        currentFTRReviewInCreation: {
          ...state.currentFTRReviewInCreation,
          errorOnSubmit: action.payload,
        },
      }
    case CloudfaasFTRReviewForm.SET_DEFAULT_ASSESSMENT_INFO:
      return {
        ...state,
        FTRReviewDefaultInfo: action.payload,
      }
    default:
      return state
  }
}
