import React, { useEffect, useState } from 'react'
import {
  BoxV2,
  ExternalLink,
  ExternalLinkButton,
} from '@labrav/react-components'
import { Typography } from '@material-ui/core'
import { HelpDrawer } from '../../../../../../common/components/HelpDrawerV2/HelpDrawer'
import { useStyles as iamPolicyGenerationSectionStyles } from './IamPolicyGenerationSection.styles'
import { useStyles as commonStyles } from '../CloudSettingsWrapper.styles'
import { FormikProps, useFormikContext } from 'formik'
import {
  AwsCloudSettingsSections,
  AwsCloudSettingsSectionsProps,
} from '../../types'
import { NextSectionButton } from '../NextSectionButton/NextSectionButton'
import StepStatusIcon from '../StepStatusIcon/StepStatusIcon'
import WhatAreYouDoingHereData from '../../../../../../mocks/whatAreYouDoingHere.json'
import { IamPolicyGeneration } from './IamPolicyGeneration/IamPolicyGeneration'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons'
import Persona from '../Persona/Persona'

type IamPolicyGenerationSection = {
  isOnboardingCompleted?: boolean
}
export const IamPolicyGenerationSection: React.FC<
  IamPolicyGenerationSection
> = ({ isOnboardingCompleted }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const classes = iamPolicyGenerationSectionStyles()
  const commonClasses = commonStyles()
  const handleThumnailClick = () => {
    const newWind = window.open(
      WhatAreYouDoingHereData.coSellGeneratingIAMPolicy.video,
      '_blank',
      'noopener noreferrer'
    )
    if (newWind) {
      newWind.opener = null
    }
  }

  const handleReadMore = () => {
    setIsExpanded(!isExpanded)
  }

  const testConnectionStatus = 'success'
  useEffect(() => {
    updateCloudSettings()
  }, [testConnectionStatus])
  const updateCloudSettings = async () => {
    ;() => {}
  }

  return (
    <BoxV2
      heading="Generating IAM policies"
      subHeading={
        <Typography className={commonClasses.subHeading}>
          Generate the policy for the AWS-owned S3 bucket using Partner Central.
          Once the policy is generated, add it below by clicking the{' '}
          {!isExpanded ? (
            <>
              <span
                //TODO: update to use component from https://ibexlabs.atlassian.net/browse/LT-10058
                className={commonClasses.readmore}
                onClick={handleReadMore}
              >
                ...read more
              </span>
              .
            </>
          ) : (
            <>
              'Add Policy' button. For detailed guidance on the S3 bucket and
              step-by-step instructions for creating the policy through Partner
              Central, refer to the{' '}
              <ExternalLink href="#">Help section</ExternalLink>.
            </>
          )}
        </Typography>
      }
      actions={
        <>
          <div
            className={commonClasses.personaSection}
            data-testid="persona-section"
          >
            <Persona memberType="allianceLead" />
          </div>
        </>
      }
      headingIcon={
        <>
          <StepStatusIcon
            sectionName={AwsCloudSettingsSections.GENERATE_POLICIES}
          />
          {!isOnboardingCompleted && (
            <Typography className={commonClasses.loadingText}>
              STEP 05
            </Typography>
          )}
        </>
      }
      // TODO: TO BE ADDED IN ACE API ONBOARDING PHASE-2
      // secondaryActions={
      //   <HelpDrawer
      //     sectionName="oppsyncCloudAccountDetails"
      //     headerTitle="Help"
      //   />
      // }
      variant={'small'}
      headingVariant={'small'}
      collapsible
    >
      <div
        className={classes.mainContainer}
        data-testid="iam-policy-generation-main-container"
      >
        <div className={commonClasses.buttonSection}>
          <div>
            <ExternalLinkButton
              buttonTitle={'Generate IAM Policies'}
              href={
                'https://partnercentral.awspartner.com/partnercentral2/s/acecrmintegration'
              }
              modalTitleAcceptButton="Open window"
              modalTitleCancleButton="Back"
              modalTitle="You are being re-directed to another window"
              modalIcon={
                <FontAwesomeIcon
                  icon={faUpRightFromSquare}
                  className={commonClasses.externalLinkIcon}
                />
              }
              modalContent={
                <>
                  <div className={commonClasses.modalContentContainer}>
                    <Typography className={commonClasses.modalDescription}>
                      You are being redirected to the AWS Partner Central window
                      to generate the policy for the AWS-owned S3 bucket. <br />{' '}
                      To complete this process, you must have Partner Central
                      access as an Alliance lead. Once the policies are
                      generated, add them in Step 5 for each environment—Staging
                      and Production respectively.
                    </Typography>
                  </div>
                </>
              }
            />
          </div>
        </div>
        <div className={classes.primaryContainer}>
          <div data-testid="left" className={commonClasses.leftContainer}>
            <IamPolicyGeneration
              env="staging"
              isOnboardingCompleted={isOnboardingCompleted ? true : false}
            />
            <IamPolicyGeneration
              env="production"
              isOnboardingCompleted={isOnboardingCompleted ? true : false}
            />
          </div>

          {/* TODO: This will be added in Phase-2 */}
          {/* <div className={commonClasses.verticalDivider}></div>
          <div className={classes.videoWrapper}>
            <div
              className={classes.videoContainer}
              data-testid="video-cloud-settings-details"
            >
              <div className={classes.thumbnailWrapper}>
                <img
                  className={classes.thumbnailImages}
                  src={GeneratingIAMPolicy}
                  title="Generating IAM policies"
                  alt={'co-sell-cloud-settings'}
                  onClick={handleThumnailClick}
                />
                <FontAwesomeIcon
                  icon={faCirclePlay}
                  className={classes.playButton}
                  onClick={handleThumnailClick}
                />
              </div>
            </div>
            <div data-testid="video-link-cloud-settings">
              <ExternalLink
                href={WhatAreYouDoingHereData.coSellGeneratingIAMPolicy.video}
              >
                {WhatAreYouDoingHereData.coSellGeneratingIAMPolicy.linkText}
              </ExternalLink>
            </div>
          </div> */}
        </div>
        <div>
          {!isOnboardingCompleted && (
            <>
              <NextSectionButton
                sectionName={AwsCloudSettingsSections.GENERATE_POLICIES}
              />
            </>
          )}
        </div>
      </div>
    </BoxV2>
  )
}
