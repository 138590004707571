import { faExternalLinkAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LabraToolTipV2 } from '@labrav/react-components'
import React from 'react'
import { useStyles } from './NoOptionsCustomForAutoComplete.styles'
export interface RenderNoCustomOptionsProps {
  handleExternalIcon: () => void
  showLink: boolean
}
export const NoOptionsCustomForAutoComplete: React.FC<
  RenderNoCustomOptionsProps
> = ({ handleExternalIcon, showLink }) => {
  const classes = useStyles()
  const channelText = showLink ? 'No channels found' : 'No channel found'
  return (
    <div className={classes.noOptionsTextContainer}>
      <div className={classes.noOptionsText}>{channelText}</div>
      {showLink ? (
        <div className={classes.noOptionsButton}>
          <div className={classes.text}>Link Slack channels</div>
          <LabraToolTipV2
            tooltipPlacement="top"
            description={
              'To enable Slack channels, link Slack with the Labra platform.'
            }
          >
            <FontAwesomeIcon
              className={classes.externalIcon}
              icon={faExternalLinkAlt}
              onClick={handleExternalIcon}
              data-testid="external-icon"
            />
          </LabraToolTipV2>
        </div>
      ) : null}
    </div>
  )
}
