import { EngineServicePartnerActionTypes } from './actionTypes'
import { PartnerData } from '../../../common/modules/partner/action'

export interface EngineServicePartnerState {
  engineServicePartnerData: PartnerData | null
}

const initialState: EngineServicePartnerState = {
  engineServicePartnerData: null,
}

export const engineServicePartnerReducer = (
  state = initialState,
  action: { type: EngineServicePartnerActionTypes; payload: any }
): EngineServicePartnerState => {
  switch (action.type) {
    case EngineServicePartnerActionTypes.SET_ENGINE_SERVICE_PARTNER_DATA:
      return {
        ...state,
        engineServicePartnerData: {
          ...(state.engineServicePartnerData || {}),
          ...action.payload,
        },
      }
    default:
      return state
  }
}
