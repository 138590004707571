import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  environmentsSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  extraGap: {
    marginBottom: theme.spacing(4),
  },
  inputField: {
    width: theme.spacing(59.37),
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: theme.palette.labraText.tertiary,
    fontFamily: theme.typography.fontFamily,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  iamPolicyButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1),
  },
  checkCircle: {
    color: theme.palette.textBackground.dark,
    '&.MuiSvgIcon-root': {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      flexShrink: 0,
      fill: 'currentColor',
      userSelect: 'none',
    },
  },
  heading: {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: theme.palette.labraText.primary,
    fontFamily: theme.typography.fontFamily,
  },
  verticalDivider: {
    width: theme.spacing(0.125),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: theme.spacing(0.5),
    background: theme.palette.dividerV2.main,
  },
  addButton: {
    width: 'fit-content',
  },
  description: {
    marginBottom: theme.spacing(2),
    fontFamily: theme.typography.fontFamily,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    color: theme.palette.labraText.description,
  },
  contentContainer: {
    marginTop: theme.spacing(-3),
  },
  editIcon: {
    height: theme.spacing(2),
    weight: theme.spacing(2),
  },
  errorColor: {
    color: theme.palette.error.dark,
  },
}))
