import { Dispatch } from 'redux'
import { RootState } from '../../../store'

import { get } from 'lodash'
import { camelize } from 'casing'
import { TestConnectionStatusType } from '../../../flyout/modules/flyOutOnboarding/reducer'
import { getArnConnectionStatus } from '../../api'
import { errorLogger } from '../../../common/utils/errorLogger'

export const coSellTestConnections =
  (partnerId: string, arn: string, environment: string) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      const data = await getArnConnectionStatus(partnerId, arn, environment)
      const statusCode = data.aceApisConnection.code
      const statusValue: TestConnectionStatusType = 'failure'
      if (statusCode === 200) {
        return {
          status: 'success',
        }
      } else {
        return {
          status: 'failure',
          error: data.aceApisConnection.message,
        }
      }
    } catch (error) {
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      return {
        status: 'failure',
        error: 'Unexpected error, cannot get connection status.',
      }
    }
  }
