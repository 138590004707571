import { useLocation } from 'react-router-dom'
import { FlyoutFunction } from '../../../flyout/modules/productListingFilter/reducer'

export const getFlyOutFunctionTypeBasedOnUrl = () => {
  const { pathname } = useLocation()
  if (pathname.includes('private-offers')) {
    return FlyoutFunction.PRIVATE_OFFERS
  } else if (pathname.includes('resale-authorizations')) {
    return FlyoutFunction.RESALE_AUTHORIZATIONS
  } else if (pathname.includes('revenue')) {
    return FlyoutFunction.REVENUE
  } else if (pathname.includes('subscriptions')) {
    return FlyoutFunction.SUBSCRIPTION
  } else {
    return ''
  }
}
