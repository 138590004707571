import { AnyAction, Dispatch } from 'redux'
import { getErrorMessages } from '../../../common/utils/error'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { errorLogger } from '../../../common/utils/errorLogger'
import { RootState } from '../../../store'
import { UsageDataState } from './reducer'
import usageMeteringMockData from '../../../mocks/usageMeteringMockData.json'
import { camelize } from 'casing'
import FileDownload from 'js-file-download'
import { fetchMeteringReport } from '../../api/markeplace'

export enum UsageMeteringActionTypes {
  SET_USAGE_METERING_DATA = 'SET_USAGE_METERING_DATA',
  CLEAR_USAGE_METERING_DATA = 'CLEAR_USAGE_METERING_DATA',
}

export const getUsageData =
  () => async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))
    await dispatch(clearUsageMeteringData())
    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const response = camelize(usageMeteringMockData)
      await dispatch(setUsageMeteringData(response))
      await dispatch(
        updateAppAlert({
          message: '',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
export const setUsageMeteringData = (usageData: UsageDataState) => {
  return {
    type: UsageMeteringActionTypes.SET_USAGE_METERING_DATA,
    payload: usageData,
  }
}
export const clearUsageMeteringData = () =>
  ({
    type: UsageMeteringActionTypes.CLEAR_USAGE_METERING_DATA,
  } as unknown as AnyAction)

export const getMeteringReportFile =
  (
    fromDate: string,
    toDate: string,
    cloudProductId: string,
    cloudProvider: string
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.MARKETPLACE_INFO))

    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      const response = await fetchMeteringReport({
        partnerId,
        fromDate,
        toDate,
        cloudProductId,
      })

      const filename = `metering_history_${cloudProvider}_${cloudProductId}_${fromDate}_${toDate}.csv`
      FileDownload(response.data, filename)

      dispatch(
        updateAppAlert({
          message: 'Metering history downloaded.',
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      throw error
    } finally {
      await dispatch(stopLoading(LoadingTypes.MARKETPLACE_INFO))
    }
  }
