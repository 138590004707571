import { Reducer } from 'redux'

export interface Links {
  self: string
  nextPage?: string
  previousPage?: string
  type?: string
}

export interface Assessment {
  id: string
  name: string
  description: string
  createdAt: string
  updatedAt: string
  status: string
  staffId?: string
  solutionHostedOn: string
  partnerId: string
  assessmentTypeId: string
  progressBarPercentage: number
  productRunOn: string
  answered: number
  unanswered: number
  flagged: number
  workloadName?: string
  workloadType?: string
}

export interface AssessmentsState {
  rows_per_size: number
  assessments: Assessment[]
  selectedAssessment?: Assessment
  loading: boolean
  error?: string
  pageNumber: number
  rowsPerSize: number
  totalNumberOfPages: number
  totalAssessmentsCount: number
  links: Links
  hasMore?: boolean
}

export const initialState: AssessmentsState = {
  assessments: [],
  selectedAssessment: {} as Assessment,
  loading: false,
  error: undefined,
  pageNumber: 1,
  rowsPerSize: 10,
  totalNumberOfPages: 0,
  totalAssessmentsCount: 0,
  links: {
    self: '',
  },
  rows_per_size: 0,
  hasMore: true,
}

export enum AssessmentsActionTypes {
  SET_ASSESSMENTS = 'SET_ASSESSMENTS',
  SET_SELECTED_ASSESSMENT = 'SET_SELECTED_ASSESSMENT',
  CLEAR_ASSESSMENTS = 'CLEAR_ASSESSMENTS',
  CLEAR_SELECTED_ASSESSMENT = 'CLEAR_SELECTED_ASSESSMENT',
  SET_ERROR = 'SET_ERROR',
}

type actionType =
  | {
      type: AssessmentsActionTypes.SET_ASSESSMENTS
      payload: AssessmentsState
    }
  | {
      type: AssessmentsActionTypes.SET_SELECTED_ASSESSMENT
      payload: Assessment
    }
  | {
      type: AssessmentsActionTypes.CLEAR_ASSESSMENTS
    }
  | {
      type: AssessmentsActionTypes.CLEAR_SELECTED_ASSESSMENT
    }
  | {
      type: AssessmentsActionTypes.SET_ERROR
      payload: string
    }

export const reducer: Reducer<AssessmentsState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case AssessmentsActionTypes.SET_ERROR: {
      return {
        ...state,
        error: action.payload,
      }
    }
    case AssessmentsActionTypes.SET_ASSESSMENTS: {
      return {
        ...state,
        assessments:
          action.payload.pageNumber === 1
            ? action.payload.assessments
            : [...state.assessments, ...action.payload.assessments],
        pageNumber: action?.payload?.pageNumber + 1,
        hasMore: action.payload.assessments.length > 0,
        rowsPerSize: action.payload.rowsPerSize,
        totalNumberOfPages: action.payload.totalNumberOfPages,
        totalAssessmentsCount: action.payload.totalAssessmentsCount,
        links: action.payload.links,
      }
    }
    case AssessmentsActionTypes.SET_SELECTED_ASSESSMENT: {
      return {
        ...state,
        selectedAssessment: action.payload,
      }
    }
    case AssessmentsActionTypes.CLEAR_SELECTED_ASSESSMENT: {
      return {
        ...state,
        selectedAssessment: {} as Assessment,
      }
    }
    case AssessmentsActionTypes.CLEAR_ASSESSMENTS: {
      return {
        ...state,
        assessments: [],
        pageNumber: 1,
        rowsPerPage: 10,
        totalNumberOfPages: 0,
        totalAssessmentsCount: 0,
        links: {
          self: '',
        },
      }
    }
    default: {
      return state
    }
  }
}
