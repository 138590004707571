import {
  AWSConfigMetaDataResponse,
  PartnerData,
  PartnerType,
  PartnershipTypeValue,
} from '../../../common/modules/partner/action'
import { RootState, AppDispatch } from '../../../store'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import {
  getPartnerDataFromEngineService,
  patchPartnerEngineService,
  patchPartnerIdentityService,
} from '../../../common/api'
import { getErrorMessages } from '../../../common/utils/error'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { AxiosError } from 'axios'
import { errorLogger } from '../../../common/utils/errorLogger'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import {
  AwsCloudSettingsSections,
  AwsCloudSettingsSectionsProps,
} from '../../pages/components/ACEApi/types'
import { get, isEmpty } from 'lodash'
import { camelize, snakeize } from 'casing'
import {
  getArnConnectionStatus,
  getPartnerSolutions,
  TestConnectionResponse,
} from '../../api'
import { coSellCloudSettingsDataToAPI } from '../../pages/components/ACEApi/CloudSettingsWrapper/CoSellCloudSettingsFooter/CoSellCloudSettingsFooter'
import { isLoading } from '../../../common/utils/loadingState'
import { EngineServicePartnerActionTypes } from './actionTypes'
import { Dispatch } from 'redux'
import { actionTypeWrapper } from '../../../common/utils/actionTypeWrapper'

export const getPartnerDataForAceApi =
  (partnerId: string, partnerType: PartnerType) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (isLoading(getState().loading)(LoadingTypes.COSELL_CLOUD_SETTINGS)) {
      console.warn(
        'getPartnerDataForAceApi: already loading, so skipping execution'
      )
      return
    }
    const savedPartnerId =
      getState().engineServicePartner[partnerType].engineServicePartnerData
        ?.partnerId
    if (savedPartnerId && savedPartnerId === partnerId) {
      console.warn(
        'getPartnerDataForAceApi: already have the partner data for the given partnerId, so skipping execution'
      )
      return
    }
    dispatch(startLoading(LoadingTypes.COSELL_CLOUD_SETTINGS))
    try {
      let partnerData =
        getState().engineServicePartner[partnerType].engineServicePartnerData
      if (partnerData === null || partnerId !== partnerData.partnerId) {
        const { data } = await getPartnerDataFromEngineService(partnerId, false)
        const { aws_config_metadata } = data
        partnerData = {
          ...camelize(data),
          awsConfigMetadata: {
            prerequisites: {
              ACEEligible:
                aws_config_metadata?.prerequisites?.ACE_eligible ?? false,
              APNEnrolled:
                aws_config_metadata?.prerequisites?.APN_enrolled ?? false,
              solutionsConfigured:
                aws_config_metadata?.prerequisites?.solutions_configured ??
                false,
            },
            steps: {
              ...camelize(aws_config_metadata.steps),
            },
            acknowledgedRequiredPersons:
              aws_config_metadata?.acknowledged_required_persons ?? false,
          },
        }
      }

      if (partnerData) {
        await dispatch(setEngineServicePartnerData(partnerData, partnerType))
      }
    } catch (err: unknown) {
      const errMess = getErrorMessages([RequestFailureMessage])(
        (err as AxiosError<ErrorResponse>).response
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
      await dispatch(
        updateAppAlert({
          message: errMess,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
    } finally {
      dispatch(stopLoading(LoadingTypes.COSELL_CLOUD_SETTINGS))
    }
  }
const snakeCasePartnerData = (partnerData: Partial<PartnerData>) => {
  const snakeizedData = snakeize(partnerData)
  let awsConfigMetadata = undefined

  if (partnerData.awsConfigMetadata) {
    awsConfigMetadata = {
      prerequisites: {
        APN_enrolled: partnerData.awsConfigMetadata.prerequisites?.APNEnrolled,
        solutions_configured:
          partnerData.awsConfigMetadata.prerequisites?.solutionsConfigured,
        ACE_eligible: partnerData.awsConfigMetadata.prerequisites?.ACEEligible,
      },
      steps: snakeizedData?.aws_config_metadata?.steps || {},
      acknowledged_required_persons:
        snakeizedData?.aws_config_metadata?.acknowledged_required_persons ||
        false,
    }
  }

  return {
    ...snakeizedData,
    ...(awsConfigMetadata !== undefined && {
      aws_config_metadata: awsConfigMetadata,
    }),
  }
}

export const updateEngineServicePartnerData =
  (
    partnerId: string,
    partnerType: PartnerType,
    transformedPartnerData: Partial<PartnerData>
  ) =>
  async (dispatch: Dispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading(LoadingTypes.COSELL_CLOUD_SETTINGS))
      const { partnershipType, ...rest } = transformedPartnerData
      await patchPartnerEngineService(partnerId, snakeCasePartnerData(rest))
      await dispatch(setEngineServicePartnerData({ ...rest }, partnerType))
      const storedPartnershipType =
        getState().PartnerData[partnerType].partnerData?.partnershipType
      if (
        !isEmpty(partnershipType) &&
        storedPartnershipType !== partnershipType
      ) {
        await patchPartnerIdentityService(partnerId, {
          partnershipType: partnershipType,
        })
      }
    } catch (err) {
      dispatch(
        updateAppAlert({
          message: RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(err as Error)
      return err
    } finally {
      dispatch(stopLoading(LoadingTypes.COSELL_CLOUD_SETTINGS))
    }
  }

export const setEngineServicePartnerData = (
  data: Partial<PartnerData>,
  partnerType: PartnerType
) =>
  actionTypeWrapper(partnerType, {
    type: EngineServicePartnerActionTypes.SET_ENGINE_SERVICE_PARTNER_DATA,
    payload: data,
  })

//   Transform the data from the API to the format that the formik form expects
export const combineTransformedData = (data: PartnerData) => {
  return {
    ...{
      [AwsCloudSettingsSections.LINK_ACCOUNT]: {
        awsAccountId: get(data, 'awsAccountId', ''),
      },
    },
    ...transformStepsFromGet(camelize(data.awsConfigMetadata)),
    ...transformAcknowledgedRequiredPersonsFromGet(
      camelize(data.awsConfigMetadata)
    ),
    ...transformAccountDetailsFromGet(data),
    ...transformAwsConfigMetadataFromGet(camelize(data.awsConfigMetadata)),
    ...transformIamRolesCreationFromGet(data),
    ...transformIamPolicyGenerationFromGet(data),
  }
}

export const transformStepsFromGet = (data: AWSConfigMetaDataResponse) => {
  return {
    [AwsCloudSettingsSections.STEPS]: {
      prerequisites: data.steps.prerequisites,
      linkAccount: data.steps.linkAccount,
      cloudDetails: data.steps.cloudDetails,
      createIamRole: data.steps.createIamRole,
      generatePolicies: data.steps.generatePolicies,
      assignPolicies: data.steps.assignPolicies,
      solutionsSavedOnce: data.steps.solutionsSavedOnce,
    },
  }
}
export const transformAcknowledgedRequiredPersonsFromGet = (
  data: AWSConfigMetaDataResponse
) => {
  return {
    [AwsCloudSettingsSections.ACKNOWLEDGED_REQUIRED_PERSONS]: {
      acknowledgedRequiredPersons: data.acknowledgedRequiredPersons,
    },
  }
}

export const transformAccountDetailsFromGet = (data: PartnerData) => {
  return {
    [AwsCloudSettingsSections.CLOUD_DETAILS]: {
      partnerServicePath: (
        get(data, 'partnershipType', []) as PartnershipTypeValue[]
      ).includes('CONSULTING_PARTNER'),
      partnerSoftwarePath: (
        get(data, 'partnershipType', []) as PartnershipTypeValue[]
      ).includes('TECHNOLOGY_PARTNER'),
      isv: data.isIsv ?? null,
      awsIsvAccelerateProgram: data.accelerateProgram ?? null,
      securityServiceSoftwareOnly: data.securityServiceSoftwareOnly ?? null,
      securityServiceManagedServices:
        data.securityServiceManagedServices ?? null,
      securityServiceProfessionalServices:
        data.securityServiceProfessionalServices ?? null,
      msspProgram: data.msspProgram ?? null,
      awsServicesPartnerTier: data.partnershipTier || null,
    },
  }
}

export const transformAwsConfigMetadataFromGet = (
  metadata: AWSConfigMetaDataResponse
) => {
  return {
    [AwsCloudSettingsSections.PREREQUISITES]: {
      isApnEnrolled: metadata.prerequisites.APNEnrolled ?? false,
      areSolutionsConfigured:
        metadata.prerequisites.solutionsConfigured ?? false,
      isAceEligible: metadata.prerequisites.ACEEligible ?? false,
    },
  }
}

export const transformIamRolesCreationFromGet = (data: PartnerData) => {
  return {
    [AwsCloudSettingsSections.CREATE_IAM_ROLE]: {
      stagingArn: data.iamArnStaging || null,
      productionArn: data.iamArn || null,
    },
  }
}
export const transformIamPolicyGenerationFromGet = (data: PartnerData) => {
  return {
    [AwsCloudSettingsSections.GENERATE_POLICIES]: {
      iamPolicyStaging: data.iamPolicyStaging || null,
      iamPolicyProduction: data.iamPolicyProduction || null,
    },
  }
}

export const saveCosellCloudSettings =
  (
    values: AwsCloudSettingsSectionsProps,
    partnerId: string,
    partnerType: PartnerType = PartnerType.User
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      dispatch(startLoading(LoadingTypes.COSELL_CLOUD_SETTINGS))
      const connectionStatus = {
        staging: {} as TestConnectionResponse,
        production: {} as TestConnectionResponse,
      }
      const stagingArn = values.createIamRole?.stagingArn
      const productionArn = values.createIamRole?.productionArn
      const isAceEligible = values.prerequisites?.isAceEligible

      // Test staging connection if ARN exists
      if (stagingArn) {
        const stagingConnectionStatus = await getArnConnectionStatus(
          partnerId,
          stagingArn,
          'staging'
        )
        connectionStatus.staging = stagingConnectionStatus
      }

      // Test production connection (always required)
      if (productionArn) {
        const productionConnectionStatus = await getArnConnectionStatus(
          partnerId,
          productionArn,
          'production'
        )
        connectionStatus.production = productionConnectionStatus
      } else {
        dispatch(
          updateAppAlert({
            message: 'Production ARN is required to save cloud settings.',
            messageType: 'ERROR',
            autoClose: false,
          })
        )
        return false
      }

      if (isAceEligible) {
        if (
          connectionStatus.production.legacyConnection.code !== 200 ||
          connectionStatus.production.legacyConnection.code !== 200 ||
          connectionStatus.production.aceApisConnection.code !== 200 ||
          connectionStatus.production.aceApisConnection.code !== 200
        ) {
          let errorMessage =
            'Failed to verify ARN connections. Please check your ARN settings and try again.'
          if (connectionStatus.production.legacyConnection.code !== 200) {
            errorMessage += `\nProduction Leads System: ${connectionStatus.production.legacyConnection.message}`
          }
          if (connectionStatus.staging.legacyConnection.code !== 200) {
            errorMessage += `\nStaging Leads System: ${connectionStatus.staging.legacyConnection.message}`
          }
          if (connectionStatus.production.aceApisConnection.code !== 200) {
            errorMessage += `\nProduction ACE APIs: ${connectionStatus.production.aceApisConnection.message}`
          }
          if (connectionStatus.staging.aceApisConnection.code !== 200) {
            errorMessage += `\nStaging ACE APIs: ${connectionStatus.staging.aceApisConnection.message}`
          }
          dispatch(
            updateAppAlert({
              message: errorMessage,
              messageType: 'ERROR',
              autoClose: false,
            })
          )
          return false
        }
      } else {
        if (
          connectionStatus.production.aceApisConnection.code !== 200 ||
          connectionStatus.staging.aceApisConnection.code !== 200
        ) {
          let errorMessage =
            'Failed to verify ARN connections. Please check your ARN settings and try again.'
          if (connectionStatus.production.aceApisConnection.code !== 200) {
            errorMessage += `\nProduction ACE APIs: ${connectionStatus.production.aceApisConnection.message}`
          }
          if (connectionStatus.staging.aceApisConnection.code !== 200) {
            errorMessage += `\nStaging ACE APIs: ${connectionStatus.staging.aceApisConnection.message}`
          }
          dispatch(
            updateAppAlert({
              message: errorMessage,
              messageType: 'ERROR',
              autoClose: false,
            })
          )
          return false
        }
      }

      const solutionsAndProducts = await getPartnerSolutions(partnerId, {
        updateCache: true,
      })

      if (isEmpty(solutionsAndProducts?.cloud_config?.aws_solutions)) {
        dispatch(
          updateAppAlert({
            message:
              'No solutions found for the partner, please add solutions on the Partner Central Portal',
            messageType: 'ERROR',
            autoClose: false,
          })
        )
        return false
      }

      // Update all steps to true
      const updatedValues = {
        ...values,
        steps: {
          ...values.steps,
          prerequisites: true,
          linkAccount: true,
          cloudDetails: true,
          createIamRole: true,
          generatePolicies: true,
          assignPolicies: true,
          solutionsSavedOnce: true,
        },
      }

      const transformedData = coSellCloudSettingsDataToAPI(updatedValues)
      await dispatch(
        updateEngineServicePartnerData(partnerId, partnerType, transformedData)
      )

      dispatch(
        updateAppAlert({
          message: 'Successfully saved cloud settings',
          messageType: 'SUCCESS',
          autoClose: false,
        })
      )
      return true
    } catch (error) {
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
      dispatch(
        updateAppAlert({
          message:
            'An error occurred while processing your request. Please try again.',
          messageType: 'ERROR',
          autoClose: false,
        })
      )
      return false
    } finally {
      dispatch(stopLoading(LoadingTypes.COSELL_CLOUD_SETTINGS))
    }
  }
