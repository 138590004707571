import React from 'react'
import { Typography } from '@material-ui/core'
import { ShortenedNameV2 } from '../../../../../../common/components/ShortenedNameV2/ShortenedNameV2'

import { Clipboard as CustomClipboard } from '@labrav/react-components'
import { useStyles } from './EnvironmentDetails.styles'

interface EnvironmentDetailsProps {
  arn: string
  envType: 'staging' | 'production'
  envPolicy: string
}

const EnvironmentDetails: React.FC<EnvironmentDetailsProps> = ({
  arn,
  envType,
  envPolicy,
}) => {
  const classes = useStyles()
  const infoHeading =
    envType === 'production'
      ? 'ACE production environment'
      : 'ACE staging environment'
  const policyName =
    envType === 'production' ? 'Production policy' : 'Staging policy'

  return (
    <div className={classes.envInfoContainer}>
      <Typography className={classes.envInfoHeading}>{infoHeading}</Typography>
      <div className={classes.horizontalDivider}></div>
      <Typography className={classes.envInfoSubHeading}>
        Click on the texts below to copy them
      </Typography>
      <ShortenedNameV2
        name={arn}
        length={25}
        showClipboardTooltip={true}
        data-testid="shortened-name-v2"
      />

      <CustomClipboard
        label={policyName}
        children={envPolicy}
        data-testid="custom-clipboard"
      />
    </div>
  )
}

export default EnvironmentDetails
