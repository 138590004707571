import { AnyAction } from '@reduxjs/toolkit'
import { AppDispatch, RootState } from '../../../store'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import {
  deleteSingleCPPO,
  deleteSinglePrivateOffer,
  fetchChannelPartnerPrivateOffers,
  getSingleChannelOffer,
  patchChannelOffer,
  patchChannelPartnerPrivateOffersData,
  postChannelOffer,
  sendPrivateOfferReminderForCPPO,
} from '../../api/markeplace'
import { camelize, snakeize } from 'casing'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import {
  CancelChannelOfferFailureMessage,
  CancelChannelOfferFailureProgresMessage,
  channelPrivateOfferCreationSuccess,
  channelPrivateOfferSaveSuccess,
  cppoCreationPlaceholderEmailMessage,
  DeletePrivateOfferFailureMessage,
  DeletePrivateOfferSuccessMessage,
  PatchChannelOfferGeneralMessage,
  RequestFailureMessage,
  resendPrivateOfferSuccess,
  sendReminderToBuyerSuccess,
} from '../../../common/utils/messagesContants'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { getErrorMessages } from '../../../common/utils/error'
import { errorLogger } from '../../../common/utils/errorLogger'
import {
  ChannelPartnerPrivateOffer,
  ChannelPartnerPrivateOfferInCreation,
  CppoDimension,
  CppoPriceAdjustmentType,
} from './reducer'
import { isEmpty } from 'lodash/fp'
import { AxiosResponse } from 'axios'
import { ReceivedResellerAuthorization } from '../receivedResellerAuthorization/reducer'
import { CloudMarketplace } from '../productsListing/reducer'
import {
  MetaData,
  OfferStakeholders,
  OfferStatus,
  PriceTypes,
  ScheduleType,
} from '../privateOffer/reducer'
import {
  ContractDurationType,
  DiscountType,
} from '../../../admin/modules/resaleAuthorizations/reducer'
import { getPricesArray } from '../resaleAuthorization/actions'
import { getMappedMonthKey } from '../../components/AddEditChannelOfferComponents/ContractDuration/ContractDuration'
import { isNonEmptyNumber } from '../../../common/utils/validateMinMax'
import { mapObjectPropertyEmptyToNull } from '../../../common/utils/mapObjectPropertyEmptyToNull'
import {
  convertDateFromAPIResponse,
  getFilteredInstallments,
} from '../privateOffer/privateOfferCreation/actions'
import { getMarkupValues } from '../../utils/channelOfferUtil'
import { isNull, isUndefined } from 'lodash'
import { UserProfile } from '../../../common/modules/userProfile/action'
import { PlanType } from '@labrav/react-components'
import { UserState } from '../../../oppsync/modules/userList/reducer'
import { isAdminRole } from '../../../common/utils/isAdminRole'
import { PartnerType } from '../../../common/modules/partner/action'
import { DateTime } from 'luxon'

export enum ChannelPartnerPrivateOfferActionTypes {
  SET_CHANNEL_PARTNER_PRIVATE_OFFERS_DATA = 'SET_CHANNEL_PARTNER_PRIVATE_OFFERS_DATA',
  SET_CREATE_CHANNEL_OFFERS_DATA = 'SET_CREATE_CHANNEL_OFFERS_DATA',
  SET_SINGLE_CHANNEL_OFFER_DATA = 'SET_SINGLE_CHANNEL_OFFER_DATA',
  CLEAR_CHANNEL_OFFER_IN_CREATION = 'CLEAR_CHANNEL_OFFER_IN_CREATION',
  CLEAR_CHANNEL_OFFERS = 'CLEAR_CHANNEL_OFFERS',
}

export const setChannelPartnerPrivateOffers = (
  awsCppos: ChannelPartnerPrivateOffer[],
  count: number,
  pageNumber: number,
  pageSize: number
) =>
  ({
    type: ChannelPartnerPrivateOfferActionTypes.SET_CHANNEL_PARTNER_PRIVATE_OFFERS_DATA,
    payload: { awsCppos, count, pageNumber, pageSize },
  } as unknown as AnyAction)

export const clearChannelOfferInCreation = (authId: string) => ({
  type: ChannelPartnerPrivateOfferActionTypes.CLEAR_CHANNEL_OFFER_IN_CREATION,
  payload: authId,
})

export const clearChannelOffers = () => ({
  type: ChannelPartnerPrivateOfferActionTypes.CLEAR_CHANNEL_OFFERS,
})

export const processChannelPartnerPrivateOffers = async (
  partnerId: string,
  pageSize: number,
  pageNumber: number,
  crmOpportunityId?: string,
  input?: string,
  selectedInput?: string,
  sectionName?: string,
  selectedResellerAuth?: string
) => {
  if (isEmpty(input)) {
    const cppoResponse = await fetchChannelPartnerPrivateOffers(
      partnerId,
      pageSize,
      pageNumber,
      'created_at',
      'desc',
      crmOpportunityId,
      '',
      selectedResellerAuth
    )
    return { data: cppoResponse.data }
  } else {
    // placeholder for search functionality
    return { data: null }
  }
}

export const getChannelPartnerPrivateOffers =
  ({
    partnerId,
    pageSize = 10,
    pageNumber = 1,
    crmOpportunityId,
    input,
    selectedInput,
    sectionName,
    selectedResellerAuth,
  }: {
    partnerId: string
    pageSize?: number
    pageNumber?: number
    crmOpportunityId?: string
    input?: string // for search functionality
    selectedInput?: string // for search functionality
    sectionName?: string // for search functionality
    selectedResellerAuth?: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.CHANNEL_OFFER_LOADING))
    try {
      const { data } = await processChannelPartnerPrivateOffers(
        partnerId,
        pageSize,
        pageNumber,
        crmOpportunityId,
        input,
        selectedInput,
        sectionName,
        selectedResellerAuth
      )
      data?.aws_cppos &&
        (await dispatch(
          setChannelPartnerPrivateOffers(
            camelize(data.aws_cppos),
            data.count,
            pageNumber,
            pageSize
          )
        ))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(error?.response),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.CHANNEL_OFFER_LOADING))
    }
  }

export const updateChannelPartnerPrivateOffer =
  (
    cppoId: string,
    data: Record<string, unknown>,
    crmOpportunityId?: string,
    editOffer?: boolean,
    callBack?: () => void,
    resellerAuthId?: string
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.UPDATE_CHANNEL_OFFER))
    const cloudMarketplace = getState().productListingFilters?.cloudMarketplace
    const { offerStatus, operation } = data
    const isCallForCancel =
      offerStatus === OfferStatus.PRIVATE_OFFER_RESTRICTION_IN_PROGRESS ||
      operation === 'UPDATE'
    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      await patchChannelPartnerPrivateOffersData(
        partnerId,
        cppoId,
        snakeize(data),
        false,
        editOffer
      )
      const successMessage = isCallForCancel
        ? CancelChannelOfferFailureProgresMessage
        : PatchChannelOfferGeneralMessage
      dispatch(
        updateAppAlert({
          message: successMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      if (!editOffer && resellerAuthId) {
        await dispatch(
          getChannelPartnerPrivateOffer({
            channelOfferId: cppoId,
            cloudMarketplace,
            partnerId,
            resellerAuthId,
          }) as unknown as AnyAction
        )
      }
    } catch (error: any) {
      const errorMessage =
        offerStatus === OfferStatus.PRIVATE_OFFER_RESTRICTION_IN_PROGRESS
          ? CancelChannelOfferFailureMessage
          : RequestFailureMessage
      dispatch(
        updateAppAlert({
          message: getErrorMessages([errorMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      if (callBack) {
        await callBack()
      }
      await dispatch(stopLoading(LoadingTypes.UPDATE_CHANNEL_OFFER))
    }
  }

export const getInitialChannelOfferBasedOnCloud = ({
  receivedResellerAuth,
  cloudMarketplace,
  userProfile,
}: {
  receivedResellerAuth?: ReceivedResellerAuthorization
  cloudMarketplace: CloudMarketplace
  userProfile: UserProfile
}) => {
  switch (cloudMarketplace) {
    case 'AWS': {
      const { email, firstName, lastName } = userProfile
      const priceValue = getPricesArray(receivedResellerAuth?.dimensions || [])

      const serviceLength =
        receivedResellerAuth?.contractDurationType ===
          ContractDurationType.CUSTOM ||
        receivedResellerAuth?.discountType ===
          DiscountType.FLEXIBLE_PAYMENT_TERMS
          ? receivedResellerAuth?.contractDurationInMonthsForEndCustomer
          : getMappedMonthKey(priceValue[0])

      const dimensionsList = receivedResellerAuth?.dimensions?.sort(
        (firstDimension, secondDimension) => {
          if (
            firstDimension?.isAdditionalUsageAllowed ===
            secondDimension?.isAdditionalUsageAllowed
          )
            return 0
          return firstDimension.isAdditionalUsageAllowed ? 1 : -1
        }
      )
      return {
        offerName: '',
        companyName: '',
        offerDescription: '',
        customEmailMessage: cppoCreationPlaceholderEmailMessage,
        offerRecipients: [
          { firstName: '', lastName: '', title: '', email: '' },
        ],
        offerExpirationDate: null,
        awsAccountId: receivedResellerAuth?.buyerAwsAccountId || '',
        subscriptionEndDate: null,
        priceAdjustmentType: CppoPriceAdjustmentType.PERCENTAGE,
        adjustmentPercentage: undefined,
        adjustmentPercentageInputValue: 0,
        flexiblePaymentType: ScheduleType.CUSTOM,
        dimensions: dimensionsList,
        serviceLength: serviceLength,
        flexiblePaymentTerms:
          receivedResellerAuth?.discountType ===
          DiscountType.FLEXIBLE_PAYMENT_TERMS,
        metaData: [{ key: '', value: '' }],
        installments:
          receivedResellerAuth?.discountType ===
          DiscountType.FLEXIBLE_PAYMENT_TERMS
            ? receivedResellerAuth?.installmentInfo?.map(item => ({
                ...item,
                amount: item?.amount,
                otherAmount: item?.amount,
              }))
            : [{ paymentDate: '', amount: null, currency: 'USD' }],
        variableStartDate: true,
        agreementStartDate: null,
        offerStakeholders:
          email && firstName
            ? [
                {
                  email,
                  name: firstName,
                  firstName,
                  lastName: lastName || '',
                },
              ]
            : [],
      }
    }
    case 'AZURE': {
      return {}
    }
    case 'GCP': {
      return {}
    }
    case 'REDHAT': {
      return {}
    }
  }
}

export const getChannelPartnerPrivateOffer =
  ({
    cloudMarketplace,
    partnerId,
    resellerAuthId,
    channelOfferId,
  }: {
    cloudMarketplace: CloudMarketplace
    partnerId: string
    resellerAuthId: string
    channelOfferId: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const receivedResellerAuth =
        getState().receivedResellerAuthorization
          .currentReceivedResellerAuthorizations[resellerAuthId!]
      const isAdmin = isAdminRole(getState().userProfile.userProfile)
      const useType = isAdmin ? PartnerType.Admin : PartnerType.User
      const allUsers = getState().userList[useType].userList.users
      const channelOfferData = await getSingleChannelOfferBasedOnCloud({
        cloudMarketplace,
        partnerId,
        channelOfferId,
        receivedResellerAuth,
        allUsers,
      })
      if (!isEmpty(channelOfferData)) {
        await dispatch(
          setSingleChannelPartnerPrivateOffer({
            resellerAuthId,
            channelOffer: camelize(channelOfferData),
          })
        )
      }
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
    }
  }

export const getSingleChannelOfferBasedOnCloud = async ({
  cloudMarketplace,
  partnerId,
  channelOfferId,
  receivedResellerAuth,
  allUsers,
}: {
  cloudMarketplace: CloudMarketplace
  partnerId: string
  channelOfferId: string
  receivedResellerAuth?: ReceivedResellerAuthorization
  allUsers?: UserState[]
}) => {
  switch (cloudMarketplace) {
    case 'AWS': {
      const { data } = await getSingleChannelOffer(partnerId, channelOfferId)
      if (data?.aws_cppos && data?.aws_cppos?.length) {
        const {
          installments,
          customMetaData,
          adjustmentPercentage,
          subscriptionEndDate,
          agreementStartDate,
          offerExpirationDate,
          dimensions,
          offerStakeholders,
          flexiblePaymentTerms,
          ...otherOfferData
        } = camelize(data.aws_cppos[0])

        const allUsersEmail = (allUsers || [])?.map(item => item?.email)
        const offerStakeholdersData = offerStakeholders?.filter(
          (item: OfferStakeholders) => allUsersEmail.includes(item?.email)
        )
        const customOfferStakeholdersData = offerStakeholders?.filter(
          (item: OfferStakeholders) => !allUsersEmail.includes(item?.email)
        )

        const dimensionsList = (dimensions || [])
          ?.sort(
            (firstDimension: CppoDimension, secondDimension: CppoDimension) => {
              if (
                firstDimension?.isAdditionalUsageAllowed ===
                secondDimension?.isAdditionalUsageAllowed
              )
                return 0
              return firstDimension.isAdditionalUsageAllowed ? 1 : -1
            }
          )
          .map(
            ({ customPrice, ...dimensionItem }: Record<string, unknown>) => ({
              ...dimensionItem,
              price: customPrice,
            })
          )

        const adjustmentPercentageValue =
          otherOfferData?.priceAdjustmentType ===
          CppoPriceAdjustmentType.PERCENTAGE
            ? adjustmentPercentage
            : getAdjustedPercentageValue({
                priceAdjustmentType: otherOfferData?.priceAdjustmentType,
                adjustmentPercentage,
                dimensions: dimensionsList,
                resaleAuthorization: receivedResellerAuth,
              })
        const channelOfferData = {
          ...otherOfferData,
          adjustmentPercentage: adjustmentPercentageValue,
          installments,
          metaData: (customMetaData || [])?.length
            ? customMetaData
            : [{ key: '', value: '' }],
          flexiblePaymentTerms:
            receivedResellerAuth?.discountType ===
            DiscountType?.FLEXIBLE_PAYMENT_TERMS
              ? true
              : flexiblePaymentTerms,
          offerStakeholders: allUsersEmail
            ? offerStakeholdersData
            : offerStakeholders,
          customOfferStakeholders: customOfferStakeholdersData,
          adjustmentPercentageInputValue: adjustmentPercentageValue,
          offerExpirationDate: offerExpirationDate
            ? convertDateFromAPIResponse(offerExpirationDate)
            : offerExpirationDate,
          agreementStartDate: agreementStartDate
            ? convertDateFromAPIResponse(agreementStartDate)
            : agreementStartDate,
          subscriptionEndDate: subscriptionEndDate
            ? convertDateFromAPIResponse(subscriptionEndDate)
            : subscriptionEndDate,
          dimensions: dimensionsList,
          firstInstallmentDate: installments?.length
            ? installments[0].paymentDate
            : null,
        }
        return channelOfferData
      }
    }
  }
}

const getAdjustedPercentageValue = ({
  dimensions,
  priceAdjustmentType,
  adjustmentPercentage,
  resaleAuthorization,
}: {
  dimensions?: CppoDimension[]
  priceAdjustmentType: CppoPriceAdjustmentType
  adjustmentPercentage?: number | null
  resaleAuthorization?: ReceivedResellerAuthorization
}) => {
  if (priceAdjustmentType === CppoPriceAdjustmentType.PERCENTAGE) {
    return adjustmentPercentage
  }

  if (isNull(adjustmentPercentage) || isUndefined(adjustmentPercentage)) {
    return null
  }

  if (!dimensions || !dimensions?.length) return null

  const { totalDueToIsv } = getMarkupValues({
    dimensions: dimensions,
    adjustmentPercentage: adjustmentPercentage,
    priceAdjustmentType: priceAdjustmentType,
    resaleAuthorization,
  })

  if (!isNonEmptyNumber(totalDueToIsv)) return 0

  const adjustmentPercentageValue =
    totalDueToIsv! + totalDueToIsv! * (adjustmentPercentage / 100)
  return adjustmentPercentageValue
}

export const getChannelPartnerPrivateOffersInCreation =
  ({
    channelOfferId,
    resellerAuthId,
    partnerId,
  }: {
    channelOfferId?: string
    resellerAuthId: string
    partnerId: string
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const cloudMarketplace = getState().productListingFilters.cloudMarketplace
    const receivedResellerAuth =
      getState().receivedResellerAuthorization.activeReceivedResellerAuthorizations?.find(
        resellerAuth => resellerAuth?.id === resellerAuthId
      )
    await dispatch(clearChannelOfferInCreation(resellerAuthId))
    try {
      const userProfile = getState().userProfile.userProfile
      if (isEmpty(channelOfferId)) {
        const initialChannelOffer = getInitialChannelOfferBasedOnCloud({
          receivedResellerAuth,
          cloudMarketplace,
          userProfile,
        })
        await dispatch(
          setChannelPartnerPrivateOfferInCreation({
            resellerAuthId,
            channelOffer: initialChannelOffer,
          })
        )
      } else {
        const isAdmin = isAdminRole(getState().userProfile.userProfile)
        const useType = isAdmin ? PartnerType.Admin : PartnerType.User
        const allUsers = getState().userList[useType].userList.users
        const channelOfferData = await getSingleChannelOfferBasedOnCloud({
          channelOfferId,
          cloudMarketplace,
          partnerId,
          receivedResellerAuth,
          allUsers,
        })
        if (!isEmpty(channelOfferData)) {
          await dispatch(
            setChannelPartnerPrivateOfferInCreation({
              resellerAuthId,
              channelOffer: channelOfferData,
            })
          )
        }
      }
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
    }
  }

export const setChannelPartnerPrivateOfferInCreation = ({
  resellerAuthId,
  channelOffer,
}: {
  resellerAuthId: string
  channelOffer: ChannelPartnerPrivateOfferInCreation
}) =>
  ({
    type: ChannelPartnerPrivateOfferActionTypes.SET_CREATE_CHANNEL_OFFERS_DATA,
    payload: { resellerAuthId, channelOffer },
  } as unknown as AnyAction)

export const setSingleChannelPartnerPrivateOffer = ({
  resellerAuthId,
  channelOffer,
}: {
  resellerAuthId: string
  channelOffer: ChannelPartnerPrivateOfferInCreation
}) =>
  ({
    type: ChannelPartnerPrivateOfferActionTypes.SET_SINGLE_CHANNEL_OFFER_DATA,
    payload: { resellerAuthId, channelOffer },
  } as unknown as AnyAction)

const getSendPayloadDataBasedOnCloud = ({
  selectedCloud,
  channelOffer,
  receivedResellerAuth,
  saveAsDraft,
}: {
  selectedCloud: CloudMarketplace
  channelOffer: ChannelPartnerPrivateOfferInCreation
  receivedResellerAuth?: ReceivedResellerAuthorization
  saveAsDraft: boolean
}) => {
  switch (selectedCloud) {
    case 'AWS': {
      const {
        variableStartDate,
        offerDescription,
        agreementStartDate,
        metaData: metaDataValue,
        offerExpirationDate,
        subscriptionEndDate,
        serviceLength,
        installments,
        awsAccountId,
        dimensions,
        adjustmentPercentage,
        customOfferStakeholders,
        offerStakeholders,
        adjustmentPercentageInputValue,
        flexiblePaymentTerms,
        firstInstallmentDate,
        labraOfferId,
        previousSubscriptionEndDate,
        draft,
        ...remainingOffer
      } = channelOffer

      const { markupOrTotal } = getMarkupValues({
        dimensions: dimensions,
        adjustmentPercentage: adjustmentPercentage,
        priceAdjustmentType: remainingOffer.priceAdjustmentType,
        resaleAuthorization: receivedResellerAuth,
      })

      const passServiceLengthEmpty =
        receivedResellerAuth?.productType === PlanType.SAAS_SUBSCRIPTION ||
        (receivedResellerAuth?.discountType !==
          DiscountType.FLEXIBLE_PAYMENT_TERMS &&
          variableStartDate &&
          !flexiblePaymentTerms)

      const shouldSendAgreementEndDate =
        !isEmpty(agreementStartDate) &&
        isNonEmptyNumber(serviceLength) &&
        !passServiceLengthEmpty

      const returnOffer = {
        ...remainingOffer,
        variableStartDate,
        buyerCloudAccountId: awsAccountId,
        isvName: receivedResellerAuth?.isvName,
        awsProductId: receivedResellerAuth?.productId,
        resaleAuthorizationId: receivedResellerAuth?.id,
        cloudProvider: selectedCloud,
        offerDescription: offerDescription || '-',
        flexiblePaymentTerms:
          receivedResellerAuth?.discountType ===
          DiscountType?.FLEXIBLE_PAYMENT_TERMS
            ? false
            : flexiblePaymentTerms,
        offerStakeholders: [
          ...(offerStakeholders || []),
          ...(customOfferStakeholders || []),
        ],
        ...(shouldSendAgreementEndDate
          ? {
              agreementEndDate: DateTime.fromJSDate(
                new Date(agreementStartDate)
              )
                .plus({ months: serviceLength })
                .toISODate(),
            }
          : {}),
        adjustmentPercentage: isNonEmptyNumber(adjustmentPercentageInputValue)
          ? remainingOffer.priceAdjustmentType ===
            CppoPriceAdjustmentType.PERCENTAGE
            ? Number(adjustmentPercentageInputValue)
            : !isEmpty(markupOrTotal)
            ? Number(markupOrTotal)
            : null
          : null,
        ...(!variableStartDate && !isEmpty(agreementStartDate)
          ? { agreementStartDate }
          : { agreementStartDate: null }),

        ...(!isEmpty(subscriptionEndDate) ? { subscriptionEndDate } : {}),
        serviceLength: isNonEmptyNumber(serviceLength)
          ? !passServiceLengthEmpty
            ? Number(serviceLength)
            : null
          : null,
        eulaType: 'STANDARD',
        customMetaData: ((metaDataValue as MetaData[]) || [])
          ?.filter(
            metaDataItem =>
              !isEmpty(metaDataItem?.key) || !isEmpty(metaDataItem?.value)
          )
          ?.map((item: MetaData) => ({ key: item?.key, value: item?.value })),
        ...mapObjectPropertyEmptyToNull({
          offerExpirationDate,
        }),
        ...(receivedResellerAuth?.discountType !==
        DiscountType.FLEXIBLE_PAYMENT_TERMS
          ? {
              installmentInfo: installments
                ? getFilteredInstallments(installments)
                : [],
            }
          : {
              installmentInfo: installments,
            }),
      }
      return {
        ...snakeize(returnOffer),
        ...(saveAsDraft ? { draft: !!saveAsDraft } : {}),
        dimensions: getSendCPPODimensions({
          channelOffer,
          receivedResellerAuth,
        }),
      }
    }
    case 'AZURE': {
      return {}
    }
    case 'GCP': {
      return {}
    }
    case 'REDHAT': {
      return {}
    }
  }
}

const getSendCPPODimensions = ({
  channelOffer,
  receivedResellerAuth,
}: {
  channelOffer: ChannelPartnerPrivateOfferInCreation
  receivedResellerAuth?: ReceivedResellerAuthorization
}) => {
  const dimensions = channelOffer?.dimensions

  const updatedDimensions = (dimensions || [])?.map(
    ({
      price,
      prices,
      dimensionId,
      quantity,
      ...remainingDimensionDetail
    }) => ({
      ...snakeize(remainingDimensionDetail),
      prices: {
        price_1m: (prices as PriceTypes)?.price1m,
        price_12m: (prices as PriceTypes)?.price12m,
        price_24m: (prices as PriceTypes)?.price24m,
        price_36m: (prices as PriceTypes)?.price36m,
        additional_usage_price: (prices as PriceTypes)?.additionalUsagePrice,
        subscription_price: (prices as PriceTypes)?.subscriptionPrice,
      },
      reseller_agreement_dimension_id: dimensionId,
      quantity: !isNull(quantity) ? Number(quantity) : null,
      custom_price: price,
    })
  )
  return updatedDimensions
}

const returnSpecificCPPODetails = (sendPayload: Record<string, unknown>) => {
  const {
    abo_agreement_id,
    aws_cppo_id,
    audit_json,
    contract_duration,
    created_at,
    crm_object_id,
    error,
    reseller_agreement,
    updated_at,
    type,
    id,
    ...remainingOfferData
  } = sendPayload
  return remainingOfferData
}

export const sendChannelOffer =
  ({
    partnerId,
    channelOfferId,
    saveAsDraft,
    channelOffer,
    resellerAuthId,
    handleNext,
  }: {
    partnerId: string
    channelOfferId?: string
    saveAsDraft: boolean
    resellerAuthId: string
    channelOffer: ChannelPartnerPrivateOfferInCreation
    handleNext?: (id: string, draft: boolean) => void
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    if (saveAsDraft) {
      await dispatch(
        startLoading(LoadingTypes.DRAFT_CHANNEL_PARTNER_PRIVATE_OFFER)
      )
    } else {
      await dispatch(
        startLoading(LoadingTypes.SUBMIT_CHANNEL_PARTNER_PRIVATE_OFFER)
      )
    }

    try {
      const selectedCloud = getState().productListingFilters.cloudMarketplace
      const receivedResellerAuth =
        getState().receivedResellerAuthorization.activeReceivedResellerAuthorizations?.find(
          item => item?.id === resellerAuthId
        )
      const sendPayload = getSendPayloadDataBasedOnCloud({
        selectedCloud,
        channelOffer,
        receivedResellerAuth,
        saveAsDraft,
      })
      if (isEmpty(channelOfferId)) {
        const { data } = await postChannelOffer({
          data: sendPayload,
          partnerId,
          saveAsDraft,
        })
        if (data && handleNext) {
          handleNext(data?.cppo_id, saveAsDraft)
        }
      } else {
        const updatedPayload = returnSpecificCPPODetails(sendPayload)
        await patchChannelOffer({
          data: updatedPayload,
          partnerId,
          saveAsDraft,
          channelOfferId,
        })
        if (channelOfferId && handleNext && !saveAsDraft) {
          handleNext(channelOfferId, false)
        }
      }
      dispatch(
        updateAppAlert({
          message: saveAsDraft
            ? channelPrivateOfferSaveSuccess
            : channelPrivateOfferCreationSuccess,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
    } finally {
      if (saveAsDraft) {
        await dispatch(
          stopLoading(LoadingTypes.DRAFT_CHANNEL_PARTNER_PRIVATE_OFFER)
        )
      } else {
        await dispatch(
          stopLoading(LoadingTypes.SUBMIT_CHANNEL_PARTNER_PRIVATE_OFFER)
        )
      }
    }
  }

export const sendReminderForCPPO =
  ({
    privateOfferId,
    data,
    callBack,
  }: {
    privateOfferId: string
    data: Record<string, unknown>
    callBack?: () => void
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.UPDATE_FLYOUT_OFFER))

    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      await sendPrivateOfferReminderForCPPO(
        partnerId,
        privateOfferId,
        snakeize({ ...data })
      )
      dispatch(
        updateAppAlert({
          message: sendReminderToBuyerSuccess,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      const errorMessage = RequestFailureMessage
      dispatch(
        updateAppAlert({
          message: getErrorMessages([errorMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      if (callBack) {
        await callBack()
      }
      await dispatch(stopLoading(LoadingTypes.UPDATE_FLYOUT_OFFER))
    }
  }

export const resendCPPO =
  ({
    privateOfferId,
    data,
    callBack,
  }: {
    privateOfferId: string
    data: Record<string, unknown>
    callBack?: () => void
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.UPDATE_FLYOUT_OFFER))

    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      await sendPrivateOfferReminderForCPPO(
        partnerId,
        privateOfferId,
        snakeize({ ...data }),
        true
      )
      dispatch(
        updateAppAlert({
          message: resendPrivateOfferSuccess,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      const errorMessage = RequestFailureMessage
      dispatch(
        updateAppAlert({
          message: getErrorMessages([errorMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      if (callBack) {
        await callBack()
      }
      await dispatch(stopLoading(LoadingTypes.UPDATE_FLYOUT_OFFER))
    }
  }

export const deleteCurrentCPPO =
  (privateOfferId: string, callBack?: () => void) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const cloud = getState().productListingFilters.cloudMarketplace
    await dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''
      await deleteCPPOBasedOnCloud(cloud, partnerId, privateOfferId)
      dispatch(
        updateAppAlert({
          message: DeletePrivateOfferSuccessMessage,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([DeletePrivateOfferFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      if (callBack) {
        await callBack()
      }
      await dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

const deleteCPPOBasedOnCloud = async (
  cloud: CloudMarketplace,
  partnerId: string,
  privateOfferId: string
) => {
  switch (cloud) {
    case 'AWS': {
      return await deleteSingleCPPO(partnerId, privateOfferId)
    }
    case 'AZURE': {
      // Yet to be implemented
      return
    }
    case 'GCP': {
      // Yet to be implemented
    }
  }
}
