import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSwapArrows, faUser } from '@fortawesome/pro-regular-svg-icons'
import { Typography } from '@material-ui/core'
import {
  ExternalLink,
  ModalConfirmV2,
  ModalSizeVariants,
} from '@labrav/react-components'
import { useStyles } from './MigrationAlert.styles'

interface MigrationAlertModalProps {
  open: boolean
  onClose: () => void
  onAccept: () => void
}

export const MigrationAlert: React.FC<MigrationAlertModalProps> = ({
  open,
  onClose,
  onAccept,
}) => {
  const classes = useStyles()

  return (
    <ModalConfirmV2
      data-testid="migration-modal"
      titleAcceptButton="Start migration"
      title="AWS Partner Central API Migration"
      icon={
        <FontAwesomeIcon icon={faSwapArrows} className={classes.swapIcon} />
      }
      onCloseModal={onClose}
      onAccept={onAccept}
      scrollContent={false}
      content={
        <div className={classes.root}>
          <div className={classes.descriptionText}>
            AWS is replacing the S3-based CRM integration with the Partner
            Central API. Migration is required to avoid disruptions.
          </div>
          <div className={classes.contentContainer}>
            <Typography className={classes.contentText}>
              Once you start the migration, this process cannot be undone. For
              any questions, please contact{' '}
              <ExternalLink href={'https://helpcenter.labra.io/hc/en-us'}>
                {' '}
                Labra Support{' '}
              </ExternalLink>{' '}
            </Typography>
          </div>
        </div>
      }
      acceptDisabled={false}
      hideAcceptButton={false}
      onlyCloseModal={true}
      open={open}
      hideCancelButton={true}
      variant={ModalSizeVariants.large}
      onDecline={onClose}
    />
  )
}
