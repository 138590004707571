import { AppDispatch, RootState } from '../../../store'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { getErrorMessages } from '../../../common/utils/error'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { AxiosResponse } from 'axios'
import { errorLogger } from '../../../common/utils/errorLogger'
import { getBuyerRevenuesAPI } from '../../api/markeplace'
import { camelize } from 'casing'
import { Revenues } from './reducer'

export enum revenuesActionTypes {
  SET_BUYER_REVENUES = 'SET_BUYER_REVENUES',
}

export const getBuyerRevenues =
  ({ partnerId, buyerId }: { partnerId: string; buyerId: string }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.BUYERS_OVERVIEW))
    try {
      const response = await getBuyerRevenuesAPI({ partnerId, buyerId })
      const revenues = camelize(response?.data)
      await dispatch(setBuyerRevenues(revenues))
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      await dispatch(stopLoading(LoadingTypes.BUYERS_OVERVIEW))
    }
  }

export const setBuyerRevenues = (buyerRevenues: Revenues) => ({
  type: revenuesActionTypes.SET_BUYER_REVENUES,
  payload: buyerRevenues,
})
