import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons'
import {
  LabraToolTipV2,
  ModalConfirmV2,
  ModalSizeVariants,
} from '@labrav/react-components'
import aws from '../../../../common/images/aws.png'
import { CloudMarketplace } from '../../../../flyout/modules/productsListing/reducer'
import { useStyles } from './CoSellAddRecipientsNotificationsButton.styles'
import {
  NotificationsSettingPage,
  RecipientType,
} from '../../../modules/notification/reducer'
import {
  usePartnerId,
  useUserType,
} from '../../../../common/utils/Hooks/usePartnerData'
import { useDispatch, useSelector } from '../../../../store'
import { UserListState, UserState } from '../../../modules/userList/reducer'
import { isEmpty, set } from 'lodash'
import { CoSellEmailNotificationsRecipientContent } from '../CoSellEmailNotificationsRecipientContent/CoSellEmailNotificationsRecipientContent'
import { emailRegex } from '../../../../common/utils/constants'
import { Product } from '../../../../common/modules/partner/action'
import { actionTypeWrapper } from '../../../../common/utils/actionTypeWrapper'
import { setNotificationsSettingsPage } from '../../../modules/notification/actions'
import { InvitationStatus } from '../../../../common/utils/roles'
import { useTracker } from '../../../../common/utils/Tracker/hook'

export interface CoSellAddRecipientsNotificationsButtonProps {
  key: string
  handleClick: () => void
  open: boolean
  cloud: CloudMarketplace | null
  recipients: RecipientType[]
  specialRecipients: string[]
  currentNotification: string[]
  setCurrentNotification: React.Dispatch<React.SetStateAction<string[]>>
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedItem: React.Dispatch<React.SetStateAction<string[]>>
}

export const CoSellAddRecipientsNotificationsButton: React.FC<
  CoSellAddRecipientsNotificationsButtonProps
> = ({
  key,
  handleClick,
  open,
  setOpen,
  recipients,
  specialRecipients,
  currentNotification,
  setCurrentNotification,
  setSelectedItem,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const tracker = useTracker()
  const partnerType = useUserType()
  const { userList } = useSelector<UserListState>(
    state => state.userList[partnerType]
  )
  const partnerId = usePartnerId()
  const product = useSelector<Product>(
    state => state.platformSettings.selectedProductOnSettings
  )
  const sellerName = useSelector<string | undefined>(
    state => state.flyoutSellerInfo.awsAccountName
  )
  const notificationsData = useSelector<NotificationsSettingPage>(
    state => state.notifications?.[product]?.notificationsSettingData
  )
  const registeredUsers = useMemo(() => {
    const { users = [] } = userList || {}
    return users.filter(
      user =>
        !isEmpty(user.email) &&
        user.invitationStatus === InvitationStatus.INVITATION_ACCEPTED
    )
  }, [userList])

  const trackEvent = async (
    heading: string,
    subHeading: string,
    action: string,
    title: string,
    source: string
  ) => {
    const formattedHeading = heading.replace(/\s+/g, '-')
    const formattedSubHeading = subHeading.replace(/\s+/g, '-')
    tracker?.track({
      customTracking: true,
      section: `${formattedHeading}`,
      component: `${formattedSubHeading}`,
      action: `${action}`,
      title: `${title}`,
      source: `${source}`,
      partnerId: `${partnerId}`,
      customerName: `${sellerName}`,
    })
  }

  const combinedRecipients = useMemo(() => {
    if (currentNotification.length > 1) {
      const allRecipients = notificationsData.productNotificationTypes
        .filter(notification =>
          currentNotification.includes(notification.productNotificationTypeId)
        )
        .flatMap(notification =>
          notification.productNotificationTypeMethods
            .filter(method => method.methodId === 'EMAIL')
            .flatMap(method => method.recipients)
        )
        .filter(recipient => !isEmpty(recipient))

      // Remove duplicates by creating a Set and converting it back to an array
      return Array.from(
        new Set(allRecipients.map(recipient => JSON.stringify(recipient)))
      ).map(recipient => JSON.parse(recipient))
    }
    return recipients
  }, [currentNotification, notificationsData, recipients])

  const isSpecialRecipients = useMemo(() => {
    if (currentNotification.length > 1) {
      return notificationsData.productNotificationTypes
        .filter(notification =>
          currentNotification.includes(notification.productNotificationTypeId)
        )
        .some(notification => notification.specialRecipients?.includes('OWNER'))
    }
    return specialRecipients.includes('OWNER')
  }, [currentNotification, notificationsData, specialRecipients])

  const [recipientsStateData, setRecipientsStateData] = useState({
    referralOwner: isSpecialRecipients,
    registeredRecipients: combinedRecipients.filter(
      recipient => !isEmpty(recipient.idpUserId)
    ),
    unregisteredRecipients: combinedRecipients.filter(
      recipient => !isEmpty(recipient.destinationValue)
    ),
    selectedTags: combinedRecipients
      .filter(recipient => !isEmpty(recipient.destinationValue))
      .map(recipient => recipient.destinationValue || ''),
    externalUsersError: '',
  })

  const {
    referralOwner,
    registeredRecipients,
    unregisteredRecipients,
    selectedTags,
    externalUsersError,
  } = recipientsStateData

  const handleOwnerChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRecipientsStateData(prev => ({
        ...prev,
        referralOwner: e.target.checked,
      }))
    },
    []
  )

  const handleRegisteredRecipientChange = useCallback(
    (idpUserId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setRecipientsStateData(prev => ({
        ...prev,
        registeredRecipients: e.target.checked
          ? [
              ...prev.registeredRecipients,
              { idpUserId, destinationValue: null, metaData: null },
            ]
          : prev.registeredRecipients.filter(
              recipient => recipient?.idpUserId !== idpUserId
            ),
      }))
    },
    []
  )

  const handleTagsChange = useCallback(
    (addedTag: string) => {
      if (addedTag && !selectedTags.includes(addedTag)) {
        setRecipientsStateData(prev => ({
          ...prev,
          selectedTags: [...prev.selectedTags, addedTag],
          unregisteredRecipients: [
            ...prev.unregisteredRecipients,
            { idpUserId: null, destinationValue: addedTag, metaData: null },
          ],
        }))
      }
    },
    [selectedTags]
  )

  const handleDelete = useCallback((deletedTag: string) => {
    setRecipientsStateData(prev => ({
      ...prev,
      selectedTags: prev.selectedTags.filter(
        selectedTag => selectedTag !== deletedTag
      ),
      unregisteredRecipients: prev.unregisteredRecipients.filter(
        recipient => recipient?.destinationValue !== deletedTag
      ),
    }))
  }, [])

  const validateEmails = useCallback((emails: string[]) => {
    const invalidEmails = emails.filter(email => !emailRegex.test(email))
    setRecipientsStateData(prev => ({
      ...prev,
      externalUsersError:
        invalidEmails.length > 0 ? 'Please enter a valid email(s)' : '',
    }))
  }, [])

  const handleCancel = useCallback(() => {
    setRecipientsStateData({
      referralOwner: isSpecialRecipients,
      registeredRecipients: combinedRecipients.filter(
        recipient => !isEmpty(recipient.idpUserId)
      ),
      unregisteredRecipients: combinedRecipients.filter(
        recipient => !isEmpty(recipient.destinationValue)
      ),
      selectedTags: combinedRecipients
        .filter(recipient => !isEmpty(recipient.destinationValue))
        .map(recipient => recipient.destinationValue || ''),
      externalUsersError: '',
    })
    setCurrentNotification([])
    setOpen(false)
    trackEvent(
      'Co-sell Notifications',
      'Email recipients cancel button',
      'clicked',
      `Email recipients cancelled for ${
        currentNotification.length === 1
          ? 'single notification'
          : 'multiple notifications'
      }`,
      'Co-sell Notifications'
    )
  }, [combinedRecipients, isSpecialRecipients, setCurrentNotification, setOpen])

  const handleSave = useCallback(async () => {
    const updatedSettingsPage = {
      ...notificationsData,
      productNotificationTypes: notificationsData?.productNotificationTypes.map(
        productNotificationType => {
          if (
            !currentNotification.includes(
              productNotificationType.productNotificationTypeId
            )
          ) {
            return productNotificationType
          }

          const updatedMethods =
            productNotificationType.productNotificationTypeMethods.map(
              method => {
                if (method.methodId !== 'EMAIL') return method

                return {
                  ...method,
                  recipients: [
                    ...registeredRecipients,
                    ...unregisteredRecipients,
                  ],
                }
              }
            )

          const result = {
            ...productNotificationType,
            ...(referralOwner ? { specialRecipients: ['OWNER'] } : null),
            productNotificationTypeMethods: updatedMethods,
          }

          // Remove specialRecipients if referralOwner is false
          if (!referralOwner && Object.hasOwn(result, 'specialRecipients')) {
            delete result.specialRecipients
          }

          return result
        }
      ),
    }

    dispatch(
      actionTypeWrapper(
        product,
        setNotificationsSettingsPage(updatedSettingsPage, partnerId || '')
      )
    )

    trackEvent(
      'Co-sell Notifications',
      'Email recipients add button',
      'clicked',
      `Email recipients added for ${
        currentNotification.length === 1
          ? 'single notification'
          : 'multiple notifications'
      }`,
      'Co-sell Notifications'
    )

    setSelectedItem(prevSelectedItems => [
      ...new Set([...prevSelectedItems, ...currentNotification]),
    ])

    setCurrentNotification([])
    setOpen(false)
  }, [
    currentNotification,
    notificationsData,
    referralOwner,
    registeredRecipients,
    unregisteredRecipients,
    product,
    partnerId,
    setCurrentNotification,
    setOpen,
  ])

  useEffect(() => {
    validateEmails(selectedTags)
  }, [selectedTags, validateEmails])

  useEffect(() => {
    if (open) {
      setRecipientsStateData({
        referralOwner: isSpecialRecipients,
        registeredRecipients: combinedRecipients.filter(
          recipient => !isEmpty(recipient.idpUserId)
        ),
        unregisteredRecipients: combinedRecipients.filter(
          recipient => !isEmpty(recipient.destinationValue)
        ),
        selectedTags: combinedRecipients
          .filter(recipient => !isEmpty(recipient.destinationValue))
          .map(recipient => recipient.destinationValue || ''),
        externalUsersError: '',
      })
    }
  }, [open, combinedRecipients, isSpecialRecipients])

  return (
    <div
      className={classes.addButton}
      data-testid="add-notif-recipients-button"
    >
      <LabraToolTipV2
        tooltipPlacement="top"
        description={'Add recipients to this notification'}
      >
        <FontAwesomeIcon
          icon={faUserPlus}
          data-testid="add-recipients"
          onClick={handleClick}
          key={key}
          style={{
            cursor: 'pointer',
            width: '20px',
            height: '20px',
            color: '#1942C5',
          }}
        />
      </LabraToolTipV2>
      <ModalConfirmV2
        title={
          currentNotification.length > 1
            ? 'Add email recipients to all the selected notifications'
            : 'Add email recipients to the notification'
        }
        icon={
          <FontAwesomeIcon
            icon={faUserPlus}
            data-testid="add-recipients-icon-modal"
          />
        }
        rightHeaderContent={<img className={classes.img} src={aws} alt="AWS" />}
        onlyCloseModal
        onCloseModal={handleCancel}
        onDecline={handleCancel}
        content={
          <>
            <div className={classes.description}>
              To ensure the referral owner receives the email notification
              {currentNotification.length > 1 ? 's' : ''}, enable the option
              below. The referral owner is the AWS Partner Central user
              responsible for managing the referral.
            </div>
            <CoSellEmailNotificationsRecipientContent
              id={key}
              selectedTags={selectedTags}
              referralOwner={referralOwner}
              registeredRecipients={registeredRecipients}
              unregisteredRecipients={unregisteredRecipients}
              handleDelete={handleDelete}
              handleOwnerChange={handleOwnerChange}
              registeredUsers={registeredUsers}
              handleRegisteredRecipientChange={handleRegisteredRecipientChange}
              handleTagsChange={handleTagsChange}
              externalUsersError={externalUsersError}
            />
          </>
        }
        acceptDisabled={externalUsersError}
        open={open}
        titleCancelButton={'Cancel'}
        titleAcceptButton={'Add'}
        onAccept={handleSave}
        variant={ModalSizeVariants.large}
        hideCancelButton={false}
      />
    </div>
  )
}
