import React, { Suspense, useEffect, useState } from 'react'
import { useStyles } from './NotificationsWrapper.styles'
import { MainTabsV2 } from '@labrav/react-components'
import { CoSellNotifications } from '../../../oppsync/components/CoSellNotifications/CoSellNotifications'
import { settingsTabs } from '../../utils/Hooks/settingsTabs'
import { Route, Routes } from 'react-router-dom'
import { Product } from '../../modules/partner/action'
import { useDispatch } from '../../../store'
import { setSelectedProductOnSettings } from '../../modules/platformSettings/action'
import useScreenSize from '../../utils/Hooks/useScreenSize'
import { MarketplaceNotifications } from '../../../flyout/components/MarketplaceNotifications/MarketplaceNotifications'

export const NotificationsWrapper: React.FC = () => {
  const { screenHeight } = useScreenSize()
  const classes = useStyles(screenHeight)()
  const dispatch = useDispatch()

  const productTypeTabs = [
    {
      label: 'Marketplace',
      key: 'marketplace',
      product: 'flyout',
      path: 'marketplace',
      renderElement: (
        <div
          data-testid="marketplace-notifications-content"
          className={classes.marketplace}
        >
          <MarketplaceNotifications />
        </div>
      ),
    },
    {
      label: 'Co-sell',
      key: 'co-sell',
      product: 'oppsync',
      path: 'co-sell',
      renderElement: (
        <div data-testid="co-sell-notifications-content">
          <CoSellNotifications />
        </div>
      ),
    },
    {
      label: 'Marketing',
      key: 'marketing',
      path: 'marketing',
      disabled: true,
      tootTipText: 'Coming soon',
    },
    {
      label: 'Assessments',
      key: 'assessments',
      path: 'assessments',
      disabled: true,
      tootTipText: 'Coming soon',
    },
  ]

  const {
    defaultSettingsTabs,
    activeTab,
    handleTabChange,
    settingsTabsForRouting,
  } = settingsTabs([], productTypeTabs, true, true, 'Notifications')

  useEffect(() => {
    if (defaultSettingsTabs[activeTab]?.product as Product) {
      dispatch(
        setSelectedProductOnSettings(
          (defaultSettingsTabs[activeTab]?.product as Product) ?? 'oppsync'
        )
      )
    }
  }, [activeTab])

  return (
    <>
      <div className={classes.root} data-testid="notifications-container">
        <div
          className={classes.tabContainer}
          data-testid="notifications-tab-container"
        >
          <MainTabsV2
            tabs={productTypeTabs}
            activeTabIndex={activeTab}
            onChange={handleTabChange}
            tabsType={'main'}
          />
        </div>
        <div className={classes.content}>
          <Suspense fallback={<div />}>
            <Routes>
              {settingsTabsForRouting.map(t => {
                return (
                  <Route
                    key={t.path}
                    path={t.path}
                    element={t.renderElement ? t.renderElement : <></>}
                  />
                )
              })}
            </Routes>
          </Suspense>
        </div>
      </div>
    </>
  )
}
