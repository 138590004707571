import { Reducer } from 'redux'
import { Stakeholders } from '../../../admin/modules/resaleAuthorizations/reducer'
import { CloudProvider } from '../../../common/modules/partner/action'
import {
  Dimension,
  EulaType,
  Installments,
  MetaData,
  OfferRecipients,
  OfferStakeholders,
  OfferStatus,
  OfferTypeRenew,
  PriceTypes,
  ScheduleType,
} from '../privateOffer/reducer'
import { ChannelPartnerPrivateOfferActionTypes } from './actions'
import { links } from '../productsListing/reducer'

export interface ResellerAgreementInCPPO {
  awsResellerAgreementId?: string
  agreementName?: string
  agreementDescription?: string
}

export interface CppoDimension extends Dimension {
  partnerMargin?: number | null
  cppoId?: string | null
}

export enum CppoPriceAdjustmentType {
  PERCENTAGE = 'discount',
  FIXED = 'markup',
}

export interface CppoStages {
  'Created at Labra'?: string | null
  Sent?: string | null
  Viewed?: string | null
  Cancelled?: string | null
  Accepted?: string | null
}

export enum CppoStageToStatusRelationMapEnum {
  IN_PROGRESS = 'Created at Labra',
  SENT = 'Sent',
  VIEWED = 'Viewed',
  CANCELLED = 'Cancelled',
  ACCEPTED = 'Accepted',
}

export type CppoAuditJson = {
  offerCreatedInLabraAt: string | null
  offerEmailSentAt: string | null
  offerViewedAt: string | null
  offerCancelledAt: string | null
  offerAcceptedAt: string | null
}

export type ChannelPartnerPrivateOfferInCreation = {
  companyName?: string
  awsAccountId?: string
  offerName?: string
  offerExpirationDate?: string | null
  subscriptionEndDate?: string | null
  offerDescription?: string
  offerRecipients?: OfferRecipients[]
  customEmailMessage?: string
  priceAdjustmentType?: CppoPriceAdjustmentType
  adjustmentPercentage?: number
  adjustmentPercentageInputValue?: number //to use for input before apply
  flexiblePaymentTerms?: boolean
  offerStakeholders?: OfferStakeholders[]
  customOfferStakeholders?: OfferStakeholders[]
  metaData?: MetaData[]
  installments?: Installments[]
  serviceLength?: number
  flexiblePaymentType?: ScheduleType
  dimensions?: CppoDimension[]
  variableStartDate?: boolean
  agreementStartDate?: string | null
  eulaType?: EulaType
  eulaFile?: string[]
  isResellerLicenseAgreementAccepted?: boolean
  draft?: boolean
  previousSubscriptionEndDate?: string
  firstInstallmentDate?: string | null
  labraOfferId?: string
}

export interface ChannelPartnerPrivateOffer {
  id: string
  companyName?: string
  awsAccountId?: string
  resaleAuthorizationId?: string
  offerStatus?: OfferStatus
  subscriptionEndDate?: string
  isvName?: string
  cloudProvider?: string | CloudProvider
  offerStakeholders?: OfferStakeholders[]
  customOfferStakeholders?: OfferStakeholders[]
  customEmailMessage?: string | null
  type?: string | OfferTypeRenew
  aboAgreementId?: string
  channelPartnerName?: string
  channelPartnerAccountId?: string
  offerName?: string
  offerDescription?: string
  offerExpirationDate?: string
  offerUrl?: string
  offerId?: string
  error?: string
  serviceLength?: number
  priceAdjustmentType?: CppoPriceAdjustmentType
  adjustmentPercentage?: number | null
  flexiblePaymentTerms?: boolean
  contractDuration?: number
  eulaType: EulaType
  eulaFile: string[]
  metaData?: MetaData[]
  offerRecipients?: OfferRecipients[]
  installments?: Installments[]
  awsProductId?: string
  draft?: boolean
  crmObjectId?: string
  cloudProductName?: string
  productType?: string
  createdAt?: string
  updatedAt?: string
  resellerAgreement?: ResellerAgreementInCPPO
  dimensions?: CppoDimension[] // prices are still unclear
  agreementStartDate?: string
  auditJson?: CppoAuditJson
  emailCustomMessage?: string
  cloudOfferId?: string
  labraSubscriptionId?: string
  creationOrigin?: string
  labraResaleAuthorizationId?: string
  labraOfferId?: string
}

export type ApiResponse = {
  pageNumber?: number
  pageSize?: number
  totalCount?: number
  sortBy?: string
  sortOrder?: string
  links?: links
}

export type ChannelPartnerPrivateOffersState = {
  awsCppos: ChannelPartnerPrivateOffer[]
  count: number
  pageNumber: number
  noOfPage: number | null
  pageSize: number
  currentChannelPartnerPrivateOffer: {
    [x: string]: ChannelPartnerPrivateOffer
  }
  channelPartnerPrivateOfferInCreation: {
    [x: string]: ChannelPartnerPrivateOfferInCreation
  }
} & ApiResponse

const initialState: ChannelPartnerPrivateOffersState = {
  awsCppos: [],
  count: 0,
  pageNumber: 0,
  noOfPage: null,
  pageSize: 10,
  currentChannelPartnerPrivateOffer: {},
  channelPartnerPrivateOfferInCreation: {},
}

type actionType =
  | {
      type: ChannelPartnerPrivateOfferActionTypes.SET_CHANNEL_PARTNER_PRIVATE_OFFERS_DATA
      payload: {
        awsCppos: ChannelPartnerPrivateOffer[]
        count: number
        pageNumber: number
        pageSize: number
      }
    }
  | {
      type: ChannelPartnerPrivateOfferActionTypes.SET_CREATE_CHANNEL_OFFERS_DATA
      payload: {
        resellerAuthId: string
        channelOffer: ChannelPartnerPrivateOfferInCreation
      }
    }
  | {
      type: ChannelPartnerPrivateOfferActionTypes.SET_SINGLE_CHANNEL_OFFER_DATA
      payload: {
        resellerAuthId: string
        channelOffer: ChannelPartnerPrivateOffer
      }
    }
  | {
      type: ChannelPartnerPrivateOfferActionTypes.CLEAR_CHANNEL_OFFER_IN_CREATION
      payload: string
    }
  | {
      type: ChannelPartnerPrivateOfferActionTypes.CLEAR_CHANNEL_OFFERS
    }

export const reducer: Reducer<ChannelPartnerPrivateOffersState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ChannelPartnerPrivateOfferActionTypes.SET_CHANNEL_PARTNER_PRIVATE_OFFERS_DATA: {
      return {
        ...state,
        awsCppos: state.awsCppos.concat(action.payload.awsCppos),
        count: action.payload.count,
        pageNumber: action.payload.pageNumber,
        pageSize: action.payload.pageSize,
      }
    }
    case ChannelPartnerPrivateOfferActionTypes.SET_CREATE_CHANNEL_OFFERS_DATA: {
      return {
        ...state,
        channelPartnerPrivateOfferInCreation: {
          [action.payload.resellerAuthId]: {
            ...action.payload.channelOffer,
          },
        },
      }
    }
    case ChannelPartnerPrivateOfferActionTypes.SET_SINGLE_CHANNEL_OFFER_DATA: {
      return {
        ...state,
        currentChannelPartnerPrivateOffer: {
          [action.payload.resellerAuthId]: {
            ...action.payload.channelOffer,
          },
        },
      }
    }
    case ChannelPartnerPrivateOfferActionTypes.CLEAR_CHANNEL_OFFER_IN_CREATION: {
      return {
        ...state,
        channelPartnerPrivateOfferInCreation: {
          [action.payload]: {},
        },
      }
    }
    case ChannelPartnerPrivateOfferActionTypes.CLEAR_CHANNEL_OFFERS: {
      return {
        ...state,
        awsCppos: [],
        count: 0,
        pageNumber: 1,
        pageSize: 10,
      }
    }
    default:
      return state
  }
}
