import React, { useEffect, useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from '../../../../../../store'
import {
  autoSave,
  AutoSaveTypes,
} from '../../../../../../common/modules/autoSave/actions'
import { useStyles } from './CoSellCloudSettingsFooter.styles'
import { FooterV2 } from '../../../../../../common/components/FooterV2/FooterV2'
import {
  AutoSaveFeedback,
  ButtonV2,
  ModalConfirmV2,
  ModalSizeVariants,
} from '@labrav/react-components'
import {
  usePartnerId,
  useUserType,
} from '../../../../../../common/utils/Hooks/usePartnerData'
import { FormikProps, useFormikContext } from 'formik'
import {
  PartnerData,
  PartnershipTypeValue,
  PartnerType,
} from '../../../../../../common/modules/partner/action'
import { updateEngineServicePartnerData } from '../../../../../../oppsync/modules/engineServicePartner/action'
import {
  AwsCloudSettingsSections,
  AwsCloudSettingsSectionsProps,
} from '../../types'
import { autosaveTimeout } from '../../../../../../common/utils/constants'
import debounce from 'lodash/debounce'
import { isCloudAccountFormComplete } from '../CloudSettingsWrapper'
import { LoadingTypes } from '../../../../../../common/modules/loading/reducer'
import { isLoading } from '../../../../../../common/utils/loadingState'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBookmark } from '@fortawesome/pro-regular-svg-icons'
import { updateAppAlert } from '../../../../../../common/modules/appAlert/actions'

type CoSellCloudSettingsFooter = {
  isOnboardingCompleted: boolean
}
export const CoSellCloudSettingsFooter: React.FC<CoSellCloudSettingsFooter> = ({
  isOnboardingCompleted,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const partnerId = usePartnerId()
  const dispatch = useDispatch()
  const isFirstRender = useRef(true)

  const { values, isValid, dirty } =
    useFormikContext() as FormikProps<AwsCloudSettingsSectionsProps>

  const isAdmin = useUserType() === PartnerType.Admin
  const partnerType = isAdmin ? PartnerType.Admin : PartnerType.User

  const autoSaveStatus = useSelector<{ status: AutoSaveTypes; error?: string }>(
    state => state.autoSave.coSellCloudSettings
  )

  const isCosellBackendLoading = useSelector<boolean>(state =>
    isLoading(state.loading)(LoadingTypes.COSELL_CLOUD_SETTINGS)
  )

  const autoSaving = (
    values: AwsCloudSettingsSectionsProps,
    partnerId?: string
  ) => {
    if (partnerId && !isOnboardingCompleted) {
      const transformedPartnerData = coSellCloudSettingsDataToAPI(values)
      dispatch(
        autoSave('coSellCloudSettings', async () => {
          return dispatch(
            updateEngineServicePartnerData(
              partnerId,
              partnerType,
              transformedPartnerData
            )
          )
        })
      )
    }
  }

  // Create a debounced version of autoSaving that persists between renders
  const debouncedAutoSave = useCallback(debounce(autoSaving, 5000), [
    partnerId,
    partnerType,
    isOnboardingCompleted,
  ])

  // Trigger autoSave when values change, but skip the first render
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    debouncedAutoSave(values, partnerId)

    // Cleanup: cancel any pending debounced calls when component unmounts
    return () => {
      debouncedAutoSave.cancel()
    }
  }, [values, debouncedAutoSave, partnerId])

  const isButtonLoading =
    autoSaveStatus?.status === 'loading' || isCosellBackendLoading

  const isFormValid =
    isValid &&
    !isButtonLoading &&
    isCloudAccountFormComplete(
      values.steps,
      values.prerequisites?.isAceEligible
    )

  const [openSaveChangesModal, setSaveChangesModal] = useState<boolean>(false)
  const openModal = () => {
    setSaveChangesModal(true)
  }
  const onCloseModal = () => {
    setSaveChangesModal(false)
  }
  const handleSaveChanges = async () => {
    setSaveChangesModal(false)

    if (partnerId) {
      const transformedPartnerData = coSellCloudSettingsDataToAPI(values)
      const error = await dispatch(
        updateEngineServicePartnerData(
          partnerId,
          partnerType,
          transformedPartnerData
        )
      )
      if (!error) {
        dispatch(
          updateAppAlert({
            message: 'Saved successfully',
            messageType: 'SUCCESS',
            autoClose: true,
          })
        )
      }
    }
  }
  return (
    <>
      {!isOnboardingCompleted ? (
        <FooterV2
          actions={
            <div
              className={classes.onboardingActions}
              data-testid="co-sell-cloud-settings-onboarding-footer"
            >
              <div className={classes.autosave}>
                {autoSaveStatus?.status === 'standBy' ? (
                  <></>
                ) : (
                  <AutoSaveFeedback
                    status={autoSaveStatus?.status || 'standBy'}
                  />
                )}
              </div>
              <div className={classes.btnWrapper}>
                <ButtonV2
                  styleType="outlined"
                  data-testid={'back-button-co-sell-cloud-settings'}
                  onClick={() => navigate(-1)}
                >
                  Back
                </ButtonV2>
                <ButtonV2
                  data-testid="continue-button-co-sell-cloud-settings"
                  styleType="primary"
                  type="submit"
                  loading={isButtonLoading}
                  disabled={!isFormValid}
                >
                  Continue
                </ButtonV2>
              </div>
            </div>
          }
          customClass={classes.root}
        />
      ) : (
        <FooterV2
          actions={
            <div
              className={classes.onboardingCompletedActions}
              data-testid="co-sell-cloud-settings-footer"
            >
              <div className={classes.btnWrapper}>
                <ButtonV2
                  styleType="outlined"
                  data-testid={'discard-button-co-sell-cloud-settings'}
                  onClick={() => navigate(-1)}
                >
                  Discard
                </ButtonV2>
                <ButtonV2
                  data-testid="save-changes-button-co-sell-cloud-settings"
                  styleType="primary"
                  onClick={openModal}
                  loading={isButtonLoading}
                  disabled={!isFormValid || !dirty}
                >
                  Save changes
                </ButtonV2>
              </div>
            </div>
          }
          customClass={classes.root}
          width={'calc(100vw - 74px)'}
        />
      )}
      <ModalConfirmV2
        data-testid="save-changes-modal"
        titleAcceptButton={'Save'}
        titleCancelButton={'Discard'}
        title="Save changes"
        icon={
          <FontAwesomeIcon icon={faBookmark} className={classes.bookmarkIcon} />
        }
        onCloseModal={onCloseModal}
        onAccept={handleSaveChanges}
        scrollContent={true}
        description="Are you sure you want to save these changes?"
        acceptDisabled={false}
        hideAcceptButton={false}
        onlyCloseModal={true}
        open={openSaveChangesModal}
        hideCancelButton={false}
        variant={ModalSizeVariants.small}
        onDecline={onCloseModal}
      />
    </>
  )
}

const partnershipTypeValue = (
  partnerServicePath?: boolean,
  partnerSoftwarePath?: boolean
): PartnershipTypeValue[] => {
  const partnershipType: PartnershipTypeValue[] = []
  if (partnerServicePath) partnershipType.push('CONSULTING_PARTNER')
  if (partnerSoftwarePath) partnershipType.push('TECHNOLOGY_PARTNER')
  return partnershipType
}

export const coSellCloudSettingsDataToAPI = (
  values: AwsCloudSettingsSectionsProps
): Partial<PartnerData> => {
  return {
    awsAccountId: values[AwsCloudSettingsSections.LINK_ACCOUNT]?.awsAccountId,
    partnershipType: partnershipTypeValue(
      values[AwsCloudSettingsSections.CLOUD_DETAILS]?.partnerServicePath,
      values[AwsCloudSettingsSections.CLOUD_DETAILS]?.partnerSoftwarePath
    ),
    isIsv: values[AwsCloudSettingsSections.CLOUD_DETAILS]?.isv ?? undefined,
    accelerateProgram:
      values[AwsCloudSettingsSections.CLOUD_DETAILS]?.awsIsvAccelerateProgram ??
      undefined,
    securityServiceSoftwareOnly:
      values[AwsCloudSettingsSections.CLOUD_DETAILS]
        ?.securityServiceSoftwareOnly ?? undefined,
    securityServiceManagedServices:
      values[AwsCloudSettingsSections.CLOUD_DETAILS]
        ?.securityServiceManagedServices ?? undefined,
    securityServiceProfessionalServices:
      values[AwsCloudSettingsSections.CLOUD_DETAILS]
        ?.securityServiceProfessionalServices ?? undefined,
    msspProgram:
      values[AwsCloudSettingsSections.CLOUD_DETAILS]?.msspProgram ?? undefined,
    partnershipTier:
      values[AwsCloudSettingsSections.CLOUD_DETAILS]?.awsServicesPartnerTier,
    iamArnStaging:
      values[AwsCloudSettingsSections.CREATE_IAM_ROLE]?.stagingArn ?? undefined,
    iamArn:
      values[AwsCloudSettingsSections.CREATE_IAM_ROLE]?.productionArn ??
      undefined,
    iamPolicyStaging:
      values[AwsCloudSettingsSections.GENERATE_POLICIES]?.iamPolicyStaging ??
      undefined,
    iamPolicyProduction:
      values[AwsCloudSettingsSections.GENERATE_POLICIES]?.iamPolicyProduction ??
      undefined,
    awsConfigMetadata: {
      prerequisites: {
        APNEnrolled:
          values[AwsCloudSettingsSections.PREREQUISITES]?.isApnEnrolled ??
          false,
        solutionsConfigured:
          values[AwsCloudSettingsSections.PREREQUISITES]
            ?.areSolutionsConfigured ?? false,
        ACEEligible:
          values[AwsCloudSettingsSections.PREREQUISITES]?.isAceEligible ??
          false,
      },
      steps: values[AwsCloudSettingsSections.STEPS] || {},
      acknowledgedRequiredPersons:
        values[AwsCloudSettingsSections.ACKNOWLEDGED_REQUIRED_PERSONS]
          ?.acknowledgedRequiredPersons ?? false,
    },
  }
}
