import * as Yup from 'yup'
import { AwsCloudSettingsSections } from '../../oppsync/pages/components/ACEApi/types'
import { get, isEmpty } from 'lodash'
import { emptySpaceCharsAtStartAndEndRegex, IamArnRegex } from './constants'
import {
  iamArnValidationMessage,
  mandatoryFieldMessage,
} from './messagesContants'

const CloudSettingsFormikValidationSchema = Yup.object().shape({
  [AwsCloudSettingsSections.PREREQUISITES]: Yup.object().shape({
    isApnEnrolled: Yup.boolean()
      .oneOf([true], 'Please confirm the above checkbox')
      .required('This field is required.'),
    areSolutionsConfigured: Yup.boolean()
      .oneOf([true], 'Please confirm the above checkbox')
      .required('This field is required.'),
    isAceEligible: Yup.boolean().nullable(),
  }),
  [AwsCloudSettingsSections.LINK_ACCOUNT]: Yup.object().shape({
    awsAccountId: Yup.string()
      .matches(/^\d{12}$/, 'AWS Account ID must be a 12-digit number')
      .required('This is a mandatory requirement.'),
  }),
  [AwsCloudSettingsSections.CLOUD_DETAILS]: Yup.object().shape({
    partnerServicePath: Yup.boolean(),
    partnerSoftwarePath: Yup.boolean(),
    _partnerTypeValidation: Yup.string()
      .nullable()
      .test(
        'at-least-one-path',
        'At least one partnership type must be selected',
        (values, ctx) => {
          const { parent } = ctx
          const result = !!(
            parent?.partnerServicePath || parent?.partnerSoftwarePath
          )
          return result
        }
      ),
    awsServicesPartnerTier: Yup.string()
      .nullable()
      .test(
        'required-when-partner-service-path',
        'Partner tier is required when services path is selected',
        (value, ctx) => {
          const { parent } = ctx
          if (parent?.partnerServicePath) {
            if (isEmpty(value)) {
              return false // there is an error
            }
          }
          return true // no error
        }
      ),
  }),
  [AwsCloudSettingsSections.CREATE_IAM_ROLE]: Yup.object().shape({
    stagingArn: Yup.string().required(mandatoryFieldMessage),

    _customStagingArnError: Yup.string()
      .nullable()
      .test(
        'staging-arn-error',
        'Staging ARN is required',
        (errorValue, ctx) => {
          const { parent } = ctx
          return !(parent?.stagingArn && !isEmpty(errorValue))
        }
      ),
    productionArn: Yup.string().required(mandatoryFieldMessage),

    _customProductionArnError: Yup.string()
      .nullable()
      .test(
        'production-arn-error',
        'Production ARN is required',
        (errorValue, ctx) => {
          const { parent } = ctx
          return !(parent?.productionArn && !isEmpty(errorValue))
        }
      ),
  }),
  [AwsCloudSettingsSections.GENERATE_POLICIES]: Yup.object().shape({
    iamPolicyStaging: Yup.string().nullable(),
    iamPolicyProduction: Yup.string()
      .nullable()
      .test(
        'required-when-ace-eligible',
        'This field is required when ACE eligible is true',
        (value, ctx) => {
          const isAceEligible = get(
            ctx,
            'from.1.value.prerequisites.isAceEligible'
          )
          if (isEmpty(value) && !!isAceEligible) {
            return false // there is an error
          }
          return true // no error
        }
      ),
  }),
})

export default CloudSettingsFormikValidationSchema
