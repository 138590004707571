import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { AppDispatch, RootState } from '../../../store'
import { getErrorMessages } from '../../utils/error'
import {
  DefaultSuccesrOnSubmitData,
  RequestFailureMessage,
} from '../../utils/messagesContants'
import { AxiosResponse } from 'axios'
import { errorLogger } from '../../utils/errorLogger'
import {
  CompetitorPayload,
  CompetitorType,
  KeywordCompetitorMapping,
  KeywordItem,
  KeywordType,
  KeywordTypePayload,
} from './reducer'
import {
  fetchCompetitorName,
  saveCompetitorName,
  fetchCompetitorsList,
  fetchKeywordsList,
  fetchKeywordCompetitorMapping,
  saveKeywordRecord,
  updateKeywordRecord,
  searchKeywordsRankingList,
  deleteCompetitor,
  fetchSelfListingUrl,
  startRankingProcess,
  patchGenerateContentAi,
} from '../../api/marketplaceSeo'
import { CloudMarketplace } from '../../../flyout/modules/productsListing/reducer'
import { actionTypeWrapper } from '../../utils/actionTypeWrapper'
import { camelize, snakeize } from 'casing'
import {
  patchProductListingData,
  patchProductListingDataSEO,
} from '../../../flyout/api/markeplace'
import {
  getProducts,
  getProductsWithListing,
} from '../../../flyout/modules/productsListing/actions'
import { AnyAction } from '@reduxjs/toolkit'

export enum MarketplaceSeoActionTypes {
  SET_DEFAULT_KEYWORDS = 'SET_DEFAULT_KEYWORDS',
  SET_DEFAULT_COMPETITOR = 'SET_DEFAULT_COMPETITOR',
  SET_KEYWORD_COMPETITOR_MAPPING = 'SET_KEYWORD_COMPETITOR_MAPPING',
  SET_SEARCH_RANKING = 'SET_SEARCH_RANKING',
  SET_SELF_LISTING_URL = 'SET_SELF_LISTING_URL',
  SET_AI_GENERATED_RESPONSE = 'SET_AI_GENERATED_RESPONSE',
  CLEAR_AI_GENERATED_RESPONSE = 'CLEAR_AI_GENERATED_RESPONSE',
}

export const setDefaultCompetitors = (competitors: CompetitorType[]) => ({
  type: MarketplaceSeoActionTypes.SET_DEFAULT_COMPETITOR,
  payload: {
    competitors,
  },
})
export const clearAiGeneratedResponse = () => ({
  type: MarketplaceSeoActionTypes.CLEAR_AI_GENERATED_RESPONSE,
})
export const setDefaultKeywords = (keywords: KeywordType[]) => ({
  type: MarketplaceSeoActionTypes.SET_DEFAULT_KEYWORDS,
  payload: {
    keywords,
  },
})

export const setSelfListingUrl = (listingUrl: string) => ({
  type: MarketplaceSeoActionTypes.SET_SELF_LISTING_URL,
  payload: {
    listingUrl,
  },
})

export const setSearchRankingResult = (keywordRanking: KeywordItem[]) => ({
  type: MarketplaceSeoActionTypes.SET_SEARCH_RANKING,
  payload: {
    keywordRanking,
  },
})
export const setAiGeneratedResponse = (aiGeneratedResponse: any) => ({
  type: MarketplaceSeoActionTypes.SET_AI_GENERATED_RESPONSE,
  payload: {
    aiGeneratedResponse,
  },
})

export const setKeywordCompetitorMapping = (
  keywordCompetitorMapping: KeywordCompetitorMapping[]
) => ({
  type: MarketplaceSeoActionTypes.SET_KEYWORD_COMPETITOR_MAPPING,
  payload: {
    keywordCompetitorMapping,
  },
})

export const getCompetitors =
  (partnerId: string, cloudMarketplace: CloudMarketplace) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const response = await fetchCompetitorsList(partnerId, cloudMarketplace)
      if (response?.data) {
        dispatch(
          actionTypeWrapper(
            cloudMarketplace,
            setDefaultCompetitors(response?.data?.records)
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const getKeywordCompetitorMapping =
  (
    partnerId: string,
    cloudMarketplace: CloudMarketplace,
    hideLoading?: boolean
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    !hideLoading &&
      dispatch(startLoading(LoadingTypes.FETCH_KEYWORD_COMPETITOR_MAPPING))
    try {
      const response = await fetchKeywordCompetitorMapping(
        partnerId,
        cloudMarketplace
      )
      if (response?.data) {
        dispatch(
          actionTypeWrapper(
            cloudMarketplace,
            setKeywordCompetitorMapping(response?.data?.records)
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      !hideLoading &&
        dispatch(stopLoading(LoadingTypes.FETCH_KEYWORD_COMPETITOR_MAPPING))
    }
  }

export const getKeywords =
  (partnerId: string, cloudMarketplace: CloudMarketplace) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const response = await fetchKeywordsList(partnerId, cloudMarketplace)
      if (response?.data) {
        dispatch(
          actionTypeWrapper(
            cloudMarketplace,
            setDefaultKeywords(response?.data?.keywords || [])
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const getSelfListingUrl =
  (partnerId: string, cloudMarketplace: CloudMarketplace) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const response = await fetchSelfListingUrl(partnerId)
      if (response?.data) {
        dispatch(
          actionTypeWrapper(
            cloudMarketplace,
            setSelfListingUrl(response?.data?.marketplace_url || '')
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const fetchAndSaveCompetitor =
  ({
    partnerId,
    productId,
    cloudMarketplace,
    callBackCompetitor,
    self = false,
  }: {
    partnerId: string
    productId: string
    cloudMarketplace: CloudMarketplace
    self?: boolean
    callBackCompetitor?: ({
      success,
      error,
    }: {
      success?: true
      error?: string
    }) => void
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SAVE_COMPETITOR))
    try {
      const existingCompetitor = getState().marketplaceSeo[
        cloudMarketplace
      ].competitors?.map(item => item?.name)

      const response = await fetchCompetitorName(productId)

      if (
        response?.data &&
        !existingCompetitor.includes(response?.data?.displayName)
      ) {
        const competitorDetail: CompetitorPayload = {
          name: response?.data?.displayName,
          cloud: cloudMarketplace,
          partnerId: partnerId,
          cloudSellerId: response?.data?.value,
          ...(self ? { self: true } : {}),
        }

        await dispatch(
          saveCompetitor({
            partnerId,
            competitors: [competitorDetail],
            cloudMarketplace,
            callBackCompetitor,
          })
        )
      } else if (existingCompetitor.includes(response?.data?.displayName)) {
        callBackCompetitor &&
          callBackCompetitor({
            error: `${response?.data?.displayName} already added.`,
          })
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const errorMsg = getErrorMessages([RequestFailureMessage])(
        error.response as AxiosResponse<ErrorResponse>
      )
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      callBackCompetitor && callBackCompetitor({ error: errorMsg })
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SAVE_COMPETITOR))
    }
  }

export const saveCompetitor =
  ({
    partnerId,
    competitors,
    cloudMarketplace,
    callBackCompetitor,
  }: {
    partnerId: string
    competitors: CompetitorPayload[]
    cloudMarketplace: CloudMarketplace
    callBackCompetitor?: ({
      success,
      error,
    }: {
      success?: true
      error?: string
    }) => void
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SAVE_COMPETITOR))
    try {
      await saveCompetitorName(
        partnerId,
        snakeize({
          competitors: [...competitors],
        })
      )
      callBackCompetitor && callBackCompetitor({ success: true })
      await dispatch(getCompetitors(partnerId, cloudMarketplace))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SAVE_COMPETITOR))
    }
  }

export const saveKeyword =
  ({
    partnerId,
    keywords,
  }: {
    partnerId: string
    keywords: KeywordTypePayload[]
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SAVE_COMPETITOR))
    try {
      await saveKeywordRecord(
        partnerId,
        snakeize({
          keywords: [...keywords],
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SAVE_COMPETITOR))
    }
  }

export const updateKeyword =
  ({
    partnerId,
    keywords,
  }: {
    partnerId: string
    keywords: KeywordTypePayload[]
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SAVE_COMPETITOR))
    try {
      await updateKeywordRecord(
        partnerId,
        snakeize({
          keywords: [...keywords],
        })
      )

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SAVE_COMPETITOR))
    }
  }

export const addEditKeywordSection =
  ({
    existingKeywordsCompetitors,
    updatedKeywordCompetitorMapping,
    partnerId,
    cloudMarketplace,
    callBack,
  }: {
    existingKeywordsCompetitors?: KeywordCompetitorMapping
    updatedKeywordCompetitorMapping: KeywordCompetitorMapping
    partnerId: string
    cloudMarketplace: CloudMarketplace
    callBack?: () => void
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const selectedCompetitors = updatedKeywordCompetitorMapping?.competitors

    const existingKeywordsList = getState().marketplaceSeo[
      cloudMarketplace
    ].keywords?.map(item => item?._id)

    const updatedKeywordIds = updatedKeywordCompetitorMapping?.keywords?.map(
      item => item?._id
    )

    const removedKeywords = existingKeywordsCompetitors?.keywords.filter(
      item => !updatedKeywordIds?.includes(item?._id)
    )

    const removedKeywordsIds = removedKeywords?.map(item => item._id)

    const existingKeywords = updatedKeywordCompetitorMapping?.keywords?.filter(
      keywordItem =>
        keywordItem?._id &&
        existingKeywordsList.includes(keywordItem?._id) &&
        !removedKeywordsIds?.includes(keywordItem._id)
    )
    const newKeywords = updatedKeywordCompetitorMapping?.keywords?.filter(
      keywordItem => !keywordItem?._id
    )

    if (existingKeywords.length) {
      const payload = existingKeywords?.map(
        ({ competitors, name, ...item }) => ({
          ...item,
          keyword: name,
          cloud: cloudMarketplace,
          partnerId: partnerId,
          competitors: selectedCompetitors?.map(
            itemCompetitor => itemCompetitor._id
          ),
        })
      )
      await dispatch(updateKeyword({ partnerId, keywords: payload }))
    }

    if (newKeywords.length) {
      const payload = newKeywords?.map(({ competitors, name, ...item }) => ({
        ...item,
        keyword: name,
        cloud: cloudMarketplace,
        partnerId: partnerId,
        competitors: selectedCompetitors?.map(
          itemCompetitor => itemCompetitor._id
        ),
      }))
      await dispatch(saveKeyword({ partnerId, keywords: payload }))
    }

    if (removedKeywords?.length) {
      const payload = removedKeywords?.map(
        ({ competitors, name, ...item }) => ({
          ...item,
          keyword: name,
          cloud: cloudMarketplace,
          partnerId: partnerId,
          competitors: [],
        })
      )
      await dispatch(updateKeyword({ partnerId, keywords: payload }))
    }

    await dispatch(getKeywords(partnerId, cloudMarketplace))
    await dispatch(
      getKeywordCompetitorMapping(partnerId, cloudMarketplace, true)
    )

    await dispatch(startRankingProcessData(partnerId, cloudMarketplace))

    if (callBack) {
      await callBack()
    }
  }

export const removeKeywordCompetitor =
  ({
    keywordCompetitor,
    partnerId,
    cloudMarketplace,
  }: {
    keywordCompetitor: KeywordCompetitorMapping
    partnerId: string
    cloudMarketplace: CloudMarketplace
  }) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    const filterExistingKeyword = keywordCompetitor?.keywords?.filter(
      item => item?._id
    )
    if (filterExistingKeyword?.length) {
      const payload = filterExistingKeyword?.map(
        ({ competitors, name, ...item }) => ({
          ...item,
          keyword: name,
          cloud: cloudMarketplace,
          partnerId: partnerId,
          competitors: [],
        })
      )
      await dispatch(updateKeyword({ partnerId, keywords: payload }))
      await dispatch(
        getKeywordCompetitorMapping(partnerId, cloudMarketplace, true)
      )
      await dispatch(startRankingProcessData(partnerId, cloudMarketplace))
    }
  }

export const searchRanking =
  (partnerId: string, cloudMarketplace: CloudMarketplace) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.SEARCH_SEO_RANKING))
    try {
      const response = await searchKeywordsRankingList(
        partnerId,
        cloudMarketplace
      )
      if (response?.data) {
        dispatch(
          actionTypeWrapper(
            cloudMarketplace,
            setSearchRankingResult(
              camelize(response?.data?.records) as KeywordItem[]
            )
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SEARCH_SEO_RANKING))
    }
  }

export const deleteCompetitorData =
  (
    partnerId: string,
    competitorId: string,
    competitorName: string,
    cloudMarketplace: CloudMarketplace
  ) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      await deleteCompetitor(partnerId, competitorId)
      await dispatch(getCompetitors(partnerId, cloudMarketplace))
      await dispatch(
        updateAppAlert({
          message: `${competitorName} deleted successfully!.`,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

export const startRankingProcessData =
  (partnerId: string, cloudMarketplace: CloudMarketplace) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      await startRankingProcess(partnerId, cloudMarketplace)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.SEARCH_SEO_RANKING))
    }
  }

const getHighlights = (data: any, index?: number) => {
  if (index) {
    return [
      {
        [`highlight_${index + 1}`]: data.listingData.highlights.value[0],
        limit: data.listingData.highlights?.maxLength || 100,
      },
    ]
  }
  return data.listingData.highlights.value.map(
    (highlight: any, index: number) =>
      highlight
        ? {
            [`highlight_${index + 1}`]: highlight,
            limit: data.listingData.highlights?.maxLength || 100,
          }
        : null
  )
}

export const generateContentAi =
  (partnerId: string, data: any, values?: any, index?: number) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERATE_AI_LOADING))
    try {
      const currentState = getState().marketplaceSeo['AWS'].aiGeneratedResponse
      const listing_data = [
        ...(data?.data?.listingData?.title?.value
          ? [
              {
                title: data.data.listingData.title.value,
                limit: data.data.listingData.title.maxLength || 72,
              },
            ]
          : []),

        ...(data?.data?.listingData?.shortProductDescription?.value
          ? [
              {
                short_description:
                  data.data.listingData.shortProductDescription.value,
                limit: data.data.listingData.shortProductDescription.maxLength,
              },
            ]
          : []),

        ...(data?.data?.listingData?.highlights
          ? getHighlights(data.data, index)
          : []),

        ...(data?.data?.listingData?.productDescription?.value
          ? [
              {
                long_description:
                  data.data.listingData.productDescription.value,
                limit: data.data.listingData.productDescription.maxLength,
              },
            ]
          : []),
      ]
      const updatedData = {
        keywords: data?.keywords || data?.data.keywords,
        listing_data: listing_data,
      }

      const response = await patchGenerateContentAi(partnerId, updatedData)
      if (response?.data) {
        const transformedData = transformResponseData(
          response.data,
          values ? values : currentState
        )
        const updatedDataValues = { ...values, ...transformedData }
        const dataToUpdate = values ? updatedDataValues : transformedData
        dispatch(
          actionTypeWrapper(
            'AWS',
            setAiGeneratedResponse(camelize(dataToUpdate))
          )
        )
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERATE_AI_LOADING))
    }
  }
interface updateProductListingDataArgs {
  partnerId: string
  productId: string
  data: Record<string, unknown>
}

export const updateProductListingDataSEO =
  ({ partnerId, productId, data }: updateProductListingDataArgs) =>
  async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.GENERAL))
    try {
      const { cloudMarketplace } = getState().productListingFilters
      await patchProductListingDataSEO(partnerId, productId, snakeize(data))

      await dispatch(
        updateAppAlert({
          message: DefaultSuccesrOnSubmitData,
          messageType: 'SUCCESS',
          autoClose: true,
        })
      )
      await dispatch(
        getProducts(partnerId, cloudMarketplace) as unknown as AnyAction
      )
      await dispatch(
        getProductsWithListing(
          partnerId,
          cloudMarketplace
        ) as unknown as AnyAction
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: RequestFailureMessage,
          messageType: 'ERROR',
          autoClose: true,
        })
      )

      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }

const transformResponseData = (
  data: Record<string, any>,
  currentState: any
) => {
  const transformedData: Record<string, any> = {
    ...(data.title ? { title: data.title } : {}),
    ...(data.short_description
      ? { shortDescription: data.short_description }
      : {}),
    ...(data.long_description
      ? { longDescription: data.long_description }
      : {}),
  }
  const highlightKeys = Object.keys(data).filter(
    key => key.startsWith('highlight_') && data[key]
  )
  let highlights = []
  for (const key of highlightKeys) {
    if (currentState.highlights) {
      const idx = parseInt(key.split('highlight_')[1]) - 1
      currentState.highlights[idx] = data[key]
      highlights = currentState.highlights
    } else {
      highlights.push(data[key])
    }
  }

  if (highlights.length) transformedData.highlights = highlights

  return transformedData
}
