import { industryOptions } from '../../../../common/utils/CoSellHelpers/constants'

export enum AwsCloudSettingsSections {
  PREREQUISITES = 'prerequisites',
  LINK_ACCOUNT = 'linkAccount',
  CLOUD_DETAILS = 'cloudDetails',
  CREATE_IAM_ROLE = 'createIamRole',
  GENERATE_POLICIES = 'generatePolicies',
  ASSIGN_POLICIES = 'assignPolicies',
  STEPS = 'steps',
  ACKNOWLEDGED_REQUIRED_PERSONS = 'acknowledgedRequiredPersons',
}
export type AwsCloudSettingsSectionsProps = {
  [AwsCloudSettingsSections.PREREQUISITES]: Prerequisites
  [AwsCloudSettingsSections.LINK_ACCOUNT]: LinkingAccount
  [AwsCloudSettingsSections.CLOUD_DETAILS]: AccountDetails
  [AwsCloudSettingsSections.CREATE_IAM_ROLE]: IamRolesCreation
  [AwsCloudSettingsSections.GENERATE_POLICIES]: IamPolicyGeneration
  [AwsCloudSettingsSections.STEPS]: CloudSettingsSteps
  [AwsCloudSettingsSections.ACKNOWLEDGED_REQUIRED_PERSONS]: AcknowledgedRequiredPersons
}
export interface Prerequisites {
  isApnEnrolled: boolean
  areSolutionsConfigured: boolean
  isAceEligible: boolean
}
export interface LinkingAccount {
  awsAccountId: string
}
export interface AccountDetails {
  partnerServicePath: boolean
  partnerSoftwarePath: boolean
  isv: boolean | null
  awsIsvAccelerateProgram: boolean | null
  securityServiceSoftwareOnly: boolean | null
  securityServiceManagedServices: boolean | null
  securityServiceProfessionalServices: boolean | null
  msspProgram: boolean | null
  awsServicesPartnerTier: string | null
  _partnerTypeValidation?: string
}
export interface IamRolesCreation {
  stagingArn: string | null
  productionArn: string | null
  _customStagingArnError?: string | null
  _customProductionArnError?: string | null
}
export interface IamPolicyGeneration {
  iamPolicyStaging: string | null
  iamPolicyProduction: string | null
}

export interface CloudSettingsSteps {
  prerequisites: boolean
  linkAccount: boolean
  cloudDetails: boolean
  createIamRole: boolean
  generatePolicies: boolean
  assignPolicies: boolean
  solutionsSavedOnce: boolean
}
export interface AcknowledgedRequiredPersons {
  acknowledgedRequiredPersons: boolean
}

export const initialValues: AwsCloudSettingsSectionsProps = {
  [AwsCloudSettingsSections.PREREQUISITES]: {
    isApnEnrolled: false,
    areSolutionsConfigured: false,
    isAceEligible: false,
  },
  [AwsCloudSettingsSections.LINK_ACCOUNT]: {
    awsAccountId: '',
  },
  [AwsCloudSettingsSections.CLOUD_DETAILS]: {
    partnerServicePath: false,
    partnerSoftwarePath: false,
    isv: false,
    awsIsvAccelerateProgram: false,
    securityServiceSoftwareOnly: false,
    securityServiceManagedServices: false,
    securityServiceProfessionalServices: false,
    msspProgram: false,
    awsServicesPartnerTier: null,
  },
  [AwsCloudSettingsSections.CREATE_IAM_ROLE]: {
    stagingArn: '',
    productionArn: '',
  },
  [AwsCloudSettingsSections.GENERATE_POLICIES]: {
    iamPolicyStaging: '',
    iamPolicyProduction: '',
  },
  [AwsCloudSettingsSections.STEPS]: {
    prerequisites: false,
    linkAccount: false,
    cloudDetails: false,
    createIamRole: false,
    generatePolicies: false,
    assignPolicies: false,
    solutionsSavedOnce: false,
  },
  [AwsCloudSettingsSections.ACKNOWLEDGED_REQUIRED_PERSONS]: {
    acknowledgedRequiredPersons: false,
  },
}
