import {
  RadioButtonGroupMapping,
  RadioButtonType,
} from '@labrav/react-components' // Import necessary types from react-components

export const getContractDurationOptions = (
  isContainerPricingModalNotContract: boolean,
  isFlexible: boolean,
  noCustom = false
): RadioButtonGroupMapping[] => {
  const contractDurationOptions: RadioButtonGroupMapping[] = [
    ...(isContainerPricingModalNotContract
      ? [
          ...(!isFlexible
            ? [
                {
                  key: '1H',
                  label: 'Hourly',
                  type: RadioButtonType.STANDARD,
                },
              ]
            : []),
          {
            key: '1Y',
            label: '1 year',
            type: RadioButtonType.STANDARD,
          },
          {
            key: '2Y',
            label: '2 years',
            type: RadioButtonType.STANDARD,
          },
          {
            key: '3Y',
            label: '3 years',
            type: RadioButtonType.STANDARD,
          },
        ]
      : [
          {
            key: '1',
            label: '1 month',
            type: RadioButtonType.STANDARD,
          },
          {
            key: '12',
            label: '12 months',
            type: RadioButtonType.STANDARD,
          },
          {
            key: '24',
            label: '24 months',
            type: RadioButtonType.STANDARD,
          },
          {
            key: '36',
            label: '36 months',
            type: RadioButtonType.STANDARD,
          },
        ]),
    ...(!noCustom
      ? [
          {
            key: 'custom',
            label: `custom duration (in ${
              !isContainerPricingModalNotContract ? 'months' : 'days'
            })`,
            type: RadioButtonType.STANDARD,
          },
        ]
      : []),
  ]
  return contractDurationOptions
}

export const startDateOptions = [
  {
    label: 'Offer acceptance date',
    value: true,
  },
  {
    label: 'Specific future date',
    value: false,
  },
]
