import { Truncate } from '@labrav/react-components'
import React from 'react'

interface ShortenedNameV2Props {
  name: string | JSX.Element
  length?: number
  showClipboardTooltip?: boolean
}

export const ShortenedNameV2 = React.memo(
  ({ length, showClipboardTooltip = true, ...props }: ShortenedNameV2Props) => {
    const passedName = props.name || ''
    const shouldTruncate =
      typeof passedName === 'string' && length && passedName.length > length
    return (
      <Truncate
        width={shouldTruncate ? `${length}ch` : undefined}
        clipboardTooltip={showClipboardTooltip}
      >
        {passedName}
      </Truncate>
    )
  }
)
