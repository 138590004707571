import React, { useState } from 'react'
import { MigrationAlert } from './MigrationAlert/MigrationAlert'

export const MigrationAlertModalWrapper: React.FC = () => {
  const [migrationModal, setMigrationModal] = useState<boolean>(true)
  const closeModal = () => {
    setMigrationModal(false)
  }

  const handleMigration = async () => {
    closeModal()
  }
  return (
    <MigrationAlert
      open={migrationModal}
      onClose={closeModal}
      onAccept={handleMigration}
    />
  )
}
