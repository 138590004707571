import { Reducer } from 'redux'
import { contactsActionTypes } from './action'

interface Contact {
  email: string
  firstName: string
  lastName: string
  title: string
  createdAt: string
  associatedOffers: string[]
}

export interface Contacts {
  contacts: Contact[]
}

export const initialState: Contacts = { contacts: [] }

type actionType = {
  type: contactsActionTypes.SET_BUYER_CONTACTS
  payload: Contacts
}

export const reducer: Reducer<Contacts, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case contactsActionTypes.SET_BUYER_CONTACTS: {
      return {
        ...state,
        contacts: action.payload.contacts,
      }
    }
    default:
      return state
  }
}
