import { FlyoutFunction } from '../../../flyout/modules/productListingFilter/reducer'
import { CloudMarketplace } from '../../../flyout/modules/productsListing/reducer'
import { useSelector } from '../../../store'
import { getFlyOutFunctionTypeBasedOnUrl } from './getFlyOutFunctionTypeBasedOnUrl'

export const useCurrentCloudProvider = () => {
  const selectedFunctionType = getFlyOutFunctionTypeBasedOnUrl()
  const currentCloudProvider = useSelector<CloudMarketplace>(
    state => state.productListingFilters.cloudMarketplace
  )
  const returnCloudTypeValue =
    selectedFunctionType === FlyoutFunction.REVENUE ||
    selectedFunctionType === FlyoutFunction.RESALE_AUTHORIZATIONS ||
    selectedFunctionType === FlyoutFunction.SUBSCRIPTION
      ? 'AWS'
      : currentCloudProvider
  return returnCloudTypeValue
}
