import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  envInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    border: `1px solid ${theme.palette.textField.light}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2, 3),
  },
  horizontalDivider: {
    height: theme.spacing(0.125),
    borderRadius: theme.spacing(0.5),
    backgroundColor: theme.palette.dividerV2.main,
  },
  envInfoHeading: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
  envInfoSubHeading: {
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: theme.palette.labraText.description,
  },
}))
