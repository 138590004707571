import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
  },
  root: {
    display: 'flex',
    padding: theme.spacing(5),
  },
  disableImage: {
    marginRight: theme.spacing(4),
  },
  contentContainer: {
    paddingLeft: theme.spacing(7),
  },
  title: {
    marginBottom: theme.spacing(4),
    fontFamily: theme.typography.fontFamily,
    fontSize: '22px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px',
    color: theme.palette.labraText.primary,
  },
  subTitle: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: theme.palette.labraText.tertiary,
  },
  listItem: {
    display: 'flex',
    marginTop: theme.spacing(1.5),
    alignItems: 'center',
    fontWeight: 400,
    width: '100%',
  },
  listItemText: {
    listStyleType: 'none',
    fontSize: '14px',
  },
  button: {
    marginTop: theme.spacing(4),
  },
}))
