import { DateTime } from 'luxon'
import { StorefrontMetricsActionTypes } from './action'
import { Reducer } from 'redux'

export type StatRiseType = 'up' | 'down'
export interface ImpressionCount {
  totalCount?: number
  percentage?: number
  key?: string
}
export interface ButtonsRecords {
  clicks?: number
  clickPercentage?: number
  formSubmissionPercentage?: number
  formSubmission?: number
  key: string
}
export interface TrafficSource {
  ref?: string
  count?: number
  percentage?: number
}
export interface TrafficSourceData {
  top3?: TrafficSource[]
  otherTrafficSource?: TrafficSource[]
}

export interface InteractionsData {
  id: string
  data: { date: string; value: number }[]
}
export interface DateRange {
  startDate: string
  endDate: string
}

export interface MetricsData {
  impressionCount: ImpressionCount[]
  buttonRecords: ButtonsRecords[]
  trafficSource: TrafficSourceData
  campaignsEfficiency: CampaignEfficiency[]
  domain?: string
  graphData?: InteractionsData[]
  domainNames?: string[]
  selectedProduct: any
  productOptions: []
  dateRange: DateRange
  mapData: any
}
export interface CampaignEfficiency {
  count: number
  name: string
  percentage: number
  otherCampaignsCounts?: CampaignEfficiency[]
}
const initialState: MetricsData = {
  impressionCount: [],
  buttonRecords: [],
  trafficSource: {},
  campaignsEfficiency: [],
  domain: 'All domains',
  selectedProduct: 'allListings',
  graphData: [],
  domainNames: [],
  productOptions: [],
  dateRange: {
    startDate: DateTime.now().minus({ months: 6 }).toISODate() as string,
    endDate: DateTime.now().toISODate() as string,
  },
  mapData: {},
}

type actionType =
  | {
      type: StorefrontMetricsActionTypes.SET_STOREFRONT_METRICS_DATA
      payload: MetricsData
    }
  | {
      type: StorefrontMetricsActionTypes.CLEAR_STOREFRONT_METRICS_DATA
    }
  | {
      type: StorefrontMetricsActionTypes.SET_STOREFRONT_METRICS_DOMAIN
      payload: string
    }
  | {
      type: StorefrontMetricsActionTypes.SET_STOREFRONT_METRICS_PRODUCT
      payload: string
    }
  | {
      type: StorefrontMetricsActionTypes.SET_DATE_RANGE
      payload: DateRange
    }

export const reducer: Reducer<MetricsData, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case StorefrontMetricsActionTypes.SET_STOREFRONT_METRICS_DATA: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case StorefrontMetricsActionTypes.SET_STOREFRONT_METRICS_DOMAIN: {
      return {
        ...state,
        domain: action.payload,
      }
    }
    case StorefrontMetricsActionTypes.SET_STOREFRONT_METRICS_PRODUCT: {
      return {
        ...state,
        selectedProduct: action.payload,
      }
    }
    case StorefrontMetricsActionTypes.SET_DATE_RANGE: {
      return {
        ...state,
        dateRange: action.payload,
      }
    }
    case StorefrontMetricsActionTypes.CLEAR_STOREFRONT_METRICS_DATA:
      return initialState

    default: {
      return state
    }
  }
}
