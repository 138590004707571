import React, { useEffect, useState } from 'react'
import { useStyles } from '../EnvironmentConnectionSection.styles'
import { useStyles as commonStyles } from '../../CloudSettingsWrapper.styles'
import {
  StatusChipBg,
  StatusChipText,
  StatusChipV3,
} from '@labrav/react-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGears } from '@fortawesome/pro-regular-svg-icons'
import {
  faCircleCheck as solidCircleCheck,
  faCircleXmark as solidCircleXmark,
} from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx'
import { RootState, useSelector, useDispatch } from '../../../../../../../store'
import {
  usePartnerId,
  useUserType,
} from '../../../../../../../common/utils/Hooks/usePartnerData'
import {
  getArnConnectionStatus,
  getPartnerSolutions,
} from '../../../../../../api'
import { updateAppAlert } from '../../../../../../../common/modules/appAlert/actions'
import {
  startLoading,
  stopLoading,
} from '../../../../../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../../../../../common/modules/loading/reducer'
import { AwsCloudSettingsSectionsProps } from '../../../types'
import { useFormikContext } from 'formik'
import { isEmpty } from 'lodash'
import { faLoader } from '@fortawesome/pro-duotone-svg-icons'
import { Typography } from '@material-ui/core'
import { useOppSyncOnboardingStatus } from '../../../../../../utils/hooks/useOppSyncOnboardingStatus'
import { MigrationAlertModalWrapper } from '../../MigrationAlertModalWrapper/MigrationAlertModalWrapper'

export const EnvironmentConnectionStatus: React.FC = () => {
  const classes = useStyles()
  const commonClasses = commonStyles()
  const formik = useFormikContext<AwsCloudSettingsSectionsProps>()
  const { values } = formik
  const partnerType = useUserType()
  const partnerId = usePartnerId()
  const dispatch = useDispatch()
  const { onboardingComplete } = useOppSyncOnboardingStatus()
  const isOldUser = useSelector(
    (state: RootState) =>
      state.PartnerData[partnerType].partnerData?.awsConfigMetadata?.steps
        .solutionsSavedOnce
  )
  const [connectionStatus, setConnectionStatus] = useState<
    'loading' | 'connected' | 'failed'
  >('loading')

  const [currentEnvStatus, setCurrentEnvStatus] = useState<{
    legacyConnection?: { code: number; message?: string }
    aceApisConnection?: { code: number; message?: string }
  }>({})

  const isSandbox = useSelector(
    (state: RootState) =>
      state.engineServicePartner[partnerType]?.engineServicePartnerData
        ?.isSandbox
  )
  const envType = isSandbox ? 'staging' : 'production'

  const isUsingAceApi = useSelector(
    (state: RootState) =>
      state.engineServicePartner[partnerType]?.engineServicePartnerData
        ?.useAceApi
  )

  const fetchAndValidateArns = (
    partnerId: string | null,
    values: AwsCloudSettingsSectionsProps
  ): { stagingArn?: string; productionArn?: string } => {
    if (!partnerId) {
      throw new Error('Partner Id is missing')
    }
    const stagingArn = values.createIamRole?.stagingArn
    const productionArn = values.createIamRole?.productionArn

    if (!productionArn) {
      throw new Error(
        `${!stagingArn ? 'Staging &' : ''} Production ARN is required.`
      )
    }
    if (!stagingArn) {
      throw new Error(
        `${!productionArn ? 'Production &' : ''} Staging ARN is required.`
      )
    }

    return { stagingArn, productionArn }
  }

  const fetchAndValidateSolutions = async (
    partnerId: string
  ): Promise<boolean> => {
    const solutionsAndProducts = await getPartnerSolutions(partnerId, {
      updateCache: false,
    })
    return !isEmpty(solutionsAndProducts?.cloud_config?.aws_solutions)
  }

  const handleConnectionSuccess = () => {
    setConnectionStatus('connected')
    dispatch(stopLoading(LoadingTypes.COSELL_CLOUD_SETTINGS))
  }

  const handleConnectionFailure = (message: string) => {
    setConnectionStatus('failed')
    dispatch(
      updateAppAlert({
        message,
        messageType: 'ERROR',
        autoClose: false,
      })
    )
    dispatch(stopLoading(LoadingTypes.COSELL_CLOUD_SETTINGS))
  }

  useEffect(() => {
    const testConnection = async () => {
      if (!partnerId) {
        handleConnectionFailure('Partner Id is missing')
        return
      }

      dispatch(startLoading(LoadingTypes.COSELL_CLOUD_SETTINGS))
      setConnectionStatus('loading')
      const isAceEligible = values.prerequisites?.isAceEligible

      try {
        const { stagingArn, productionArn } = fetchAndValidateArns(
          partnerId,
          values
        )
        const currentArn = envType === 'staging' ? stagingArn : productionArn

        if (!currentArn) {
          handleConnectionFailure(`${envType} ARN is missing.`)
          return
        }

        const currentEnvStatus = await getArnConnectionStatus(
          partnerId,
          currentArn,
          envType
        )

        setCurrentEnvStatus(currentEnvStatus)

        let isConnectionSuccessful
        if (!isUsingAceApi) {
          // Only check legacy connection when not using ACE APIs
          isConnectionSuccessful =
            currentEnvStatus.legacyConnection?.code === 200
        } else {
          // Original logic for ACE API users
          isConnectionSuccessful = isAceEligible
            ? currentEnvStatus.legacyConnection?.code === 200 &&
              currentEnvStatus.aceApisConnection?.code === 200
            : currentEnvStatus.aceApisConnection?.code === 200
        }

        if (!isConnectionSuccessful) {
          let errorMessage = `Failed to verify ${envType} ARN connection. Please check your ARN settings and try again.`

          if (!isUsingAceApi) {
            // Only show legacy connection error when not using ACE APIs
            if (currentEnvStatus?.legacyConnection?.code !== 200) {
              errorMessage += `\n${envType} Leads System: ${
                currentEnvStatus.legacyConnection?.message ||
                'Connection failed'
              }`
            }
          } else {
            // Original error message logic for ACE API users
            if (
              isAceEligible &&
              currentEnvStatus?.legacyConnection?.code !== 200
            ) {
              errorMessage += `\n${envType} Leads System: ${
                currentEnvStatus.legacyConnection?.message ||
                'Connection failed'
              }`
            }

            if (currentEnvStatus?.aceApisConnection?.code !== 200) {
              errorMessage += `\n${envType} ACE APIs: ${
                currentEnvStatus.aceApisConnection?.message ||
                'Connection failed'
              }`
            }
          }

          handleConnectionFailure(errorMessage)
          return
        }

        const hasSolutions = await fetchAndValidateSolutions(partnerId)
        if (!hasSolutions) {
          handleConnectionFailure(
            'No solutions found for the partner, please add solutions on the Partner Central Portal'
          )
          return
        }

        handleConnectionSuccess()
      } catch (error: any) {
        handleConnectionFailure(error.message || 'Failed to verify connection.')
      }
    }

    testConnection()
  }, [isSandbox, partnerType, partnerId, dispatch, isUsingAceApi])

  return (
    <>
      <div className={classes.envName}>
        <FontAwesomeIcon className={classes.gearsIcon} icon={faGears} />
        <Typography className={classes.envNameText}>
          {isSandbox ? 'Staging' : 'Production'} environment
        </Typography>
      </div>
      <div className={classes.envStatus}>
        <StatusChipV3
          bgColor={
            connectionStatus === 'connected'
              ? StatusChipBg.GREEN
              : connectionStatus === 'failed'
              ? StatusChipBg.RED
              : StatusChipBg.GRAY
          }
          label={
            connectionStatus === 'connected'
              ? 'Connected'
              : connectionStatus === 'failed'
              ? 'Connection failed'
              : 'Loading'
          }
          sizeVarient="normal"
          icon={
            <FontAwesomeIcon
              data-testid="step-icon"
              icon={
                connectionStatus === 'connected'
                  ? solidCircleCheck
                  : connectionStatus === 'failed'
                  ? solidCircleXmark
                  : faLoader
              }
              spinPulse={connectionStatus === 'loading'}
              className={clsx(
                commonClasses.loader,
                connectionStatus === 'connected'
                  ? classes.check
                  : connectionStatus === 'failed'
                  ? classes.failed
                  : classes.loading
              )}
            />
          }
          textColor={
            connectionStatus === 'connected'
              ? StatusChipText.GREEN
              : connectionStatus === 'failed'
              ? StatusChipText.RED
              : StatusChipText.GRAY
          }
        />
      </div>

      {/* dev-comment: Modal should be displayed to the customer when:
      1. Their S3 legacy connection is a success
      2. Their ACE APIs connection is a failure
      3. Their onboarding is already completed (solutions_saved_once is true) this means they are an old customers and not a new one */}
      {currentEnvStatus?.aceApisConnection?.code !== 200 &&
        currentEnvStatus?.legacyConnection?.code === 200 &&
        isOldUser &&
        onboardingComplete && <MigrationAlertModalWrapper />}
    </>
  )
}
