import React, { useState } from 'react'
import { SidePanelInfo, ButtonV2 } from '@labrav/react-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons'
import { HelpContent } from './HelpContent/HelpContent'
import helpData from '../../../mocks/HelpData.json'
import { helpDrawerUrl } from '../../utils/constants'
import { Typography } from '@material-ui/core'
import { useStyles } from './HelpDrawer.styles'
import { useTracker } from '../../utils/Tracker/hook'

export interface HelpTextData {
  type: string
  text?: string
  link?: string
  linkText?: string
}
export interface HelpContentData {
  question: string
  helpAnswer: HelpTextData[]
}
export interface HelpContentForSidePanel {
  title: string
  content: HelpContentData[]
}
const faqs: Record<string, HelpContentForSidePanel> = helpData
export interface HelpDrawerProps {
  headerTitle: string
  sectionName: string
  type?: 'button' | 'text'
  helpTextTitle?: string
  dataOrder?: string[]
  helpDrawerText?: string
}
export const HelpDrawer = React.memo(
  ({
    headerTitle,
    sectionName,
    type = 'button',
    helpTextTitle,
    helpDrawerText,
  }: HelpDrawerProps) => {
    const tracker = useTracker()
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [sectionData, setSectionData] = useState<HelpContentForSidePanel>(
      {} as HelpContentForSidePanel
    )
    const styles = useStyles()
    const openDrawer = () => {
      setSectionData(faqs[sectionName])
      setIsOpen(true)
      tracker?.track({
        product: 'OPPSYNC',
        section: sectionName,
        component: 'help-drawer',
        action: 'opened',
        title: headerTitle,
      })
    }
    const toggleDrawer = async () => {
      setSectionData({} as HelpContentForSidePanel)
      setIsOpen(false)
    }

    const openHelpUrl = () => {
      const newWindow = window.open(
        helpDrawerUrl,
        '_blank',
        'noopener, noreferrer'
      )
      if (newWindow) newWindow.opener = null
    }

    return (
      <>
        {type === 'button' ? (
          <div key={`${sectionName}-${headerTitle}`}>
            <ButtonV2
              key={`${sectionName}-${headerTitle}`}
              styleType="link"
              data-testid="help-button-drawer"
              icon={
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  style={{ height: '16px', width: '16px', fontSize: '16px' }}
                />
              }
              onClick={openDrawer}
            >
              {helpDrawerText ? helpDrawerText : 'Help & FAQs'}
            </ButtonV2>
          </div>
        ) : (
          <Typography className={styles.textUrl} onClick={openDrawer}>
            {helpTextTitle ? helpTextTitle : 'Help'}
          </Typography>
        )}
        <div style={{ display: 'none' }}>
          <SidePanelInfo
            icon={
              <FontAwesomeIcon
                icon={faCircleInfo}
                style={{ width: '24px', height: '24px', fontSize: '24px' }}
              />
            }
            headerTitle={headerTitle}
            title={sectionData?.title}
            open={isOpen}
            onClose={toggleDrawer}
            content={<HelpContent helpContent={sectionData?.content} />}
            getHelp={openHelpUrl}
          />
        </div>
      </>
    )
  }
)
