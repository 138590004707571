import { makeStyles } from '@material-ui/core/styles'
export const useStyles = makeStyles(theme => ({
  titleUserIcon: {
    color: theme.palette.button.primary,
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },
  memberRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  roleBadgeBlue: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.avatarColors.blue,
    width: 'fit-content',
    padding: theme.spacing(0.5, 1),
    gap: theme.spacing(1),
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
  },
  roleBadgePurple: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.avatarColors.purple,
    width: 'fit-content',
    padding: theme.spacing(0.5, 1),
    gap: theme.spacing(1),
    alignItems: 'center',
    borderRadius: theme.spacing(0.5),
  },
  descriptionContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  boldText: {
    fontWeight: 500,
  },
  marginLeftText: {
    fontWeight: 500,
    marginLeft: theme.spacing(0.5),
  },
}))
