import { useState, useEffect } from 'react'

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    screenWidth: screenSize.width,
    screenHeight: screenSize.height,
    pageSize: getInitialPageSize(screenSize.height),
  }
}

export default useScreenSize

const getInitialPageSize = (screenHeight: number) => {
  if (screenHeight < 800) {
    return 15
  } else if (screenHeight >= 800 && screenHeight <= 1200) {
    return 25
  } else if (screenHeight > 1201 && screenHeight <= 2160) {
    return 35
  } else {
    return Math.ceil(screenHeight / 62)
  }
}
