import React, { useState } from 'react'
import {
  ButtonV2,
  ModalConfirmV2,
  ModalSizeVariants,
} from '@labrav/react-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import { useStyles } from '../EnvironmentConnectionSection.styles'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../../../store'
import {
  usePartnerId,
  useUserType,
} from '../../../../../../../common/utils/Hooks/usePartnerData'
import { updateEngineServicePartnerData } from '../../../../../../modules/engineServicePartner/action'
import { updateAppAlert } from '../../../../../../../common/modules/appAlert/actions'

export const ChangeEnvironment = () => {
  const classes = useStyles()
  const partnerType = useUserType()
  const partnerId = usePartnerId()
  const dispatch = useDispatch()
  const isSandbox = useSelector(
    (state: RootState) =>
      state.engineServicePartner[partnerType].engineServicePartnerData
        ?.isSandbox
  )

  const companyName = useSelector(
    (state: RootState) =>
      state.PartnerData[partnerType].partnerData?.companyName
  )
  const [openChangeEnvModal, setChangeEnvModal] = useState<boolean>(false)
  const openModal = () => {
    setChangeEnvModal(true)
  }
  const onCloseModal = () => {
    setChangeEnvModal(false)
  }
  const handleChangeEnv = async () => {
    setChangeEnvModal(false)

    if (partnerId) {
      const updatedIsSandbox = !isSandbox

      const error = await (dispatch as any)(
        updateEngineServicePartnerData(partnerId, partnerType, {
          isSandbox: updatedIsSandbox,
        })
      )

      if (!error) {
        dispatch(
          updateAppAlert({
            message: `ACE environment changed to ${
              !isSandbox ? 'Staging' : 'Production'
            } successfully.`,
            messageType: 'SUCCESS',
            autoClose: true,
          })
        )
      }
    }
  }
  return (
    <>
      <ButtonV2
        styleType="outlined"
        data-testid={'change-environment-button'}
        smallVariant={true}
        onClick={openModal}
      >
        Change environment
      </ButtonV2>
      <ModalConfirmV2
        data-testid="change-env-modal"
        titleAcceptButton={`Move to ${!isSandbox ? 'Staging' : 'Production'}`}
        title="Change ACE environment"
        icon={
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className={classes.exclaimIcon}
          />
        }
        onCloseModal={onCloseModal}
        onAccept={handleChangeEnv}
        scrollContent={true}
        description={`Are you sure you want to move "${companyName}" to ${
          !isSandbox ? 'Staging' : 'Production'
        }? 
              It can disrupt the customer's sync.`}
        acceptDisabled={false}
        hideAcceptButton={false}
        onlyCloseModal={true}
        open={openChangeEnvModal}
        hideCancelButton={false}
        variant={ModalSizeVariants.small}
        onDecline={onCloseModal}
      />
    </>
  )
}
