import { Reducer } from 'redux'
import { BuyersActionTypes } from './action'

export interface BuyersState {
  overview: BuyersData
}
export type ContractType = 'PUBLIC' | 'PRIVATE'
interface BuyerInfo {
  companyName: string
  logoUrl: string
}

interface BuyerDetails {
  totalTcv: number
  renewal: string
  autoRenew: boolean
  buyerSince: string
  totalContracts: number
  activeContracts: number
  churnSignal: string
  awsCloudAccountIds: string[]
  awsCustomerIdentifierIds: string[]
  creatorNames: string[]
}
export interface JourneyStates {
  offerSent?: string
  offerAccepted?: string
  contractStarted?: string
  contractEnded?: string
  contractRenewal?: null
  offerAboSent?: string
  offerAboAccepted?: string
  contractUnsubscribed?: string
}

export interface JourneyContractDetails {
  contractName: string
  states: { contractId: string; states: JourneyStates }[]
}

export interface BuyerJourneyContracts {
  contractCreatedAt: string
  contractId: string
  contractName: string
  contractType: ContractType
}

interface Buyer {
  companyName: string
  domain: string
  labraBuyerDetailsId: string
  buyerInfo: BuyerInfo
  details: BuyerDetails
}

export interface BuyersData {
  buyers: Buyer[]
  buyerJourney: JourneyContractDetails
  buyerContracts: BuyerJourneyContracts[]
}

export const initialState: BuyersData = {
  buyers: [],
  buyerJourney: {} as JourneyContractDetails,
  buyerContracts: [],
}

type actionType =
  | {
      type: BuyersActionTypes.SET_BUYERS
      payload: BuyersData
    }
  | {
      type: BuyersActionTypes.SET_JOURNEY_DATA
      payload: JourneyContractDetails
    }
  | {
      type: BuyersActionTypes.SET_BUYER_CONTRACTS_DATA
      payload: BuyerJourneyContracts[]
    }
  | {
      type: BuyersActionTypes.CLEAR_BUYERS
    }

export const reducer: Reducer<BuyersData, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case BuyersActionTypes.SET_BUYERS: {
      return {
        ...state,
        buyers: action.payload.buyers,
      }
    }
    case BuyersActionTypes.SET_BUYER_CONTRACTS_DATA: {
      return {
        ...state,
        buyerContracts: action.payload,
      }
    }
    case BuyersActionTypes.SET_JOURNEY_DATA: {
      return {
        ...state,
        buyerJourney: action.payload,
      }
    }
    case BuyersActionTypes.CLEAR_BUYERS: {
      return {
        ...state,
        buyers: [],
      }
    }
    default:
      return state
  }
}
