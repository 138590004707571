import { Template } from './reducer'
import {
  startLoading,
  stopLoading,
} from '../../../common/modules/loading/actions'
import { LoadingTypes } from '../../../common/modules/loading/reducer'
import { updateAppAlert } from '../../../common/modules/appAlert/actions'
import { AxiosResponse } from 'axios'
import { RequestFailureMessage } from '../../../common/utils/messagesContants'
import { AppDispatch, RootState } from '../../../store'
import { camelize, snakeize } from 'casing'
import { getErrorMessages } from '../../../common/utils/error'
import { errorLogger } from '../../../common/utils/errorLogger'
import { getIntegrationsData, getTemplatesData } from '../../api/markeplace'
import { IntegrationData } from './templateCreation/reducer'
import integrationsListingMock from '../../../mocks/integrationsListingMock.json'
import { isEmpty } from 'lodash'
export enum StorefrontActionTypes {
  SET_TEMPLATES_DATA = 'SET_TEMPLATES_DATA',
  SET_INTEGRATIONS_DATA = 'SET_INTEGRATIONS_DATA',
}

export const getTemplates =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(startLoading(LoadingTypes.TEMPLATES))

    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''

      const { data } = await getTemplatesData(partnerId)
      await dispatch(setTemplatesData(camelize(data)))
    } catch (error: any) {
      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.TEMPLATES))
    }
  }
export const getListingIntegrations =
  () => async (dispatch: AppDispatch, getState: () => RootState) => {
    await dispatch(startLoading(LoadingTypes.GENERAL))

    try {
      const partnerId = getState().PartnerData.user.partnerData?.partnerId || ''

      const { data } = await getIntegrationsData(partnerId)
      await dispatch(setListingIntegrationsData(camelize(data)))
    } catch (error: any) {
      // Remove when not required
      await dispatch(
        setListingIntegrationsData(
          camelize(integrationsListingMock.integrations)
        )
      )

      dispatch(
        updateAppAlert({
          message: getErrorMessages([RequestFailureMessage])(
            error.response as AxiosResponse<ErrorResponse>
          ),
          messageType: 'ERROR',
          autoClose: true,
        })
      )
      const globalState = getState()
      errorLogger({ globalState })(error as Error)
    } finally {
      dispatch(stopLoading(LoadingTypes.GENERAL))
    }
  }
export const setTemplatesData = (templates: Template[]) => ({
  type: StorefrontActionTypes.SET_TEMPLATES_DATA,
  payload: templates,
})

export const setListingIntegrationsData = (
  integrations: IntegrationData[]
) => ({
  type: StorefrontActionTypes.SET_INTEGRATIONS_DATA,
  payload: integrations,
})
