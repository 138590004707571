import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/pro-regular-svg-icons'
import { Typography } from '@material-ui/core'
import { ModalConfirmV2, ModalSizeVariants } from '@labrav/react-components'
import { useStyles } from './RequiredPersonsModal.styles'

interface RequiredPersonsModalProps {
  open: boolean
  onClose: () => void
  onAccept: () => void
}

export const RequiredPersonsModal: React.FC<RequiredPersonsModalProps> = ({
  open,
  onClose,
  onAccept,
}) => {
  const classes = useStyles()

  return (
    <ModalConfirmV2
      data-testid="requirements-modal"
      titleAcceptButton="I understand"
      titleCancelButton="Back"
      title="You need these team members"
      icon={<FontAwesomeIcon icon={faUser} className={classes.titleUserIcon} />}
      onCloseModal={onClose}
      onAccept={onAccept}
      scrollContent={true}
      description="To complete this step, you may need your team members who have these roles/permissions"
      content={
        <>
          <div className={classes.contentContainer}>
            <div className={classes.memberRow}>
              <div className={classes.roleBadgeBlue}>
                <FontAwesomeIcon
                  icon={faUser}
                  className={classes.titleUserIcon}
                />
                <Typography className={classes.boldText}>
                  AWS alliance lead
                </Typography>
              </div>
              <div className={classes.descriptionContainer}>
                <Typography>
                  The Alliance Lead is your company's primary account admin in
                  the
                </Typography>
                <Typography className={classes.marginLeftText}>
                  Partner Central account.
                </Typography>
              </div>
            </div>
            <div className={classes.memberRow}>
              <div className={classes.roleBadgePurple}>
                <FontAwesomeIcon
                  icon={faUser}
                  className={classes.titleUserIcon}
                />
                <Typography className={classes.boldText}>AWS admin</Typography>
              </div>
              <div className={classes.descriptionContainer}>
                <Typography>
                  The AWS admin is a user in the AWS Console with access to
                  create or update
                </Typography>
                <Typography className={classes.marginLeftText}>
                  IAM role resources.
                </Typography>
              </div>
            </div>
          </div>
        </>
      }
      acceptDisabled={false}
      hideAcceptButton={false}
      onlyCloseModal={true}
      open={open}
      hideCancelButton={false}
      variant={ModalSizeVariants.xLarge}
      onDecline={onClose}
    />
  )
}
