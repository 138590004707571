export enum PartnerActionTypes {
  SET_PARTNER = 'SET_PARTNER',
  SET_SELECTED_PARTNER_ID = 'SET_SELECTED_PARTNER_ID',
  GET_PARTNER_FAILED = 'GET_PARTNER_FAILED',
  PARTNER_LOADING = 'PARTNER_LOADING',
  SET_CRM_ID = 'SET_CRM_ID',
  PARTNER_CLEANUP = 'PARTNER_CLEANUP',
  SET_LAST_SYNC = 'SET_LAST_SYNC',
  SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT',
  CLEAR_CURRENT_PRODUCT = 'CLEAR_CURRENT_PRODUCT',
  SET_CLOUD = 'SET_CLOUD',
  SET_IAM_POLICY = 'SET_IAM_POLICY',
  SET_S3_STATUS = 'SET_S3_STATUS',
  SET_IF_AWS_CUSTOM_CATALOG_UPLOAD = 'SET_IF_AWS_CUSTOM_CATALOG_UPLOAD',
  SET_G2_REVIEW = 'SET_G2_REVIEW',
}
