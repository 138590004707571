import React from 'react'
import { useStyles } from './CompleteOnboardingPage.styles'
import CompleteOnboarding from '../../images/Disable.png'
import {
  OnboardingProductInfo,
  OnboardingProductInfoProps,
} from './OnboardingProductInfo'

interface CompleteOnboardingPageProps {
  onboardingProductInfo: OnboardingProductInfoProps
}

export const CompleteOnboardingPage: React.FC<CompleteOnboardingPageProps> = ({
  onboardingProductInfo,
}) => {
  const classes = useStyles()
  return (
    <>
      <div className={classes.container}>
        <div className={classes.root} data-testid="complete-onboarding-page">
          <img
            src={CompleteOnboarding}
            alt="Stock-Image"
            className={classes.disableImage}
            data-testid="stock-image"
          />
          <OnboardingProductInfo {...onboardingProductInfo} />
        </div>
      </div>
    </>
  )
}
