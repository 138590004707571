import React from 'react'
import { faCheck } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@material-ui/core'
import { useStyles } from './DisbalePage.styles'
import {
  ButtonV2,
  ColumnMenuOption,
  DropdownButtonV2,
  ExternalLink,
} from '@labrav/react-components'
import padlock from '../../images/padlock.png'
import { PageVariant } from '../../../oppsync/modules/Propensity/reducer'

export interface ProductInfoProps {
  title: string
  variant?: PageVariant
  features: { text: string; subItems?: string[] }[]
  subheading?: string
  products?: string[]
  status?: string
  statusIndex?: number
  link?: string
  buttonText?: string
  customButton?: JSX.Element
}

const ProductInfo: React.FC<ProductInfoProps> = ({
  title,
  variant = PageVariant.LOCKED,
  features,
  subheading,
  products,
  status,
  statusIndex,
  link,
  buttonText,
  customButton,
}) => {
  const classes = useStyles({ variant })

  return (
    <div className={classes.contentContainer} data-testid="info">
      <Typography className={classes.title} data-testid="title">
        {(variant === PageVariant.LOCKED ||
          variant === PageVariant.PTBLOCK) && (
          <img src={padlock} alt="lock" className={classes.lock} />
        )}
        {title}
      </Typography>
      {products && (
        <div className={classes.smallImagesContainer}>
          {products?.map((imageSrc, index) => (
            <div key={index} className={classes.imageWrapper}>
              <img
                src={imageSrc}
                alt={`Product ${index}`}
                className={classes.smallImage}
                data-testid={`product-image-${index}`}
              />
              {index === statusIndex && status && (
                <span className={classes.status}>{status}</span>
              )}
            </div>
          ))}
        </div>
      )}
      {subheading && (
        <Typography className={classes.subTitle} data-testid="subheading">
          {subheading}
        </Typography>
      )}
      {features.map((feature, index) => (
        <div className={classes.listItem} data-testid={`feature-${index}`}>
          <FontAwesomeIcon
            icon={faCheck}
            style={{
              width: '14px',
              height: '15px',
              marginRight: '8px',
            }}
          />
          <li key={index} className={classes.listItemText}>
            {feature.text}
            {feature.subItems && (
              <div className={classes.subItems}>
                {feature.subItems.map((subItem, subIndex) => (
                  <div
                    key={`${index}-${subIndex}`}
                    className={classes.subItem}
                    data-testid={`subitem-${index}-${subIndex}`}
                  >
                    {subItem}
                  </div>
                ))}
              </div>
            )}
          </li>
        </div>
      ))}
      {link && (
        <div className={classes.link}>
          <ExternalLink href={link}>
            <ButtonV2 styleType="link">Visit website to learn more</ButtonV2>
          </ExternalLink>
        </div>
      )}
      <div className={classes.button}>
        {variant === PageVariant.LOCKED || variant === PageVariant.PTBLOCK ? (
          <ButtonV2
            styleType="primary"
            onClick={() =>
              window.open('https://helpcenter.labra.io/hc/en-us/requests/new')
            }
            data-testid="contact-button"
          >
            {buttonText}
          </ButtonV2>
        ) : (
          customButton
        )}
      </div>
    </div>
  )
}

export default ProductInfo
