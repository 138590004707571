export const extractHexAndAlpha = (hexString: string) => {
  if (hexString.length === 9) {
    const alphaVal = Math.round(
      (parseInt(hexString.substring(7, 9), 16) / 255) * 100
    )
    return {
      hexCode: hexString.substring(0, 7),
      alpha: alphaVal + '',
    }
  }
  return {
    hexCode: hexString.substring(0, 7),
    alpha: 100 + '',
  }
}

export const extractColor = (colorType: string, colorString: string) => {
  if (colorString === '') {
    return colorType === 'RGB'
      ? ['0', '0', '0', '0', '']
      : ['0', '0', '0', '', '']
  }
  const hexAndAlpha = extractHexAndAlpha(colorString)
  return colorType === 'RGB'
    ? colorString
        .substring(5, colorString.length - 1)
        .split(',')
        .map(function (value, index) {
          return index === 3
            ? parseFloat(value.trim()) * 100 + ''
            : value.trim()
        })
        .concat('')
    : ['0', '0', '0', hexAndAlpha?.alpha || '', hexAndAlpha?.hexCode || '']
}
