import { Typography } from '@material-ui/core'
import { CloudType } from '../../../../../common/modules/types'
import { useStyles } from './OnboardingHeaderTitleBar.styles'
import aws from '../../../../../common/images/aws.png'
import azure from '../../../../../common/images/azure.png'

type OnboardingHeaderTitleBarProps = {
  title?: string
  cloudType: CloudType
}

const cloudLogos = {
  [CloudType.AWS]: aws,
  [CloudType.AZURE]: azure,
  [CloudType.GCP]: '',
  [CloudType.REDHAT]: '',
}

export const OnboardingHeaderTitleBar: React.FC<
  OnboardingHeaderTitleBarProps
> = ({ title, cloudType }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.upperContainer}>
        <Typography data-testid="wrapper-title" className={classes.title}>
          {title}
        </Typography>
      </div>
      <div className={classes.image}>
        <img
          className={classes.logo}
          src={cloudLogos[cloudType]}
          alt="aws"
          data-testid="aws-logo"
        />
      </div>
    </div>
  )
}

export default OnboardingHeaderTitleBar
