import { cloneDeep, isEmpty, isEqual } from 'lodash'
import { Reducer } from 'redux'
import { NotificationsActions } from './actions'
import { Recipient } from './notificationSidePanel/reducer'

export enum NotificationEnabledType {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
}

export interface PartnerLevel {
  name: string
  isConfigured: boolean
  metaDataFields: Record<string, string>
}
export interface ActiveChannelType {
  channelId: string
}

export interface MetaDataType {
  activeChannel?: ActiveChannelType
}

export interface MetaDataV2Type {
  channelId: string
}

export interface RecipientType {
  idpUserId: string | null
  destinationValue: string | null
  metaData: MetaDataType | null
  notificationMethodId?: string
}

export interface NotificationsTypeMethods {
  productNotificationTypeMethodId: string
  methodId: string
  type: string
  isEnabled?: boolean
  recipients?: RecipientType[]
}

export interface availableMethodsData {
  partnerLevel: {
    [x: string]: PartnerLevel
  }
  userLevel: {
    [x: string]: Record<string, string>
  }
}

export interface ProductNotificationTypes {
  productNotificationTypeId: string
  notificationTypeId: string
  notificationTypeDescription: string
  notificationTypeName: string
  notificationEnabled: NotificationEnabledType
  specialRecipients?: string[]
  productNotificationTypeMethods: NotificationsTypeMethods[]
}
export interface NotificationsSettingPage {
  productName: string
  productId: string
  availableMethods: availableMethodsData
  productNotificationTypes: ProductNotificationTypes[]
}

export interface NotificationsDetails {
  productNotificationTypeId: string
  notificationMethodId: string
  recipients?: string[]
}

export interface Notifications {
  partnerId: string
  idpUserId?: string
  notifications: NotificationsDetails[]
}

export interface RecipientV2Type {
  idpUserId: string | null
  destinationValue: string | null
  metaData: MetaDataV2Type | null
  notificationMethodId?: string
}

export interface productNotificationRecipient {
  productNotificationTypeId: string
  notificationEnabled: NotificationEnabledType
  recipients: RecipientV2Type[]
  specialRecipients?: string[]
}

export interface productNotificationRecipients {
  productNotificationRecipients: productNotificationRecipient[]
}

export type MetaData = Record<string, string>

export interface recipients {
  idpUserId: string | null
  destinationValue: string | null
  metaData?: MetaDataType | null
}

export interface ProductNotificationsTypeMethods {
  partnerId: string
  productNotificationTypeId: string
  notificationMethodId: string
  recipients: recipients[]
}

export type NotificationsState = {
  notifications?: Notifications
  initialNotificationsSettingData: NotificationsSettingPage
  notificationsSettingData: NotificationsSettingPage
  productNotificationTypeMethods: ProductNotificationsTypeMethods
  isFormDirty: boolean
  settingsPageDataToBeUpdatedForDiscard: NotificationsSettingPage
}
export const initialSettingsPageData: NotificationsSettingPage = {
  productName: '',
  productId: '',
  productNotificationTypes: [],
  availableMethods: {
    partnerLevel: {},
    userLevel: {},
  },
}
export const initialState: NotificationsState = {
  isFormDirty: false,
  notifications: undefined,
  initialNotificationsSettingData: {
    ...initialSettingsPageData,
  },
  notificationsSettingData: {
    ...initialSettingsPageData,
  },
  productNotificationTypeMethods: {
    partnerId: '',
    productNotificationTypeId: '',
    notificationMethodId: '',
    recipients: [],
  },
  settingsPageDataToBeUpdatedForDiscard: {
    ...initialSettingsPageData,
  },
}
type actionType =
  | {
      type: NotificationsActions.SET_NOTIFICATIONS_SETTING_PAGE_DATA
      payload: {
        settingsPage: NotificationsSettingPage
        partnerId: string
        idpUserId?: string
      }
    }
  | {
      type: NotificationsActions.SET_INITIAL_NOTIFICATIONS_SETTING_DATA
      payload: {
        settingsPage: NotificationsSettingPage
        partnerId: string
        idpUserId?: string
      }
    }
  | {
      type: NotificationsActions.SET_NOTIFICATIONS
      payload: Notifications
    }
  | {
      type: NotificationsActions.ADD_UNREGISTERED_USER
      payload: string
    }
  | {
      type: NotificationsActions.ADD_REGISTERED_USER
      payload: string
    }
  | {
      type: NotificationsActions.REMOVE_REGISTERED_USER
      payload: string
    }
  | {
      type: NotificationsActions.SET_PRODUCT_NOTIFICATIONS_TYPE_METHODS
      payload: ProductNotificationsTypeMethods
    }
  | {
      type: NotificationsActions.DELETE_USER
      payload: number
    }
  | {
      type: NotificationsActions.UPDATE_CHECKBOX
      payload: { value: boolean; index: number; methodId: string }
    }
  | {
      type: NotificationsActions.DISCONNECT_SLACK
      payload: {
        [x: string]: PartnerLevel
      }
    }
  | {
      type: NotificationsActions.CONNECT_SLACK
      payload: {
        [x: string]: PartnerLevel
      }
    }
  | {
      type: NotificationsActions.FORM_DIRTY
      payload: boolean
    }
  | {
      type: NotificationsActions.SET_SLACK_RECIPIENTS
      payload: Record<string, RecipientType[]>
    }
  | {
      type: NotificationsActions.UPDATE_PRODUCT_NOTIFICATION_VALUES
      payload: { id: string; key: string; value: string }
    }
  | {
      type: NotificationsActions.CLEAR_NOTIFICATIONS_DATA
    }
  | {
      type: NotificationsActions.DISCARD_SETTINGS_PAGE_DATA
    }

export const reducer: Reducer<NotificationsState, actionType> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case NotificationsActions.SET_NOTIFICATIONS_SETTING_PAGE_DATA: {
      const data = cloneDeep({ ...action.payload.settingsPage })
      return {
        ...state,
        notificationsSettingData: cloneDeep(data),
        settingsPageDataToBeUpdatedForDiscard: cloneDeep(data),
      }
    }
    case NotificationsActions.SET_INITIAL_NOTIFICATIONS_SETTING_DATA: {
      const data = cloneDeep({ ...action.payload.settingsPage })
      return {
        ...state,
        initialNotificationsSettingData: cloneDeep(data),
      }
    }
    case NotificationsActions.SET_NOTIFICATIONS: {
      return {
        ...state,
        notifications: action.payload,
      }
    }
    case NotificationsActions.SET_PRODUCT_NOTIFICATIONS_TYPE_METHODS: {
      return {
        ...state,
        productNotificationTypeMethods: action.payload,
      }
    }
    case NotificationsActions.DELETE_USER: {
      const recipientList = [
        ...(state.productNotificationTypeMethods?.recipients || []),
      ]
      recipientList.splice(action.payload, 1)
      return {
        ...state,
        isFormDirty: true,
        productNotificationTypeMethods: {
          ...state.productNotificationTypeMethods,
          recipients: recipientList,
        },
      }
    }
    case NotificationsActions.ADD_UNREGISTERED_USER: {
      const list = [...state.productNotificationTypeMethods.recipients]
      const recipientList = isEmpty(action.payload)
        ? list
        : list.concat({
            destinationValue: action.payload,
            idpUserId: null,
          })
      return {
        ...state,
        isFormDirty: true,
        productNotificationTypeMethods: {
          ...state.productNotificationTypeMethods,
          recipients: recipientList,
        },
      }
    }
    case NotificationsActions.ADD_REGISTERED_USER: {
      const list = [...state.productNotificationTypeMethods.recipients]
      const recipientList = isEmpty(action.payload)
        ? list
        : list.concat({
            idpUserId: action.payload,
            destinationValue: null,
          })
      return {
        ...state,
        isFormDirty: true,
        productNotificationTypeMethods: {
          ...state.productNotificationTypeMethods,
          recipients: recipientList,
        },
      }
    }
    case NotificationsActions.REMOVE_REGISTERED_USER: {
      const recipientList = [
        ...(state.productNotificationTypeMethods?.recipients || []),
      ].filter(user => user.idpUserId !== action.payload)

      return {
        ...state,
        isFormDirty: true,
        productNotificationTypeMethods: {
          ...state.productNotificationTypeMethods,
          recipients: recipientList,
        },
      }
    }

    case NotificationsActions.DISCONNECT_SLACK: {
      return {
        ...state,
        notificationsSettingData: {
          ...state.notificationsSettingData,
          availableMethods: {
            ...state.notificationsSettingData.availableMethods,
            partnerLevel: cloneDeep(action.payload),
          },
        },
        settingsPageDataToBeUpdatedForDiscard: {
          ...state.settingsPageDataToBeUpdatedForDiscard,
          availableMethods: {
            ...state.settingsPageDataToBeUpdatedForDiscard.availableMethods,
            partnerLevel: cloneDeep(action.payload),
          },
        },
      }
    }

    case NotificationsActions.CONNECT_SLACK: {
      return {
        ...state,
        notificationsSettingData: {
          ...state.notificationsSettingData,
          availableMethods: {
            ...state.notificationsSettingData.availableMethods,
            partnerLevel: cloneDeep(action.payload),
          },
          settingsPageDataToBeUpdatedForDiscard: {
            ...state.settingsPageDataToBeUpdatedForDiscard,
            availableMethods: {
              ...state.settingsPageDataToBeUpdatedForDiscard.availableMethods,
              partnerLevel: cloneDeep(action.payload),
            },
          },
        },
      }
    }

    case NotificationsActions.UPDATE_CHECKBOX: {
      const { value, index, methodId } = action.payload
      const tempObject = { ...state.notificationsSettingData }
      const notificationType = { ...tempObject.productNotificationTypes[index] }
      const selectedObjectIndex =
        notificationType.productNotificationTypeMethods.findIndex(
          (method: NotificationsTypeMethods) => method.methodId === methodId
        )
      const methodList = [...notificationType.productNotificationTypeMethods]
      methodList[selectedObjectIndex].isEnabled = value
      notificationType.productNotificationTypeMethods = methodList
      tempObject.productNotificationTypes[index] = notificationType
      return {
        ...state,
        isFormDirty: true,
        notificationsSettingData: tempObject,
      }
    }
    case NotificationsActions.FORM_DIRTY: {
      const val = action.payload
      return {
        ...state,
        isFormDirty: val,
      }
    }
    case NotificationsActions.SET_SLACK_RECIPIENTS: {
      const tempObject = { ...state.notificationsSettingData }
      const productNotificationTypeIdsToUpdate = Object.keys(action.payload)
      tempObject.productNotificationTypes.forEach(notification => {
        if (
          productNotificationTypeIdsToUpdate.includes(
            notification.productNotificationTypeId
          )
        ) {
          const method = notification.productNotificationTypeMethods.find(
            method => method.methodId === 'SLACK'
          )
          if (method) {
            method.recipients = [
              ...action.payload[notification.productNotificationTypeId],
            ]
          }
        }
      })

      const isFormDirty = !isEqual(
        tempObject,
        state.settingsPageDataToBeUpdatedForDiscard
      )

      return {
        ...state,
        notificationsSettingData: tempObject,
        isFormDirty: isFormDirty,
      }
    }
    case NotificationsActions.UPDATE_PRODUCT_NOTIFICATION_VALUES: {
      const { id, key, value } = action.payload
      const tempObject = { ...state.notificationsSettingData }
      const notification =
        (tempObject.productNotificationTypes.find(
          notifcation => notifcation.productNotificationTypeId === id
        ) as ProductNotificationTypes) || ({} as ProductNotificationTypes)
      if (notification) {
        ;(notification as any)[key as keyof ProductNotificationTypes] = value
      }
      const isFormDirty = !isEqual(
        tempObject,
        state.settingsPageDataToBeUpdatedForDiscard
      )

      return {
        ...state,
        notificationsSettingData: tempObject,
        isFormDirty: isFormDirty,
      }
    }
    case NotificationsActions.CLEAR_NOTIFICATIONS_DATA: {
      return {
        ...initialState,
      }
    }
    case NotificationsActions.DISCARD_SETTINGS_PAGE_DATA: {
      return {
        ...state,
        notificationsSettingData: cloneDeep(
          state.settingsPageDataToBeUpdatedForDiscard
        ),
        isFormDirty: false,
      }
    }
    default: {
      return state
    }
  }
}
