import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    height: 'auto',
  },
  topContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
  },
  tableCell: {
    background: theme.palette.bg.main,
    textAlign: 'left',
  },
  noBorder: {
    border: 'none !important',
    boxShadow: 'none !important',
  },
  tableHeadCell: {
    padding: `${theme.spacing(2.5, 0, 2.5, 0)}!important`,
  },
  tableHeading: {
    color: `var(--M3-sys-light-on-primary,) ${theme.palette.labraText.primary}`,
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px',
  },
  subHeading: {
    color: `var(--M3-sys-light-on-surface-variant, ${theme.palette.labraText.description})`,
    textAlign: 'right',
    fontFamily: theme.typography.fontFamily,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
  },
  sectionHeading: {
    color: `var(--M3-sys-light-on-primary, ${theme.palette.labraText.primary})`,
    fontFamily: theme.typography.fontFamily,
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px' /* 155.556% */,
  },
  notificationStatusTableCell: {
    width: theme.spacing(17.5),
  },
  headingContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: '100%',
    '& > div': {
      display: 'flex',
      flex: 1,
    },
  },
  button: {
    color: `${theme.palette.select.light} !important`,
    fontSize: '14px !important',
    lineHeight: '16px !important',
  },
  tableContainer: {
    marginTop: theme.spacing(1),
  },
  notificationType: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  notificationRow: {
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontWeight: 400,
    color: theme.palette.labraText.tertiary,
    lineHeight: '20px',
    fontStyle: 'normal',
    letterSpacing: '0.25px',
  },
  infoIcon: {
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(1),
    cursor: 'pointer',
    opacity: 0,
    color: theme.palette.select.main,
    transition: 'opacity 0.2s ease-in-out',
  },
  '.info-on-hover': {},
  '@global': {
    '.MuiTableRow-root:hover .info-on-hover': {
      opacity: 1,
    },
  },
  image: {
    width: theme.spacing(50.125),
    height: theme.spacing(62.75),
  },
  footerContainer: {
    width: '100%',
  },
  footerRoot: {
    height: theme.spacing(8),
    padding: theme.spacing(1.5, 5),
  },
  btnWrapper: {
    display: 'flex',
    gap: theme.spacing(3),
    marginRight: theme.spacing(11),
  },
  switchContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  slackChannelContainer: {
    width: '500px',
  },
  tableCheckBox: {
    padding: `${theme.spacing(0)} !important`,
  },
  tablesContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(3),
    height: 'auto',
    width: '100%',
    paddingBottom: theme.spacing(20),
  },
  table: {
    width: '100%',
    border: `1px solid ${theme.palette.boxShadow.main}`,
    borderRadius: theme.spacing(0.5),
  },
  description: {
    color: theme.palette.labraText.description,
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    marginBottom: theme.spacing(1.5),
  },
  autoComplete: {
    width: '468px',
  },
  modalContent: {
    color: theme.palette.labraText.description,
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
    marginTop: theme.spacing(-2),
  },
  slackChannelModalContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
  },
  discardModalContent: {
    color: theme.palette.labraText.description,
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  mainHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: theme.spacing(0),
    minWidth: '530px',
    gap: theme.spacing(1),
  },
  customOption: {
    background: theme.palette.text.white,
    boxShadow: 'none',
    padding: theme.spacing(0.75, 2),
    margin: theme.spacing(-0.75, -2),
    cursor: 'default',
    width: '100%',
    '&:hover': {
      background: theme.palette.text.white,
    },
  },
}))
