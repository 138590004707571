import { LabraToolTipV2 } from '@labrav/react-components'
import { useStyles } from './FooterV2.styles'

export interface FooterV2Props {
  actions?: JSX.Element
  width?: string
  open?: boolean
  footerState?: string
  lockedToolTipMessage?: string
  customClass?: string
}
export enum footerStateType {
  LOCKED = 'locked',
  UNLOCKED = 'unlocked',
}
export const FooterV2: React.FC<FooterV2Props> = ({
  actions,
  width,
  open = true,
  footerState,
  lockedToolTipMessage = '',
  customClass = '',
}) => {
  const classes = useStyles(width, footerState)()
  return (
    <div
      className={`${open ? classes.open : classes.closed} ${
        classes.footerContainer
      } ${customClass}`}
      data-testid="footerV2"
    >
      <LabraToolTipV2
        tooltipPlacement="top-end"
        description={
          footerState === footerStateType.LOCKED ? lockedToolTipMessage : ''
        }
        tooltipPlacmentGap="32px"
      >
        <div className={classes.actionsContainer}>{actions}</div>
      </LabraToolTipV2>
    </div>
  )
}
