import React, { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useCurrentCloudProvider } from '../../utils/Hooks/useCurrentCloudProvider'
import { actionTypeWrapper } from '../../utils/actionTypeWrapper'
import { useFlag } from '@labrav/flags'
import { cloudTypes } from '../../utils/constants'
import { useDispatch } from '../../../store'
import { setSelectedProductOnListing } from '../../../flyout/modules/productsListing/actions'
import {
  setCloudMarketplaceProductListing,
  updateProductIdOnFilters,
} from '../../../flyout/modules/productListingFilter/action'
import { CloudMarketplace } from '../../../flyout/modules/productsListing/reducer'
import { CloudSelectorV2, SelectSize } from '@labrav/react-components'
import { useFlyOutProductsBasedOnSelectedCloud } from '../../utils/Hooks/useFlyOutProductsBasedOnSelectedCloud'
import { isEmpty } from 'lodash'
import { FlyoutFunction } from '../../../flyout/modules/productListingFilter/reducer'
import { startLoading, stopLoading } from '../../modules/loading/actions'
import { LoadingTypes } from '../../modules/loading/reducer'

export type CustomCloudOptions = {
  name: string
  disabled: boolean
}[]

interface CloudChangeV2Props {
  size?: SelectSize
  customCloudOptions?: CustomCloudOptions
  setInputValue?: React.Dispatch<React.SetStateAction<string>>
  setShowCrossMark?: React.Dispatch<React.SetStateAction<boolean>>
  setSearchNow?: React.Dispatch<React.SetStateAction<boolean>>
}

export const CloudChangeV2: React.FC<CloudChangeV2Props> = ({
  size,
  customCloudOptions,
  setInputValue = () => {},
  setShowCrossMark = () => {},
  setSearchNow = () => {},
}) => {
  const { selectedProduct: productId, products } =
    useFlyOutProductsBasedOnSelectedCloud()
  const { flag: flyoutAzureCloudSelectionEnable } = useFlag(
    'flyoutAzureCloudSelection'
  )
  const { flag: flyoutGcpCloudSelectionEnable } = useFlag(
    'flyoutGcpCloudSelection'
  )
  const cloudMarketplace = useCurrentCloudProvider()
  const dispatch = useDispatch()

  const { pathname } = useLocation()
  const isEnabled = !(
    pathname.includes('subscriptions') ||
    pathname.includes('resale-authorizations') ||
    pathname.includes('revenue')
  )
  useEffect(() => {
    if (!isEmpty(products) && productId === undefined) {
      dispatch(
        updateProductIdOnFilters(
          products?.length === 1 ? products[0].productId : '',
          cloudMarketplace,
          FlyoutFunction.PRIVATE_OFFERS
        )
      )
    }
  }, [products])

  const _cloudTypes = cloudTypes
    .filter(cloud => cloud.name !== 'REDHAT')
    .map(cloud => {
      if (
        ((cloud.name === 'AZURE' && flyoutAzureCloudSelectionEnable?.value) ||
          (cloud.name === 'GCP' && flyoutGcpCloudSelectionEnable?.value)) &&
        isEnabled
      ) {
        return { ...cloud, disabled: false }
      } else {
        return cloud
      }
    })

  const handleCloudChange = useCallback(
    async (
      event: React.ChangeEvent<{
        value: unknown
      }>
    ) => {
      const selectedCloud = event.target.value as CloudMarketplace
      await dispatch(startLoading(LoadingTypes.GENERAL))
      await dispatch(
        actionTypeWrapper(
          cloudMarketplace,
          setSelectedProductOnListing(undefined)
        )
      )
      await dispatch(setCloudMarketplaceProductListing(selectedCloud))
      await dispatch(stopLoading(LoadingTypes.GENERAL))
      setInputValue('')
      setShowCrossMark(false)
      setSearchNow(false)
    },
    [cloudMarketplace, setInputValue, setShowCrossMark, setSearchNow]
  )

  return (
    <CloudSelectorV2
      cloudTypes={customCloudOptions || _cloudTypes}
      selectedCloud={cloudMarketplace}
      label="Cloud"
      onChange={handleCloudChange}
      minWidth={171}
      size={size || 'small'}
    />
  )
}
