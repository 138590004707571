import { DateTime } from 'luxon'
import { DataType } from '../../modules/onboarding/ruleDefinitions/reducer'
//test

export const validate = (mappedDataType: DataType, defaultValue: string) => {
  switch (mappedDataType) {
    case DataType.date:
    case DataType.datetime: {
      const date = DateTime.fromFormat(defaultValue || '', 'yyyy-MM-dd')
      if (
        !/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(defaultValue)
      ) {
        return {
          error: true,
          errMess: 'Value should be of format YYYY-MM-DD. Eg. 2016-11-02',
        }
      } else if (!date.isValid) {
        return { error: true, errMess: 'Not a valid date.' }
      } else return { error: false, errMess: '' }
    }
    case DataType.integer: {
      if (isNaN(Number(defaultValue))) {
        return { error: true, errMess: 'Value should be of type integer.' }
      } else {
        return { error: false, errMess: '' }
      }
    }
    case DataType.float: {
      if (isNaN(Number(defaultValue))) {
        return { error: true, errMess: 'Value should be of type float.' }
      } else {
        return { error: false, errMess: '' }
      }
    }
    case DataType.bool: {
      const validBoolStrings = ['true', 'false']
      if (!validBoolStrings.some(str => defaultValue.toLowerCase() === str)) {
        return { error: true, errMess: 'Value should be of type boolean.' }
      } else {
        return { error: false, errMess: '' }
      }
    }
    default:
      return { error: false, errMess: '' }
  }
}

export const checkEmailAndPhone = (type: string, value: string) => {
  switch (type) {
    case 'email': {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
        ? { error: false, errMess: '' }
        : { error: true, errMess: 'Should be of type email.' }
    }
    case 'text': {
      return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
        value
      )
        ? { error: false, errMess: '' }
        : { error: true, errMess: 'Should be of type phone number.' }
    }
    default:
      return { error: false, errMess: '' }
  }
}

export const noStartingNumber = (value: string | undefined) => {
  if (value === undefined) {
    return false
  }
  return !/^[0-9]/.test(value)
}
