import { useEffect, useState } from 'react'
import {
  gethasAWSCustomCatalog,
  getPartner,
  PartnerData,
  PartnerType,
} from '../../modules/partner/action'
import { useDispatch, useSelector } from '../../../store'
import {
  getUserProfile,
  setUserProfile,
} from '../../modules/userProfile/action'
import { newrelicErrLogger } from '../ErrorHandler'
import { useFlagValue, useUpdateConfigFlags } from '@labrav/flags'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthProvider } from '../../../oppsync/modules/userList/action'
import { useTracker } from '../Tracker/hook'

export const usePartnerUser = (isExternal = false) => {
  const dispatch = useDispatch()
  const { user } = useAuth0()
  const { value: auth0Enabled } = useFlagValue('auth0')

  const { partnerData, partnerLoading, partnerIdFromExternalPage } =
    useSelector<{
      partnerData: PartnerData | undefined
      partnerLoading: boolean
      partnerIdFromExternalPage: string | undefined
    }>(state => ({
      partnerData: state.PartnerData.user.partnerData || undefined,
      partnerLoading: state.PartnerData.user.isLoading,
      partnerIdFromExternalPage:
        state.customForm.hubspotForm.customData?.referralMetadata?.partner_id,
    }))
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const { setConfig } = useUpdateConfigFlags()
  const tracker = useTracker()
  useEffect(() => {
    const setUp = async () => {
      if (user?.partner_id && !isExternal) {
        try {
          dispatch(getPartner(user.partner_id as string, PartnerType.User))
          dispatch(getUserProfile(user.partner_id, user.partner_user_id))
          tracker?.identify(user.sub || '', {
            partner_id: user.partner_id,
            roles: user.auth0_roles,
          })
          tracker?.group(user.partner_id)
          setConfig({
            user: {
              userId: user.sub,
              partnerId: user.partner_id as string,
            },
          })
        } catch (err: any) {
          newrelicErrLogger(err)
          setError(err)
        } finally {
          setLoading(false)
        }
      } else if (partnerIdFromExternalPage && isExternal) {
        setConfig({
          user: {
            partnerId: partnerIdFromExternalPage as string,
          },
        })
      }
    }

    setUp()
  }, [auth0Enabled, partnerIdFromExternalPage])

  return { loading, error, partnerData }
}
